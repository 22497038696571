import { createTarget } from '../../../lib/util/repository';
import sanitizeNamePart from '../../../lib/util/sanitizeNamePart';
import { getTablesWithMetadata } from '../../../services/tableService';

import {
  CONTENT_TYPE_NAME_MAX_LENGTH,
  SERVICE_NAME_MAX_LENGTH,
  TABLE_NAME_MIN_LENGTH,
  TABLE_DESCRIPTION_MIN_LENGTH,
  TABLE_DESCRIPTION_MAX_LENGTH
} from '../../../constants/defaultValues';

const maxLengthTableName =
  CONTENT_TYPE_NAME_MAX_LENGTH + SERVICE_NAME_MAX_LENGTH;

let error = null;

// Call setResultWizard from props means validation is passed, otherwise it failes
// Use error to call _notifyError to show a Toast
const validation = async props => {
  const { core, selectedTableGroup, setIsLoading, setResultWizard } = props;
  const { newTableName, tableDescription } = core;

  if (!selectedTableGroup) {
    error = {
      title: 'Error in the Target Definition Step',
      message: 'Table Group missing or wrong'
    };
    _notifyError(props);
    return;
  }

  const serviceName = selectedTableGroup.name;

  error = null;

  if (!serviceName || serviceName === '') {
    error = {
      title: 'Error in the Target Definition Step',
      message: 'Table Group missing or wrong'
    };
    _notifyError(props);
    return;
  }
  setIsLoading(true);

  // Service name pattern rules:
  // - has to start with a segment containing one or more letters
  // - can be followed by segments containing letters or digits.
  // - subsequent segments are separated by either a space or an underscore
  const serviceNamePattern = /^[a-zA-Z]+([_ ]?[A-Za-z0-9])*$/;
  const reservedServicePrefix = 'SYS_';

  if (!serviceNamePattern.test(serviceName)) {
    error = {
      title: 'Error in the Target Definition Step',
      message: 'Invalid Table Group Name'
    };
    _notifyError(props);
    return;
  }

  if (serviceName.startsWith(reservedServicePrefix)) {
    error = {
      title: 'Error in the Target Definition Step',
      message: `The Table Group Name cannot start with  '${reservedServicePrefix}'`
    };
    _notifyError(props);
    return;
  }

  const isTableNameExist = newTableName !== null && newTableName !== undefined;

  if (!isTableNameExist) {
    error = {
      title: 'Error in the Target Definition Step',
      message: 'Table name is missing or wrong'
    };
    _notifyError(props);
    return;
  } else {
    const isTableNameEmpty = newTableName === '';
    const isTableNameLengthGreaterThanMin =
      newTableName.length >= TABLE_NAME_MIN_LENGTH;

    if (isTableNameEmpty || !isTableNameLengthGreaterThanMin) {
      error = {
        title: 'Error in the Target Definition Step',
        message: `The name of a table must be at least ${TABLE_NAME_MIN_LENGTH} characters long.
      Please specify a different name.`
      };
      _notifyError(props);
      return;
    }
  }

  const isUniqueName = await checkIfTableNameIsUnique(props);
  if (isUniqueName) {
    error = {
      title: 'Error in the Target Definition Step',
      message: `A Table with the name "${newTableName}" already exist, Please specify a different name`
    };
    _notifyError(props);
    return;
  }

  // Content Type name pattern rules:
  // - has to start with a segment containing one or more letters
  // - can be followed by segments containing letters or digits.
  // - subsequent segments are separated by either a space or an underscore
  const contentTypeNamePattern = /^[a-zA-Z]+([_ ]?[A-Za-z0-9])*$/;

  if (!contentTypeNamePattern.test(newTableName)) {
    error = {
      title: 'Error in the Target Definition Step',
      message: 'Invalid Table Name'
    };
    _notifyError(props);
    return;
  }

  if (newTableName.length > CONTENT_TYPE_NAME_MAX_LENGTH) {
    error = {
      title: 'Error in the Target Definition Step',
      message:
        'The Table Length is greater than the maximum length allowed which is 28 characters'
    };
    _notifyError(props);
    return;
  }

  const lengthTableName = serviceName.length + newTableName.length;

  if (lengthTableName > maxLengthTableName) {
    error = {
      title: 'Error in the Target Definition Step',
      message: `The Table group name with the Table name are used for generating the physical table name. The sum of the Table Group name and the Table name Length is greater than the maximum length allowed which is ${maxLengthTableName} characters. Try making smaller the Table Group name or Table name`
    };
    _notifyError(props);
    return;
  }

  const {
    s3: {
      checked: isS3checked,
      s3BucketFields: { acl, region, uri }
    }
  } = props.formValues;

  if (isS3checked) {
    const s3BucketError = validateS3BucketUrl(isS3checked, acl, region, uri);

    if (s3BucketError) {
      error = {
        title: s3BucketError.title,
        message: s3BucketError.message
      };
      _notifyError(props);
      return;
    }
  }

  // Validate Description field length (10 - 255 symbols)

  if (!tableDescription) {
    error = {
      title: 'Error in the Target Definition Step',
      message: `Please enter a description for the Table`
    };
    _notifyError(props);
    return;
  }
  // Validate Description field length (10 - 255 symbols)
  if (tableDescription?.length < TABLE_DESCRIPTION_MIN_LENGTH) {
    error = {
      title: 'Error in the Target Definition Step',
      message: `The Description length is lower than minimum allowed which is ${TABLE_DESCRIPTION_MIN_LENGTH} characters.`
    };
    _notifyError(props);
    return;
  }

  if (tableDescription?.length > TABLE_DESCRIPTION_MAX_LENGTH) {
    error = {
      title: 'Error in the Target Definition Step',
      message: `The Description length is greater than the maximum length allowed which is  ${TABLE_DESCRIPTION_MAX_LENGTH} characters.`
    };
    _notifyError(props);
    return;
  }

  if (error === null) {
    const result = await createTarget(props);

    setResultWizard(result);
  }
};

const _notifyError = props => {
  const { wizardStepStatusSet, addNotification, setIsLoading } = props;
  wizardStepStatusSet('error');
  const notification = {
    isModal: true,
    title: error.title,
    message: error.message
  };
  setIsLoading(false);
  addNotification(notification);
};

const checkIfTableNameIsUnique = async props => {
  const { core, selectedTableGroup } = props;
  const { newTableName } = core;
  const inputtedTableName = sanitizeNamePart(newTableName);
  const tables = await getTablesWithMetadata(selectedTableGroup?.name);

  const tableNames = tables.map(table => table.name);
  return tableNames.includes(inputtedTableName);
};

export const validateS3BucketUrl = (
  isS3ReplicationTargetChecked,
  amazonBucketAcl,
  amazonBucketRegion,
  amazonBucketUri
) => {
  let error = null;

  /**
   * see: https://docs.aws.amazon.com/AmazonS3/latest/dev/BucketRestrictions.html
   *
   * The following are the rules for naming S3 buckets in all AWS Regions:
   * - Bucket names must be unique across all existing bucket names in Amazon S3.
   * - Bucket names must comply with DNS naming conventions.
   * - Bucket names must be at least 3 and no more than 63 characters long.
   * - Bucket names must not contain uppercase characters or underscores.
   * - Bucket names must start with a lowercase letter or number.
   * - Bucket names must be a series of one or more labels.
   * - Adjacent labels are separated by a single period (.).
   * - Bucket names can contain lowercase letters, numbers, and hyphens.
   * - Each label must start and end with a lowercase letter or a number.
   * - Bucket names must not be formatted as an IP address (for example, 192.168.5.4).
   *
   */
  const s3BucketUrlPattern =
    /(?=^s3:\/\/.{3,128}$)(?!^(s3:\/\/\d+(\.\d+)*(\/[^/]+)*\/?)$)(s3:\/\/([a-z0-9]([a-z0-9-]*[a-z0-9])?)(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*)(\/[^/]+)*\/?$/;

  if (!amazonBucketRegion) {
    error = {
      title: 'Error in the Target Definition Step',
      message:
        'A Custom Bucket is set up as Amazon S3 Sync target, therefore an Amazon Region must be specified'
    };
  } else if (!amazonBucketAcl) {
    error = {
      title: 'Error in the Target Definition Step',
      message: 'An ACL must be specified'
    };
  } else if (!amazonBucketUri) {
    error = {
      title: 'Error in the Target Definition Step',
      message:
        'A Custom Bucket is set up as Amazon S3 Sync target, therefore a Custom URI must be specified'
    };
  } else if (!s3BucketUrlPattern.test(amazonBucketUri)) {
    error = {
      title: 'Error in the Target Definition Step',
      message: 'Invalid Custom Amazon S3 Bucket URI'
    };
  }

  return error;
};

export default validation;
