import React from 'react';
import {
  Steps,
  WizardResult,
  WizardFooter,
  NotificationWrapper
} from '../index';
import './wizard.css';

const Wizard = props => {
  const { core, wizardSteps } = props;
  const { currentStep } = core;

  const handleNext = () => {
    if (wizardSteps[currentStep].beforeNextCallback) {
      wizardSteps[currentStep].beforeNextCallback(
        wizardSteps[currentStep].validation
      );
    } else {
      wizardSteps[currentStep].validation();
    }
  };

  const handleBack = () => {
    const { wizardStepGotoIndex } = props;

    wizardStepGotoIndex(currentStep - 1);
  };

  const handleReset = () => {
    const { resetWizard } = props;
    resetWizard();
  };

  const handleCancel = () => {
    const { cancelWizard } = props;
    cancelWizard();
  };

  const onStepChange = step => {
    const { wizardStepGotoIndex } = props;

    if (step > currentStep) {
      props.wizardSteps[currentStep].validation();
    } else {
      wizardStepGotoIndex(step);
    }
  };

  if (props.core.currentStatus === 'completed') {
    return (
      <WizardResult
        key-="result-content-definer-wizard"
        title={
          props.resultStatus === 'success'
            ? 'Successfully Created!'
            : 'Error processing!'
        }
        selectedTableId={props.selectedTableId}
        selectedTableGroupId={props.selectedTableGroupId}
        timeLineItems={props.timeLineItems}
        status={props.status}
        acceptCallback={handleReset}
        finishCallback={handleCancel}
        acceptButtonTitle={props.acceptButtonTitle}
        path={props.path}
      />
    );
  }
  return (
    <NotificationWrapper {...props} className="upload-wizard">
      <Steps
        current={props.core.currentStep}
        onChange={step => onStepChange(step)}
        status={props.core.currentStatus}
      >
        {props.wizardSteps.map(step => {
          return (
            <Steps.Step
              key={step.title}
              title={step.title}
              description={step.description}
            />
          );
        })}
      </Steps>
      {props.wizardSteps[props.core.currentStep].component}
      <WizardFooter
        path={props.path}
        steps={props.wizardSteps}
        activeStep={props.core.currentStep}
        handleBack={handleBack}
        handleReset={handleReset}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </NotificationWrapper>
  );
};

export default Wizard;
