import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  TextInput,
  FormSection,
  NotificationWrapper,
  ContentSection,
  FormToolbar,
  LoadingScreen
} from '../../components/index';
import tableGroupLogo from '../../assets/img/database_2.svg';

const getTableGroupNameFromInput = value =>
  value.toString().trim().replace(/\s/g, '_').toUpperCase();

const FormTableGroup = withRouter(props => {
  const [tableGroup, setTableGroup] = React.useState({
    name: '',
    description: ''
  });
  const [isBusy, setIsBusy] = React.useState(false);
  const [errors, setErrors] = React.useState({
    name: { hasError: false, error: '' },
    description: { hasError: false, error: '' }
  });

  React.useEffect(() => {
    const fetchData = async () => {
      if (props.tableGroups === null) {
        await props.getTableGroups();
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveRecordClick = () => {
    const confirmation = {
      acceptCallback: () => {
        onAcceptClick();
      },
      cancelCallback: () => {
        props.removeConfirmation();
      }
    };
    props.setOnSaveRecordConfirmation(confirmation);
  };

  const validateTableGroup = () => {
    let isValid = true;
    let name = { hasError: false, error: '' };
    let description = { hasError: false, error: '' };

    if (
      tableGroup.name.trim().length < 3 ||
      tableGroup.name.trim().length > 50
    ) {
      name = {
        hasError: true,
        error: 'Name must be between 3 and 50 characters long'
      };
      isValid = false;
    } else {
      // Table Group name pattern rules:
      // - has to start with a segment containing one or more letters
      // - can be followed by segments containing letters or digits.
      // - subsequent segments are separated by either a space or an underscore
      // - cannot start with the reserved prefix SYS_
      const tableGroupNamePattern = /^[a-zA-Z]+([_]?[A-Za-z0-9])*$/;
      const reservedPrefix = 'SYS_';

      if (!tableGroupNamePattern.test(tableGroup.name)) {
        name = {
          hasError: true,
          error: 'Invalid Table Group Name'
        };
      } else if (tableGroup.name.startsWith(reservedPrefix)) {
        name = {
          hasError: true,
          error: `The Table Group Name cannot start with '${reservedPrefix}'`
        };
      }
    }

    if (
      tableGroup.description.trim().length < 10 ||
      tableGroup.description.trim().length > 200
    ) {
      description = {
        hasError: true,
        error: 'Description must be between 10 and 200 characters long'
      };
      isValid = false;
    }

    setErrors({
      name: name,
      description: description
    });

    return isValid;
  };

  const onAcceptClick = async () => {
    if (props.getConfirmation().id === 'form-save') {
      const isValid = validateTableGroup();

      if (isValid === true) {
        const existingTableGroup = props.tableGroups.filter(
          tableGr => tableGr.name === tableGroup.name
        );
        if (existingTableGroup && existingTableGroup.length > 0) {
          props.removeConfirmation();
          props.addNotification({
            isModal: true,
            title: 'Error creating Table Group',
            message:
              'A Table Group with the same name already exists. Please use a different name.'
          });
        } else {
          setIsBusy(true);
          await props.createTableGroup({
            name: tableGroup.name,
            description: tableGroup.description
          });
          props.history.push('/tablegroup');
        }
      } else {
        props.removeConfirmation();
        props.addNotification({
          isModal: true,
          title: 'Error creating Table Group',
          message:
            'At least one error has been found, please check the highlighted fields.'
        });
      }
    }
  };

  return (
    <NotificationWrapper {...props}>
      <LoadingScreen isLoading={isBusy} />
      <div className="toopbar-title-no-navigable">Creation Mode</div>
      <ContentSection
        small
        colorfullTitle
        logo={tableGroupLogo}
        asideRight={
          <FormToolbar
            data-test="toolbar"
            onNewRecordClick={() => props.createNewTableGroup()}
            onSaveRecordClick={() => onSaveRecordClick()}
            isEditing={false}
            isNew={true}
            showNavigationButtons={false}
            showLock={false}
            redirectWhenCancel={true}
            redirectCancelPath={'/tablegroup'}
          />
        }
      />
      <FormSection className="formGeneralSection" title="Add Table Group">
        <div className="target-medium-size-component">
          <TextInput
            key="input-name"
            data-test="name"
            full
            value={tableGroup.name}
            onChange={event => {
              const tableGroupName = getTableGroupNameFromInput(
                event.target.value
              );

              setTableGroup({
                ...tableGroup,
                name: tableGroupName
              });
            }}
            required
            label="Name"
            border={false}
            hasErrors={errors.name.hasError}
            errorMessage={errors.name.error}
            maxLength={50}
          />
        </div>
        <div className="target-medium-size-component">
          <TextInput
            data-test="description"
            key="input-desc"
            full
            value={tableGroup.description}
            onChange={event =>
              setTableGroup({ ...tableGroup, description: event.target.value })
            }
            required
            label="Description"
            border={false}
            hasErrors={errors.description.hasError}
            errorMessage={errors.description.error}
            maxLength={200}
          />
        </div>
      </FormSection>
    </NotificationWrapper>
  );
});

export default FormTableGroup;
