const tweakJsDate = date => {
  const isISOString =
    /^(?<year>\d{4})[-/._ ](?<month>\d{2})[-/._ ](?<day>\d{2})(?<time>[Tt ](?<hours>\d{2}):(?<minutes>\d{2}):(?<seconds>\d{2})[.Zz ]?(?<miliseconds>\d{3}[zZ ]?)?)?$/.test(
      date
    );

  if (isISOString === false) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}.000Z`;
  } else return date;
};

export const convertValueListToIsoDates = (valueList, datatype) => {
  const numericDates = valueList.split(';');
  const isoDates = numericDates.map(ele => {
    const date = new Date(parseInt(ele, 10));
    return datatype.toUpperCase() === 'DATE'
      ? tweakJsDate(date).substr(0, 10)
      : date.toISOString();
  });
  return isoDates.join(';');
};

export default tweakJsDate;
