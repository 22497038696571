import {
  GET_TABLE_GROUPS_SUCCESS,
  SET_SELECTED_TABLE_GROUP
} from './actionTypes';
import * as tableGroupService from '../services/tableGroupService';
import {
  getTables,
  resetSelectedTable,
  resetTableLayout
} from './tableGroupDetails';
import {
  addNotification,
  setIsLoading,
  addConfirmation,
  removeConfirmation
} from './core';
import { getPrivileges } from './privileges';
import { I18n } from 'react-redux-i18n';

export const getTableGroups =
  (strFilter = '') =>
  async dispatch => {
    try {
      dispatch(resetSelectedTable());
      const tableGroupsData =
        (await tableGroupService.fetchTableGroups(strFilter, '&sort=name')) ||
        [];
      dispatch(getTableGroupsSuccess(tableGroupsData));
    } catch (err) {
      dispatch(
        addNotification({
          isModal: true,
          title: 'Error fetching table groups',
          message: err
        })
      );
    }
  };

export const createTableGroup = tableGroup => async dispatch => {
  try {
    await dispatch(removeConfirmation());
    await tableGroupService.createTableGroup(tableGroup);
  } catch (err) {
    dispatch(setIsLoading(false));
    dispatch(
      addNotification({
        isModal: true,
        title: 'Error creating the Table Group',
        message: err
      })
    );
  }
};

// Set the Notification for the succeeded creation
export const setCreateSuccessNotification = () => ({
  notType: 'success',
  isModal: false,
  isToast: true,
  title: I18n.t('tableGroupLayout.notification.createSuccededTitle'),
  message: I18n.t('tableGroupLayout.notification.createSuccededMessage')
});

// Set the Confirmation for the Save
export const setOnSaveRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'form-save',
      question: I18n.t('tableGroupLayout.confirmation.saveNewRecordQuestion'),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};

export const setSelectedTableGroup =
  newSelectedTableGroupName => async (dispatch, getState) => {
    const selectedTableGroup = getState().tableGroupLayout.selectedTableGroup;
    const newSelectedTableGroup =
      getState().tableGroupLayout.tableGroups.filter(ele => {
        return (
          ele.name.toUpperCase() === newSelectedTableGroupName.toUpperCase()
        );
      })[0];

    if (
      selectedTableGroup?.name.toUpperCase() !==
      newSelectedTableGroupName.toUpperCase()
    ) {
      const action = { type: SET_SELECTED_TABLE_GROUP, newSelectedTableGroup };
      dispatch(action);
    }

    await dispatch(getTables(newSelectedTableGroup));
    // To display the statistic minicard with the number of privileges
    await dispatch(getPrivileges('TABLE_GROUP'));
  };

export const resetSelectedTableGroup = () => dispatch => {
  const newSelectedTable = null;
  const action = { type: SET_SELECTED_TABLE_GROUP, newSelectedTable };
  dispatch(action);
  dispatch(resetTableLayout());
};

export const getTableGroupsSuccess = tableGroupsData => ({
  type: GET_TABLE_GROUPS_SUCCESS,
  tableGroupsData
});
