import { REACT_APP_ENV } from '../../constants/environment';

const getGemSinkName = (tableGroupName, tableName, type) => {
  const baseName = `UMD_${tableGroupName}__${tableName}_`;
  const suffix =
    REACT_APP_ENV.toLowerCase() === 'local'
      ? 'qa'
      : REACT_APP_ENV.toLowerCase();
  if (type === 'S3') {
    return `${baseName}S3_CUSTOM_${suffix}`;
  } else {
    return `${baseName}SF_DEFAULT_${suffix}`;
  }
};

export default getGemSinkName;
