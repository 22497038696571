import * as actionName from '../actions/wizards';
import {
  generatedColumnsConfiguration,
  autoKeyColumnConfiguration,
  TABLE_CREATION_MODES
} from '../constants/defaultValues';

const wizards = (state, action) => {
  const defaultState = {
    source: {
      filename: null,
      filetype: null,
      fileSize: null,
      fileContents: {
        blob: null
      },
      filetypeOptions: null,
      data: {
        contents: [],
        meta: {
          columns: [],
          columnsDefinition: [],
          generatedColumns: generatedColumnsConfiguration,
          addGeneratedKeyColumn: false
        }
      },
      csvParserOptions: {
        delimiter: null,
        dateFormat: null
      }
    },
    mapping: {
      columnMapping: {},
      mappedData: null
    },
    validation: {},
    core: {
      sendBatches: null,
      chunckSize: null,
      cellsCount: null,
      rowsCount: null,
      cellLimit: null,
      batchesNumber: null,
      currentStep: 0,
      currentStatus: 'process',
      tableCreationMode: TABLE_CREATION_MODES.MANUAL,
      dataloadingMode: 'update',
      successResponses: [],
      errorResponses: [],
      definitionErrors: [],
      replicationTargets: [
        {
          description: 'Replicates this Content Type to Snowflake',
          label: 'Snowflake',
          name: 'SNOWFLAKE',
          checked: false
        },
        {
          description: 'Replicates this Content Type to Amazon S3',
          label: 'Amazon S3',
          name: 'S3',
          checked: false
        }
      ],
      customTargetOption: '',
      amazonBucketUri: '',
      amazonBucketRegion: '',
      amazonBucketAcl: {
        value: 'bucket-owner-full-control',
        label: 'bucket-owner-full-control'
      },
      result: []
    },
    isProgressVisible: false
  };
  state = state || defaultState;
  let target = null;
  let targetColumns = null;
  let source = null;
  let data = null;
  let sourceColumns = null;
  let mapping = null;
  let meta = null;
  let columnsDefinition = null;
  let contents = null;
  let core = null;
  let previousIndex;
  let previousOrder;
  let currentIndex;
  let currentOrder;
  let nextIndex;
  let nextOrder;
  let i;
  let newGeneratedColumns;

  switch (action.type) {
    /**
     * Set the result
     */
    case actionName.setResult.type:
      return {
        ...state,
        core: {
          ...state.core,
          result: action.payload
        }
      };

    /**
     * Set Table Description
     */
    case actionName.setTableDescription.type:
      return {
        ...state,
        core: {
          ...state.core,
          tableDescription: action.payload
        }
      };

    /**
     * Wizard Related Reducers
     */
    case actionName.wizardStepGotoNext.type:
      return {
        ...state,
        core: {
          ...state.core,
          currentStep: state.core.currentStep + 1,
          currentStatus: 'process'
        }
      };

    case actionName.wizardStepGotoIndex.type:
      return {
        ...state,
        core: {
          ...state.core,
          currentStep: action.payload,
          currentStatus: 'process'
        }
      };

    case actionName.wizardStepStatusSet.type:
      return {
        ...state,
        core: {
          ...state.core,
          currentStatus: action.payload
        }
      };

    case actionName.setDataLoadingMode.type:
      return {
        ...state,
        core: {
          ...state.core,
          dataloadingMode: action.payload
        }
      };

    case actionName.setCreationMode.type:
      return {
        ...state,
        core: {
          ...state.core,
          tableCreationMode: action.payload
        }
      };

    case actionName.setCsvParserOptions.type:
      return {
        ...state,
        source: {
          ...state.source,
          csvParserOptions: {
            ...state.source.csvParserOptions,
            ...action.payload
          }
        }
      };

    case actionName.finalizeWizard.type:
      return {
        ...state,
        core: {
          ...state.core,
          currentStatus: 'completed',
          successResponses: action.payload.successResponses,
          errorResponses: action.payload.errorResponses
        }
      };

    /**
     * Source Related Reducers
     */
    case actionName.sourceFileSet.type:
      return {
        ...state,
        source: {
          ...state.source,
          filename: action.payload.filename,
          filetype: action.payload.filetype,
          fileSize: action.payload.fileSize,
          fileContents: {
            ...state.source.fileContents,
            blob: action.payload.fileContents
          }
        }
      };

    // @todo UMD-1509 we are deleting the data in a store in that action
    case actionName.sourceDataSet.type: {
      return {
        ...state,
        source: {
          ...state.source,
          ...action.payload
        },
        mapping: {
          columnMapping: {},
          mappedData: null
        },
        validation: {}
      };
    }

    case actionName.setSourceMetaData.type: {
      return {
        ...state,
        source: {
          ...state.source,
          data: {
            ...state.source.data,
            meta: {
              ...state.source.data.meta,
              ...action.payload
            }
          }
        }
      };
    }

    case actionName.setSourceFile.type: {
      return {
        ...state,
        source: {
          ...state.source,
          ...action.payload
        }
      };
    }

    case actionName.resetWizard.type: {
      return {
        ...defaultState
      };
    }

    case actionName.sourceExcelSheetSelect.type:
      // set the source data via a lookup of the options.sheets using the the selected sheet
      source = { ...state.source };
      source.type.options.selectedSheetName = action.payload.value;
      source.type.options.selectedSheetItem = action.payload;
      source.data.contents =
        source.type.options.sheets[action.payload.value].data;
      source.data.meta.columns =
        source.type.options.sheets[action.payload.value].columns;

      // set the 1:1 mapping based on sourceColumnName === targetColumnName
      target = { ...state.target };
      targetColumns = target.data.meta.columns.map(col => col.name);
      sourceColumns = source.data.meta.columns.map(col => col.name);
      mapping = {
        columnMapping: {},
        mappedData: null
      };

      for (let x = 0; x < sourceColumns.length; x++) {
        const targetColumnIndex = targetColumns.indexOf(
          sourceColumns[x].trim()
        );
        const targetColConfig = target.data.meta.columns[targetColumnIndex];
        if (
          targetColumnIndex > -1 &&
          (!targetColConfig.valueGeneratorMoment ||
            targetColConfig.valueGeneratorMoment !== 'ALWAYS')
        ) {
          mapping.columnMapping[targetColumns[targetColumnIndex]] =
            sourceColumns[x];
        }
      }

      return {
        ...state,
        source,
        mapping
      };

    /**
     * Mapping related actions
     */
    case actionName.mappingAllSet.type:
      return {
        ...state,
        mapping: action.payload
      };

    case actionName.mappingMapSet.type:
      mapping = { ...state.mapping };
      if (action.payload.sourceColumn === '__UNDEFINED__') {
        delete mapping.columnMapping[action.payload.targetColumn];
      } else {
        mapping.columnMapping[action.payload.targetColumn] =
          action.payload.sourceColumn;
      }
      return {
        ...state,
        mapping
      };

    case actionName.mappingMappedDataSet.type:
      mapping = { ...state.mapping };
      mapping.mappedData = action.payload;
      return {
        ...state,
        mapping
      };

    /**
     * Data Validation related actions
     */
    case actionName.validateTransformedResultSet.type:
      return {
        ...state,
        validation: action.payload.validation,
        core: {
          ...state.core,
          sendBatches: action.payload.sendBatches,
          chunckSize: action.payload.chunckSize,
          cellsCount: action.payload.cellsCount,
          rowsCount: action.payload.rowsCount,
          cellLimit: action.payload.cellLimit,
          batchesNumber: action.payload.batchesNumber
        }
      };

    /**
     * Update Cell's Column Specification
     */
    case actionName.updateColumSpec.type:
      source = { ...state.source };
      data = { ...source.data };
      meta = source.data.meta;
      columnsDefinition = meta.columnsDefinition;
      columnsDefinition[action.payload.cellInfo.index][
        action.payload.cellInfo.column.id
      ] = action.payload.value;

      // @todo pass the data to the store only one time when we change the step
      return {
        ...state,
        source: {
          ...source,
          data: {
            ...data,
            meta: {
              ...meta,
              columnsDefinition: meta.columnsDefinition.map((property, index) =>
                index === action.payload.cellInfo.index
                  ? {
                      ...property,
                      [action.payload.cellInfo.column.id]: action.payload.value
                    }
                  : property
              )
            }
          }
        }
      };

    /**
     * Update Column Specifications, The entire Row
     */
    case actionName.setDatatypeColumSpec.type:
      source = { ...state.source };
      meta = source.data.meta;
      columnsDefinition = meta.columnsDefinition;
      columnsDefinition[action.payload.cellInfo.index] =
        action.payload.newColumnsDefinition;
      return {
        ...state,
        source: {
          ...source,
          data: {
            ...data,
            meta: { ...meta, columnsDefinition: columnsDefinition }
          }
        }
      };

    /**
     * Set SelectedService
     */
    case actionName.setSelectedService.type:
      return {
        ...state,
        core: {
          ...state.core,
          selectedService: action.payload
        }
      };

    /**
     * Set SelectedService
     */
    case actionName.setNewTableName.type:
      return {
        ...state,
        core: {
          ...state.core,
          newTableName: action.payload
        }
      };

    /**
     * Set the errors in the Column Specifications
     */
    case actionName.setDefinitionErrors.type:
      core = { ...state.core };
      return {
        ...state,
        core: { ...core, definitionErrors: action.payload }
      };

    /**
     * Set the Row's order for the Columns Specifications (Next)
     */
    case actionName.setNextColumnSpec.type:
      source = { ...state.source };
      meta = source.data.meta;
      contents = source.data.contents;
      columnsDefinition = meta.columnsDefinition;
      currentOrder = action.payload;
      nextOrder = currentOrder + 1;
      currentIndex = columnsDefinition.findIndex(
        column => column.displayOrder === currentOrder
      );
      nextIndex = columnsDefinition.findIndex(
        column => column.displayOrder === nextOrder
      );
      columnsDefinition[currentIndex].displayOrder = nextOrder;
      columnsDefinition[nextIndex].displayOrder = currentOrder;
      columnsDefinition.sort((a, b) =>
        a.displayOrder > b.displayOrder ? 1 : -1
      );
      return {
        ...state,
        source: {
          ...source,
          data: {
            ...data,
            contents: contents,
            meta: { ...meta, columnsDefinition: columnsDefinition }
          }
        }
      };

    /**
     * Set the Row's order for the Columns Specifications (Previous)
     */
    case actionName.setPreviousColumnSpec.type:
      currentOrder = action.payload;
      source = { ...state.source };
      meta = source.data.meta;
      contents = source.data.contents;
      columnsDefinition = meta.columnsDefinition;
      previousOrder = currentOrder - 1;
      currentIndex = columnsDefinition.findIndex(
        column => column.displayOrder === currentOrder
      );
      previousIndex = columnsDefinition.findIndex(
        column => column.displayOrder === previousOrder
      );
      columnsDefinition[currentIndex].displayOrder = previousOrder;
      columnsDefinition[previousIndex].displayOrder = currentOrder;
      columnsDefinition.sort((a, b) =>
        a.displayOrder > b.displayOrder ? 1 : -1
      );
      return {
        ...state,
        source: {
          ...source,
          data: {
            ...data,
            contents: contents,
            meta: { ...meta, columnsDefinition: columnsDefinition }
          }
        }
      };

    /**
     * Delete a Column Specification
     */
    case actionName.deleteColumnSpec.type:
      currentOrder = action.payload;
      source = { ...state.source };
      meta = source.data.meta;
      columnsDefinition = meta.columnsDefinition;
      currentIndex = columnsDefinition.findIndex(
        column => column.displayOrder === currentOrder
      );
      columnsDefinition = columnsDefinition.filter(
        column => column.displayOrder !== currentOrder
      );
      i = currentIndex;
      for (i; i < columnsDefinition.length; i++) {
        columnsDefinition[i].displayOrder = i + 1;
      }
      columnsDefinition.sort((a, b) =>
        a.displayOrder > b.displayOrder ? 1 : -1
      );
      return {
        ...state,
        source: {
          ...source,
          data: {
            ...data,
            meta: { ...meta, columnsDefinition: columnsDefinition }
          }
        }
      };

    /**
     * Add a new Column Specification
     */
    case actionName.addColumnSpec.type:
      source = { ...state.source };
      meta = source.data.meta;
      contents = source.data.contents;
      columnsDefinition = meta.columnsDefinition;
      columnsDefinition.push(action.payload);
      return {
        ...state,
        source: {
          ...source,
          data: {
            ...data,
            contents: contents,
            meta: { ...meta, columnsDefinition: columnsDefinition }
          }
        }
      };

    /**
     * Set Column Specifications
     */
    case actionName.setColumSpec.type:
      source = { ...state.source };
      meta = source.data.meta;
      contents = source.data.contents;
      return {
        ...state,
        source: {
          ...source,
          data: {
            ...data,
            contents: contents,
            meta: { ...meta, columnsDefinition: action.payload }
          }
        }
      };

    /**
     * Set Add Generated Key Column  flag
     */
    case actionName.setAddGeneratedKeyColumn.type:
      source = { ...state.source };
      meta = source.data.meta;
      contents = source.data.contents;

      newGeneratedColumns = [...generatedColumnsConfiguration];
      if (action.payload === true)
        newGeneratedColumns.push(autoKeyColumnConfiguration);

      return {
        ...state,
        source: {
          ...source,
          data: {
            ...data,
            contents: contents,
            meta: {
              ...meta,
              addGeneratedKeyColumn: action.payload,
              generatedColumns: newGeneratedColumns
            }
          }
        }
      };
    default:
      return state;
  }
};

export default wizards;
