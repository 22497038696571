import _ from 'lodash';
import {
  SET_SELECTED_PRIVILEGE,
  GET_USERS_SUCCESS,
  GET_TABLE_GROUP_PRIV_SUCCESS,
  GET_USER_TABLE_GROUP_PRIV_SUCCESS,
  GET_TABLE_PRIV_SUCCESS,
  GET_USER_TABLE_PRIV_SUCCESS
} from './actionTypes';
import { addNotification, addConfirmation, removeConfirmation } from './core';
import * as privilegeService from '../services/privilegeService';
import { I18n } from 'react-redux-i18n';

export const getPrivileges =
  (level = 'TABLE_GROUP') =>
  async (dispatch, getState) => {
    const tableGroup = getState().tableGroupLayout.selectedTableGroup;
    const table = getState().tableGroupDetails.selectedTable;
    try {
      const privileges =
        (await privilegeService.fetchPrivileges(tableGroup, table)) || [];
      if (level === 'TABLE_GROUP') {
        await dispatch(getTableGroupPrivilegesSuccess(privileges));
      } else {
        await dispatch(getTablePrivilegesSuccess(privileges));
      }
    } catch (err) {
      dispatch(
        addNotification({
          isModal: true,
          title: 'Error fetching the Privileges',
          message: err.message
        })
      );
    }
  };

export const getUsers = strFilter => async dispatch => {
  try {
    const users = (await privilegeService.fetchUsers(strFilter)) || [];
    dispatch(getUsersSuccess(users));
  } catch (err) {
    dispatch(
      addNotification({
        isModal: true,
        title: 'Error fetching the Users',
        message: err
      })
    );
  }
};

export const processCreatePrivilege = async (
  privilege,
  dispatch,
  tableGroup,
  table
) => {
  try {
    const response = await privilegeService.upsertPrivilege(
      privilege,
      tableGroup,
      table
    );

    if (response.status === 201)
      dispatch(addNotification(setCreateSuccessNotification()));

    if (response.status === 204)
      dispatch(addNotification(setUpdateSuccessNotification()));
  } catch (err) {
    // @todo add error handler
  }
};

export const setSelectedPrivilege =
  (newSelectedPrivilegeId, level) => (dispatch, getState) => {
    const selectedPrivilege = getState().privileges.selectedPrivilege;
    const newSelectedPrivilege =
      level === 'TABLE_GROUP'
        ? getState().privileges.tableGroupPrivileges.filter(privilege => {
            return (
              privilege?.userIdentifier?.toLowerCase() ===
              newSelectedPrivilegeId.toLowerCase()
            );
          })[0]
        : getState().privileges.tablePrivileges.filter(privilege => {
            return (
              privilege?.userIdentifier?.toLowerCase() ===
              newSelectedPrivilegeId.toLowerCase()
            );
          })[0];

    if (_.isEqual(newSelectedPrivilege, selectedPrivilege)) return;

    const action = { type: SET_SELECTED_PRIVILEGE, newSelectedPrivilege };
    dispatch(action);
  };

export const resetTableGroupUsersLayout = () => dispatch => {
  const newSelectedPrivilege = null;
  const action = { type: SET_SELECTED_PRIVILEGE, newSelectedPrivilege };
  dispatch(action);
  dispatch(getUsersSuccess([]));
};

export const resetSelectedPrivilege = () => dispatch => {
  const newSelectedPrivilege = null;
  const action = { type: SET_SELECTED_PRIVILEGE, newSelectedPrivilege };
  dispatch(action);
};

export const createNewPrivilege = () => dispatch => {
  dispatch(resetSelectedPrivilege());
};

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  users
});

export const getTableGroupPrivilegesSuccess = tableGroupPrivileges => ({
  type: GET_TABLE_GROUP_PRIV_SUCCESS,
  tableGroupPrivileges
});

export const getTablePrivilegesSuccess = tablePrivileges => ({
  type: GET_TABLE_PRIV_SUCCESS,
  tablePrivileges
});

export const getUserTableGroupPrivilegesSuccess = userTableGroupPrivileges => ({
  type: GET_USER_TABLE_GROUP_PRIV_SUCCESS,
  userTableGroupPrivileges
});

export const getUserTablePrivilegesSuccess = userTablePrivileges => ({
  type: GET_USER_TABLE_PRIV_SUCCESS,
  userTablePrivileges
});

// Set the Notification for the succeeded creation
export const setCreateSuccessNotification = () => ({
  notType: 'success',
  isModal: false,
  isToast: true,
  title: I18n.t('tableGroupUsersLayout.notification.createSuccededTitle'),
  message: I18n.t('tableGroupUsersLayout.notification.createSuccededMessage')
});

// Set the Notification for the succeeded modification
export const setUpdateSuccessNotification = () => ({
  notType: 'success',
  isModal: false,
  isToast: true,
  title: I18n.t('tableGroupUsersLayout.notification.updateSuccededTitle'),
  message: I18n.t('tableGroupUsersLayout.notification.updateSuccededMessage')
});

// Set the Confirmation for the Save
export const setOnSaveRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'form-save',
      question: I18n.t('overviewForm.confirmation.saveNewRecordQuestion'),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};

// Set the Confirmation for the Delete
export const setOnDeleteRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'form-delete',
      question: I18n.t('overviewForm.confirmation.deleteRecordQuestion'),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};

// Accept confirmation when saving a record
export const savePrivilegeAction =
  ({ newPrivileges, level }) =>
  async (dispatch, getState) => {
    await dispatch(removeConfirmation());
    const tableGroup = getState().tableGroupLayout.selectedTableGroup;
    const table = getState().tableGroupDetails.selectedTable;

    const calls = newPrivileges.selectedUsers.map(newPriv => ({
      method: 'post',
      privilege: {
        email: newPriv,
        roles: [newPrivileges.selectedRole.value]
      }
    }));

    const promises = calls.map(async call => {
      const result = await processCreatePrivilege(
        call.privilege,
        dispatch,
        tableGroup,
        table
      );

      return Promise.resolve((res, rej) => {
        res(result);
      });
    });

    Promise.all(promises).then(async results => {
      await dispatch(getPrivileges(level));
    });
  };

// Accept confirmation when deleting a record
export const deletePrivilege = privilege => async (dispatch, getState) => {
  dispatch(removeConfirmation());
  const tableGroup = getState().tableGroupLayout.selectedTableGroup;
  const table = getState().tableGroupDetails.selectedTable;
  try {
    const response = await privilegeService.deletePrivilege(
      {
        email: privilege.userIdentifier,
        roles: [privilege.userRole]
      },
      tableGroup,
      table
    );
    if (response.status === 204) {
      const level = table ? 'TABLE' : 'TABLE_GROUP';
      await dispatch(getPrivileges(level));
    }
  } catch (err) {
    dispatch(
      addNotification({
        isModal: true,
        title: 'Error Deleting Privilege',
        message: err
      })
    );
  }
};
