import React, { Fragment } from 'react';
import { Spinner, Overlay } from '../index';

const LoadingScreen = props => {
  let shouldReturnComponent = false;
  const { isLoading, notification, confirmation } = props;

  if (!notification && !confirmation) {
    shouldReturnComponent = isLoading ? isLoading : false;
  } else {
    const { isNotification, isModal } = notification;
    const { isConfirmation } = confirmation;

    if (
      isLoading &&
      !isConfirmation &&
      !(isNotification === true && isModal === true)
    )
      shouldReturnComponent = true;
  }

  return (
    shouldReturnComponent === true && (
      <Fragment>
        <Overlay show={true} />
        <Spinner
          loadingText="Loading..."
          ellipsesOuterThinClassName="ellipses ellipses-outer--thin ellipses-outer--thin--fast"
          ellipsesOuterThickClassName="ellipses ellipses-outer--thick ellipses-outer--thick--fast"
        />
      </Fragment>
    )
  );
};
export default LoadingScreen;
