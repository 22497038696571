import _ from 'lodash';
import {
  SET_IS_OVERVIEW_FORM_NEW_RECORD,
  SET_IS_OVERVIEW_FORM_EDITING,
  ADD_OVERVIEW_FORM_ERRORS,
  REMOVE_OVERVIEW_FORM_ERRORS,
  RESET_OVERVIEW_FORM_ERRORS
} from '../actions/actionTypes';

export const initialState = {
  isOverviewFormNewRecord: false,
  isOverviewFormEditing: false,
  overviewFormErrors: []
};
const overviewForm = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case SET_IS_OVERVIEW_FORM_NEW_RECORD:
      return {
        ...state,
        isOverviewFormNewRecord: action.isOverviewFormNewRecord
      };
    case SET_IS_OVERVIEW_FORM_EDITING:
      return { ...state, isOverviewFormEditing: action.isOverviewFormEditing };
    case ADD_OVERVIEW_FORM_ERRORS:
      return { ...state, overviewFormErrors: action.error };
    case REMOVE_OVERVIEW_FORM_ERRORS:
      // eslint-disable-next-line no-case-declarations
      const newOverviewFormErrors = _.remove(
        state.overviewFormErrors,
        function (element) {
          return !_.isEqual(element, action.error);
        }
      );
      return { ...state, overviewFormErrors: [...newOverviewFormErrors] };
    case RESET_OVERVIEW_FORM_ERRORS:
      return { ...state, overviewFormErrors: [] };
    default:
      return state;
  }
};

export default overviewForm;
