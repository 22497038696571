import { getValueList } from '../util/getValueList';

export const deserializeProperties = columns => {
  const deserializedColumns = columns.map(column => {
    return {
      ...column,
      displayOrder: parseInt(column.displayOrder) / 10,
      valueList: getValueList(column.valueList, column.datatype)
    };
  });

  return deserializedColumns;
};
