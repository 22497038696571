export const getValueList = (valueList, datatype) => {
  if (
    valueList !== null &&
    (datatype.toUpperCase() === 'DATE' || datatype.toUpperCase() === 'DATETIME')
  ) {
    const valueListArray = valueList.split(';');
    const valueListArraySerialized = [];

    valueListArray.forEach(element => {
      const jsDate = new Date(element);
      if (jsDate instanceof Date && !isNaN(jsDate)) {
        const jsDateSerialized = jsDate.getTime();
        valueListArraySerialized.push(jsDateSerialized);
      }
    });
    return valueListArraySerialized.join(';');
  } else {
    return valueList;
  }
};
