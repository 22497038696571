import React, { useState, useEffect } from 'react';
import {
  CardsContainer,
  FormSection,
  ContentSection,
  ContentSectionSearch,
  Button,
  RouterLink,
  AsideLeftTableSection,
  AsideLeftTableGroupSection,
  CardDetails,
  LoadingScreen,
  ModalNotification,
  CardFooter,
  RepTargetToolbar
} from '../../components';
import tableGroupLogo from '../../assets/img/database_2.svg';
import repTargetLogo from '../../assets/img/replicationtarget.svg';
import tableLogo from '../../assets/img/database_1.svg';
import getRepTargetCard from '../../lib/util/getRepTargetCard';

import { endPoints } from '../../constants/endPoints';
import getGemSinkName from '../../lib/util/getGemSinkName';
const tealTheme = { primary: 'black', secondary: 'white' };

const ReplicationTargets = props => {
  const [state, setState] = useState({
    value: '',
    isBusy: true
  });

  const { selectedTable } = props;

  useEffect(() => {
    const fetchData = async () => {
      await props?.getTableGroups();
      await props?.setSelectedTableGroup(props?.match?.params?.tableGroupId);
      await props?.setSelectedTable(
        props?.match?.params?.tableGroupId,
        props?.match?.params?.tableId
      );
      await props?.loadReplicationTargets();

      setState(prev => ({ ...prev, isBusy: false }));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isBusy) return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props?.selectedTableGroup && state.isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props?.history.push('/tablegroup')}
      />
    );
  }

  // If no table has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (!selectedTable && state.isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props?.history.push(`/tablegroup/${props?.selectedTableGroup?.name}`)
        }
      />
    );
  }

  let canChangePrivileges = false;

  // eslint-disable-next-line default-case
  switch (selectedTable?.role) {
    case 'OWNER':
    case 'ADMIN':
    case 'EDITOR':
      canChangePrivileges = true;
      break;
  }

  const canChangeTargets =
    selectedTable?.role === 'OWNER' || selectedTable?.role === 'ADMIN';
  const isPrivilegeButtonVisible = canChangePrivileges === true;

  const hasBothReplicationTargets = props.replicationTargets.length === 2;
  const isTargetsButtonVisible =
    canChangeTargets === true && !hasBothReplicationTargets;

  const searchRepTargets = () =>
    props?.replicationTargets.filter(ele => {
      return ele.type.toUpperCase().includes(state.value.toUpperCase());
    });

  let replicationTargetsToDisplay = [];

  if (props?.replicationTargets?.length > 0) {
    if (state.value.trim() === '') {
      replicationTargetsToDisplay = props?.replicationTargets;
    } else {
      const filteredRepTargets = searchRepTargets();
      replicationTargetsToDisplay = filteredRepTargets;
    }
  }

  const cards =
    replicationTargetsToDisplay?.length > 0
      ? replicationTargetsToDisplay?.map(repTarget => ({
          title: repTarget.type,
          width: '432px',
          height: '220px',
          iconPath: repTargetLogo,
          children: (
            <>
              <CardDetails
                details={getRepTargetCard(
                  repTarget,
                  props?.selectedTableGroup?.name,
                  props?.selectedTable?.name
                )}
              />
              <CardFooter
                rightSection={
                  <>
                    {repTarget?.sink?.sink?.name &&
                      repTarget?.type === 'S3' && (
                        <RepTargetToolbar
                          uri={`${
                            endPoints.nsp.sinks
                          }?resource=${getGemSinkName(
                            repTarget.tableGroupName,
                            repTarget.tableName,
                            repTarget.type
                          )}`}
                          icon="sink"
                          refer="_blank"
                        />
                      )}
                    {repTarget?.type === 'SNOWFLAKE' &&
                      selectedTable?.role === 'ADMIN' && (
                        <RepTargetToolbar
                          uri={`/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}/privileges/snowflake/create`}
                          icon="edit"
                          refer=""
                        />
                      )}
                  </>
                }
              />
            </>
          )
        }))
      : [];

  const AsideRightTableSection = (
    <div className="right-aside-wrapper">
      {isPrivilegeButtonVisible && (
        <RouterLink
          href={`/tablegroup/${props?.selectedTableGroup?.name}/table/${props?.selectedTable?.name}/privileges`}
        >
          <Button theme={tealTheme} small>
            Manage Privileges
          </Button>
        </RouterLink>
      )}
      {isTargetsButtonVisible && (
        <RouterLink
          href={`/tablegroup/${props?.selectedTableGroup?.name}/table/${props?.selectedTable?.name}/replicationTargets/create`}
        >
          <Button theme={tealTheme} small>
            Create Replication Targets
          </Button>
        </RouterLink>
      )}
    </div>
  );

  const AsideLeftReplicationTargetSection = (
    <div className="table-groups-tiles">
      <div className="table-groups-tiles-div">
        <div className="table-groups-counter">
          {replicationTargetsToDisplay.length}
        </div>
      </div>
    </div>
  );

  const handleChange = value => {
    setState(prev => ({ ...prev, value: value }));
  };

  return (
    <div
      className="container-layout-wrapper"
      data-testid="replication-targets-container"
      data-test="replication-targets-container"
    >
      <LoadingScreen isLoading={state.isBusy} />
      <ContentSection
        logo={tableGroupLogo}
        title={props?.selectedTableGroup?.name}
        description={props?.selectedTableGroup?.description}
        asideLeft={
          <AsideLeftTableGroupSection
            totalTableGroupPrivileges={props?.totalTableGroupPrivileges}
            currentUserTableGroupRole={selectedTable?.role}
            totalCount={props.selectedTableGroup?.tablesCount}
          />
        }
        asideLeftClassName="content-section-col-statistic"
        redirectWhenContentMiddleClick
        redirectContentMiddlePath={`/tablegroup/${props?.selectedTableGroup?.name}`}
      />
      <ContentSection
        logo={tableLogo}
        title={selectedTable?.name}
        description={selectedTable?.description}
        asideLeft={
          <AsideLeftTableSection
            selectedTable={selectedTable}
            currentUserTableRole={selectedTable?.role}
            totalTablePrivileges={props?.totalTablePrivileges}
          />
        }
        asideRight={AsideRightTableSection}
        asideLeftClassName="content-section-col-statistic"
        redirectWhenContentMiddleClick
        redirectContentMiddlePath={`/tablegroup/${props?.selectedTableGroup?.name}/table/${props?.selectedTable?.name}`}
      />
      <ContentSectionSearch
        logo={repTargetLogo}
        title="Replication Targets"
        value={state.value}
        handleChange={handleChange}
        asideLeft={AsideLeftReplicationTargetSection}
        asideLeftClassName="content-section-col-statistic"
      />
      <FormSection className="container-layout-body">
        <CardsContainer
          cards={cards}
          classContainer="card-container-table"
          redirectWhenClick={false}
        />
      </FormSection>
    </div>
  );
};

export default ReplicationTargets;
