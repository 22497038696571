import store from '../../store/store';
import getGemSinkName from './getGemSinkName';
import getGemSourceName from './getGemSourceName';

const getGemResourceName = (replicationTargetType, resourceType) => {
  const state = store.getState();
  const selectedTableGroup = state?.tableGroupLayout?.selectedTableGroup;
  const selectedTable = state?.tableGroupDetails?.selectedTable;

  if (resourceType === 'source') {
    return getGemSourceName(selectedTableGroup.name, selectedTable.name);
  } else {
    return getGemSinkName(
      selectedTableGroup.name,
      selectedTable.name,
      replicationTargetType
    );
  }
};

export default getGemResourceName;
