import React, { Fragment } from 'react';
import DataTransformer from '../../../lib/util/DataTransformer';
import SummaryOverview from './SummaryOverview';
import DetailsErrors from './DetailsErrors';
import './dataValidation.css';

class DataValidation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      formattedData: []
    };
  }

  componentDidMount = () => {
    const {
      mapping,
      selectedTable,
      filetypeOptions,
      handleSetStepStatus,
      handleChangeTransforedSetResult,
      getGeneratedCellsCount
    } = this.props;
    const generatedCellsCount = getGeneratedCellsCount();
    const dataTransformer = new DataTransformer(
      mapping.mappedData,
      selectedTable.properties,
      generatedCellsCount,
      filetypeOptions
    );
    dataTransformer.transformRows();

    const errors = dataTransformer.getErrors();
    const formattedData = dataTransformer.getTransformedRows();

    if (errors.length) {
      handleSetStepStatus('error');
    }

    handleChangeTransforedSetResult({
      validation: { errors: errors, formattedData: formattedData },
      sendBatches: dataTransformer.getSendBatches(),
      chunckSize: dataTransformer.getChunckSize(),
      cellsCount: dataTransformer.getCellsCount(),
      rowsCount: dataTransformer.getRowsCount(),
      cellLimit: dataTransformer.getCellLimit(),
      batchesNumber: dataTransformer.getBatchesNumber()
    });

    this.setState({
      errors,
      formattedData
    });
  };

  getGroupErrors = errors => {
    const grouping = {
      byRow: {},
      byCol: {}
    };
    errors.forEach(err => {
      // group by row using the stringified column values
      grouping.byRow[JSON.stringify(err.rowIndex)] = 1;

      // group by column
      grouping.byCol[err.id] = grouping.byCol[err.id]
        ? grouping.byCol[err.id]
        : {
            columnName: err.id,
            totalErrors: 0,
            groupedValues: {}
          };
      grouping.byCol[err.id].totalErrors =
        grouping.byCol[err.id].totalErrors + 1;
      grouping.byCol[err.id].groupedValues[err.rowIndex[err.id]] = grouping
        .byCol[err.id].groupedValues[err.rowIndex[err.id]] || {
        valueName: err.rowIndex[err.id],
        count: 0,
        message: err.message
      };
      grouping.byCol[err.id].groupedValues[err.rowIndex[err.id]].count =
        grouping.byCol[err.id].groupedValues[err.rowIndex[err.id]].count + 1;
    });

    // sort the grouped errors by column values
    const columnNames = Object.keys(grouping.byCol);
    for (let x = 0; x < columnNames.length; x++) {
      const columnName = columnNames[x];
      const groupedError = grouping.byCol[columnName];
      const valuesArray = Object.keys(groupedError.groupedValues).map(
        i => groupedError.groupedValues[i]
      );
      valuesArray.sort((a, b) => {
        return a.count <= b.count ? 1 : -1;
      });
      grouping.byCol[columnName].groupedValuesArray = valuesArray;
    }
    return grouping;
  };

  render() {
    const { mapping, selectedTable } = this.props;
    const { errors, formattedData } = this.state;
    const grouping = this.getGroupErrors(errors);

    return (
      <Fragment>
        <SummaryOverview
          totalRows={formattedData.length}
          totalColumns={Object.keys(mapping.columnMapping).length}
          columnsWithErrors={Object.keys(grouping.byCol).length}
          rowsWithErrors={Object.keys(grouping.byRow).length}
          cellsWithErrors={errors.length}
        />
        {errors.length > 0 && (
          <DetailsErrors
            targetColumnMeta={selectedTable.properties}
            errorsByColumn={grouping.byCol}
            totalRows={formattedData.length}
          />
        )}
      </Fragment>
    );
  }
}

export default DataValidation;
