import { connect } from 'react-redux';
import EditTable from './EditTable';
import * as Actions from '../../actions/editTable';
import * as wizardActions from '../../actions/wizards';
import {
  getTableGroups,
  setSelectedTableGroup
} from '../../actions/tableGroupLayout';
import {
  addModal,
  removeModal,
  addNotification,
  removeConfirmation
} from '../../actions/core';
import { setSelectedTable } from '../../actions/tableGroupDetails';

import {
  getTemplateTableGroups,
  getTemplateTables
} from '../../actions/templateSelector';

const mapStateToProps = state => {
  return {
    ...state.wizards,
    selectedTable: state.tableGroupDetails.selectedTable,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup,
    modal: state.core.modal,
    template: state.template
  };
};

const mapDispatchToProps = {
  ...Actions,
  ...wizardActions,
  setSelectedTable,
  getTableGroups,
  setSelectedTableGroup,
  addModal,
  addNotification,
  removeModal,
  getTemplateTableGroups,
  getTemplateTables,
  removeConfirmation
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTable);
