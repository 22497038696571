import {
  GET_TEMPLATE_TABLE_GROUPS,
  SET_TEMPLATE_TABLE_GROUPS,
  SET_TEMPLATE_TABLES
} from './actionTypes';

import * as tableService from '../services/tableService';
import { addNotification, setIsLoading } from './core';
import { removeGeneratedProperties } from '../lib/util/removeGeneratedProperties';

export const getTemplateTableGroups = tableGroups => async dispatch => {
  dispatch({ type: GET_TEMPLATE_TABLE_GROUPS });

  try {
    const accessibleTableGroups = await tableGroups.filter(
      tableGroup => tableGroup.hasAccess === true
    );
    dispatch(setTemplateTableGroups(accessibleTableGroups));
  } catch (err) {
    dispatch(
      addNotification({
        isModal: true,
        title: 'Error getting table groups',
        message: err
      })
    );
  }
};

export const setTemplateTableGroups = tableGroups => dispatch => {
  dispatch({
    type: SET_TEMPLATE_TABLE_GROUPS,
    payload: tableGroups
  });
};

export const getTemplateTables = tableGroup => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    const { error, tables } = await tableService.fetchTables(tableGroup);

    if (error) {
      dispatch(
        addNotification({
          isModal: true,
          title: 'Error fetching the tables',
          message: error
        })
      );
      dispatch(setTemplateTables([]));
    } else {
      const tablesWithoutGeneratedProperties = tables.map(
        removeGeneratedProperties
      );
      dispatch(setTemplateTables(tablesWithoutGeneratedProperties));
    }
    dispatch(setIsLoading(false));
  } catch (err) {
    dispatch(setIsLoading(false));
    dispatch(setTemplateTables([]));
    dispatch(
      addNotification({
        isModal: true,
        title: 'Error fetching the tables',
        message: err
      })
    );
  }
};

export const setTemplateTables = tables => ({
  type: SET_TEMPLATE_TABLES,
  payload: tables
});
