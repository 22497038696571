import { connect } from 'react-redux';
import ContentDefiner from './ContentDefiner';
import * as Actions from '../../actions/wizards';
import {
  getTableGroups,
  setSelectedTableGroup
} from '../../actions/tableGroupLayout';
import {
  addModal,
  removeModal,
  addNotification,
  removeConfirmation
} from '../../actions/core';
import { getTables, setSelectedTable } from '../../actions/tableGroupDetails';
import {
  setOnSelectReplicationTarget,
  saveReplicationTargets
} from '../../actions/replicationTargets';

import {
  getTemplateTableGroups,
  getTemplateTables
} from '../../actions/templateSelector';

import {
  toggleFormCheckboxes,
  setS3BucketField,
  resetReplicationTargetsForm
} from '../../reducers/replicationTargets';

const mapStateToProps = state => {
  return {
    ...state.wizards,
    tableGroups: state.tableGroupLayout.tableGroups,
    tables: state.tableGroupDetails.tables || [],
    selectedTable: state.tableGroupDetails.selectedTable,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup,
    modal: state.core.modal,
    isLoading: state.core.isLoading,
    template: state.template,
    formValues: state.replicationTargets.formValues,
    replicationTargetsErrors: state.replicationTargets.errors
  };
};

const mapDispatchToProps = {
  ...Actions,
  getTables,
  setSelectedTable,
  getTableGroups,
  setSelectedTableGroup,
  addModal,
  addNotification,
  removeModal,
  getTemplateTableGroups,
  getTemplateTables,
  setOnSelectReplicationTarget,
  removeConfirmation,
  toggleFormCheckboxes,
  setS3BucketField,
  resetReplicationTargetsForm,
  saveReplicationTargets
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentDefiner);
