import getHeadersWithDefaults from '../../lib/util/getHeadersWithDefaults';
import { endPoints } from '../../constants/endPoints';
import { getRecords } from './getRecords.js';
import { uploadRecords } from './uploadRecords.js';
import { saveRecords } from './saveRecords.js';
import { deleteRecords } from './deleteRecords';

const recordsService = async (
  action,
  payload,
  getState,
  method = 'XMERGE',
  anchor = 0
) => {
  const tableGroup = getState().tableGroupLayout.selectedTableGroup;
  const table = getState().tableGroupDetails.selectedTable;

  const recordsGetUrl = `${endPoints.records.get}/${tableGroup.name}/${table.name}/records`;
  const recordsCudUrl = `${endPoints.records.cud}/${tableGroup.name}/${table.name}/records`;
  const globalHeaders = await getHeadersWithDefaults();

  switch (action) {
    case 'get':
      return getRecords(recordsGetUrl, globalHeaders, getState, anchor);
    case 'save':
      return saveRecords(recordsCudUrl, payload, globalHeaders, getState);
    case 'delete':
      return deleteRecords(recordsCudUrl, payload, globalHeaders);
    case 'upload':
      return uploadRecords(recordsCudUrl, payload, globalHeaders, method);
    default:
      return;
  }
};

export default recordsService;
