const validation = props => {
  let error = null;
  const { core, processUpload, wizardStepStatusSet, addNotification } = props;

  if (!core.dataloadingMode) {
    error = {
      title: 'Error in the Summary Step',
      message:
        'No Data Loading Mode selected. Please select a DataLoading Mode to continue'
    };
  }

  if (error) {
    wizardStepStatusSet('error');
    const notification = {
      isModal: true,
      title: error.title,
      message: error.message
    };
    addNotification(notification);
    return;
  }

  processUpload();
};

export default validation;
