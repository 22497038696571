import getHeadersWithDefaults from '../lib/util/getHeadersWithDefaults';
import { endPoints } from '../constants/endPoints';
import axios from 'axios';

export const downloadFile = async (getState, format = 'csv') => {
  const tableGroupName = getState().tableGroupLayout.selectedTableGroup.name;
  const { name: tableName } = getState().tableGroupDetails.selectedTable;
  const uri = `${endPoints.file}/${tableGroupName}/${tableName}/file?fileType=${format}`;
  const headers = await getHeadersWithDefaults({}, format);
  const fileName = `${tableName}.${format}`;

  return fetchFile(uri, headers, fileName);
};

const fetchFile = async (uri, headers, fileName) => {
  await axios
    .get(uri, {
      headers: headers,
      responseType: 'blob',
      data: {}
    })
    .then(response => appendFileToDom(response, fileName));
};

const appendFileToDom = (response, fileName) => {
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  link.remove();
};
