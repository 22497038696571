import { reset } from 'redux-form';
import {
  SET_IS_OVERVIEW_FORM_EDITING,
  SET_IS_OVERVIEW_FORM_NEW_RECORD,
  ADD_OVERVIEW_FORM_ERRORS,
  REMOVE_OVERVIEW_FORM_ERRORS,
  RESET_OVERVIEW_FORM_ERRORS
} from './actionTypes';
import {
  addNotification,
  addConfirmation,
  removeConfirmation,
  setIsLoading,
  setDataViewMode
} from './core';
import { setSelectedRow, initSelectedRow, getRows } from './overviewTable';
import { I18n } from 'react-redux-i18n';
import DataTransformer from '../lib/util/DataTransformer';
import initializeValues from '../lib/util/initializeValues';
import recordsService from '../services/recordsService/recordsService';

// Add a new record
export const createOverviewFormRecord = () => dispatch => {
  dispatch(setSelectedRow(null, null));
  dispatch(resetOverviewForm());
  dispatch(setDataViewMode('form'));
  dispatch(setIsOverviewFormNewRecord(true));
};

// Cancel a creation or modification action
export const cancelOverviewFormAction =
  (isOverviewFormEditing, isOverviewFormNewRecord) => dispatch => {
    if (isOverviewFormNewRecord === true) {
      dispatch(setIsOverviewFormNewRecord(false));
      dispatch(initSelectedRow());
    }
    if (isOverviewFormEditing === true) {
      dispatch(setIsOverviewFormEditing(false));
    }
    dispatch(resetOverviewFormError());
  };

// Accept confirmation when saving a record
export const saveOverviewFormAction = () => async (dispatch, getState) => {
  const properties = getState().tableGroupDetails.selectedTable.properties;
  const reduxForm = getState().form.reduxForm;
  const formRecord =
    reduxForm.values === undefined || reduxForm.values === null
      ? [initializeValues(properties)]
      : [reduxForm.values];

  const dataTransformer = new DataTransformer(formRecord, properties);
  dispatch(removeConfirmation());
  dispatch(resetOverviewFormError());

  dataTransformer.transformRows();

  const errors = dataTransformer.getErrors();
  if (errors.length > 0) {
    dispatch(addOverviewFormError(errors));
    const notification = {
      isModal: true,
      title: 'Invalid fields',
      message:
        'At least one field is not valid, please check the data and try again. For more information take a look at the Fields highlighted with red.'
    };
    dispatch(addNotification(notification));
  } else {
    await processSave(dataTransformer, dispatch, getState);
  }
};

// Accept confirmation when deleting a record
export const deleteOverviewFormAction = () => async (dispatch, getState) => {
  const properties = getState().tableGroupDetails.selectedTable.properties;
  const reduxForm = getState().form.reduxForm;

  if (Object.keys(reduxForm).length === 0) return;

  const formRecord = [reduxForm.values];
  const dataTransformer = new DataTransformer(formRecord, properties);

  dispatch(removeConfirmation());
  dispatch(resetOverviewFormError());

  dataTransformer.generateRowsToDelete();

  const rowsToDelete = dataTransformer.getRowsToDelete();

  if (rowsToDelete.length > 0)
    await processDelete(rowsToDelete, dispatch, getState);
};

// Save the changes into the backend
export const processSave = async (dataTransformer, dispatch, getState) => {
  const payload = dataTransformer.getTransformedRows();
  const isOverviewFormEditing = getState().overviewForm.isOverviewFormNewRecord;
  dispatch(setIsLoading(true));
  try {
    const response = await recordsService('save', payload, getState);

    switch (response.status) {
      case 200: // record created
        dispatch(setIsOverviewFormNewRecord(false, false));
        await dispatch(getRows());
        dispatch(addNotification(setCreateSuccessNotification()));
        break;

      case 204: // record updated
        dispatch(setIsOverviewFormEditing(false, false));
        await dispatch(getRows());
        dispatch(addNotification(setUpdateSuccessNotification()));
        break;
      default:
    }
  } catch (error) {
    dispatch(
      addNotification(
        setUpdateFailNotification(
          isOverviewFormEditing,
          error || error.response.data
        )
      )
    );
  }
  dispatch(setIsLoading(false));
};

// Delete records from the backend
export const processDelete = async (rowsToDelete, dispatch, getState) => {
  const payload = rowsToDelete;
  dispatch(setIsLoading(true));
  const response = await recordsService('delete', payload, getState);
  if (response.status === 204) {
    dispatch(addNotification(setDeleteSuccessNotification()));
    dispatch(getRows());
  }
  dispatch(setIsLoading(false));
};

// Reset the redux form
export const resetOverviewForm = () => dispatch => {
  dispatch(reset('reduxForm'));
  dispatch(resetOverviewFormError());
};

// Set the flag for the edition mode
export const setIsOverviewFormEditing =
  (isOverviewFormEditing, showNotification = true) =>
  (dispatch, getState) => {
    const action = {
      type: SET_IS_OVERVIEW_FORM_EDITING,
      isOverviewFormEditing
    };
    dispatch(action);
    if (showNotification === true)
      dispatch(
        addNotification(
          setIsOverviewFormEditingNotification(isOverviewFormEditing)
        )
      );
  };

// Set the flag for new records (creation mode)
export const setIsOverviewFormNewRecord =
  (isOverviewFormNewRecord, showNotification = true) =>
  (dispatch, getState) => {
    const action = {
      type: SET_IS_OVERVIEW_FORM_NEW_RECORD,
      isOverviewFormNewRecord
    };
    dispatch(action);
    if (showNotification === true)
      dispatch(
        addNotification(
          setIsOverviewFormNewRecordNotification(isOverviewFormNewRecord)
        )
      );
  };

// Add errors to the error's list of the form
export const addOverviewFormError = error => dispatch => {
  const action = { type: ADD_OVERVIEW_FORM_ERRORS, error };
  dispatch(action);
};

// Remove an error from the error's list of the form
export const removeOverviewFormError = error => dispatch => {
  const action = { type: REMOVE_OVERVIEW_FORM_ERRORS, error };
  dispatch(action);
};

// Reset the list of errors for the form
export const resetOverviewFormError = () => dispatch => {
  const action = { type: RESET_OVERVIEW_FORM_ERRORS };
  dispatch(action);
};

// Set the Notification for the new record's flag
export const setIsOverviewFormNewRecordNotification =
  isOverviewFormNewRecord => {
    if (isOverviewFormNewRecord === true) {
      return {
        notType: 'info',
        isModal: false,
        isToast: true,
        title: I18n.t('overviewForm.notification.isNewRecordTitle'),
        message: I18n.t('overviewForm.notification.isNewRecordMessage')
      };
    } else {
      return {
        notType: 'info',
        isModal: false,
        isToast: true,
        title: I18n.t('overviewForm.notification.notIsNewRecordTitle'),
        message: I18n.t('overviewForm.notification.notIsNewRecordMessage')
      };
    }
  };

// Set the Notification for the edition mode's flag
export const setIsOverviewFormEditingNotification = isOverviewFormEditing => {
  if (isOverviewFormEditing === true) {
    return {
      notType: 'info',
      isModal: false,
      isToast: true,
      title: I18n.t('overviewForm.notification.enableEditingTitle'),
      message: I18n.t('overviewForm.notification.enableEditingMessage')
    };
  } else {
    return {
      notType: 'info',
      isModal: false,
      isToast: true,
      title: I18n.t('overviewForm.notification.disableEditingTitle'),
      message: I18n.t('overviewForm.notification.disableEditingMessage')
    };
  }
};

// Set the Notification for the failed creation and modification
export const setUpdateFailNotification = (
  isOverviewFormEditing,
  errorMessage
) => {
  if (isOverviewFormEditing === true) {
    return {
      isModal: true,
      title: I18n.t('overviewForm.notification.updateFailedTitle'),
      message: I18n.t('overviewForm.notification.updateFailedMessage', {
        message: errorMessage
      })
    };
  } else {
    return {
      isModal: true,
      title: I18n.t('overviewForm.notification.createFailedTitle'),
      message: I18n.t('overviewForm.notification.createFailedMessage', {
        message: errorMessage
      })
    };
  }
};

// Set the Notification for the succeeded creation
export const setCreateSuccessNotification = () => ({
  notType: 'success',
  isModal: false,
  isToast: true,
  title: I18n.t('overviewForm.notification.createSuccededTitle'),
  message: I18n.t('overviewForm.notification.createSuccededMessage')
});

// Set the Notification for the succeeded modification
export const setUpdateSuccessNotification = () => ({
  notType: 'success',
  isModal: false,
  isToast: true,
  title: I18n.t('overviewForm.notification.updateSuccededTitle'),
  message: I18n.t('overviewForm.notification.updateSuccededMessage')
});

// Set the Notification for the succeeded deletion
export const setDeleteSuccessNotification = () => ({
  notType: 'success',
  isModal: false,
  isToast: true,
  title: I18n.t('overviewForm.notification.deleteSuccededTitle'),
  message: I18n.t('overviewForm.notification.deleteSuccededMessage')
});

// Set the Confirmation for the Save
export const setOnSaveRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'form-save',
      question: I18n.t('overviewForm.confirmation.saveNewRecordQuestion'),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};

// Set the Confirmation for the Delete
export const setOnDeleteRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'form-delete',
      question: I18n.t('overviewForm.confirmation.deleteRecordQuestion'),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};
