import React from 'react';
// components
import { Table, TablePagination } from '../../components';
// subcomponents
import { TableColumns, UpdatableCell, CheckboxCell } from './index';
// libs
import getDataTypeConf from '../../lib/util/getDataTypeConf';
import './editableTable.css';

const EditableTable = props => {
  const updatableCell = cellInfo => {
    const cellValue = props.rows[cellInfo.index][cellInfo.column.id] || '';
    const columnMetaData = props.selectedTable.properties.filter(
      row => row.name === cellInfo.column.id
    )[0];
    const dataTypeConf = getDataTypeConf(columnMetaData.datatype);
    const textAlignCell = dataTypeConf.textAlign;
    const isDisabled =
      !!columnMetaData.valueGeneratorName === true ||
      columnMetaData.isKeyProperty === 'Y' ||
      props.isOverviewTableEditing !== true
        ? true
        : false;
    const error = props.getError(cellInfo);

    return (
      <UpdatableCell
        key={`updatable-${columnMetaData.name}`}
        typeConfiguration={dataTypeConf}
        cellValue={cellValue}
        columnMetaData={columnMetaData}
        cellInfo={cellInfo}
        textAlignCell={textAlignCell}
        isDisabled={isDisabled}
        onBlur={event =>
          props.onUpdatableCellInputBlur(columnMetaData, cellInfo, event)
        }
        error={error}
        addModal={props.addModal}
        removeModal={props.removeModal}
        onUpdatableCellFromHelper={props.onUpdatableCellFromHelper}
        setSelectedRow={(original, index) =>
          props.setSelectedRow(original, index)
        }
        selectedRowPosition={props.selectedRowPosition}
      />
    );
  };

  const checkboxCell = cellInfo => {
    const rowToDelete = props.rowsDeletionList.filter(deletionItem => {
      return deletionItem === cellInfo.original;
    })[0];
    const cellValue = rowToDelete ? true : false;
    return (
      <CheckboxCell
        cellInfo={cellInfo}
        cellValue={cellValue}
        onChange={event => props.handleCheckBoxChange(cellInfo, event)}
      />
    );
  };

  const columns = TableColumns(
    props.properties,
    updatableCell,
    checkboxCell,
    props.isOverviewTableEditing
  );

  return (
    <Table
      columns={columns}
      data={props.rows}
      sortable={false}
      pages={props.pages}
      defaultPageSize={props.pageSize}
      pageSize={props.pageSize}
      onFetchData={props.onFetchData}
      style={{
        maxHeight: `${props.height - 82}px`,
        height: `${props.height - 82}px`
      }}
      minRows={props.minRows}
      showPagination={props.showPagination}
      PaginationComponent={TablePagination}
      getTdProps={props.getTdProps}
    />
  );
};

export default EditableTable;
