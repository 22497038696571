// react
import React, { useEffect } from 'react';
// components
import { FormSection, Button } from '../../components';
// utils
// constants
import {
  dataTypesOptions,
  trimmingOptions,
  letterCaseOptions,
  defaultProperties,
  autoKeyColumnConfiguration
} from '../../constants/defaultValues';
// assets
import list from '../../assets/img/list.svg';
// sub-components
import ListValues from '../ContentDefiner/Step2_Definition/ListValues';
import DefinitionHelp from './DefinitionHelp';
import FieldsConfigurator from '../ContentDefiner/Step2_Definition/FieldsConfigurator/FieldsConfigurator';
import { deserializeProperties } from '../../lib/serialization/deserialize';
import { Icon } from '@nike/epic-react-ui';

const styles = {
  actionsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  divAvatar: {
    backgroundColor: 'var(--er-blue)',
    verticalAlign: 'middle',
    marginLeft: 14
  }
};

const handleDeleteColumnSpec = (cellInfo, deleteColumnSpec) => {
  const { displayOrder } = cellInfo.original;
  deleteColumnSpec(displayOrder);
};

const handleListColumnSpec = (
  cellInfo,
  addModal,
  removeModal,
  setIsLoading,
  updateColumSpec
) => {
  const { original } = cellInfo;
  addModal({
    id: 'values_list_display',
    title: `List of Values for '${original.name}'`,
    message: `Data type: ${original.datatype}`,
    children: (
      <ListValues
        cellInfo={cellInfo}
        updateColumSpec={updateColumSpec}
        removeModal={removeModal}
        setIsLoading={isLoading => setIsLoading(isLoading)}
      />
    ),
    cancelCallback: () => handleListClose(removeModal)
  });
};

const handleListClose = removeModal => {
  removeModal();
};

const handleGoPrevious = (cellInfo, setPreviousColumnSpec) => {
  const { displayOrder } = cellInfo.original;
  if (displayOrder === 1) return;
  setPreviousColumnSpec(displayOrder);
};

const handleGoNext = (cellInfo, pageSize, setNextColumnSpec) => {
  const { displayOrder } = cellInfo.original;
  if (displayOrder === pageSize) return;
  setNextColumnSpec(displayOrder);
};

const getColumnSpec = () => ({
  datatype: 'TEXT',
  length: 120,
  scale: defaultProperties.SCALE,
  nullable: 'Y',
  isKeyProperty: 'N',
  trim: 'BOTH',
  letterCase: defaultProperties.LETTER_CASE,
  regexPattern: defaultProperties.REGEX_PATTERN,
  validationErrorMessage: defaultProperties.VALIDATION_ERROR_MSG,
  valueList: defaultProperties.VALUE_LIST
});

const handleAddNewColumnSpec = (addColumnSpec, pageSize) => {
  const columnSpec = getColumnSpec();
  addColumnSpec({
    ...columnSpec,
    displayOrder: pageSize + 1,
    name: 'Field' + (pageSize + 1)
  });
};

const getTextIsDisabled = original => {
  const { valueList, datatype } = original;
  return valueList?.length > 1 ? true : datatype?.toUpperCase() !== 'TEXT';
};

const getTableHeaders = props => {
  const {
    isEmpty,
    updateColumSpec,
    deleteColumnSpec,
    setNextColumnSpec,
    setPreviousColumnSpec,
    addModal,
    removeModal,
    setIsLoading
  } = props;

  const enableIfEmpty = fn => original => {
    return !isEmpty && fn(original);
  };

  const { data } = props.source;
  const tableData = data.meta.columnsDefinition;
  const pageSize = tableData.length;
  return [
    {
      header: 'Order',
      accessor: 'displayOrder',
      disabled: true,
      align: 'left',
      constructor: 'input',
      type: 'text',
      width: 75,
      updatableCellComponent: function createUpdatableCell(cellInfo, value) {
        return isEmpty ? (
          <div>
            <button
              onClick={() => handleGoPrevious(cellInfo, setPreviousColumnSpec)}
            >
              <Icon type="chevron-up" />
            </button>
            <button
              onClick={() =>
                handleGoNext(cellInfo, pageSize, setNextColumnSpec)
              }
            >
              <Icon type="chevron-down" />
            </button>
            <input
              key="input-key-order"
              name="input-name-order"
              type="input"
              defaultValue={value}
              readOnly={true}
              disabled={true}
              style={{
                textAlign: 'right',
                width: 'calc(100% - 62px)',
                height: '27px'
              }}
              className="cell-input"
            />
          </div>
        ) : (
          <div>
            <input
              key="input-key-order"
              name="input-name-order"
              type="input"
              defaultValue={value}
              readOnly={true}
              disabled={true}
              style={{
                textAlign: 'center',
                width: 'calc(100%)',
                height: '27px'
              }}
              className="cell-input"
            />
          </div>
        );
      }
    },
    {
      header: 'Name',
      accessor: 'name',
      align: 'left',
      constructor: 'input',
      type: 'text',
      width: 170,
      disabled: enableIfEmpty(original => {
        const { valueList } = original;
        return original.description === null || valueList?.length > 1;
      })
    },
    {
      header: 'Datatype',
      accessor: 'datatype',
      constructor: 'select',
      type: 'text',
      options: dataTypesOptions,
      disabled: enableIfEmpty(original => {
        const { valueList } = original;
        return original.description === null || valueList?.length > 1;
      })
    },
    {
      header: 'Length',
      accessor: 'length',
      constructor: 'input',
      type: 'number',
      disabled: enableIfEmpty(original => {
        const { valueList, datatype } = original;
        return (
          original.description === null ||
          valueList?.length > 1 ||
          !(
            datatype?.toUpperCase() === 'TEXT' ||
            datatype?.toUpperCase() === 'DECIMAL'
          )
        );
      }),
      width: 70
    },
    {
      header: 'Decimals',
      accessor: 'scale',
      constructor: 'input',
      type: 'number',
      disabled: enableIfEmpty(original => {
        const { valueList, datatype } = original;
        return (
          original.description === null ||
          valueList?.length > 1 ||
          datatype?.toUpperCase() !== 'DECIMAL'
        );
      }),
      width: 70
    },
    {
      header: 'Mandatory',
      accessor: 'nullable',
      constructor: 'checkbox',
      width: 80,
      updatableCellComponent: function createUpdatableCell(cellInfo) {
        const { value: newValue } = cellInfo;

        return (
          <input
            type="checkbox"
            checked={newValue === 'N'}
            onChange={event => {
              const checked = event.currentTarget.checked ? 'N' : 'Y';

              updateColumSpec({ cellInfo: { ...cellInfo }, value: checked });
            }}
            className="cell-checkbox"
            disabled={!isEmpty}
          />
        );
      },
      disabled: enableIfEmpty(original => {
        const { valueList } = original;
        return valueList?.length > 1;
      })
    },
    {
      header: 'Keyfield',
      accessor: 'isKeyProperty',
      constructor: 'checkbox',
      width: 80,
      updatableCellComponent: function createUpdatableCell(cellInfo) {
        const { value: newValue } = cellInfo;

        return (
          <input
            type="checkbox"
            checked={newValue === 'Y'}
            onChange={event => {
              updateColumSpec({
                cellInfo: { ...cellInfo },
                value: event.currentTarget.checked ? 'Y' : 'N'
              });
            }}
            className="cell-checkbox"
            disabled={!isEmpty}
          />
        );
      },
      disabled: enableIfEmpty(original => {
        const { valueList } = original;
        return valueList?.length > 1;
      })
    },
    {
      header: 'Trimming',
      accessor: 'trim',
      constructor: 'select',
      type: 'text',
      options: trimmingOptions,
      disabled: original => {
        const { valueList, datatype } = original;
        return (
          !isEmpty &&
          (original.description === null ||
            valueList?.length > 1 ||
            datatype?.toUpperCase() !== 'TEXT')
        );
      }
    },
    {
      header: 'Letter Case',
      accessor: 'letterCase',
      constructor: 'select',
      type: 'text',
      options: letterCaseOptions,
      disabled: enableIfEmpty(original => {
        return original.description === null || getTextIsDisabled(original);
      })
    },
    {
      header: 'Regex Pattern',
      accessor: 'regexPattern',
      constructor: 'input',
      type: 'text',
      disabled: enableIfEmpty(original => {
        return original.description === null || getTextIsDisabled(original);
      })
    },
    {
      header: 'Pattern Validation Message',
      accessor: 'validationErrorMessage',
      constructor: 'input',
      type: 'text',
      disabled: enableIfEmpty(original => {
        const { valueList, datatype } = original;
        return (
          original.description === null ||
          valueList?.length > 1 ||
          datatype?.toUpperCase() !== 'TEXT'
        );
      })
    },
    {
      header: 'List',
      accessor: 'valueList',
      constructor: 'input',
      type: 'text',
      disabled: true
    },
    {
      header: '',
      accessor: '',
      width: 64,
      updatableCellComponent: function createUpdatableCell(cellInfo) {
        const { original } = cellInfo;
        return (
          <div>
            {(isEmpty || original.description !== null) && (
              <button
                onClick={() =>
                  handleDeleteColumnSpec(cellInfo, deleteColumnSpec)
                }
              >
                <i className="epic-icon epic-icon-delete" />
              </button>
            )}
            {(isEmpty || original.description !== null) && (
              <button
                onClick={() =>
                  handleListColumnSpec(
                    cellInfo,
                    addModal,
                    removeModal,
                    setIsLoading,
                    updateColumSpec
                  )
                }
              >
                <img src={list} alt="" />
              </button>
            )}
          </div>
        );
      }
    }
  ];
};

const onUpdatableCellSelectChange = (
  cellInfo,
  value,
  source,
  updateColumSpec,
  setDatatypeColumSpec
) => {
  const columnId = cellInfo.column.id;

  if (columnId.toUpperCase() === 'DATATYPE') {
    const meta = source.data.meta;
    const columnsDefinition = meta.columnsDefinition;
    const newColumnsDefinition = columnsDefinition[cellInfo.index];
    newColumnsDefinition.datatype = value;

    // Hydrate columnSpec with properties depending on datatype of the row
    switch (value.toUpperCase()) {
      case 'DATE':
      case 'DATETIME':
      case 'UUID':
        newColumnsDefinition.scale = defaultProperties.SCALE;
        newColumnsDefinition.length = defaultProperties.LENGTH;
        newColumnsDefinition.letterCase = defaultProperties.LETTER_CASE;
        newColumnsDefinition.trim = 'BOTH';
        break;
      case 'INTEGER':
        newColumnsDefinition.scale = defaultProperties.SCALE;
        newColumnsDefinition.length = 32;
        break;
      case 'DECIMAL':
        newColumnsDefinition.length = 32;
        newColumnsDefinition.scale = 4;
        newColumnsDefinition.letterCase = defaultProperties.LETTER_CASE;
        newColumnsDefinition.trim = defaultProperties.TRIM;
        break;
      default:
        break;
    }

    setDatatypeColumSpec({
      cellInfo: { ...cellInfo },
      newColumnsDefinition: newColumnsDefinition,
      value: value
    });
  } else {
    updateColumSpec({ cellInfo: { ...cellInfo }, value: value });
  }
};

const getError = (cellInfo, definitionErrors) =>
  definitionErrors
    .filter(definitionError => {
      return definitionError.rowIndex === cellInfo.original;
    })
    .filter(err => {
      return err.id === cellInfo.column.id;
    })[0];

const onUpdatableCellInputChange = (cellInfo, event, updateColumSpec) => {
  updateColumSpec({ cellInfo: { ...cellInfo }, value: event.target.value });
};

const onUpdatableCellCheckBoxChange = (cellInfo, event, updateColumSpec) => {
  const checked = event.currentTarget.checked;
  const newValue = checked ? 'Y' : 'N';
  updateColumSpec({ cellInfo: { ...cellInfo }, value: newValue });
};

const EditTableColumnDefinition = props => {
  const {
    source,
    isEmpty,
    updateColumSpec,
    setDatatypeColumSpec,
    core,
    addColumnSpec,
    setColumSpec,
    setAddGeneratedKeyColumn
  } = props;
  const { definitionErrors } = core;
  const { data } = source;
  const { meta } = data;
  const { columnsDefinition } = meta;

  const pageSize = columnsDefinition.length;
  const tableHeaders = getTableHeaders(props);

  const filterProperties = () =>
    props.selectedTable?.properties?.filter(
      property => !property.valueGeneratorMoment
    );

  const intialProperties = deserializeProperties(filterProperties()) || [];
  useEffect(() => {
    setColumSpec(deserializeProperties(filterProperties()) || []);
    const hasAutoKey =
      props.selectedTable?.properties.findIndex(
        x => x.name === autoKeyColumnConfiguration.name
      ) >= 0;
    setAddGeneratedKeyColumn(hasAutoKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.table]);

  return (
    <div data-testid="column-definition">
      <FormSection
        className="formPreviewSection"
        title={<DefinitionHelp />}
        subtitle="Add new columns that make up the Table. You can not edit existing structure."
      >
        <div style={styles.actionsContainer}>
          <Button
            data-testid="add-column-button"
            onClick={() => {
              handleAddNewColumnSpec(addColumnSpec, pageSize);
            }}
            theme={{ primary: '#5197D6', secondary: 'white' }}
          >
            Add
          </Button>
          <Button
            onClick={() => {
              setColumSpec(intialProperties);
            }}
            theme={{ primary: '#FA5400', secondary: 'white' }}
          >
            Reset
          </Button>
        </div>
        <FieldsConfigurator
          data={columnsDefinition}
          tableHeaders={tableHeaders}
          sorted={[{ id: 'order', desc: false }]}
          showPagination={false}
          sortable={isEmpty}
          pageSize={pageSize}
          onUpdatableCellInputChange={(cellInfo, event) => {
            onUpdatableCellInputChange(cellInfo, event, updateColumSpec);
          }}
          onUpdatableCellSelectChange={(cellInfo, value) => {
            onUpdatableCellSelectChange(
              cellInfo,
              value,
              source,
              updateColumSpec,
              setDatatypeColumSpec
            );
          }}
          onUpdatableCellCheckBoxChange={(cellInfo, event) => {
            onUpdatableCellCheckBoxChange(cellInfo, event, updateColumSpec);
          }}
          getError={cellInfo => getError(cellInfo, definitionErrors)}
        />
      </FormSection>
    </div>
  );
};

export default EditTableColumnDefinition;
