import _ from 'lodash';
import applyFormatting from '../util/applyFormatting';

export default class DataTransformer {
  constructor(rows, properties, generatedCellsCount = 3, fileTypeOptions = {}) {
    this.rows = rows;
    this.properties = properties;
    this.cleanedProperties = this._applyPropertiesCleaning(this.properties);
    this.keys = this._getKeys(this.properties);
    this.errors = [];
    this.transformedRows = [];
    this.rowsToDelete = [];
    this.cellLimit = 65535;
    this.totalCells = generatedCellsCount + this.cleanedProperties.length;
    this.cellsCount = this.rows.length * this.totalCells;
    this.rowsCount = this.rows.length;
    this.sendBatches = this.cellsCount >= this.cellLimit ? true : false;
    this.chunckSizeLimit = Math.floor(this.cellLimit / this.totalCells);
    this.batchesNumber = Math.ceil(this.rows.length / this.chunckSizeLimit);
    this.chunckSize = Math.ceil(this.rows.length / this.batchesNumber);
    this.formatDate =
      _.isEmpty(fileTypeOptions) || fileTypeOptions.dateFormat === undefined
        ? 'ISO'
        : fileTypeOptions.dateFormat;
    this.dateFormatOptions = [
      {
        value: 'ISO',
        pattern:
          /^(?<year>\d{4})[-/._ ](?<month>\d{2})[-/._ ](?<day>\d{2})(?<time>[Tt ](?<hours>\d{2}):(?<minutes>\d{2}):?(?<seconds>\d{2})?[.Zz ]?(?<miliseconds>\d{2,3}[zZ ]?)\+?(?<hourOffset>\d{2})?:?(?<minuteOffset>\d{2})?)?$/
      },
      {
        value: 'mm-dd-yyyy',
        pattern:
          /^(?<month>\d{1,2})[-/._ ](?<day>\d{1,2})[-/._ ](?<year>\d{4})(?<time>[Tt ](?<hours>\d{2}):(?<minutes>\d{2}):?(?<seconds>\d{2})?)?$/
      },
      {
        value: 'dd-mm-yyyy',
        pattern:
          /^(?<day>\d{1,2})[-/._ ](?<month>\d{1,2})[-/._ ](?<year>\d{4})(?<time>[Tt ](?<hours>\d{2}):(?<minutes>\d{2}):?(?<seconds>\d{2})?)?$/
      },
      {
        value: 'yyyy-mm-dd',
        pattern:
          /^(?<year>\d{4})[-/._ ](?<month>\d{1,2})[-/._ ](?<day>\d{1,2})(?<time>[Tt ](?<hours>\d{2}):(?<minutes>\d{2}):?(?<seconds>\d{2})?)?$/
      },
      {
        value: 'yyyy-dd-mm',
        pattern:
          /^(?<year>\d{4})[-/._ ](?<day>\d{1,2})[-/._ ](?<month>\d{1,2})(?<time>[Tt ](?<hours>\d{2}):(?<minutes>\d{2}):?(?<seconds>\d{2})?)?$/
      }
    ];
  }

  getTransformedRows() {
    return this.transformedRows;
  }

  getErrors() {
    return this.errors;
  }

  getKeys() {
    return this.keys;
  }

  getRowsToDelete() {
    return this.rowsToDelete;
  }

  getBatchesNumber() {
    return this.batchesNumber;
  }

  getSendBatches() {
    return this.sendBatches;
  }

  getChunckSize() {
    return this.chunckSize;
  }

  getCellLimit() {
    return this.cellLimit;
  }

  getCellsCount() {
    return this.cellsCount;
  }

  getRowsCount() {
    return this.rowsCount;
  }

  transformRows() {
    const keyMap = {};
    const keyColumnNames = this.keys.map(key => key.name);

    this.rows.forEach((row, index) => {
      if (row !== undefined) {
        const cleanedRow = this._applyRowCleaning(row);
        const transformedRow = this._transformRow(
          row,
          cleanedRow,
          keyMap,
          keyColumnNames
        );

        const data = {
          columns: []
        };

        // @todo UMD-2368 remove this cycle
        for (let key in transformedRow) {
          data.columns.push({
            name: key,
            value: transformedRow[key]
          });
        }

        this.transformedRows.push(data);
      }
    });
  }

  generateRowsToDelete() {
    this.rows.forEach(row => {
      const keysRow = this._applyExtractKeys(row);
      const transformedRow = this._transformRowForDeletion(keysRow);

      const data = {
        columns: []
      };

      // @todo UMD-2368 remove this cycle
      for (let key in transformedRow) {
        data.columns.push({
          name: key,
          value: transformedRow[key]
        });
      }

      this.rowsToDelete.push(data);
    });
  }

  _getKeys() {
    return this.properties.filter(property => {
      return property.isKeyProperty === 'Y';
    });
  }

  _applyPropertiesCleaning() {
    return this.properties.filter(property => {
      const isSystemField = !!property.valueGeneratorName || false;
      return property.name !== '__metadata' && isSystemField === false;
    });
  }

  _applyExtractKeys(row) {
    return Object.keys(row).reduce((object, keyRow) => {
      const matchedKey = this.keys.filter(key => {
        return key.name === keyRow;
      })[0];
      if (matchedKey) {
        object[keyRow] = row[keyRow];
      }
      return object;
    }, {});
  }

  _applyRowCleaning(row) {
    return Object.keys(row).reduce((object, key) => {
      if (
        key !== '__rowIndex' &&
        key !== '__metadata' &&
        key !== 'OPERATION_ACTION' &&
        key !== 'OPERATION_USER' &&
        key !== 'OPERATION_TIMESTAMP'
      ) {
        object[key] = row[key];
      }
      return object;
    }, {});
  }

  _transformRow(index, row, keyMap, keyColumnNames) {
    let transformedRow = { ...row };
    let keyValues = [];
    this.cleanedProperties.forEach(property => {
      const key = property.name;
      const cellValue = this._transformCell(index, row[key], property);
      transformedRow[key] = cellValue;

      const isKeyProperty = keyColumnNames.indexOf(key) > -1;
      if (isKeyProperty) {
        keyValues.push(cellValue);

        const isFinalKeyProperty = keyValues.length === keyColumnNames.length;
        if (isFinalKeyProperty) {
          const keyValuesJSON = JSON.stringify(keyValues);

          const isDuplicateKey = keyMap[keyValuesJSON] >= 1;
          if (isDuplicateKey) {
            keyMap[keyValuesJSON] += 1;
            const messageText = `Duplicated Key: ${keyValuesJSON}`;
            this.errors.push({
              rowIndex: row,
              id: key,
              message: messageText
            });
          } else {
            keyMap[keyValuesJSON] = 1;
          }
        }
      }
    });
    return transformedRow;
  }

  _transformRowForDeletion(row) {
    let transformedRow = { ...row };
    this.keys.forEach(property => {
      const key = property.name;
      let cellValue;
      cellValue = row[key];
      transformedRow[key] = this._applyDataTypeFormating(cellValue, property);
    });
    return transformedRow;
  }

  _transformCell(index, value, property) {
    value = this._applyDataTypeFormating(value, property);
    this._validateCell(index, value, property);
    return value;
  }

  _validateEntryPropertyDatatype(index, value, property) {
    const { name, nullable } = property;
    const type = property.datatype;

    if (value === undefined || value === '') {
      value = null;
    }
    if (nullable === 'N' && value === null) {
      const messageText = `Got null value for non-nullable property: '${name}'`;
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: messageText
      });
      return;
    }
    switch (type.toUpperCase()) {
      case 'TEXT':
        this._validateTextDatatype(index, value, property);
        break;
      case 'INTEGER':
        this._validateIntegerDatatype(index, value, property);
        break;
      case 'DECIMAL':
        this._validateDecimalDatatype(index, value, property);
        break;
      case 'DATETIME':
      case 'DATE':
        this._validateDateTimeDatatype(index, value, property);
        break;
      case 'UUID':
        this._validateGuidDatatype(index, value, property);
        break;
      default:
        console.error(
          `Datatype validation for type: '${type}' is not implemented.`
        );
        break;
    }
  }

  _validateTextDatatype(index, value, property) {
    if (value === null) {
      return;
    }
    const { name } = property;
    const maxLength = property.length;

    if (maxLength === undefined || maxLength.length === 0) {
      const messageText =
        "The length of the column is empty. A column of type 'Text' must have a length";
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: messageText
      });
    }

    if (value.length > maxLength) {
      const messageText = `Value: '${value}' of property: '${name}' exceeds the max length of ${maxLength}.`;
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: messageText
      });
    }
  }

  _validateIntegerDatatype(index, value, property) {
    if (value === null) {
      return;
    }
    const { name } = property;
    const type = property.datatype;

    const parsedValue = parseFloat(value);

    if (!Number.isInteger(parsedValue)) {
      const defaultMessageText = `Value: '${value}' of property: '${name}' is not of type '${type}'.`;
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: defaultMessageText
      });
      return;
    }

    const integerBitRange = 31;
    const minValue = -(2 ** integerBitRange);
    const maxValue = 2 ** integerBitRange - 1;
    if (parsedValue < minValue || parsedValue > maxValue) {
      const messageText = `Value: '${value}' of property: '${name}' should be a number between: ${minValue} and ${maxValue}.`;
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: messageText
      });
    }
  }

  _validateDecimalDatatype(index, value, property) {
    if (value === null) {
      return;
    }
    const { name } = property;
    const type = property.datatype;
    const precision = property.length;
    const scale = property.scale;

    if (typeof value !== 'string') {
      value = String(value);
    }

    if (isNaN(value)) {
      const defaultMessageText = `Value: '${value}' of property: '${name}' is not of type '${type}'.`;
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: defaultMessageText
      });
      return;
    }

    const splitedValue = value.split('.');
    const numberCount = splitedValue[0] ? splitedValue[0].length : 0;
    const decimalCount = splitedValue[1] ? splitedValue[1].length : 0;

    if (
      precision === undefined ||
      precision === null ||
      precision === '' ||
      scale === undefined ||
      scale === null ||
      scale === ''
    ) {
      const defaultMessageText =
        'Can not validate decimal datatype with missing scale or precision.';
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: defaultMessageText
      });
      return;
    }

    if (
      numberCount > parseInt(precision, 10) - parseInt(scale, 10) ||
      decimalCount > parseInt(scale, 10)
    ) {
      const messageText = `Value: '${value}' of property: '${name}' should contain a maximum of ${
        parseInt(precision, 10) - parseInt(scale, 10)
      } numbers and ${scale} decimals.`;
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: messageText
      });
    }
  }

  _validateDateTimeDatatype(index, value, property) {
    if (value === null) {
      return;
    }

    const resultRegex = /^\/Date\((-?\d+)\)\/$/.exec(value);
    if (resultRegex !== null) {
      const integerDate = resultRegex[1];
      const jsDate = new Date(parseInt(integerDate, 10));
      if (jsDate instanceof Date) {
        return;
      }
    }
    const { name } = property;
    const type = property.datatype;
    const defaultMessageText = `Value: '${value}' of property: '${name}' is not of type '${type}' or the format is wrong.`;
    this.errors.push({
      rowIndex: index,
      id: property.name,
      message: defaultMessageText
    });
  }

  _validateGuidDatatype(index, value, property) {
    if (value === null) {
      return;
    }
    var guidRegex =
      /^\s*\{?\s*([0-9A-Fa-f]{8})-?([0-9A-Fa-f]{4})-?([0-9A-Fa-f]{4})-?([0-9A-Fa-f]{4})-?([0-9A-Fa-f]{12})\s*\}?\s*$/;
    var match = guidRegex.exec(value);
    const { name } = property;
    const type = property.datatype;
    if (match === null) {
      const messageText = `Value: '${value}' of property: '${name}' is not of type '${type}' or the format is wrong. A valid Guid shoul be something like 'A0EEBC99-9C0B-4EF8-BB6D-6BB9BD380A11' or
      '{a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a11}'`;
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: messageText
      });
    }
  }

  _applyDataTypeFormating(value, property) {
    let valueToFormat =
      value === null || value === undefined || value === '' ? null : value;
    let valueSerialized;
    const type = property.datatype;
    switch (type.toUpperCase()) {
      case 'DATETIME':
      case 'DATE':
        valueSerialized =
          valueToFormat === null
            ? null
            : this._applyDateTimeDatatypeFormating(valueToFormat);
        break;
      case 'INTEGER':
        valueSerialized = null;
        if (valueToFormat !== null && valueToFormat !== '') {
          const parsedValue = parseInt(valueToFormat, 10);
          if (parsedValue !== 'NaN') {
            valueSerialized = parsedValue;
          }
        }
        break;
      case 'TEXT':
        valueSerialized =
          valueToFormat === null
            ? null
            : applyFormatting(property, valueToFormat);
        break;
      case 'DECIMAL':
        valueSerialized =
          valueToFormat === null
            ? null
            : this._applyDecimalDatatypeFormating(
                String(valueToFormat),
                property
              );
        break;
      case 'UUID':
        var guidRegex =
          /^\s*\{?\s*([0-9A-Fa-f]{8})-?([0-9A-Fa-f]{4})-?([0-9A-Fa-f]{4})-?([0-9A-Fa-f]{4})-?([0-9A-Fa-f]{12})\s*\}?\s*$/;
        var match = guidRegex.exec(valueToFormat);
        valueSerialized =
          match === null
            ? valueToFormat
            : `${match[1]}-${match[2]}-${match[3]}-${match[4]}-${match[5]}`;
        break;
      default:
        valueSerialized = valueToFormat;
    }
    return valueSerialized;
  }

  _applyDecimalDatatypeFormating(stringNumber, property) {
    const scale = property.scale || 0;
    let numberHasSign =
      stringNumber.startsWith('-') || stringNumber.startsWith('+');
    let sign = numberHasSign ? stringNumber[0] : '';
    stringNumber = numberHasSign
      ? stringNumber.replace(sign, '')
      : stringNumber;
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[+-]*\d+/i.test(stringNumber)) {
      let zero = '0';
      let parts = String(stringNumber).toLowerCase().split('e'); //split into coeff and exponent
      let e = parts.pop(); //store the exponential part
      let l = Math.abs(e); //get the number of zeros
      sign = e / l;
      let coeff_array = parts[0].split('.');

      if (sign === -1) {
        coeff_array[0] = Math.abs(coeff_array[0]);
        stringNumber =
          zero + '.' + new Array(l).join(zero) + coeff_array.join('');
      } else {
        let dec = coeff_array[1];
        if (dec) l = l - dec.length;
        stringNumber = coeff_array.join('') + new Array(l + 1).join(zero);
      }
    }

    if (stringNumber !== '') {
      const splitedValue = stringNumber.split('.');
      const strNumber = splitedValue[0] ? splitedValue[0] : '0';
      const strDecimals = splitedValue[1]
        ? splitedValue[1].padEnd(scale, '0').substring(0, scale)
        : ''.padEnd(scale, '0');

      stringNumber = `${strNumber}.${strDecimals}`;
    }

    return `${sign}${stringNumber}`;
  }

  _applyDateTimeDatatypeFormating(valueToFormat) {
    let jsDate;
    let day;
    let month;
    let year;
    let hours;
    let minutes;
    let seconds;

    if (valueToFormat === '') return null;

    // use chosen date format to find object in dateFormatOptions
    // apply exec of formatOption.pattern to parse the data
    // use exec result.groups to get date/time field (year, month,...)
    const regex = this.dateFormatOptions.filter(
      dateFormat => dateFormat.value === this.formatDate
    )[0].pattern;
    const result = regex.exec(valueToFormat);

    if (result != null) {
      let isoValue;
      if (this.formatDate === 'ISO') {
        isoValue = valueToFormat;
      } else {
        day = result.groups.day.padStart(2, '0');
        month = result.groups.month.padStart(2, '0');
        year = result.groups.year;
        hours = result.groups.hours
          ? result.groups.hours.padStart(2, '0')
          : '00';
        minutes = result.groups.minutes
          ? result.groups.minutes.padStart(2, '0')
          : '00';
        seconds = result.groups.seconds
          ? result.groups.seconds.padStart(2, '0')
          : '00';
        isoValue = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
      }
      jsDate = new Date(isoValue);
    }
    return jsDate instanceof Date && !isNaN(jsDate)
      ? `/Date(${jsDate.getTime()})/`
      : valueToFormat; // Is an invalid Date but We keep the value to display it in the errors list;
  }

  _validateCell(index, value, property) {
    if (property.isKeyProperty === 'Y')
      this._validateIsKey(index, value, property);
    this._validateEntryPropertyDatatype(index, value, property);
    this._validateRegexPattern(index, value, property);
  }

  _validateIsKey(index, value, property) {
    if (value === null)
      this.errors.push({
        rowIndex: index,
        id: property.name,
        message: 'Missing Key'
      });
  }

  _validateRegexPattern(index, value, property) {
    const {
      nullable,
      valueList,
      regexPattern,
      validationErrorMessage = ''
    } = property;

    if (regexPattern === null || regexPattern.length === 0) {
      return;
    }
    if (
      (value === undefined || value === null || value === '') &&
      nullable === 'Y'
    ) {
      return;
    }
    const valueIsValid = new RegExp(regexPattern).test(String(value));
    if (valueIsValid) {
      return;
    }
    const validationCustomErrorMessage =
      validationErrorMessage !== ''
        ? validationErrorMessage
        : 'The maintainer can specify a custom error message in the repository configuration';
    const messageText = valueList
      ? 'The value is not in the list.'
      : `Value: '${value}' does not match regex pattern: '${regexPattern}'. ${validationCustomErrorMessage}`;
    this.errors.push({
      rowIndex: index,
      id: property.name,
      message: messageText
    });
  }
}
