// react
import React, { useState, useEffect } from 'react';
// router
import { withRouter } from 'react-router-dom';
// components
import {
  Wizard,
  ModalNotification,
  LoadingScreen
} from '../../components/index';

import TimeLineItems from './TimeLineItems';
import getWizardSteps from './getWizardSteps';

const UploadWizard = props => {
  const [isBusy, setIsBusy] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsBusy(true);
      await props.getTableGroups();
      await props.setSelectedTableGroup(props.match.params.tableGroupId);
      await props.setSelectedTable(
        props.match.params.tableGroupId,
        props.match.params.tableId
      );
      setIsLoaded(true);
      setIsBusy(false);
    };
    if (isLoaded === false) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If it is not loaded the the Loading screen is rendered
  if (isLoaded === false) return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props.selectedTableGroup && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push('/tablegroup')}
      />
    );
  }

  // If no table has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (!props.selectedTable && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props.history.push(`/tablegroup/${props.selectedTableGroup.name}`)
        }
      />
    );
  }

  // If the user doesn't have access to the table
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (
    props.selectedTable &&
    isLoaded === true &&
    props.selectedTable.hasAccess === false
  ) {
    const notification = {
      isNotification: true,
      title: 'Access Denied!',
      message: "The user doesn't have access to the specified table.",
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props.history.push(
            `/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}`
          )
        }
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point all the data needed to render this layout is in place
  // ------------------------------------------------------------------

  const wizardSteps = getWizardSteps(props);
  const resultStatus =
    props.core.errorResponses.length > 0 ? 'error' : 'success';
  const subTitle =
    props.core.errorResponses.length > 0
      ? props.core.errorResponses.map(field => field).join(',')
      : null;

  return (
    <>
      <LoadingScreen isLoading={isBusy || props.isLoading} />
      <Wizard
        removeConfirmation={props.removeConfirmation}
        setOnSelectReplicationTarget={props.setOnSelectReplicationTarget}
        core={props.core}
        subTitle={subTitle}
        confirmation={props.confirmation}
        notification={props.notification}
        removeNotification={props.removeNotification}
        isError={props.isError}
        errorMessage={props.errorMessage}
        modal={props.modal}
        wizardSteps={wizardSteps}
        resultStatus={resultStatus}
        selectedTableId={props.selectedTable.name}
        selectedTableGroupId={props.selectedTableGroup.name}
        timeLineItems={
          props.core.currentStatus === 'completed' && (
            <TimeLineItems
              fileName={props.source.filename}
              batchesNumber={props.core.batchesNumber}
              recordsNumber={props.validation.formattedData.length}
              selectedTableName={props.selectedTable.name}
              selectedTableGroupName={props.selectedTableGroup.name}
            />
          )
        }
        status={resultStatus}
        acceptButtonTitle="New Upload"
        path={`/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}/data`}
        cancelWizard={props.cancelWizard}
        resetWizard={props.resetWizard}
        wizardStepGotoIndex={props.wizardStepGotoIndex}
      />
    </>
  );
};

export default withRouter(UploadWizard);
