import React from 'react';
import { Translate } from 'react-redux-i18n';
// material-ui
import MuiIconButton from '@material-ui/core/IconButton';
import Tooltip from '../Tooltip/Tooltip';
// libs
import getIconByName from '../../lib/util/getIconByName';

const IconButton = props => (
  <Tooltip content={<Translate value={props.toolTipText} />}>
    <MuiIconButton
      style={{ padding: 0 }}
      color={props.color}
      onClick={props.onClick}
      className={props.className}
      data-testid={props.dataTestId}
    >
      {getIconByName(props.iconName, props.fontSize)}
    </MuiIconButton>
  </Tooltip>
);

export default IconButton;
