import { connect } from 'react-redux';
import CoreLayout from './CoreLayout';
import { setIsLoading, removeNotification } from '../../actions/core';

const mapDispatchToProps = {
  setIsLoading,
  removeNotification
};

const mapStateToProps = state => {
  return {
    appViewMode: state.core.appViewMode,
    isError: state.globalError.error,
    errorMessage: state.globalError.errorMessage,
    notification: state.core.notification,
    confirmation: state.core.confirmation
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout);
