import React from 'react';
import { Select } from '@nike/epic-react-ui';
import generateFieldDefinitionsFromSheet from '../../../lib/util/generateFieldDefinitionsFromSheet';

const ExcelOptions = props => {
  const { source, handleChangeData, isContentDefiner } = props;
  const { filetypeOptions } = source;
  const selectedSheet = filetypeOptions
    ? filetypeOptions.selectedSheetItem
    : null;
  const sheetNames = filetypeOptions ? filetypeOptions.sheetNames : [];
  const options = [];
  sheetNames.forEach(sheetName => {
    if (
      filetypeOptions.sheets[sheetName] &&
      filetypeOptions.sheets[sheetName].columns.length > 0
    ) {
      options.push({ value: sheetName, label: sheetName });
    }
  });

  const onExcelSheetSelect = selection => {
    const tableColumns = isContentDefiner
      ? generateFieldDefinitionsFromSheet(
          filetypeOptions.sheets[selection.value]
        )
      : [];
    const data = isContentDefiner
      ? filetypeOptions.sheets[selection.value].data.map(row => {
          return Object.assign(
            ...Object.entries(row).map(([k, v]) => ({ [k]: v.value }))
          );
        })
      : filetypeOptions.sheets[selection.value].data;

    const resultObject = {
      filetypeOptions: {
        ...filetypeOptions,
        selectedSheetName: selection.value,
        selectedSheetItem: selection,
        dateFormat: 'ISO'
      },
      data: {
        meta: {
          addGeneratedKeyColumn: source.data.meta.addGeneratedKeyColumn,
          columns: filetypeOptions.sheets[selection.value].columns,
          columnsDefinition: tableColumns,
          generatedColumns: source.data.meta.generatedColumns
        },
        contents: data
      }
    };

    handleChangeData(resultObject);
  };

  if (options.length > 0) {
    return (
      <Select
        label="Sheet"
        className="select-excelsheet"
        options={options}
        value={selectedSheet}
        placeholder="Select a Sheet"
        onChange={selection => onExcelSheetSelect(selection)}
      />
    );
  } else {
    return null;
  }
};

export default ExcelOptions;
