import React from 'react';
import { ListHelper, DatePickerHelper } from '../index';
import { Popover } from 'antd';

const UpdatableCellComponent = ({
  typeConfiguration,
  cellValue,
  columnMetaData,
  isDisabled,
  error,
  selectedRowPosition,
  cellInfo,
  textAlignCell,
  setSelectedRow,
  addModal,
  removeModal,
  onUpdatableCellFromHelper,
  onBlur
}) => {
  const [value, setValue] = React.useState(cellValue);
  const [initialValue, setInitialValue] = React.useState(cellValue);

  const hasValues = columnMetaData?.valueList?.length > 0;
  const isDatePicker = typeConfiguration.controlConstructor === 'DatePicker';
  const inputListCss =
    (hasValues || isDatePicker) && !isDisabled ? 'inner-input-list' : '';

  const handleOpenHelper = async () => {
    if (columnMetaData?.valueList?.length > 0) {
      const list = columnMetaData?.valueList?.split(';').map(ele => {
        return { [columnMetaData.label]: ele };
      });
      if (selectedRowPosition !== cellInfo.index)
        await setSelectedRow(cellInfo.original, cellInfo.index);
      addModal({
        id: 'list-helper',
        children: (
          <ListHelper
            propertyLabel={columnMetaData.label}
            list={list}
            removeModal={removeModal}
            acceptValue={selectedValue => {
              setValue(selectedValue);
              onUpdatableCellFromHelper(selectedValue, cellInfo);
            }}
            initialValue={value}
          />
        ),
        cancelCallback: () => removeModal(),
        modalSize: 'sm',
        customClass: 'list-helper-modal',
        showIcon: true
      });
    } else {
      addModal({
        id: 'datepicker-helper',
        children: (
          <DatePickerHelper
            datatype={columnMetaData.datatype}
            removeModal={removeModal}
            acceptDate={date => {
              setValue(date);
              onUpdatableCellFromHelper(date, cellInfo);
            }}
            initialValue={value}
          />
        ),
        cancelCallback: () => removeModal(),
        modalSize: 'sm',
        customClass: 'datepicker-helper-modal',
        showIcon: true
      });
    }
  };
  const componentToReturn = (
    <div
      key={`input-comp-${columnMetaData.name}`}
      className="editable-table-input-wrapper"
    >
      <input
        key={`input-${columnMetaData.name}`}
        name={`input-${columnMetaData.name}`}
        type={typeConfiguration.inputType}
        onChange={event => setValue(event.target.value)}
        onBlur={event => {
          event.preventDefault();
          if (initialValue !== value) onBlur(event);
        }}
        value={value}
        readOnly={isDisabled}
        disabled={isDisabled}
        style={{
          textAlign: `${textAlignCell}`,
          width: '100%',
          height: '27px'
        }}
        className={`${inputListCss}
          ${
            error
              ? 'inputError editable-table-input-inner'
              : 'cell-input editable-table-input-inner'
          }`}
        title={error ? error.mesage : ''}
      />
      {(hasValues || isDatePicker) && !isDisabled && (
        <button
          onClick={handleOpenHelper}
          className="editable-table-helper-button"
        >
          ...
        </button>
      )}
    </div>
  );
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(cellValue);
    setInitialValue(cellValue);
  }, [cellValue]);

  return error ? (
    <Popover content={error.message}>{componentToReturn}</Popover>
  ) : (
    componentToReturn
  );
};

export default UpdatableCellComponent;
