import React, { useState, useEffect } from 'react';

import {
  FileInput,
  Table,
  FormSection,
  TablePagination,
  Progress
} from '../../index';

import SourceSelectForm from './SourceSelectForm';
import ParseFileWorker from '../../../lib/parser/parseFile.worker';
import { I18n } from 'react-redux-i18n';

import { tableHeaders } from '../../../constants/tableHeaders';
import { getPreviewTableHeaders } from '../../../lib/util/getPreviewTableHeaders';
import './sourceSelect.css';

const errorHandler = (error, cb) => {
  const message = error.message || I18n.t('wizard.fileParser.generalErrorBody');
  const stack = error.stack || './parseFile.worker.js';

  cb({
    isModal: true,
    title: I18n.t('wizard.fileParser.generalErrorTitle'),
    message
  });

  console.error(`${message} ${stack}`);
};

const onMessageHandler = ({
  message,
  handleChangeFile,
  handleChangeData,
  addNotification,
  setCsvParserOptions,
  setProgressVisibility,
  isContentDefiner
}) => {
  const { error, result } = message.data;
  setProgressVisibility(false);

  if (error) {
    errorHandler(error, addNotification);
  } else if (result) {
    const {
      filename,
      extension,
      fileSize,
      textFileContent,
      processedFile,
      parserErrorMessage
    } = result;

    if (parserErrorMessage) {
      errorHandler(new Error(parserErrorMessage), addNotification);
    }

    const isCsv = ['csv', 'txt', 'tsv'].indexOf(extension) !== -1;
    if (!isContentDefiner && isCsv) {
      setCsvParserOptions({
        delimiter: processedFile.data.options.delimiter,
        dateFormat: processedFile.data.options.dateFormat
      });
    }

    handleChangeFile({
      fileContents: textFileContent,
      fileSize,
      filename,
      filetype: extension
    });

    handleChangeData(processedFile);
  }
};

const SourceSelect = props => {
  const {
    source,
    handleChangeFile,
    handleChangeData,
    isContentDefiner,
    accept,
    addNotification,
    csvParserOptions,
    setCsvParserOptions
  } = props;
  const { data } = source;

  const [isProgressVisible, setProgressVisibility] = useState(false);
  const [file, setFile] = useState(null);
  const [csvParserConfig, setCsvParserConfig] = useState({});

  useEffect(() => {
    let worker = new ParseFileWorker();

    const messageListener = message => {
      onMessageHandler({
        message,
        handleChangeFile,
        handleChangeData,
        addNotification,
        csvParserOptions,
        setCsvParserOptions,
        setProgressVisibility,
        isContentDefiner
      });
    };
    const errorListener = event => errorHandler(event, addNotification);

    worker.addEventListener('message', messageListener);
    worker.addEventListener('error', errorListener);

    if (file) {
      worker.postMessage({ file, csvParserConfig, isContentDefiner });
    }

    return () => {
      worker.removeEventListener('message', messageListener);
      worker.removeEventListener('error', errorListener);
      worker.terminate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    file,
    addNotification,
    csvParserConfig,
    handleChangeData,
    handleChangeFile,
    isContentDefiner
  ]);

  const onFileChange = ({ target }) => {
    if (file) {
      setFile(null);
      if (!isContentDefiner) {
        setCsvParserOptions({ delimiter: null, dateFormat: null });
      }
    }

    setProgressVisibility(true);
    const [uploadedFile] = target.files;
    setFile(uploadedFile);
  };

  const handleDelimiterChange = value => {
    const delimiter = { delimiter: value };
    setCsvParserOptions({ ...csvParserOptions, ...delimiter });
    handleConfigChange(delimiter);
  };

  const handleDateFormatChange = value => {
    const dateFormat = { dateFormat: value };
    setCsvParserOptions({ ...csvParserOptions, ...dateFormat });
    handleConfigChange(dateFormat);
  };

  const handleConfigChange = config => {
    setProgressVisibility(false);
    setCsvParserConfig({ ...csvParserConfig, ...config });
  };

  const columns = isContentDefiner
    ? tableHeaders
    : getPreviewTableHeaders(data);

  const title = `2. Source  ${isContentDefiner ? '(Excel files only)' : ''} `;
  const dataTable = isContentDefiner
    ? data.meta.columnsDefinition
    : data.contents;
  return (
    <div data-testid="source-select">
      <FormSection className="formGeneralSection" title={title}>
        <FileInput
          className="file-input"
          containerClass="file-input-container"
          label="File"
          accept={accept}
          imgUrl={source.filename}
          onChange={onFileChange}
        />
        <SourceSelectForm
          source={source}
          handleChangeData={handleChangeData}
          isContentDefiner={isContentDefiner}
          handleDelimiterChange={handleDelimiterChange}
          handleDateFormatChange={handleDateFormatChange}
          dateFormat={source.csvParserOptions.dateFormat}
          delimiter={source.csvParserOptions.delimiter}
        />
        {isProgressVisible && <Progress />}
      </FormSection>
      {data?.contents?.length > 0 ? (
        <FormSection className="formPreviewSection" title="Preview">
          <Table
            columns={columns}
            data={dataTable}
            defaultPageSize={10}
            pageSize={10}
            PaginationComponent={TablePagination}
            showPagination={true}
          />
        </FormSection>
      ) : null}
    </div>
  );
};

export default SourceSelect;
