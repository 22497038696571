import { endPoints } from '../constants/endPoints';
import { http } from '../lib/util/http';
import { getQueryString } from '../lib/util/getQueryString';

export const getTablesWithMetadata = async tableGroupName => {
  const metadataUri = `${endPoints.metadata}/${tableGroupName}?anchor=0&count=3000&sort=NAME asc`;
  return getTables(metadataUri);
};

const getTables = async url => {
  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };

  const response = await http('get', url, {}, headers);
  if (response.status !== 200) {
    throw new Error();
  }
  return response?.data?.tables || [];
};

export const fetchTables = async tableGroup => {
  try {
    const tables = await getTablesWithMetadata(tableGroup.name);

    return {
      tables
    };
  } catch (error) {
    console.error(error);
    return {
      error
    };
  }
};

export const getTableCount = async (tableGroupId, tableId) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const queryString = getQueryString({
    anchor: 0,
    count: 1,
    sort: 'OPERATION_TIMESTAMP  desc'
  });

  const uri = `${endPoints.records.get}/${tableGroupId}/${tableId}/records${queryString}`;
  const res = await http('get', uri, {}, defaultHeaders);
  return { rowCount: res?.data?.totalCount ?? 0 };
};

export const createTable = async table => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const uri = `${endPoints.metadata}/${table.tableGroupName}/tables`;
  return http('post', uri, table, defaultHeaders);
};

export const deleteTable = async table => {
  const uri = `${endPoints.tableGroups.tables.d}`;
  return http('delete', uri, table);
};

export const updateTable = async table => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const uri = `${endPoints.metadata}/${table.tableGroupName}/tables/${table.name}`;
  return http('put', uri, table, defaultHeaders);
};
