import { connect } from 'react-redux';
import SecureComponent from './SecureComponent';
import { setAuthService, getIsAdmin } from '../../actions/core';

const mapDispatchToProps = { setAuthService, getIsAdmin };

const mapStateToProps = state => {
  return {
    authServiceStore: state.core.authService,
    isAdmin: state.core.isAdmin
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecureComponent);
