// react
import React from 'react';
// other
import _ from 'lodash';
// components
import {
  List,
  Button,
  TextInput,
  DatePicker,
  Tooltip,
  ModalNotification
} from '../../../components';
import { Icon } from '@nike/epic-react-ui';
// sub-components
import InputBulkList from './InputBulkList';
// utils
import applyFormatting from '../../../lib/util/applyFormatting';
import DataTransformer from '../../../lib/util/DataTransformer';
import tweakJsDate from '../../../lib/util/tweakJsDate';
import mapRecordsToRows from '../../../lib/util/mapRecordsToRows';

const styles = {
  topWrapper: { padding: '1px 0px 1px', backgroundColor: '#f7f7f7' },
  actionsContainer: {
    width: '95%',
    margin: '15px'
  },
  inputText: { width: '480px' },
  listContainer: {
    border: '1px solid #e8e8e8',
    borderRadius: '4px',
    overflow: 'auto',
    padding: '8px 24px',
    height: '300px'
  },
  deleteListButton: { background: 'red' }
};

const dataTypeEnum = {
  integer: 'number',
  decimal: 'number',
  text: 'text',
  datetime: 'datetime',
  date: 'date',
  uuid: 'uuid'
};

const ListValues = props => {
  const { cellInfo, updateColumSpec, removeModal, setIsLoading } = props;
  const stringList = cellInfo.original.valueList;
  const splitedList = stringList?.length > 0 ? stringList.split(';') : [];
  const data = splitedList.map(ele => {
    return ele;
  });
  const [value, setValue] = React.useState('');
  const [list, setList] = React.useState(data);
  const [parentCellInfo] = React.useState(cellInfo);
  const [hasSingleError, setHasSingleError] = React.useState(false);
  const [singleErrorMessage, setSingleErrorMessage] = React.useState('');
  const [bulkListErrors, setBulkListErrors] = React.useState([]);
  const [errorNotification, setErrorNotification] = React.useState({
    props: {},
    showErrorNotification: false
  });
  const [showBulkEntries, setShowBulkEntries] = React.useState(false);
  const [type] = React.useState(
    dataTypeEnum[cellInfo.original.datatype.toLowerCase()]
  );

  const handleDelete = item => {
    const newList = list.filter(ele => {
      return ele !== item;
    });
    setList(newList);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleKeyDown = event => {
    if (
      event.keyCode === 186 ||
      (event.keyCode === 69 && event.target.type === 'number')
    ) {
      event.preventDefault();
    }
  };

  const handleBlur = event => {
    const { original } = parentCellInfo;
    const row = [{ [original.name]: event.target.value }];

    const dataTransformer = new DataTransformer(row, [original]);
    dataTransformer.transformRows();

    const errors = dataTransformer.getErrors();

    if (errors.length === 0) {
      setHasSingleError(false);
      setSingleErrorMessage('');
      const valueFormatted = applyFormatting(original, event.target.value);
      setValue(valueFormatted);
    } else {
      setHasSingleError(true);
      setSingleErrorMessage(errors[0].message);
    }
  };

  const handleAddNewValue = () => {
    if (hasSingleError) return;
    if (value) {
      const counter = list.filter(ele => {
        return ele === value;
      }).length;
      if (counter === 0) setList(list.concat(value));
    }
    setValue('');
  };

  const handleDeleteEntireList = () => {
    setList([]);
    setBulkListErrors([]);
  };

  const handleBulkUpload = () => {
    setShowBulkEntries(!showBulkEntries);
  };

  const handleAccept = () => {
    if (bulkListErrors.length > 0) {
      addErrorNotification(
        'There is at least one error with the values. Please check the items highlighted in red. Hovering over the item, you will see the error description'
      );
    } else {
      cellInfo.column.id = 'valueList';
      cellInfo.original.regexPattern = '';
      cellInfo.original.validationErrorMessage = '';
      updateColumSpec({
        cellInfo: { ...cellInfo },
        value: list.join(';')
      });
      removeModal();
    }
  };

  const addErrorNotification = message => {
    setErrorNotification({
      props: {
        notification: {
          isNotification: true,
          isModal: true,
          title: 'Error',
          message: message
        },
        removeNotification: () => removeErrorNotification()
      },
      showErrorNotification: true
    });
  };

  const removeErrorNotification = () => {
    setErrorNotification({
      props: {},
      showErrorNotification: false
    });
  };

  const processBulkUpload = autoParse => {
    const { original } = parentCellInfo;
    const prevList = [...list];

    if (autoParse === undefined || autoParse === null) {
      addErrorNotification(
        'Something went wrong processing the file, please check the file and try again'
      );
      return;
    }
    if (autoParse.meta.fields.length > 1) {
      addErrorNotification(
        'The file contains more than one field. To do bulk loading the file can only contain one field and the Header must be equal to the column name'
      );
      return;
    }
    if (
      autoParse.meta.fields[0].toUpperCase() !== original.name.toUpperCase()
    ) {
      addErrorNotification(
        `The Header '${autoParse.meta.fields[0].toUpperCase()}' in the file doesn't match the column name '${original.name.toUpperCase()}'. the Header must be equal to the column name`
      );
      return;
    }

    const dataParsed = autoParse.data;
    const newList = [];

    if (dataParsed === undefined) {
      addErrorNotification(
        'Something went wrong processing the file, please check the file and try again'
      );
      return;
    }

    if (dataParsed.length > 0) {
      const dataCleaned = _.uniqBy(
        dataParsed.filter(ele => ele[original.name.toUpperCase()] !== ''),
        original.name.toUpperCase()
      );

      const dataTransformer = new DataTransformer(dataCleaned, [original]);
      dataTransformer.transformRows();

      const dataTransformed = dataTransformer.getTransformedRows();
      const dataErrors = dataTransformer.getErrors();
      const listItems = mapRecordsToRows(dataTransformed);
      listItems.forEach(eleTrans => {
        const valueParsed = checkValueDatatype(
          eleTrans[original.name.toUpperCase()],
          original.datatype
        );
        const counter = prevList.filter(eleList => {
          return eleList === valueParsed;
        }).length;
        if (counter === 0 && valueParsed && valueParsed.length > 0) {
          newList.push(valueParsed);
        }
      });

      if (newList.length > 0) setList(list.concat(newList));
      if (dataErrors.length > 0) setBulkListErrors(dataErrors);
    }
  };

  const checkValueDatatype = (valueToCheck, datatype) => {
    switch (datatype.toUpperCase()) {
      case 'DATETIME':
        return new Date(
          parseInt(/^\/Date\((-?\d+)\)\/$/.exec(valueToCheck)[1], 10)
        ).toISOString();
      case 'DATE':
        return tweakJsDate(
          new Date(parseInt(/^\/Date\((-?\d+)\)\/$/.exec(valueToCheck)[1], 10))
        ).substr(0, 10);
      default:
        return String(valueToCheck).replace(/[*']/g, '');
    }
  };

  const handleDatedPickerChange = valueDatePicker => {
    let strValue;
    if (valueDatePicker !== null && valueDatePicker !== undefined) {
      if (type.toUpperCase() === 'DATE') {
        valueDatePicker = new Date(valueDatePicker.setHours(0, 0, 0, 0));
        strValue = tweakJsDate(valueDatePicker).substr(0, 10);
      } else {
        strValue = tweakJsDate(valueDatePicker);
      }
    } else {
      strValue = '';
    }
    setValue(strValue);
  };

  const getShowTimeInput = dataType =>
    dataType.toUpperCase() === 'DATETIME' ? true : false;

  const getDateFormat = dataType =>
    dataType.toUpperCase() === 'DATETIME'
      ? 'yyyy/mm/dd hh:mm:ss'
      : 'yyyy/mm/dd';

  const getSelected = selectedValue =>
    selectedValue !== undefined && selectedValue !== ''
      ? new Date(selectedValue.toString().replace('Z', ''))
      : new Date();

  const inputElement =
    type === 'datetime' || type === 'date' ? (
      <DatePicker
        value={value}
        selected={getSelected(value)}
        isClearable={false}
        onChange={handleDatedPickerChange}
        dateFormat={getDateFormat(type)}
        showTimeInput={getShowTimeInput(type)}
        className="form-react-datepicker__input-container__input"
      />
    ) : (
      <TextInput
        value={value}
        type={type}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        border={true}
        style={styles.inputText}
        maxLength={parentCellInfo.original.length}
        hasErrors={hasSingleError}
        errorMessage={singleErrorMessage}
      />
    );

  const singleEntry = (
    <div style={styles.topWrapper}>
      <div style={styles.actionsContainer}>
        <div className="bootstrap-row">
          <div className="bootstrap-col-md-9">{inputElement}</div>
          <div className="bootstrap-col-md-1">
            <Button
              small
              onClick={() => {
                handleAddNewValue();
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
  const bulkEntries = (
    <InputBulkList
      processBulkUpload={processBulkUpload}
      setIsLoading={isLoading => setIsLoading(isLoading)}
    />
  );

  const getEntryComponent =
    showBulkEntries === false ? singleEntry : bulkEntries;

  const getItemDescription = item => {
    const { original } = parentCellInfo;
    if (bulkListErrors.length > 0) {
      const itemError = bulkListErrors.filter(err => {
        return (
          (original.datatype.toUpperCase() === 'DECIMAL' &&
            err.rowIndex[err.id].replace(',', '.') === item) ||
          err.rowIndex[err.id] === item
        );
      })[0];
      const errorMessage = itemError ? itemError.message : '';
      if (errorMessage !== '') {
        return (
          <Tooltip content={errorMessage}>
            <p style={{ color: 'red' }}>{item}</p>
          </Tooltip>
        );
      } else {
        return item;
      }
    } else {
      return item;
    }
  };

  return (
    <div>
      {getEntryComponent}
      <div style={styles.listContainer}>
        <List
          dataSource={list}
          renderItem={item => (
            <List.Item key={item}>
              <List.Item.Meta description={getItemDescription(item)} />
              <Button small onClick={() => handleDelete(item)}>
                <Icon type="delete" />
              </Button>
            </List.Item>
          )}
        />
      </div>
      <div className="modal-button-wrapper">
        <Button onClick={handleAccept}>Accept</Button>
        <Button onClick={removeModal}>Cancel</Button>
        <Button onClick={handleBulkUpload}>
          {showBulkEntries === true ? 'Single Upload' : 'Upload Bulk'}
        </Button>
        <Button
          style={styles.deleteListButton}
          onClick={handleDeleteEntireList}
        >
          Delete List
        </Button>
      </div>
      {errorNotification.showErrorNotification === true && (
        <ModalNotification props={errorNotification.props} />
      )}
    </div>
  );
};
export default ListValues;
