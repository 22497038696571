import React from 'react';
import { Notification } from '../../components/index';

const GlobalError = props => {
  const { isError, errorMessage } = props;
  if (isError !== true) return null;
  const notification = {
    message: errorMessage,
    isNotification: true,
    isModal: false
  };
  return <Notification isLoading={false} notification={notification} />;
};

export default GlobalError;
