export const getQueryString = queries => {
  let queryString = '?';
  for (let queryName in queries) {
    const queryValue = queries[queryName];
    if (queryValue === '') {
      continue;
    }
    queryString += `${queryName}=${queryValue}&`;
  }

  let result = queryString.substring(0, queryString.length - 1);
  return result;
};
