import React from 'react';
import getIconByName from '../../lib/util/getIconByName';
import { Tooltip } from '../index';

const getCheckboxColumn = checkboxCell => {
  return [
    {
      id: 'checkbox',
      Header: <div className="checkBoxHeader"> </div>,
      maxWidth: 20,
      Cell: checkboxCell
    }
  ];
};

const getOverviewTableColumns = (properties, updatableCell) =>
  properties.map(property => ({
    id: property.name,
    Header: (
      <Tooltip content={property.name}>
        <div className="row" style={{ marginLeft: 0 }}>
          <div className="divHeaderTableLabel">
            <label className="headerTableLabel">
              {property.nullable === 'N' ? '*  ' : ''}
              {property.label}
            </label>
            {getIconByName(property.datatype, 16)}
            {property.isKeyProperty === 'Y' && getIconByName('keyIcon')}
            {!!property.valueGeneratorName === true &&
              getIconByName('FingerPrint')}
          </div>
        </div>
      </Tooltip>
    ),
    accessor: property.name,
    Cell: updatableCell,
    style: {
      maxHeight: '27px',
      height: '27px'
    }
  }));

const EditableTableColumns = (
  properties,
  updatableCell,
  checkboxCell,
  isOverviewTableEditing
) => {
  const chekboxColumn =
    isOverviewTableEditing === true ? getCheckboxColumn(checkboxCell) : [];
  const overviewTableColumns = getOverviewTableColumns(
    properties,
    updatableCell
  );
  return Object.assign([], [...chekboxColumn, ...overviewTableColumns]);
};

export default EditableTableColumns;
