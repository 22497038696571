import React from 'react';

const CheckboxCellComponent = props => {
  const [value, setValue] = React.useState(props.cellValue);

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(props.cellValue);
  }, [props.cellValue]);

  return (
    <div key={'input-comp-checkbox'} className="checkBoxHeader">
      <input
        key={'input-checkbox'}
        name={'input-checkbox'}
        type="checkbox"
        onChange={event => {
          setValue(event.currentTarget.checked);
          props.onChange(event);
        }}
        checked={value}
        className="cell-input"
      />
    </div>
  );
};

export default CheckboxCellComponent;
