const getLight = value => {
  switch (value.toLowerCase()) {
    case 'running':
      return 'green';
    case 'revoked':
    case 'error':
      return 'red';
    case 'degraded':
      return 'orange';
    case 'paused':
      return 'yellow';
    default:
      return 'gray';
  }
};

export default getLight;
