import React from 'react';
import Select from 'react-select/creatable';
import PropTypes from 'prop-types';
import './creatableSelect.css';

const CreatableSelect = ({
  options,
  value,
  onChange,
  autoFocus,
  isDisabled,
  isClearable,
  placeholder,
  inputValue,
  onInputChange,
  menuIsOpen,
  onMenuOpen,
  onMenuClose
}) => (
  <Select
    className="Select"
    classNamePrefix={'Select'}
    options={options}
    onChange={onChange}
    value={value}
    autoFocus={autoFocus}
    placeholder={placeholder}
    isDisabled={isDisabled}
    isClearable={isClearable}
    inputValue={inputValue}
    onInputChange={onInputChange}
    menuIsOpen={menuIsOpen}
    onMenuOpen={onMenuOpen}
    onMenuClose={onMenuClose}
  />
);

CreatableSelect.propTypes = {
  /** The options the user can select */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  /** Currently selected option */
  value: PropTypes.any,
  /** Label for select */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]),
  /** Function triggered on change events */
  onChange: PropTypes.func.isRequired,
  /** Focus this field on load */
  autoFocus: PropTypes.bool,
  /** Disable this field */
  isDisabled: PropTypes.bool,
  /** Make clearable this field */
  isClearable: PropTypes.bool,
  /** Placeholder text displayed when no option is selected */
  placeholder: PropTypes.string,
  /** Current filter input value */
  inputValue: PropTypes.string,
  /** Function triggered on filter input change */
  onInputChange: PropTypes.func,
  /** Select is currently open */
  menuIsOpen: PropTypes.bool,
  /** Function triggered on select open */
  onMenuOpen: PropTypes.func,
  /** Function triggered on select closed */
  onMenuClose: PropTypes.func
};

CreatableSelect.defaultProps = {
  options: [],
  autoFocus: false,
  isDisabled: false,
  isClearable: false,
  placeholder: 'Select...'
};

export default CreatableSelect;
