let errors = [];

const validation = props => {
  const {
    source,
    wizardStepStatusSet,
    wizardStepGotoNext,
    addNotification,
    setDefinitionErrors
  } = props;

  const { data } = source;
  const { meta } = data;
  const { columnsDefinition, generatedColumns } = meta;
  errors = [];
  setDefinitionErrors(errors);

  if (columnsDefinition.length === 0) {
    wizardStepStatusSet('error');
    const notification = {
      isModal: true,
      title: 'Columns Definition',
      message: 'No Columns found, A Table without Columns cannot be created'
    };
    addNotification(notification);
    return;
  }

  columnsDefinition.forEach(columnDefinition => {
    _checkColumDefinition(columnDefinition);
    _checkDuplicateColumnDefinition(columnDefinition, columnsDefinition);
    _checkReservedNamesColumnDefinition(columnDefinition, generatedColumns);
  });

  const generatedKeyColumnsCount =
    generatedColumns.filter(column => {
      return column.isKeyProperty === 'Y';
    }).length || 0;

  const keyColumnsCount =
    columnsDefinition.filter(column => {
      return column.isKeyProperty === 'Y';
    }).length || 0;

  const totalKeys = generatedKeyColumnsCount + keyColumnsCount;
  if (totalKeys === 0) {
    wizardStepStatusSet('error');
    const notification = {
      isModal: true,
      title: 'Columns Definition',
      message:
        'No Key found. A table without at least one Key column cannot be created'
    };
    addNotification(notification);
    return;
  }

  if (errors.length > 0) {
    wizardStepStatusSet('error');
    const notification = {
      isModal: true,
      title: 'Columns Definition',
      message: `${errors.length} issues were found validating the Columns Definition, Please check the errors in the Table`
    };
    addNotification(notification);
    setDefinitionErrors(errors);
    return;
  }

  wizardStepGotoNext();
};

const _checkDuplicateColumnDefinition = (
  columnDefinition,
  columnsDefinition
) => {
  const { name } = columnDefinition;
  const countColumnName = columnsDefinition.filter(column => {
    return name.toUpperCase() === column.name.toUpperCase();
  }).length;

  if (countColumnName > 1) {
    const defaultMessageText =
      'The Name of a Column is more than once. Column names cannot be duplicated.';
    errors.push({
      rowIndex: columnDefinition,
      id: 'name',
      message: defaultMessageText
    });
  }
};

const _checkReservedNamesColumnDefinition = (
  columnDefinition,
  generatedColumns
) => {
  const { name } = columnDefinition;
  const countColumnName = generatedColumns.filter(column => {
    return name.toUpperCase() === column.name.toUpperCase();
  }).length;

  if (countColumnName > 0) {
    const defaultMessageText = `The name ${name} is reserved for system Columns, therefore it cannot be used as a Column Name.`;
    errors.push({
      rowIndex: columnDefinition,
      id: 'name',
      message: defaultMessageText
    });
  }
};

const _checkColumDefinition = columnDefinition => {
  _checkNameColumn(columnDefinition);
  switch (columnDefinition.datatype.toUpperCase()) {
    case 'DECIMAL':
      _checkDecimalDataType(columnDefinition);
      break;
    case 'TEXT':
      _checkTextDataType(columnDefinition);
      break;
    default:
      console.log(columnDefinition);
  }
};

const _checkTextDataType = columnDefinition => {
  _checkLengthColumn(columnDefinition);
  _checkPattern(columnDefinition);
};

const _checkDecimalDataType = columnDefinition => {
  _checkLengthColumn(columnDefinition);
  _checkDecimalsColumn(columnDefinition);
};

const _checkNameColumn = columnDefinition => {
  const fieldNamePattern = /^[a-zA-Z]+([_]?[A-Za-z0-9])*$/;
  const fieldNameMaxLength = 63;
  const { name } = columnDefinition;

  if (name === null || name === undefined || name === '') {
    const defaultMessageText = 'The Name of a Column never can be empty.';
    errors.push({
      rowIndex: columnDefinition,
      id: 'name',
      message: defaultMessageText
    });
    return;
  }

  if (name.length > fieldNameMaxLength) {
    const messageText = `The Length of the property: '${name}' cannot be greater than 63 characters.`;
    errors.push({
      rowIndex: columnDefinition,
      id: 'name',
      message: messageText
    });
    return;
  }

  if (!fieldNamePattern.test(name)) {
    const messageText = `Field name '${name}' is invalid. Fieldnames must start with a letter and contain only word characters (letters, numbers, and underscore)`;
    errors.push({
      rowIndex: columnDefinition,
      id: 'name',
      message: messageText
    });
  }
};

const _checkLengthColumn = columnDefinition => {
  const { length, name } = columnDefinition;

  if (length === null || length === undefined || length === '') {
    const defaultMessageText = `'Length' of property '${name}' cannot be empty.`;
    errors.push({
      rowIndex: columnDefinition,
      id: 'length',
      message: defaultMessageText
    });
    return;
  }
  if (length < 1) {
    const defaultMessageText = `'Length' of property '${name}' cannot be lower than 1.`;
    errors.push({
      rowIndex: columnDefinition,
      id: 'length',
      message: defaultMessageText
    });
    return;
  }
  const integerBitRange = 31;
  const minValue = -(2 ** integerBitRange);
  const maxValue = 2 ** integerBitRange - 1;

  if (length < minValue || length > maxValue) {
    const messageText = `'Length' of property: '${name}' should be a number between: ${minValue} and ${maxValue}.`;
    errors.push({
      rowIndex: columnDefinition,
      id: 'length',
      message: messageText
    });
  }
};

const _checkPattern = columnDefinition => {
  const { regexPattern, name } = columnDefinition;

  if (
    regexPattern === null ||
    regexPattern === undefined ||
    regexPattern === ''
  ) {
    return;
  }

  if (regexPattern.length > 0) {
    try {
      new RegExp(regexPattern);
    } catch (ex) {
      const messageText = `'Pattern' of property: '${name}' is not a valid Regex Expression.`;
      errors.push({
        rowIndex: columnDefinition,
        id: 'regexPattern',
        message: messageText
      });
    }
  }
};

const _checkDecimalsColumn = columnDefinition => {
  const { scale, name } = columnDefinition;

  if (scale === null || scale === undefined || scale === '') {
    const defaultMessageText = `'Decimals' of property '${name}' cannot be empty. At least '0' should be specified`;
    errors.push({
      rowIndex: columnDefinition,
      id: 'decimals',
      message: defaultMessageText
    });
    return;
  }
  const integerBitRange = 31;
  const minValue = -(2 ** integerBitRange);
  const maxValue = 2 ** integerBitRange - 1;

  if (scale < minValue || scale > maxValue) {
    const messageText = `'Decimals' of property: '${name}' should be a number between: ${minValue} and ${maxValue}.`;
    errors.push({
      rowIndex: columnDefinition,
      id: 'decimals',
      message: messageText
    });
  }
};

export default validation;
