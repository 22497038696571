import React from 'react';
import {
  ToggleSwitch,
  Table,
  FormSection,
  Label,
  Tooltip,
  Tag,
  Badge
} from '../../../../components/index';

class DetailsErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorColumnsOnly: false
    };
  }
  detailsTableColumns = [
    {
      Header: 'Column Name',
      accessor: 'columnName',
      width: 150,
      style: {
        height: '25px'
      }
    },
    {
      Header: '# Valid Values',
      accessor: 'totalValid',
      width: 150,
      style: {
        height: '25px'
      }
    },
    {
      Header: '# Error Values',
      accessor: 'totalErrors',
      width: 150,
      style: {
        height: '25px'
      }
    },
    {
      Header: 'Distinct Error Values',
      accessor: 'distinctErrorValues',
      width: 150,
      style: {
        height: '25px'
      }
    },
    {
      Header: 'Erroneous Values',
      accessor: 'errorValues',
      cell: errorValues => {
        return errorValues;
      },
      style: {
        height: '25px'
      }
    }
  ];

  onSelect(selected) {
    this.setState({ showErrorColumnsOnly: selected });
  }

  render() {
    const { targetColumnMeta, errorsByColumn, totalRows } = this.props;
    const { showErrorColumnsOnly } = this.state;

    const validateColumnData = () => {
      const validatedColumns = [];
      for (let i = 0; i < targetColumnMeta.length; i++) {
        const col = targetColumnMeta[i];
        const groupedError = errorsByColumn[col.name];
        const isValid = !groupedError;

        if (this.state.showErrorColumnsOnly && isValid) {
          continue;
        }

        let errorValues = null;
        if (!isValid) {
          const topTenErrorValues = groupedError.groupedValuesArray.slice(0, 5);
          if (groupedError.groupedValuesArray.length > 5) {
            topTenErrorValues.push({
              count: 1,
              message: `Only the top 5 error values are displayed. In total there are ${groupedError.groupedValuesArray.length} distinct erroneous error values.`,
              valueName: '...'
            });
          }

          errorValues = topTenErrorValues.map((groupedValues, index) => {
            return (
              <Tooltip
                key={groupedValues.message}
                content={groupedValues.message}
              >
                <Badge
                  count={groupedValues.count === 1 ? 0 : groupedValues.count}
                  style={{ marginTop: '3px' }}
                >
                  <Tag color="red">{groupedValues.valueName}</Tag>
                </Badge>
              </Tooltip>
            );
          });
        }
        validatedColumns.push({
          key: `table-columndetails-${i}`,
          columnName: col.name,
          totalErrors: isValid
            ? '0 (0%)'
            : `${groupedError.totalErrors} (${(
                (groupedError.totalErrors / totalRows) *
                100
              ).toFixed(1)}%)`,
          totalValid: isValid
            ? `${totalRows} (100%)`
            : `${totalRows - groupedError.totalErrors} (${(
                100 -
                (groupedError.totalErrors / totalRows) * 100
              ).toFixed(1)}%)`,
          distinctErrorValues: isValid
            ? 0
            : Object.keys(groupedError.groupedValues).length,
          errorValues
        });
      }
      return validatedColumns;
    };
    const detailsTableData = validateColumnData();
    let optionsList = [
      { value: true, label: 'On' },
      { value: false, label: 'Off' }
    ];
    return (
      <FormSection
        className="formPreviewSection"
        title="2. Error Details: By Column"
      >
        <div style={{ fontFamily: 'Nike Trade Gothic' }}>
          <Label label="Show only errors">
            <ToggleSwitch
              options={optionsList}
              value={showErrorColumnsOnly}
              onChange={selected => this.onSelect(selected)}
            />
          </Label>
        </div>
        <Table
          data={detailsTableData}
          showPagination={false}
          columns={this.detailsTableColumns}
          pageSize={detailsTableData.length}
          defaultPageSize={detailsTableData.length}
        />
      </FormSection>
    );
  }
}

export default DetailsErrors;
