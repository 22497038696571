import React from 'react';
import './semaphore.css';

const Semaphore = ({ children, text, getLight }) => (
  <div className="semaphore-wrapper">
    <div className="semaphore-light" style={{ background: getLight(text) }} />
    <div className="semaphore-text">{text}</div>
    {children}
  </div>
);

export default Semaphore;
