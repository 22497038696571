import { REACT_APP_ENV } from '../../constants/environment';

const getGemSourceName = (tableGroupName, tableName) => {
  const suffix =
    REACT_APP_ENV.toLowerCase() === 'local'
      ? 'qa'
      : REACT_APP_ENV.toLowerCase();

  return `UMD_${tableGroupName}__${tableName}_${suffix}`;
};

export default getGemSourceName;
