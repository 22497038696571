export const truncate = (input, maxNumberOfCharacters) => {
  if (typeof input !== 'string') {
    console.error(
      'Error truncating the inputted value. An argument must be a string!'
    );
    return '';
  } else if (input.length === 0 || input.length < maxNumberOfCharacters) {
    return input;
  }

  return input.slice(0, maxNumberOfCharacters) + '…';
};
