// react
import React, { Fragment } from 'react';
// redux
import { reduxForm } from 'redux-form';
// components
import {
  Notification,
  FormSection,
  ContentSection,
  TableGroupAsideSection,
  FormToolbar
} from '../../components';
// sub-components
import OverviewFormReduxField from './OverviewFormReduxField';
// assets
import tableGroupLogo from '../../assets/img/database_2.svg';
// css
import './overviewForm.css';
// libs
import sendAnalyticsEvent from '../../lib/util/sendAnalyticsEvent';

const OverviewForm = props => {
  const analyticsEventBody = {
    module_id: 'react_data_manager',
    screen_id: 'overview_form',
    table: props?.selectedTable?.name,
    table_group_name: props?.selectedTable?.tableGroupName
  };

  const allowActions =
    props.currentUserTableRole !== 'VIEWER' &&
    Object.keys(props.selectedRow).length > 0
      ? true
      : false;

  const getError = id => {
    let error;
    if (props.overviewFormErrors) {
      error = props.overviewFormErrors.filter(err => {
        return err.id === id;
      })[0];
    }
    return error;
  };

  const Fields = () =>
    props?.properties?.map(property => {
      const error = getError(property.name);
      return (
        <OverviewFormReduxField
          key={`redux_field__${property.name}`}
          property={property}
          initialValue={
            props.formValues
              ? props.formValues[property.name]
              : props.initialValues[property.name]
          }
          error={error}
          isOverviewFormNewRecord={props.isOverviewFormNewRecord}
          isOverviewFormEditing={props.isOverviewFormEditing}
          change_={props.change}
          addModal={props.addModal}
          removeModal={props.removeModal}
        />
      );
    });

  const onNavigationClick = newPosition => {
    const { rows, setSelectedRow } = props;
    const newSelectedRow = rows[newPosition];
    setSelectedRow(newSelectedRow, newPosition);
  };

  const onNewRecordClick = () => {
    const { createOverviewFormRecord } = props;
    createOverviewFormRecord();
  };

  const onSaveRecordClick = () => {
    const { setOnSaveRecordConfirmation, removeConfirmation } = props;
    const confirmation = {
      acceptCallback: () => {
        onAcceptClick();
      },
      cancelCallback: () => {
        removeConfirmation();
      }
    };
    setOnSaveRecordConfirmation(confirmation);
  };

  const handleTableGroupClick = () => {
    sendAnalyticsEvent('ui_select_table_group', analyticsEventBody);
    props.resetSelectedTable();
  };

  const onDeleteRecordClick = () => {
    const { setOnDeleteRecordConfirmation, removeConfirmation } = props;
    const confirmation = {
      acceptCallback: () => {
        onAcceptClick();
      },
      cancelCallback: () => {
        removeConfirmation();
      }
    };

    setOnDeleteRecordConfirmation(confirmation);
  };

  const onAcceptClick = () => {
    const {
      saveOverviewFormAction,
      deleteOverviewFormAction,
      getConfirmation
    } = props;

    switch (getConfirmation().id) {
      case 'form-save':
        saveOverviewFormAction();
        sendAnalyticsEvent('ui_create_edit_record', analyticsEventBody);
        break;
      case 'form-delete':
        deleteOverviewFormAction();
        sendAnalyticsEvent('ui_delete_record', analyticsEventBody);
        break;
      default:
        return;
    }
  };

  const onCancelClick = () => {
    const {
      cancelOverviewFormAction,
      isOverviewFormEditing,
      isOverviewFormNewRecord
    } = props;
    cancelOverviewFormAction(isOverviewFormEditing, isOverviewFormNewRecord);
  };

  if (
    props.notification.isNotification === true &&
    props.notification.isModal === false &&
    props.notification.isToast === false
  ) {
    return (
      <Notification
        isLoading={props.isLoading}
        notification={props.notification}
      />
    );
  } else {
    return (
      <Fragment key="form-fragment">
        <ContentSection
          small
          colorfullTitle
          logo={tableGroupLogo}
          title={props.selectedTable.name}
          contentMiddleCustomCss={!props.isOverviewFormEditing ? 'link' : ''}
          redirectWhenContentMiddleClick={!props.isOverviewFormEditing}
          redirectContentMiddlePath={`/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}`}
          asideLeft={
            <TableGroupAsideSection
              selectedTableGroup={props.selectedTableGroup}
              isNavigable={!props.isOverviewFormEditing}
              handleTableGroupClick={handleTableGroupClick}
            />
          }
          asideRight={
            <FormToolbar
              onNavigationCallback={newPosition =>
                onNavigationClick(newPosition)
              }
              onNewRecordClick={onNewRecordClick}
              onSaveRecordClick={onSaveRecordClick}
              onDeleteRecordClick={onDeleteRecordClick}
              showLockButton={allowActions}
              showNewButton={
                props.currentUserTableRole === 'VIEWER' ? false : true
              }
              showDeleteButton={allowActions}
              onCancelClick={onCancelClick}
              isEditing={props.isOverviewFormEditing}
              isNew={props.isOverviewFormNewRecord}
              setIsEditing={props.setIsOverviewFormEditing}
              setDataViewMode={props.setDataViewMode}
              recordsCount={props.rows.length}
              redirectWhenCancel={false}
              redirectWhenAccept={false}
              showNavigationButtons={props.rows.length > 1 ? true : false}
            />
          }
        />

        {props.properties.length > 0 && (
          <div className="form-section-wrapper" data-testid="form-section">
            <FormSection key="form-section" className="form-section">
              <div className="form-container">
                <Fields key="form-fields" />
              </div>
            </FormSection>
          </div>
        )}
      </Fragment>
    );
  }
};

export default reduxForm({
  form: 'reduxForm', // a unique identifier for this form
  enableReinitialize: true
})(OverviewForm);
