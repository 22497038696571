import React from 'react';
import ExcelOptions from './ExcelOptions';
import CsvOptions from './CsvOptions';

const SourceSelectForm = ({
  source,
  handleChangeData,
  isContentDefiner,
  handleDelimiterChange,
  handleDateFormatChange,
  dateFormat,
  delimiter
}) => {
  const { filetype } = source;
  if (!source || !filetype) {
    return null;
  }

  switch (filetype) {
    case 'xlsx':
    case 'xls':
      return (
        <ExcelOptions
          source={source}
          handleChangeData={handleChangeData}
          isContentDefiner={isContentDefiner}
        />
      );
    case 'csv':
    case 'txt':
    case 'tsv':
      return (
        <CsvOptions
          source={source}
          handleDelimiterChange={handleDelimiterChange}
          handleDateFormatChange={handleDateFormatChange}
          dateFormat={dateFormat}
          delimiter={delimiter}
        />
      );
    default:
      return null;
  }
};

export default SourceSelectForm;
