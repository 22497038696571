import React from 'react';
import './cardFooter.css';

const CardFooter = ({ rightSection, leftSection, rightSectionCustomClass }) => (
  <div className="card-footer container">
    <div className="bootstrap-row">
      <div className="epic-font-base-md bootstrap-col">{leftSection}</div>
      <div
        className={`epic-font-base-md bootstrap-col ${
          rightSectionCustomClass
            ? rightSectionCustomClass
            : 'card-footer-right-column'
        }`}
      >
        {rightSection}
      </div>
    </div>
  </div>
);
export default CardFooter;
