import sanitizeNamePart, { sanitize } from '../util/sanitizeNamePart';
import { getValueList } from '../util/getValueList';
import { defaultProperties } from '../../constants/defaultValues';

export const serializeColumns = (columnsDefinition, isKeyAutogenerated) => {
  return columnsDefinition.map(columnDefinition => {
    return {
      ...columnDefinition,
      name: sanitizeNamePart(columnDefinition.name),
      displayOrder: columnDefinition.displayOrder * 10,
      label: columnDefinition.label
        ? sanitize(columnDefinition.label)
        : sanitize(columnDefinition.name),
      isKeyProperty: isKeyAutogenerated ? 'N' : columnDefinition.isKeyProperty,
      zeroPadding: defaultProperties.ZERO_PADDING,
      isAutoIncrement: defaultProperties.IS_AUTO_INCREMENT,
      length:
        columnDefinition.length === null
          ? null
          : parseInt(columnDefinition.length, 10),
      scale:
        columnDefinition.scale === null
          ? null
          : parseInt(columnDefinition.scale, 10),
      trim: columnDefinition.trim === null ? 'BOTH' : columnDefinition.trim,
      regexPattern:
        columnDefinition.regexPattern === null
          ? null
          : columnDefinition.regexPattern.trim(),
      valueList: getValueList(
        columnDefinition.valueList,
        columnDefinition.datatype
      )
    };
  });
};

export const serializeGeneratedColumns = (generatedColumns, columnsLength) => {
  return generatedColumns.map((columnDefinition, index) => {
    let sanitizedFieldName = sanitizeNamePart(columnDefinition.name);

    return {
      ...columnDefinition,
      name: sanitizedFieldName,
      nullable: defaultProperties.IS_NULLABLE,
      displayOrder: (columnsLength + index + 1) * 10,
      isAutoIncrement: defaultProperties.IS_AUTO_INCREMENT,
      label: sanitizedFieldName,
      isKeyProperty:
        columnDefinition.isKeyProperty === 'Y'
          ? columnDefinition.isKeyProperty
          : defaultProperties.IS_KEY_PROPERTY,
      scale: defaultProperties.SCALE,
      trim: defaultProperties.TRIM,
      letterCase: defaultProperties.LETTER_CASE,
      zeroPadding: defaultProperties.ZERO_PADDING,
      regexPattern: defaultProperties.REGEX_PATTERN,
      validationErrorMessage: defaultProperties.VALIDATION_ERROR_MSG,
      valueList: defaultProperties.VALUE_LIST
    };
  });
};
