import React, { Fragment } from 'react';
import { RadioButton, FormSection, Button } from '../../components/index';
import sendAnalyticsEvent from '../../lib/util/sendAnalyticsEvent';

const DownloadOptions = ({ handleAccept, handleCancel, selectedTable }) => {
  const [selectedValue, setSelectedValue] = React.useState('csv');

  const analyticsEventBody = {
    module_id: 'react_data_manager',
    screen_id: 'overview_table',
    table: selectedTable?.name,
    table_group_name: selectedTable?.tableGroupName
  };

  const handleOnClick = () => {
    handleAccept(selectedValue);
    sendAnalyticsEvent('ui_download', analyticsEventBody);
  };

  const renderRadioButton = (val, label) => {
    return (
      <RadioButton
        name="downloadOption"
        value={val}
        label={label || val}
        checked={selectedValue === val}
        onChange={newValue => setSelectedValue(newValue)}
      />
    );
  };
  return (
    <Fragment>
      <FormSection className="overview-table-download-form-section">
        {renderRadioButton('csv', 'csv')}
        {renderRadioButton('xlsx', 'xlsx')}
      </FormSection>
      <div className="modal-button-wrapper">
        <Button onClick={handleOnClick}>Accept</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </div>
    </Fragment>
  );
};

export default DownloadOptions;
