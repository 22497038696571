// react
import React from 'react';
// router
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';
// okta
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
// layouts
import {
  MainLayoutContainer,
  UploadWizardContainer,
  LaunchpadContainer,
  ContentDefinerContainer,
  FormPrivTableGroupContainer,
  FormPrivTableContainer,
  FormTableGroupContainer,
  FormSnowflakeContainer,
  ReplicationTargetsFormContainer,
  EditTableContainer
} from '../index';
// constants
import { OKTA_ISSUER_URL, OKTA_CLIENT_ID } from '../../constants/defaultValues';
// components
import { AntdToast } from '../../components';
// css
import '@nike/epic-react-ui/dist/styles/main.css';
import SecureComponent from '../../components/SecureComponent/SecureComponentContainer';

const oktaConfig = {
  issuer: OKTA_ISSUER_URL,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
  pkce: true
};

const CoreLayout = props => {
  const oktaAuth = new OktaAuth(oktaConfig);
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  if (props.notification.isToast === true) {
    AntdToast[props.notification.notType]({
      placement: 'bottomRight',
      message: props.notification.title,
      description: props.notification.message
    });
    props.removeNotification();
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <SecureRoute exact path={'/'} render={() => <LaunchpadContainer />} />
        <Route path="/implicit/callback" component={LoginCallback} />
        <SecureRoute
          exact
          path={['/tablegroup/form']}
          render={() => (
            <SecureComponent>
              <FormTableGroupContainer {...props} />
            </SecureComponent>
          )}
        />
        <SecureRoute
          exact
          path={[
            '/tablegroup/:tableGroupId/table/:tableId/replicationtargets/create'
          ]}
          render={() => (
            <SecureComponent>
              <ReplicationTargetsFormContainer {...props} />
            </SecureComponent>
          )}
        />
        <SecureRoute
          exact
          path={[
            '/tablegroup',
            '/tablegroup/:tableGroupId',
            '/tablegroup/:tableGroupId/table/:tableId',
            '/tablegroup/:tableGroupId/privileges',
            '/tablegroup/:tableGroupId/table/:tableId/data',
            '/tablegroup/:tableGroupId/table/:tableId/privileges',
            '/tablegroup/:tableGroupId/table/:tableId/replicationtargets'
          ]}
          render={() => (
            <SecureComponent>
              <MainLayoutContainer {...props} />
            </SecureComponent>
          )}
        />
        <SecureRoute
          exact
          path={'/tablegroup/:tableGroupId/table/:tableId/upload'}
          render={() => (
            <SecureComponent>
              <UploadWizardContainer {...props} />
            </SecureComponent>
          )}
        />
        <SecureRoute
          exact
          path={'/tablegroup/:tableGroupId/contentDefiner'}
          render={() => (
            <SecureComponent>
              <ContentDefinerContainer {...props} />
            </SecureComponent>
          )}
        />
        <SecureRoute
          exact
          path={[
            '/tablegroup/:tableGroupId/privileges/:privilegeId',
            '/tablegroup/:tableGroupId/privileges/create'
          ]}
          render={() => (
            <SecureComponent>
              <FormPrivTableGroupContainer {...props} />
            </SecureComponent>
          )}
        />
        <SecureRoute
          exact
          path={[
            '/tablegroup/:tableGroupId/table/:tableId/privileges/:privilegeId',
            '/tablegroup/:tableGroupId/table/:tableId/privileges/create'
          ]}
          render={() => (
            <SecureComponent>
              <FormPrivTableContainer {...props} />
            </SecureComponent>
          )}
        />
        <SecureRoute
          exact
          path={[
            '/tablegroup/:tableGroupId/table/:tableId/privileges/snowflake/create'
          ]}
          render={() => (
            <SecureComponent>
              <FormSnowflakeContainer {...props} />
            </SecureComponent>
          )}
        />
        <SecureRoute
          exact
          path={`/tablegroup/:tableGroupId/table/:tableId/edit`}
          render={() => (
            <SecureComponent>
              <EditTableContainer {...props} isTableEdit={true} />
            </SecureComponent>
          )}
        />
        <Redirect to={'/'} />
      </Switch>
    </Security>
  );
};

export default CoreLayout;
