import {
  HANDLE_GLOBAL_ERROR,
  RESET_GLOBAL_ERROR
} from '../actions/actionTypes';
import createReducer from './createReducer';

export const initialState = {
  errorMessage: '',
  error: false
};

const ACTION_HANDLERS = {
  [HANDLE_GLOBAL_ERROR]: (state, action) => ({
    errorMessage: action.errorMessage,
    error: true
  }),
  [RESET_GLOBAL_ERROR]: () => initialState
};

export default createReducer(initialState, ACTION_HANDLERS);
