import _ from 'lodash';

export const getS3Configuration = ({ acl, region, uri }) => {
  const s3TargetJsonConfig = JSON.stringify({
    bucketUrl: uri,
    region: getValueFromFormOption(region, ''),
    acl: getValueFromFormOption(acl, 'bucket-owner-full-control'),
    useDefaultBucket: false
  });

  const targetOptions = JSON.parse(s3TargetJsonConfig);

  return {
    bucketUrl: targetOptions.bucketUrl,
    acl: targetOptions.acl,
    region: targetOptions.region,
    useDefaultBucket: false
  };
};

const getValueFromFormOption = (option, defaultValue) =>
  _.isObject(option) && Object.prototype.hasOwnProperty.call(option, 'label')
    ? option.label
    : defaultValue;

export const configureReplicationTargets = (
  tableName,
  tableGroupName,
  replicationTargetForm
) => {
  const configuredReplicationTargets = [];

  for (const [key, value] of Object.entries(replicationTargetForm)) {
    if (value.checked === true) {
      configuredReplicationTargets.push({
        tableName,
        tableGroupName,
        type: key.toUpperCase(),
        targetOptions:
          key.toUpperCase() === 'S3'
            ? getS3Configuration(replicationTargetForm.s3.s3BucketFields)
            : null
      });
    }
  }

  return configuredReplicationTargets;
};
