// react
import React, { Fragment } from 'react';
// others
import _ from 'lodash';
// components
import {
  Button,
  TextInput,
  ModalNotification,
  Table,
  FormSection
} from '../../components';
import { Icon } from '@nike/epic-react-ui';
// services
import { fetchUsers } from '../../services/privilegeService';

const styles = {
  deleteListButton: { background: 'red' }
};

const ListUsers = props => {
  const [value, setValue] = React.useState('');
  const [list, setList] = React.useState([]);
  const [hasSingleError, setHasSingleError] = React.useState(false);
  const [singleErrorMessage, setSingleErrorMessage] = React.useState('');
  const [listErrors, setListErrors] = React.useState([]);
  const [errorNotification, setErrorNotification] = React.useState({
    props: {},
    showErrorNotification: false
  });

  const handleDelete = cellInfo => {
    const user = cellInfo.row;
    const newList = list.filter(ele => {
      return ele.email !== user.email;
    });
    setList(newList);
  };

  const handleCheck = (event, cellInfo) => {
    const user = cellInfo.row;
    cellInfo.row._original.checked = event.currentTarget.checked;
    const newList = list.map(ele => {
      if (ele.email === user.email) ele.checked = event.currentTarget.checked;
      return ele;
    });
    setList(newList);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleSearchValue = async () => {
    if (value) {
      if (value.length > 3) {
        await props.setIsLoading(true);
        setHasSingleError(false);
        setSingleErrorMessage('');

        let anchor = 'na';
        do {
          const response = await fetchUsers(value, anchor);
          const users = await response.users.map(user => {
            return { email: user, checked: false };
          });

          const checkedUsers = list.filter(ele => ele.checked === true);
          const newList = _.uniqBy(checkedUsers.concat(users), 'email');
          setList(newList);
          anchor = response.anchor;
        } while (anchor !== null);
        await props.setIsLoading(false);
      } else {
        setHasSingleError(true);
        setSingleErrorMessage(
          'The value to be searched must be the at least 4 characters'
        );
      }
    }
  };

  const handleDeleteEntireList = () => {
    setList([]);
    setListErrors([]);
  };

  const handleAccept = () => {
    if (listErrors.length > 0) {
      addErrorNotification(
        'There is at least one error with the values. Please check the items highlighted in red. Hovering over the item, you will see the error description'
      );
    } else {
      const usersSelected = list
        .filter(ele => ele.checked === true)
        .map(ele => ele.email);
      props.acceptListUsers(usersSelected);
      props.removeModal();
    }
  };

  const addErrorNotification = message => {
    setErrorNotification({
      props: {
        notification: {
          isNotification: true,
          isModal: true,
          title: 'Error',
          message: message
        },
        removeNotification: () => removeErrorNotification()
      },
      showErrorNotification: true
    });
  };

  const removeErrorNotification = () => {
    setErrorNotification({
      props: {},
      showErrorNotification: false
    });
  };

  const SingleEntry = (
    <div className="list-users-input-row">
      <div className="bootstrap-col-md-9">
        {
          <TextInput
            value={value}
            onChange={handleChange}
            border={true}
            style={styles.inputText}
            hasErrors={hasSingleError}
            errorMessage={singleErrorMessage}
          />
        }
      </div>
      <div className="bootstrap-col-md-1">
        <Button small onClick={handleSearchValue}>
          Search
        </Button>
      </div>
    </div>
  );

  return (
    <Fragment>
      <FormSection className="list-users-input-section-wrapper">
        {SingleEntry}
      </FormSection>
      <FormSection className="list-users-list-wrapper">
        <Table
          columns={[
            {
              id: 'checked',
              accessor: 'checked',
              Header: <div className="checkBoxHeader"> </div>,
              maxWidth: 20,
              Cell: function createCell(cellInfo) {
                return (
                  <input
                    type="checkbox"
                    checked={list[cellInfo.index][cellInfo.column.id]}
                    onChange={event => handleCheck(event, cellInfo)}
                    className="cell-input"
                  />
                );
              }
            },
            {
              Header: 'Email',
              accessor: 'email'
            },
            {
              Header: '',
              accessor: '',
              width: 32,
              Cell: function createCell(cellInfo) {
                return (
                  <button onClick={() => handleDelete(cellInfo)}>
                    <Icon type="delete" />
                  </button>
                );
              }
            }
          ]}
          data={list}
          showPagination={false}
          pageSize={list.length}
          sortable={true}
        />
      </FormSection>
      <div className="modal-button-wrapper">
        <Button onClick={handleAccept}>Accept</Button>
        <Button onClick={props.removeModal}>Cancel</Button>
        <Button
          style={styles.deleteListButton}
          onClick={handleDeleteEntireList}
        >
          Delete List
        </Button>
      </div>
      {errorNotification.showErrorNotification === true && (
        <ModalNotification props={errorNotification.props} />
      )}
    </Fragment>
  );
};
export default ListUsers;
