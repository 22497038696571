import { connect } from 'react-redux';
import TableGroupLayout from './TableGroupLayout';
import {
  getTableGroups,
  setSelectedTableGroup,
  resetSelectedTableGroup
} from '../../actions/tableGroupLayout';

const mapDispatchToProps = {
  getTableGroups,
  setSelectedTableGroup,
  resetSelectedTableGroup
};

const mapStateToProps = state => {
  return {
    totalCount: state.tableGroupLayout.totalCount,
    tableGroups: state.tableGroupLayout.tableGroups
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableGroupLayout);
