import { connect } from 'react-redux';
import TableDetails from './TableDetails';

import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';
import {
  deleteTable,
  setOnDeleteRecordConfirmation,
  setSelectedTable
} from '../../actions/tableGroupDetails';
import { removeConfirmation } from '../../actions/core';

const mapDispatchToProps = {
  setSelectedTableGroup,
  getTableGroups,
  deleteTable,
  setOnDeleteRecordConfirmation,
  setSelectedTable,
  removeConfirmation
};

const mapStateToProps = state => {
  return {
    level: 'TABLE',
    totalCount: state.tableGroupDetails.totalCount,
    selectedTable: state.tableGroupDetails.selectedTable
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableDetails);
