import { connect } from 'react-redux';
import FormPrivilege from './FormPrivilege';
import * as Actions from '../../actions/privileges';
import {
  addModal,
  removeModal,
  setIsLoading,
  getConfirmation,
  removeConfirmation,
  addNotification
} from '../../actions/core';
import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';
import { setSelectedTable } from '../../actions/tableGroupDetails';

const mapDispatchToProps = {
  ...Actions,
  setIsLoading,
  addModal,
  removeModal,
  removeConfirmation,
  getConfirmation,
  addNotification,
  setSelectedTableGroup,
  getTableGroups,
  setSelectedTable
};

const mapStateToProps = state => {
  return {
    level: 'TABLE',
    selectedPrivilege: state.privileges.selectedPrivilege,
    roles: state.privileges.roles,
    privileges: state.privileges.tablePrivileges,
    userTablePrivileges: state.privileges.userTablePrivileges || [],
    userTableGroupPrivileges: state.privileges.userTableGroupPrivileges || [],
    dataViewMode: state.core.dataViewMode,
    selectedTable: state.tableGroupDetails.selectedTable,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup,
    modal: state.core.modal
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPrivilege);
