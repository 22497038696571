import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Spinner } from '../../index';

const Notification = props => {
  const { isLoading, notification } = props;
  const { message, isNotification, isModal } = notification;

  if (isLoading === false && isNotification === true && isModal === false) {
    return (
      <Spinner
        title={I18n.t('application.title')}
        message={message}
        ellipsesOuterThinClassName="ellipses ellipses-outer--thin ellipses-outer--thin--slow"
        ellipsesOuterThickClassName="ellipses ellipses-outer--thick ellipses-outer--thick--slow"
      />
    );
  } else {
    return null;
  }
};
export default Notification;
