import { OKTA_INTEGRATION_ENABLED } from '../../constants/defaultValues';
import { getAuthServiceFromStore } from '../../actions/core';

export default async function getHeadersWithDefaults(
  headerOverrides = {},
  format = 'json'
) {
  const headerContentType = getResponseContentType(format);
  const defaultHeaders = {
    'Content-Type': headerContentType
  };

  const authService = getAuthServiceFromStore();
  const oktaToken = await authService.getAccessToken();

  if (OKTA_INTEGRATION_ENABLED) {
    defaultHeaders.Authorization = `Bearer ${oktaToken}`;
  }

  return { ...defaultHeaders, ...headerOverrides };
}

const getResponseContentType = format => {
  switch (format) {
    case 'json':
      return 'application/json';
    case 'xml':
      return 'application/xml';
    case 'atom':
      return 'application/atom+xml';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'csv':
      return 'application/octet-stream';
    default:
      return 'application/json';
  }
};
