import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// router
import { withRouter } from 'react-router-dom';
// components
import {
  FormSection,
  Select,
  List,
  Button,
  NotificationWrapper,
  ContentSection,
  FormToolbar,
  Label,
  TextInput,
  LoadingScreen,
  ModalNotification
} from '../../components/index';
import { Icon } from '@nike/epic-react-ui';
// assets
import tableGroupLogo from '../../assets/img/database_2.svg';
// sub-components
import ListUsers from './ListUsers';
import PrivilegesOverview from '../../components/PrivilegesOverview/privilegesOverview';
// css
import './formPrivilege.css';

const FormPrivilege = props => {
  const [isBusy, setIsBusy] = useState(false);
  const [isTableGroupLoaded, setIsTableGroupLoaded] = useState(false);
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const [isPrivilegeLoaded, setIsPrivilegeLoaded] = useState(false);
  const [selectedRole, setSelectedRole] = useState({
    label: 'EDITOR',
    value: 'EDITOR'
  });
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsBusy(true);
      await props.getTableGroups();
      await props.setSelectedTableGroup(props.match.params.tableGroupId);
      if (props.match.params.privilegeId === 'create')
        props.resetSelectedPrivilege();
      if (props.level === 'TABLE') {
        await props.setSelectedTable(
          props.match.params.tableGroupId,
          props.match.params.tableId
        );
        setIsTableLoaded(true);
      }
      setIsTableGroupLoaded(true);
    };

    // Only if the table group hasn't be loaded then
    // we proceed to fetch the data
    if (isTableGroupLoaded === false) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (
        (props.level === 'TABLE_GROUP' && props.selectedTableGroup) ||
        (props.level === 'TABLE' && props.selectedTable)
      ) {
        props.setSelectedPrivilege(props.match.params.privilegeId, props.level);
        setIsPrivilegeLoaded(true);
      }
      setIsBusy(false);
    };

    // If the Table group has been loaded but
    // the privilege hasn't and the privilege id is not create
    // that means that the privilege must be setup in the store
    if (
      isPrivilegeLoaded === false &&
      isTableGroupLoaded === true &&
      props.match.params.privilegeId !== 'create'
    )
      fetchData();

    // At this stage if the privilege is loaded then
    // the user and role musts be set up
    if (isPrivilegeLoaded === true) {
      setSelectedUsers([props.selectedPrivilege?.userIdentifier]);
      setSelectedRole({
        label: props.selectedPrivilege?.userRole,
        value: props.selectedPrivilege?.userRole
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedTableGroup,
    props.selectedTable,
    props.match.params.privilegeId,
    props.selectedPrivilege,
    isTableGroupLoaded
  ]);

  // Depending on the privilege level if the data needed is not yet loaded
  // then the Loading screen is rendered
  if (
    (props.level === 'TABLE_GROUP' && isTableGroupLoaded === false) ||
    (props.level === 'TABLE' && isTableLoaded === false) ||
    (props.level === 'TABLE_GROUP' &&
      isTableGroupLoaded === true &&
      isPrivilegeLoaded === false &&
      props.match.params.privilegeId !== 'create')
  )
    return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props.selectedTableGroup && isTableGroupLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push('/tablegroup')}
      />
    );
  }

  // If the level is Table  and no table has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (
    props.level === 'TABLE' &&
    isTableLoaded === true &&
    !props.selectedTable
  ) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props.history.push(`/tablegroup/${props.selectedTableGroup.name}`)
        }
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point depending on the level, table group and table should be already loaded
  // ------------------------------------------------------------------

  const privilegeExists = props.selectedPrivilege ? true : false;

  // If the privilege doesn't exist that means we are creating it
  // if the busy flag is still true then at this point we should
  // set up the flag to false
  if (privilegeExists === false && isBusy === true) setIsBusy(false);

  // The rediredct path depends on the privilege level
  const redirectPath =
    props.level === 'TABLE_GROUP'
      ? `/tablegroup/${props.selectedTableGroup.name}/privileges`
      : `/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}/privileges`;

  // If no privilege has been found with the specified privilegeId
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the privileges overview
  if (!props.selectedPrivilege && isPrivilegeLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Privilege not found',
      message: 'No Privilege was found with the specified Id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push(redirectPath)}
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point all the data needed to render this layout is in place
  // ------------------------------------------------------------------

  // It is necessary to determine the user's role

  const currentUserTableRole = props?.selectedTable?.role;
  const currentUserTableGroupRole = props?.selectedTableGroup?.role;

  if (props.level === 'TABLE_GROUP') {
    if (
      currentUserTableGroupRole !== 'OWNER' &&
      currentUserTableGroupRole !== 'ADMIN'
    ) {
      const notification = {
        isNotification: true,
        title: 'Access Denied!',
        message:
          "The user doesn't have the right role to access the Table Group Privileges.",
        isModal: true,
        showIcon: true
      };
      return (
        <ModalNotification
          notification={notification}
          removeNotification={() =>
            props.history.push(`/tablegroup/${props.selectedTableGroup.name}`)
          }
        />
      );
    }
  } else if (props.level === 'TABLE') {
    if (currentUserTableRole !== 'OWNER' && currentUserTableRole !== 'ADMIN') {
      const notification = {
        isNotification: true,
        title: 'Access Denied!',
        message:
          "The user doesn't have the right role to access the Table Privileges.",
        isModal: true,
        showIcon: true
      };
      return (
        <ModalNotification
          notification={notification}
          removeNotification={() =>
            props.history.push(
              `/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}`
            )
          }
        />
      );
    }
  }

  // ------------------------------------------------------------------
  // At this point everything  is in place and the user has access for sure to this resource
  // ------------------------------------------------------------------

  const roleOptions = ['OWNER', 'EDITOR', 'VIEWER'].map(role => {
    return { value: role, label: role };
  });

  const handleDelete = user => {
    const newSelectedUsers = selectedUsers.filter(ele => {
      return ele !== user;
    });
    setSelectedUsers(newSelectedUsers);
  };

  const acceptListUsers = usersSelected => {
    const newList = _.uniq(selectedUsers.concat(usersSelected));
    setSelectedUsers(newList);
  };

  const onNavigationClick = newPosition => {
    const { privileges, setSelectedPrivilege } = props;

    const newSelectedRow = privileges[newPosition];
    setSelectedPrivilege(newSelectedRow, newPosition);
  };

  const onSaveRecordClick = () => {
    const { setOnSaveRecordConfirmation, removeConfirmation } = props;
    const confirmation = {
      acceptCallback: () => {
        onAcceptClick();
      },
      cancelCallback: () => {
        removeConfirmation();
      }
    };
    setOnSaveRecordConfirmation(confirmation);
  };

  const onDeleteRecordClick = () => {
    const { setOnDeleteRecordConfirmation, removeConfirmation } = props;
    const confirmation = {
      acceptCallback: () => {
        onAcceptClick();
      },
      cancelCallback: () => {
        removeConfirmation();
      }
    };

    setOnDeleteRecordConfirmation(confirmation);
  };

  const onAcceptClick = async () => {
    const { savePrivilegeAction, deletePrivilege, getConfirmation } = props;
    switch (getConfirmation().id) {
      case 'form-save':
        if (selectedUsers.length > 0) {
          await savePrivilegeAction({
            newPrivileges: {
              selectedRole: selectedRole,
              selectedUsers: selectedUsers
            },
            level: props.level
          });
          props.history.push(redirectPath);
        } else {
          props.removeConfirmation();
          props.addNotification({
            isModal: true,
            title: 'Adding Privileges',
            message: `There are no users selected. To Add a privilege there must be at least one user`
          });
        }
        break;
      case 'form-delete':
        deletePrivilege();
        break;
      default:
        return;
    }
  };

  const onCancelClick = () => {
    props.history.push(redirectPath);
  };

  const handleGetUsersFromApi = () => {
    props.addModal({
      id: 'users_list_display',
      title: 'Search Users',
      children: (
        <ListUsers
          {...props}
          acceptListUsers={usersSelected => acceptListUsers(usersSelected)}
        />
      ),
      cancelCallback: () => props.removeModal()
    });
  };

  const selectCls = `form-privilege-item ${
    privilegeExists === false && 'form-privilege-select'
  }`;

  return (
    <NotificationWrapper {...props}>
      <LoadingScreen isLoading={isBusy} />
      <div className="toopbar-title-no-navigable">
        {privilegeExists === false ? 'Creation Mode' : 'Edition Mode'}
      </div>
      <ContentSection
        small
        colorfullTitle
        logo={tableGroupLogo}
        title={props.selectedTableGroup.name}
        asideRight={
          <FormToolbar
            onNavigationCallback={newPosition => onNavigationClick(newPosition)}
            onSaveRecordClick={() => onSaveRecordClick()}
            onDeleteRecordClick={() => onDeleteRecordClick()}
            onCancelClick={() => onCancelClick()}
            isEditing={privilegeExists}
            isNew={!privilegeExists}
            recordsCount={props.privileges.length}
            showNavigationButtons={false}
            showLock={false}
            redirectWhenCancel={true}
            redirectCancelPath={redirectPath}
            redirectWhenAccept={false}
            redirectAcceptPath={redirectPath}
          />
        }
      />
      <div data-testid="privileges-form">
        <FormSection
          className="formGeneralSection"
          title={
            privilegeExists === false ? 'Add Privileges' : 'Edit Privilege'
          }
        >
          <div className="form-privilege-container">
            <div className={selectCls}>
              <Select
                label="Role"
                options={roleOptions}
                value={selectedRole}
                placeholder="Select a Role"
                onChange={selection => setSelectedRole(selection)}
              />
            </div>
            {privilegeExists === false ? (
              <>
                <div className="form-privilege-item form-privilege-button">
                  <Button medium onClick={handleGetUsersFromApi}>
                    Add
                  </Button>
                </div>
                <div className="form-privilege-item">
                  <Label label="Users" />
                </div>
                <div className="form-privilege-item form-privilege-list-container">
                  <List
                    dataSource={selectedUsers}
                    renderItem={user => (
                      <List.Item key={user}>
                        <List.Item.Meta description={user} />
                        <Button small onClick={() => handleDelete(user)}>
                          <Icon type="delete" />
                        </Button>
                      </List.Item>
                    )}
                  />
                </div>
              </>
            ) : (
              <div className="form-privilege-item">
                <TextInput
                  full
                  border={false}
                  label="User"
                  disabled={true}
                  value={props.selectedPrivilege?.userIdentifier}
                />
              </div>
            )}
          </div>
          <PrivilegesOverview />
        </FormSection>
      </div>
    </NotificationWrapper>
  );
};
export default withRouter(FormPrivilege);
