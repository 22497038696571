import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Popover, Avatar } from 'antd';
import { REACT_APP_PATH } from '../../../constants/defaultValues';

const getPopoverHelpContent = isSink => (
  <div>
    <p>{I18n.t('replicationTargetLayout.help.titleHelpText')}</p>
    <p>
      {isSink
        ? I18n.t('replicationTargetLayout.help.sinkHelpText')
        : I18n.t('replicationTargetLayout.help.sourceHelpText')}
    </p>
    <p>
      {I18n.t('replicationTargetLayout.help.footerIntroHelpText')}
      <a
        href={`${REACT_APP_PATH}/docs/docs/tech-nsp-integration.html`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {I18n.t('replicationTargetLayout.help.footerHelpText')}
      </a>
    </p>
  </div>
);

const RepTargetCardHelp = ({ isSink, title }) => (
  <div className="card-help-wrapper">
    <Popover content={getPopoverHelpContent(isSink)} title={title}>
      <Avatar className="card-help-avatar" size="small">
        ?
      </Avatar>
    </Popover>
  </div>
);

export default RepTargetCardHelp;
