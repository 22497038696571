export const generatedColumnHeaders = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Description', accessor: 'description' }
];

export const generatedColumnsConfiguration = [
  {
    name: 'OPERATION_USER',
    datatype: 'TEXT',
    length: 64,
    description: 'Name of the user that added or modified the data.',
    valueGeneratorName: 'currentUserIdentifier',
    valueGeneratorMoment: 'ALWAYS'
  },
  {
    name: 'OPERATION_ACTION',
    datatype: 'TEXT',
    length: 16,
    description: 'The last type of modification to the data.',
    valueGeneratorName: 'httpVerb',
    valueGeneratorMoment: 'ALWAYS'
  },
  {
    name: 'OPERATION_TIMESTAMP',
    datatype: 'DATETIME',
    description: 'A timestamp indicating when the data was added or modified.',
    valueGeneratorName: 'currentUTCTimestamp',
    valueGeneratorMoment: 'ALWAYS'
  }
];

export const autoKeyColumnConfiguration = {
  name: 'AUTO_GENERATED_KEY_UUID',
  datatype: 'UUID',
  description: 'An automatically populated Universal Unique Identifier.',
  isKeyProperty: 'Y',
  valueGeneratorName: 'uuid4',
  valueGeneratorMoment: 'IFNULL'
};

export const autoGeneratedColumnsNames = [
  ...generatedColumnsConfiguration,
  autoKeyColumnConfiguration
].map(column => column.name);
