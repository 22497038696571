import React, { useState, useEffect } from 'react';
// router
import { withRouter } from 'react-router-dom';
// components
import {
  NotificationWrapper,
  ContentSection,
  FormToolbar,
  LoadingScreen,
  ModalNotification
} from '../../components/index';
// assets
import tableGroupLogo from '../../assets/img/database_2.svg';
// sub-components
import FinalModal from './FinalModal.js';
import Form from './Form.js';
// util
import { validateS3BucketUrl } from '../../layouts/ContentDefiner/Step3_Target/validation';

const ReplicationTargetsForm = props => {
  const [state, setState] = useState({
    isBusy: true
  });

  useEffect(() => {
    props.resetReplicationTargetsForm();
    props.resetSendingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await props?.getTableGroups();
      await props?.setSelectedTableGroup(props?.match?.params?.tableGroupId);
      await props?.setSelectedTable(
        props?.match?.params?.tableGroupId,
        props?.match?.params?.tableId
      );
      await props?.loadReplicationTargets();

      setState(prev => ({ ...prev, isBusy: false }));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isBusy) return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props?.selectedTableGroup && state.isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <div data-testid="table-group-notification">
        <ModalNotification
          notification={notification}
          removeNotification={() => props?.history.push('/tablegroup')}
        />
      </div>
    );
  }

  // If no table has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (!props?.selectedTable && state.isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table id.',
      isModal: true,
      showIcon: true
    };
    return (
      <div data-testid="table-notification">
        <ModalNotification
          notification={notification}
          removeNotification={() =>
            props?.history.push(
              `/tablegroup/${props?.selectedTableGroup?.name}`
            )
          }
        />
      </div>
    );
  }

  const redirectPath = `/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}/replicationtargets`;

  if (props.replicationTargets.length === 2) {
    props?.history.push(`${redirectPath}`);
  }

  // ------------------------------------------------------------------
  // At this point everything  is in place and the user has access for sure to this resource
  // ------------------------------------------------------------------
  const hasReplicationTargets = props.replicationTargets.length > 0;

  const hasPrivileges =
    props.selectedTable?.role === 'OWNER' ||
    props.selectedTable?.role === 'ADMIN';
  const isSendingFinished =
    props.sending === 'success' || props.sending === 'failure';

  if (!hasPrivileges) {
    return (
      <div data-testid="access-notification">
        <ModalNotification
          notification={{
            isNotification: true,
            title: 'Access Denied!',
            message: `The user doesn't have the right role to change the replication targets.`,
            isModal: true,
            showIcon: true
          }}
          removeNotification={() => props.history.push(`${redirectPath}`)}
        />
      </div>
    );
  }

  const handleCancelClick = () => {
    props.resetReplicationTargetsForm();
    props.history.push(redirectPath);
  };

  const handleSaveClick = () => {
    const {
      s3: { checked: isS3CheckboxChecked, s3BucketFields },
      snowflake: { checked: isSnowflakeChecked }
    } = props.formValues;

    if (!isSnowflakeChecked && !isS3CheckboxChecked) {
      props.addModal({
        id: 'replication-targets-error-modal',
        title: 'No replication targets selected.',
        message: `Please, select the replication targets you would like to add.`,
        cancelCallback: () => props.removeModal(),
        showIcon: true
      });
      return;
    } else if (isS3CheckboxChecked) {
      const { acl, region, uri } = s3BucketFields;

      const s3BucketError = validateS3BucketUrl(
        isS3CheckboxChecked,
        acl,
        region,
        uri
      );

      if (s3BucketError) {
        props.addModal({
          id: 'replication-targets-error-modal',
          title: s3BucketError.title,
          message: s3BucketError.message,
          cancelCallback: () => props.removeModal(),
          showIcon: true
        });

        return;
      }
    }

    props.saveReplicationTargets();
  };

  return (
    <div data-test="replication-targets-form-container">
      <NotificationWrapper {...props}>
        <div className="toopbar-title-no-navigable">Creation Mode</div>
        <ContentSection
          small
          colorfullTitle
          logo={tableGroupLogo}
          title={props.selectedTableGroup.name}
          asideRight={
            <FormToolbar
              onSaveRecordClick={handleSaveClick}
              onCancelClick={handleCancelClick}
              isEditing={hasReplicationTargets}
              isNew={!hasReplicationTargets}
              showNavigationButtons={false}
              showLock={false}
            />
          }
        />

        <Form
          toggleFormCheckboxes={props.toggleFormCheckboxes}
          setS3BucketField={props.setS3BucketField}
          replicationTargets={props.replicationTargets}
          formValues={props.formValues}
        />

        {isSendingFinished && (
          <FinalModal errors={props.errors} redirectPath={redirectPath} />
        )}
      </NotificationWrapper>
    </div>
  );
};
export default withRouter(ReplicationTargetsForm);
