import { connect } from 'react-redux';
import UploadWizard from './UploadWizard';
import * as Actions from '../../actions/wizards';
import {
  addNotification,
  removeConfirmation,
  addConfirmation
} from '../../actions/core';
import {
  processUpload,
  getGeneratedCellsCount
} from '../../actions/overviewTable';
import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';
import { getTables, setSelectedTable } from '../../actions/tableGroupDetails';
import { setOnSelectReplicationTarget } from '../../actions/replicationTargets';

import {
  toggleFormCheckboxes,
  setS3BucketField
} from '../../reducers/replicationTargets';

const mapStateToProps = state => {
  return {
    ...state.wizards,
    selectedTable: state.tableGroupDetails.selectedTable,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup,
    isLoading: state.core.isLoading,
    dataloadingMode: state.wizards.core.dataloadingMode,
    formValues: state.replicationTargets.formValues
  };
};

const mapDispatchToProps = {
  ...Actions,
  setSelectedTableGroup,
  getTableGroups,
  getTables,
  setSelectedTable,
  addNotification,
  removeConfirmation,
  addConfirmation,
  processUpload,
  getGeneratedCellsCount,
  setOnSelectReplicationTarget,
  toggleFormCheckboxes,
  setS3BucketField
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadWizard);
