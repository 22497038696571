import React, { Fragment } from 'react';
import { Button, FormSection, DatePicker } from '../../index';
import tweakJsDate from '../../../lib/util/tweakJsDate';

const checkInitialValue = date => {
  const jsDate = new Date(date);
  return jsDate instanceof Date && !isNaN(jsDate) ? jsDate : new Date();
};

const DatePickerHelper = props => {
  const [value, setValue] = React.useState(
    checkInitialValue(props.initialValue)
  );
  const [tweakedValue, setTweakedValue] = React.useState('');

  const handleAccept = () => {
    props.acceptDate(tweakedValue);
  };

  const handleDateChange = date => {
    let strValue;
    if (date !== null && date !== undefined) {
      if (props.datatype.toUpperCase() === 'DATE') {
        strValue = tweakJsDate(date).substr(0, 10);
      } else {
        strValue = tweakJsDate(date);
      }
    } else {
      strValue = '';
    }
    setValue(date);
    setTweakedValue(strValue);
  };

  return (
    <Fragment>
      <FormSection className="datepicker-helper-modal-form-section">
        <DatePicker
          inline
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          value={value}
          selected={value}
          dateFormat={
            props.datatype === 'DATETIME' ? 'yyyy/mm/dd hh:mm:ss' : 'yyyy/mm/dd'
          }
          showTimeInput={props.datatype === 'DATETIME' ? true : false}
          onChange={date => handleDateChange(date)}
        />
      </FormSection>
      <div className="modal-button-wrapper">
        <Button small onClick={handleAccept}>
          Accept
        </Button>
        <Button small onClick={props.removeModal}>
          Cancel
        </Button>
      </div>
    </Fragment>
  );
};
export default DatePickerHelper;
