export default function applyFormatting(property, value) {
  if (value === null || value === undefined || value === '') {
    return value;
  }
  const datatype = property.datatype;
  value = applyDataTypeFormatting(property, value);
  if (datatype.toUpperCase() === 'TEXT') {
    value = applyTrim(property, value);
    value = applyLetterCase(property, value);
    value = applyZeroPadding(property, value);
  }
  return value;
}

function applyTrim(property, value) {
  const trimRule = property.trim;

  if (trimRule === 'NONE' || trimRule === null) {
    return String(value);
  }
  switch (trimRule.toUpperCase()) {
    case 'LEFT':
      value = value.replace(/^\s+/, '');
      break;
    case 'RIGHT':
      value = value.replace(/\s+$/, '');
      break;
    case 'BOTH':
      value = value.trim();
      break;
    case 'NONE':
    default:
      value = value.trim();
  }
  return value;
}

function applyLetterCase(property, value) {
  const letterCaseRule = property.letterCase;
  if (letterCaseRule === 'NONE' || letterCaseRule === null) {
    return String(value);
  }
  switch (letterCaseRule.toUpperCase()) {
    case 'UPPERCASE':
      value = value.toUpperCase();
      break;
    case 'LOWERCASE':
      value = value.toLowerCase();
      break;
    default:
      break;
  }
  return value;
}

function applyZeroPadding(property, value) {
  const zeroPaddingRule = property.zeroPadding;
  if (zeroPaddingRule === 'Y') {
    if (!/[^\d]/.test(value)) {
      var columnMaxLength = parseInt(property.maxLength, 10);
      value =
        Array(columnMaxLength)
          .fill(0)
          .join('')
          .substr(0, columnMaxLength - value.length) + value;
    }
  }
  return value;
}

function applyDataTypeFormatting(property, value) {
  let valueToFormat = value === null || value === undefined ? null : value;
  let valueSerialized;
  const type = property.datatype;
  switch (type.toUpperCase()) {
    case 'INTEGER':
      valueSerialized = valueToFormat === null ? null : parseInt(valueToFormat);
      break;
    case 'DECIMAL':
    case 'TEXT':
      valueSerialized = valueToFormat === null ? null : String(valueToFormat);
      break;
    default:
      valueSerialized = valueToFormat;
  }
  return valueSerialized;
}
