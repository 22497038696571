import React from 'react';
import { Card, Tag } from 'antd';

const getUnmappedTargetColumns = (mapping, selectedTable) => {
  const unmappedColumns = {
    error: null,
    columns: []
  };

  const missingNotNullableColumns = [];
  const missingKeyColumns = [];

  selectedTable.properties.forEach(colInfo => {
    if (!mapping.columnMapping[colInfo.name]) {
      if (
        colInfo.isKeyProperty === 'Y' &&
        ['ALWAYS', 'IFNULL'].indexOf(colInfo.valueGeneratorMoment) === -1
      ) {
        missingKeyColumns.push(colInfo.name);
      } else if (
        colInfo.nullable === 'N' &&
        ['ALWAYS', 'IFNULL'].indexOf(colInfo.valueGeneratorMoment) === -1
      ) {
        missingNotNullableColumns.push(colInfo.name);
      } else {
        unmappedColumns.columns.push(colInfo.name);
      }
    }
  });

  let missingKeyErrorText = null;
  if (missingKeyColumns.length) {
    missingKeyErrorText = (
      <React.Fragment>
        <div className="epic-font-base modal-message-subtitle">
          Missing Key Column Mapping
        </div>
        {missingKeyColumns.map(col => {
          return (
            <Tag key={`tag-col-miss-key-${col}`} color="red">
              {col}
            </Tag>
          );
        })}
      </React.Fragment>
    );
  }
  let missingNotNullableColumnsErrorText = null;
  if (missingNotNullableColumns.length) {
    missingNotNullableColumnsErrorText = (
      <React.Fragment>
        <div className="epic-font-base modal-message-subtitle">
          Missing Mandatory Column Mapping
        </div>
        {missingNotNullableColumns.map(col => (
          <Tag key={`tag-col-miss-not-null-${col}`} color="red">
            {col}
          </Tag>
        ))}
      </React.Fragment>
    );
  }

  if (missingKeyColumns.length || missingNotNullableColumns.length) {
    unmappedColumns.error = {
      title: 'Unmapped Columns',
      content: (
        <Card
          className="epic-font-base"
          style={{ fontSize: '14px' }}
          size="small"
          title="Some mandatory columns are not mapped. Make sure to map the columns
        below:"
        >
          {missingKeyErrorText}
          {missingNotNullableColumnsErrorText}
        </Card>
      )
    };
  }

  return unmappedColumns;
};

const getUnmappedSourceColumns = (mapping, source) => {
  const unmappedColumns = [];
  const sourceColumns = source.data.meta.columns;
  for (let i = 0; i < sourceColumns.length; i++) {
    let isAssigned = false;

    for (var prop in mapping.columnMapping) {
      if (Object.prototype.hasOwnProperty.call(mapping.columnMapping, prop)) {
        if (mapping.columnMapping[prop] === sourceColumns[i].name) {
          isAssigned = true;
          break;
        }
      }
    }
    if (!isAssigned) {
      unmappedColumns.push(sourceColumns[i].name);
    }
  }

  let missingSourceColumns = null;
  if (unmappedColumns.length) {
    missingSourceColumns = (
      <Card
        className="epic-font-base modal-message-warning-subtitle"
        style={{ fontSize: '14px' }}
        size="small"
        title="Some source columns are not mapped:"
      >
        <div className="epic-font-base modal-message-subtitle">
          Source Columns not mapped
        </div>
        {unmappedColumns.map(col => (
          <Tag key={`tag-col-unmapped-${col}`} color="gold">
            {col}
          </Tag>
        ))}
      </Card>
    );
  }
  return missingSourceColumns;
};

const applyMapping = (mapping, source, mappingMappedDataSet) => {
  const sourceData = source.data.contents;
  const mappedData = [];

  for (let i = 0; i < sourceData.length; i++) {
    const sourceRow = sourceData[i];
    const mappedObj = {};
    const targetColumns = Object.keys(mapping.columnMapping);
    for (let x = 0; x < targetColumns.length; x++) {
      const targetColumn = targetColumns[x];
      const sourceColumn = mapping.columnMapping[targetColumn];
      mappedObj[targetColumn] = sourceRow[sourceColumn];
    }
    mappedData.push(mappedObj);
  }
  mappingMappedDataSet(mappedData);
};

const validation = props => {
  // key and non-nullable targetcolumns may not be unmapped
  const {
    wizardStepStatusSet,
    addNotification,
    addConfirmation,
    removeConfirmation,
    wizardStepGotoNext,
    mapping,
    source,
    selectedTable,
    mappingMappedDataSet
  } = props;
  const unmappedTargetColumns = getUnmappedTargetColumns(
    mapping,
    selectedTable
  );

  if (unmappedTargetColumns.error) {
    wizardStepStatusSet('error');
    const notification = {
      isModal: true,
      title: unmappedTargetColumns.error.title,
      message:
        'Some issues were found while validating the mapping, Please check them out and take the needed actions. You can not proceed to the next step while there are still issues.',
      children: unmappedTargetColumns.error.content
    };
    addNotification(notification);
    return;
  }

  const unmappedSourceColumns = getUnmappedSourceColumns(mapping, source);
  if (unmappedSourceColumns !== null) {
    wizardStepStatusSet('error');
    const confirmation = {
      id: 'excel-wizard-step3',
      title: 'Unmapped Source Columns',
      question:
        'Some of the source columns are not mapped to any target. Are you sure you want to continue? ',
      children: unmappedSourceColumns,
      acceptCallback: () => {
        applyMapping(mapping, source, mappingMappedDataSet);
        wizardStepGotoNext();
        removeConfirmation();
      },
      cancelCallback: () => {
        removeConfirmation();
      }
    };
    addConfirmation(confirmation);
  } else {
    applyMapping(mapping, source, mappingMappedDataSet);
    wizardStepGotoNext();
  }
};
export default validation;
