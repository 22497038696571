import { connect } from 'react-redux';
import ReplicationTargets from './ReplicationTargets';
import { loadReplicationTargets } from '../../actions/replicationTargets';

import { getPrivileges } from '../../actions/privileges';
import { removeConfirmation } from '../../actions/core';
import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';
import { getTables, setSelectedTable } from '../../actions/tableGroupDetails';

const mapDispatchToProps = {
  setSelectedTableGroup,
  getTableGroups,
  getTables,
  setSelectedTable,
  loadReplicationTargets,
  getPrivileges,
  removeConfirmation
};

const mapStateToProps = state => {
  return {
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup,
    selectedTable: state.tableGroupDetails.selectedTable,
    replicationTargets: state.replicationTargets.replicationTargets || [],
    userTablePrivileges: state.privileges.userTablePrivileges || [],
    userTableGroupPrivileges: state.privileges.userTableGroupPrivileges || [],
    totalTablePrivileges: state.privileges.totalTablePrivileges || 0,
    totalTableGroupPrivileges: state.privileges.totalTableGroupPrivileges || 0,
    replicationTargetsErrors: state.replicationTargets.errors
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplicationTargets);
