import React from 'react';
import { MuiIconButton } from '../../index';
import '../CardToolbar/cardToolbar.css';

const RepTargetToolbar = ({ uri, icon, refer }) => (
  <div className="card-toolbar">
    <a target={refer} rel="noopener noreferrer" href={uri}>
      <MuiIconButton
        keyI={'iconBtn' + icon}
        color="inherit"
        toolTipText={
          icon === 'edit' ? 'icons.editSnowflake' : 'icons.previewSink'
        }
        className="card-toolbar-button"
        iconName={icon}
      />
    </a>
  </div>
);

export default RepTargetToolbar;
