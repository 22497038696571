// react
import React, { useRef } from 'react';
// libs
import applyFormatting from '../../lib/util/applyFormatting';
import getIconByName from '../../lib/util/getIconByName';
import getDataTypeConf from '../../lib/util/getDataTypeConf';
// components
import {
  Tooltip,
  TextInput,
  PropertyHelp,
  DatePickerHelper,
  ListHelper,
  Select
} from '../../components/index';
const getIsDisabled = (
  valueGeneratorName,
  isOverviewFormNewRecord,
  isOverviewFormEditing,
  isKeyProperty
) => {
  if (!!valueGeneratorName === true) return true;
  if (isOverviewFormNewRecord === true) return false;
  if (isOverviewFormEditing === true && isKeyProperty === 'N') return false;
  return true;
};

const OverviewFormReduxField = ({
  property,
  initialValue,
  error,
  isOverviewFormNewRecord,
  isOverviewFormEditing,
  change_,
  addModal,
  removeModal
}) => {
  const [value, setValue] = React.useState(initialValue || '');
  const isDisabled = getIsDisabled(
    property.valueGeneratorName,
    isOverviewFormNewRecord,
    isOverviewFormEditing,
    property.isKeyProperty
  );
  const required = property.nullable === 'N' ? true : false;
  const hasErrors = error === undefined ? false : true;
  const errorMessage = error === undefined ? '' : error.message;
  const rowClassName =
    !!property.valueGeneratorName === true && isOverviewFormNewRecord === true
      ? 'bootstrap-row-form-invisible'
      : 'bootstrap-row-form';

  const dataTypeConf = getDataTypeConf(property.datatype);

  let fieldRef = useRef();
  const handleKeyDown = event => {
    if (event.keyCode === 69 && event.target.type === 'number') {
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    const valueFormatted = applyFormatting(property, value);
    change_(property.name, valueFormatted);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleAcceptValueFromHelper = acceptedValue => {
    setValue(acceptedValue);
    change_(property.name, acceptedValue);
    removeModal();
  };

  const handleSelectChange = selection => {
    const newValue = selection ? selection.label : '';
    setValue(newValue);
    change_(property.name, newValue);
  };

  const handleOpenHelper = () => {
    if (property?.valueList?.length > 0) {
      const list = property?.valueList?.split(';').map(ele => {
        return { [property.name]: ele };
      });
      addModal({
        id: 'list-helper',
        children: (
          <ListHelper
            propertyLabel={property.name.toUpperCase()}
            list={list}
            removeModal={removeModal}
            acceptValue={selectedValue =>
              handleAcceptValueFromHelper(selectedValue)
            }
            initialValue={value}
          />
        ),
        cancelCallback: () => removeModal(),
        modalSize: 'sm',
        customClass: 'list-helper-modal',
        showIcon: true
      });
    } else {
      addModal({
        id: 'datepicker-helper',
        children: (
          <DatePickerHelper
            datatype={property.datatype}
            removeModal={removeModal}
            acceptDate={date => handleAcceptValueFromHelper(date)}
            initialValue={value}
          />
        ),
        cancelCallback: () => removeModal(),
        modalSize: 'sm',
        customClass: 'datepicker-helper-modal',
        showIcon: true
      });
    }
  };

  const SelectField = () => {
    const selectOptions = property?.valueList?.split(';').map(ele => {
      return { value: ele, label: ele };
    });
    const selectedOption = selectOptions?.filter(ele => {
      if (property.datatype === 'DECIMAL') {
        return String(parseFloat(ele.label)) === String(parseFloat(value));
      } else {
        return String(ele.label) === String(value);
      }
    });
    return (
      <Select
        key={`redux-field-select--${property.name}`}
        options={selectOptions}
        value={selectedOption}
        label={property.label}
        isDisabled={isDisabled}
        placeholder={`Select a '${property.name}'`}
        hasErrors={hasErrors}
        errorMessage={errorMessage}
        onChange={handleSelectChange}
        isClearable={isDisabled === true || required === true ? false : true}
      />
    );
  };

  return (
    <div className={rowClassName}>
      <div className="bootstrap-col-md-8 redux-field-input">
        {property?.valueList?.length > 0 ? (
          <SelectField />
        ) : (
          <TextInput
            key={`redux-field-text-input--${property.name}`}
            ref={fieldRef}
            data-testid={property.name}
            onChange={handleChange}
            value={value}
            type={dataTypeConf.inputType}
            full
            border={false}
            label={property.label || property.name.toUpperCase()}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            required={required}
            disabled={isDisabled}
            maxLength={property.length}
            hasErrors={hasErrors}
            errorMessage={errorMessage}
            style={{ textAlign: dataTypeConf.textAlign }}
          />
        )}
        {(property?.valueList?.length > 0 ||
          property.datatype.startsWith('DATE') === true) &&
          isDisabled !== true && (
            <button
              onClick={handleOpenHelper}
              className={`editable-form-helper-button  ${
                hasErrors === true ? ' error-input' : ''
              }`}
              data-testid={property.name + '-helper'}
            >
              ...
            </button>
          )}
      </div>
      <div className="bootstrap-col-md-1 redux-field-wrapper">
        <PropertyHelp
          property={property}
          description={dataTypeConf.description}
        />
      </div>
      <div className="bootstrap-col-md-1 redux-field-wrapper">
        <Tooltip content={dataTypeConf.description}>
          {getIconByName(property.datatype, 16)}
        </Tooltip>
      </div>
      <div className="bootstrap-col-md-1 redux-field-wrapper">
        {!!property.valueGeneratorName === true && (
          <Tooltip content="This is a System Field">
            {getIconByName('FingerPrint')}
          </Tooltip>
        )}
      </div>
      <div className="bootstrap-col-md-1 redux-field-wrapper">
        {property.isKeyProperty === 'Y' && (
          <Tooltip content="This is Primary Key">
            {getIconByName('keyIcon')}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default OverviewFormReduxField;
