/* eslint-disable no-case-declarations */
import _ from 'lodash';
import {
  GET_ROWS_SUCCESS,
  GET_ROWS_FAILURE,
  UPDATE_ROW,
  SET_OVERVIEW_TABLE_FILTERED,
  SET_OVERVIEW_TABLE_SORTED,
  SET_OVERVIEW_TABLE_PAGINATION,
  SET_IS_OVERVIEW_TABLE_EDITING,
  SET_SELECTED_ROW,
  APPEND_ROWS,
  RESET_ROWS,
  ADD_ROW_TO_DELETION_LIST,
  REMOVE_FROM_DELETION_LIST,
  RESET_ROW_TO_DELETION_LIST,
  ADD_ROW_TO_CHANGES_LIST,
  RESET_ROW_TO_CHANGES_LIST,
  ADD_OVERVIEW_TABLE_ERRORS,
  RESET_OVERVIEW_TABLE_ERRORS,
  SET_IS_TOP_REACHED,
  RESET_SELECTED_ROW
} from '../actions/actionTypes';

export const initialState = {
  selectedRow: null,
  selectedRowPosition: null,
  pageNr: 0,
  pageSize: 20,
  isTopReached: false,
  isOverviewTableEditing: false,
  overviewTableShowFilters: false,
  filtered: [],
  sorted: [{ id: 'OPERATION_TIMESTAMP', desc: true }],
  rows: [],
  rowsDeletionList: [],
  rowsChangesList: [],
  overviewTableErrors: []
};
const overviewTable = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case GET_ROWS_SUCCESS:
      return { ...state, rows: action.rows };
    case APPEND_ROWS:
      return {
        ...state,
        rows: Object.assign([], [...state.rows, ...action.rows])
      };
    case GET_ROWS_FAILURE:
      return { ...state, rows: [] };
    case RESET_SELECTED_ROW:
      return {
        ...state,
        selectedRow: null,
        selectedRowPosition: null
      };
    case RESET_ROWS:
      return {
        ...state,
        selectedRow: null,
        selectedRowPosition: null,
        rows: [],
        pageNr: 0,
        pageSize: 20,
        filtered: [],
        sorted: [{ id: 'OPERATION_TIMESTAMP', desc: true }],
        rowsDeletionList: [],
        rowsChangesList: [],
        overviewTableErrors: []
      };
    case SET_OVERVIEW_TABLE_FILTERED:
      return { ...state, filtered: action.filtered };
    case SET_OVERVIEW_TABLE_SORTED:
      return { ...state, sorted: action.sorted };
    case SET_OVERVIEW_TABLE_PAGINATION:
      return {
        ...state,
        pageNr: action.settings.pageNr,
        pageSize: action.settings.pageSize
      };
    case SET_IS_OVERVIEW_TABLE_EDITING:
      return {
        ...state,
        isOverviewTableEditing: action.isOverviewTableEditing
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        selectedRow: { ...action.newSelectedRow },
        selectedRowPosition: action.newSelectedRowPosition
      };
    case UPDATE_ROW:
      state.rows[action.cellInfo.index][action.cellInfo.column.id] =
        action.value;
      return { ...state, rows: [...state.rows] };
    case ADD_ROW_TO_DELETION_LIST:
      const newAddRowsDeletionList = _.remove(
        state.rowsDeletionList,
        function (rowInDeletionList) {
          return !_.isEqual(rowInDeletionList, action.row);
        }
      );
      newAddRowsDeletionList.push(action.row);
      return { ...state, rowsDeletionList: newAddRowsDeletionList };
    case ADD_ROW_TO_CHANGES_LIST:
      const newRowsChangesList = _.remove(
        state.rowsChangesList,
        function (rowInChangesList) {
          return !_.isEqual(rowInChangesList, action.row);
        }
      );
      newRowsChangesList.push(action.row);
      return { ...state, rowsChangesList: newRowsChangesList };
    case REMOVE_FROM_DELETION_LIST:
      const newRowsDeletionList = _.remove(
        state.rowsDeletionList,
        function (rowInDeletionList) {
          return !_.isEqual(rowInDeletionList, action.row);
        }
      );
      return { ...state, rowsDeletionList: [...newRowsDeletionList] };
    case RESET_ROW_TO_DELETION_LIST:
      return { ...state, rowsDeletionList: [] };
    case RESET_ROW_TO_CHANGES_LIST:
      return { ...state, rowsChangesList: [] };
    case SET_IS_TOP_REACHED:
      return { ...state, isTopReached: action.isTopReached };
    case ADD_OVERVIEW_TABLE_ERRORS:
      return { ...state, overviewTableErrors: action.error };
    case RESET_OVERVIEW_TABLE_ERRORS:
      return { ...state, overviewTableErrors: [] };
    default:
      return state;
  }
};

export default overviewTable;
