import React from 'react';

// components
import {
  FormSection,
  Select,
  TextInput,
  AmazonTargetHelp,
  Checkbox
} from '../../components/index';

import {
  amazonRegionsOptions,
  amazonAclOptions,
  replicationTargetNames
} from '../../constants/defaultValues';

const hasReplicationTarget = (targetList, type) => {
  return targetList.some(
    target => target.type.toUpperCase() === type.toUpperCase()
  );
};

const Form = props => {
  const handleCheckboxChange = event => {
    const targetName = event.target.name.split(' ').pop().toLowerCase();
    props.toggleFormCheckboxes(targetName);
  };

  const handleInputChange = field => {
    props.setS3BucketField(field);
  };

  const hasS3Target = hasReplicationTarget(
    props.replicationTargets,
    replicationTargetNames.s3
  );

  const hasSnowflakeTarget = hasReplicationTarget(
    props.replicationTargets,
    replicationTargetNames.snowflake
  );

  return (
    <div data-testid="replication-targets-form">
      <FormSection
        className="formGeneralSection"
        title="Add Replication Targets"
        subtitle="Define a replication target you would like to add."
      >
        {!hasSnowflakeTarget && (
          <Checkbox
            key={replicationTargetNames.snowflake}
            checked={props.formValues.snowflake.checked}
            onChange={handleCheckboxChange}
            name={replicationTargetNames.snowflake}
            label={replicationTargetNames.snowflake}
            data-testid="snowflake"
          />
        )}
        {!hasS3Target && (
          <Checkbox
            key={replicationTargetNames.s3}
            checked={props.formValues.s3.checked}
            onChange={handleCheckboxChange}
            name={replicationTargetNames.s3}
            label={replicationTargetNames.s3}
            data-testid="amazon-s3"
          />
        )}
      </FormSection>

      {props.formValues.s3.checked && (
        <div data-testid="amazon-s3-bucket-form">
          <FormSection
            className="formGeneralSection"
            title={<AmazonTargetHelp />}
          >
            <div
              data-testid="bucket-region-form"
              className="target-medium-size-component"
            >
              <Select
                required
                label="Region"
                options={amazonRegionsOptions}
                value={props.formValues.s3.s3BucketFields.region}
                placeholder="Select a Region"
                onChange={option =>
                  handleInputChange({
                    region: option
                  })
                }
              />
            </div>
            <div
              data-testid="bucket-acl-form"
              className="target-medium-size-component"
            >
              <Select
                required
                label="ACL"
                options={amazonAclOptions}
                value={props.formValues.s3.s3BucketFields.acl}
                placeholder="Select an ACL"
                onChange={option =>
                  handleInputChange({
                    acl: option
                  })
                }
              />
            </div>
            <div
              data-testid="bucket-url-form"
              className="target-medium-size-component"
            >
              <TextInput
                value={props.formValues.s3.s3BucketFields.uri}
                onChange={event => {
                  handleInputChange({
                    uri: event.target.value
                  });
                }}
                required
                label="URI"
                border={false}
                placeholder="Enter the Custom Amazon S3 Bucket URI"
                hasErrors={false}
                maxLength={128}
                full
              />
            </div>
          </FormSection>
        </div>
      )}
    </div>
  );
};

export default Form;
