import React from 'react';
import { Result as AntdResult } from 'antd';

// components
import { Button, RouterLink, Modal } from '../../components/index';

const FinalModal = ({ errors, redirectPath }) => {
  const hasErrors = errors.join('\t ');

  return (
    <div data-testid="replication-targets-final-modal">
      <Modal show logo title="Replication targets creation">
        <AntdResult
          key="antd-result-upload-wizard"
          status={hasErrors ? 'Error' : 'Success'}
          subTitle={
            hasErrors
              ? `Unable to create the replication targets: ${errors.join('\t')}`
              : 'Replication targets successfully created.'
          }
        />
        <div className="div-buttons-wrapper">
          <RouterLink href={redirectPath}>
            <div data-testid="replication-targets-finish-button">
              <Button>Finish</Button>
            </div>
          </RouterLink>
          {hasErrors && (
            <RouterLink href={`${redirectPath}/create`}>
              <Button>Try again</Button>
            </RouterLink>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default FinalModal;
