import React, { useState, useEffect } from 'react';
// router
import { withRouter } from 'react-router-dom';
// components
import {
  FormSection,
  List,
  Button,
  NotificationWrapper,
  ContentSection,
  Label,
  TextInput,
  LoadingScreen,
  ModalNotification
} from '../../components/index';
import { Icon } from '@nike/epic-react-ui';
// assets
import tableGroupLogo from '../../assets/img/database_2.svg';
// privilege service
import * as privilegeService from '../../services/privilegeService';
// css
import './formSnowflake.css';

const FormSnowflake = props => {
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [isTableGroupLoaded, setIsTableGroupLoaded] = useState(false);
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const [malformedRoles, setMalformedRoles] = useState([]);
  const [currentRoles, setCurrentRoles] = useState([]);
  const [newSfGrant, setNewSfGrant] = useState('');
  const [envResponse, setEnvResponse] = useState('');
  const [errorResponse, setErrorResponse] = useState('');

  // Get table/table group data
  useEffect(() => {
    const fetchData = async () => {
      setIsAwaitingResponse(true);
      await props.getTableGroups();
      await props.setSelectedTableGroup(props.match.params.tableGroupId);
      await props.setSelectedTable(
        props.match.params.tableGroupId,
        props.match.params.tableId
      );
      setIsTableLoaded(true);
      setIsTableGroupLoaded(true);
    };

    // Only if the table group hasn't be loaded then
    // we proceed to fetch the data
    if (isTableGroupLoaded === false || isTableLoaded === false) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get all current snowflake roles for this table
  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        response = await privilegeService.findSnowflakePrivilege(
          props.selectedTableGroup.name,
          props.selectedTable.name
        );
      } catch (error) {
        setEnvResponse(
          'This environment is disabled (PROD/QA only) or the request for snowflake grants failed'
        );
        setIsAwaitingResponse(false);
        return;
      }

      // Current roles cannot be deleted
      const classifiedData = response.data.roles.map(role => ({
        key: role,
        value: 'current'
      }));

      setCurrentRoles(classifiedData);
      setIsAwaitingResponse(false);
    };

    if (props.selectedTableGroup && props.selectedTable) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTableGroup, props.selectedTable]);

  // If the table and roles are not loaded, a loading screen is rendered
  if (
    isTableGroupLoaded === false ||
    isTableLoaded === false ||
    currentRoles === [] ||
    isAwaitingResponse
  )
    return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (
    (!props.selectedTableGroup && isTableGroupLoaded === true) ||
    (!props.selectedTable && isTableLoaded === true)
  ) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push('/tablegroup')}
      />
    );
  }

  // If error response for grants or the environment is disabled
  if (errorResponse !== '' || envResponse !== '') {
    const notification = {
      isNotification: true,
      title: 'Snowflake API Response',
      message: errorResponse ? errorResponse : envResponse,
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => {
          if (envResponse !== '')
            props.history.push(
              `/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}`
            );
          setErrorResponse('');
        }}
      />
    );
  }

  // If any invalid roles are submitted, show a modal with the invalid roles
  if (malformedRoles.length) {
    const notification = {
      isNotification: true,
      title: 'Invalid role',
      message:
        'Invalid role(s), please ensure all role(s) are valid, uses A-Z characters and underscores: \n' +
        malformedRoles.join(', '),
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => setMalformedRoles([])}
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point all the data needed to render this layout is in place
  // ------------------------------------------------------------------

  // It is necessary to determine the user's role
  // If the user is not an Admin, they cannot view this page
  const currentUserTableGroupRole = props?.selectedTableGroup?.role;

  if (currentUserTableGroupRole !== 'ADMIN') {
    const notification = {
      isNotification: true,
      title: 'Access Denied!',
      message:
        "The user doesn't have the right role to manage Snowflake grants",
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props.history.push(
            `/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}`
          )
        }
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point everything is in place and the user has access for sure to this resource
  // ------------------------------------------------------------------

  // Handling snowflake grant calls
  const handleSnowflakeGrants = async () => {
    // If a role is new, and not one of the fetched roles, we will submit it
    const rolesToGrant = currentRoles
      .filter(role => role.value === 'new')
      .map(role => role.key);

    setIsAwaitingResponse(true);

    // Make call for each role.
    const malformedRoles = [];
    let rolesGranted = new Set();
    for (const role of rolesToGrant) {
      if (errorResponse) break;
      const response = await handleSnowflakeRequest(role);
      if (response.data) rolesGranted.add(role);
    }

    // Label each role to update display.
    const updatedRoles = currentRoles.map(role => {
      if (role.value === 'current') {
        return role;
      } else if (rolesGranted.has(role.key) || role.value === 'granted') {
        return { key: role.key, value: 'granted' };
      } else {
        malformedRoles.push(role.key);
        return { key: role.key, value: 'invalid' };
      }
    });

    setCurrentRoles(updatedRoles);
    setMalformedRoles(malformedRoles);
    setIsAwaitingResponse(false);
    setNewSfGrant('');
  };

  const handleSnowflakeRequest = async roleToGrant => {
    try {
      return await privilegeService.grantSnowflakePrivilege(
        { roles: [roleToGrant] },
        props.selectedTableGroup.name
      );
    } catch (error) {
      setErrorResponse('Internal Server Error');
    }
  };

  // Validating input to roles list
  const acceptListSfGrants = () => {
    if (newSfGrant.trim(' ') === '') return;

    const currState = currentRoles;
    const flattenedRoles = currentRoles.map(role => role.key);
    const newGrants = newSfGrant.split(',').map(grant => grant.toUpperCase());
    const specialCharacters = /^[a-zA-Z0-9_]+$/;
    const invalidRoles = [];
    let uniqueRoles = new Set();

    for (let i of newGrants) {
      i = i.trim(' ');

      if (!specialCharacters.test(i)) {
        invalidRoles.push(i);
        continue;
      }

      if (!flattenedRoles.includes(i) && !uniqueRoles.has(i) && i !== '') {
        currState.push({ key: i, value: 'new' });
        uniqueRoles.add(i);
      }
    }

    setCurrentRoles(currState);
    setMalformedRoles(invalidRoles);
    setNewSfGrant('');
  };

  // Handling text input value
  const handleSfChange = event => {
    setNewSfGrant(event.target.value);
  };

  // Removing data from list
  const handleDelete = (datum, state, setState) => {
    const newState = state.filter(ele => {
      return ele.key !== datum.key;
    });
    setState(newState);
  };

  const handleRolesColors = roleState => {
    switch (roleState) {
      case 'granted':
        return 'ant-list-item-granted';
      case 'invalid':
        return 'ant-list-item-invalid';
      default:
        return '';
    }
  };

  return (
    <NotificationWrapper {...props}>
      <LoadingScreen isLoading={isAwaitingResponse} />
      <div className="toopbar-title-no-navigable">{'Creation Mode'}</div>
      <ContentSection
        small
        colorfullTitle
        logo={tableGroupLogo}
        title={props.selectedTableGroup.name}
      />
      <div data-testid="privileges-form">
        <FormSection
          className="formGeneralSection"
          title={'Add Snowflake Roles'}
        >
          <div>
            <div className="form-privilege-item">
              <TextInput
                full
                border={false}
                label="Snowflake roles delimited by commas"
                value={newSfGrant}
                onChange={handleSfChange}
              />
            </div>
            <div className="form-privilege-button-container form-privilege-item submit-buttons">
              <Button
                className="form-privilege-buttons"
                onClick={() => acceptListSfGrants()}
              >
                Add
              </Button>
              <Button
                className="form-privilege-buttons"
                onClick={() =>
                  props.history.push(
                    `/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}/replicationtargets`
                  )
                }
              >
                Back
              </Button>
            </div>
            <div className="form-privilege-item">
              <Label label="Snowflake roles" />
            </div>
            <div className="form-privilege-item form-privilege-list-container">
              <List
                dataSource={currentRoles}
                renderItem={role => (
                  <List.Item
                    key={role.key}
                    className={
                      'ant-list-item-snowflake-roles ' +
                      handleRolesColors(role.value)
                    }
                  >
                    <List.Item.Meta description={role.key} />
                    {(role.value === 'new' || role.value === 'invalid') && (
                      <Button
                        small
                        onClick={() =>
                          handleDelete(role, currentRoles, setCurrentRoles)
                        }
                      >
                        <Icon type="delete" />
                      </Button>
                    )}
                  </List.Item>
                )}
              />
            </div>
            <div className="form-privilege-button-container form-privilege-item submit-container">
              <Button
                className="form-privilege-buttons"
                onClick={() => handleSnowflakeGrants()}
              >
                Submit
              </Button>
            </div>
          </div>
        </FormSection>
      </div>
    </NotificationWrapper>
  );
};
export default withRouter(FormSnowflake);
