import React from 'react';
import { Card, RouterLink } from '../../index';
import { truncate } from '../../../lib/util/truncate';
import './cardsContainer.css';

const CustomTitle = (title, logo, classTitle) => (
  <div className={classTitle}>
    <img
      className="card-container-custom-title-icon-img-sm"
      src={logo}
      alt={title}
    />

    <div className="card-container-custom-title-icon-col-last">{title}</div>
  </div>
);

const CardComponent = ({ card, classTitle, classCard }) => {
  return (
    <Card
      id={card.title}
      className={`${classCard}`}
      iconPath={card.iconPath}
      title={CustomTitle(card.title, card.iconPath, classTitle)}
      hover
      subtitle={card.subtitle}
      description={card.description ? truncate(card.description, 120) : ''}
      width={card.width}
      height={card.height}
    >
      {card.children}
    </Card>
  );
};

const CardsGroup = ({ cards, classTitle, classCard, redirectWhenClick }) =>
  cards.map(card => {
    if (redirectWhenClick === true) {
      return (
        <RouterLink
          key={card.title}
          href={card.href}
          onClick={card.onCardClick}
        >
          <CardComponent
            card={card}
            classTitle={classTitle}
            classCard={`${classCard} ${card.customClass}`}
          />
        </RouterLink>
      );
    } else {
      return (
        <CardComponent
          key={card.title}
          card={card}
          classTitle={classTitle}
          classCard={`${classCard} ${card.customClass}`}
        />
      );
    }
  });

const CardsContainer = props => {
  const { cards, classContainer, redirectWhenClick } = props;
  const classTitle = props.classTitle
    ? props.classTitle
    : 'card-container-custom-title-row';
  const classCard = props.classCard ? props.classCard : 'card-table-group';

  return (
    <div className={classContainer}>
      <CardsGroup
        cards={cards}
        classTitle={classTitle}
        classCard={classCard}
        redirectWhenClick={redirectWhenClick}
      />
    </div>
  );
};

export default CardsContainer;
