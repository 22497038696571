import React from 'react';
import { Popover, Avatar } from 'antd';
import { REACT_APP_PATH } from '../../../constants/defaultValues';

const styles = {
  divAvatar: {
    backgroundColor: 'var(--er-blue)',
    verticalAlign: 'middle',
    marginLeft: 14
  }
};

const popoverDefinitionHelpContent = (
  <div>
    <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>MANDATORY</p>
    <p>
      Determines whether a column is mandatory or not, it doesn&apos;t allow
      nulls nor empty strings
    </p>
    <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>KEYFIELD</p>
    <p>Determines whether a column is part of a Primary Key</p>
    <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>TRIMMING</p>
    <p>Determines whether leading and trailing spaces are eliminated</p>
    <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>LETTERCASE</p>
    <p>
      Determines the leter case for a column, it could be upper, lower or none
    </p>
    <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>PATTERN</p>
    <p>
      It is a regular expression, it means that it is a text string for
      describing a pattern. It is useful to validate the format of an entered
      value.
    </p>
    <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>
      PATTERN VALIDATION MESSAGE
    </p>
    <p>
      Determines a custom message to be displayed when a PATTERN doesn&apos;t
      match
    </p>
    <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>LIST</p>
    <p>
      Determines a List of values that can be used for the specific column. Only
      values in the list can be used in the Form and Table.
    </p>
    <p>
      For more information please go to{' '}
      <a
        href={`${REACT_APP_PATH}/docs/docs/howtodefinetables.html`}
        target="_blank"
        rel="noopener noreferrer"
      >
        How to define Tables
      </a>
    </p>
  </div>
);

const DefinitionHelp = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center'
    }}
  >
    <h1>1. Table Definition</h1>
    <Popover content={popoverDefinitionHelpContent} title="Table Definition">
      <Avatar style={styles.divAvatar} size="small">
        ?
      </Avatar>
    </Popover>
  </div>
);

export default DefinitionHelp;
