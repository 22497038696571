import React, { Fragment } from 'react';
import { Button, FormSection, Table } from '../../index';
import './listHelper.css';

const ListHelper = props => {
  const columns = [
    { Header: props.propertyLabel, accessor: props.propertyLabel }
  ];

  const getTdProps = (state, rowInfo) => {
    return {
      style: {
        cursor: 'pointer'
      },
      onClick: e => {
        if (rowInfo && rowInfo.original) {
          props.acceptValue(rowInfo.original[props.propertyLabel]);
        }
      }
    };
  };

  const onFilter = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
      : true;
  };

  return (
    <Fragment>
      <FormSection className="list-helper-modal-form-section">
        <Table
          columns={columns}
          data={props.list}
          getTdProps={getTdProps}
          showPagination={false}
          filterable={true}
          pageSize={props.list.length}
          onFilter={(filter, row) => onFilter(filter, row)}
        />
      </FormSection>
      <div className="modal-button-wrapper">
        <Button small onClick={props.removeModal}>
          Cancel
        </Button>
      </div>
    </Fragment>
  );
};
export default ListHelper;
