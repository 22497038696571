import { REACT_APP_DEBUG_MODE } from '../../constants/defaultValues';

export default function sendAnalyticsEvent(eventName, eventData = {}) {
  try {
    window.gtag('event', eventName, {
      debug_mode: REACT_APP_DEBUG_MODE,
      ...eventData
    });
  } catch (error) {
    console.log(error);
  }
}
