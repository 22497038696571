import { connect } from 'react-redux';
import MainLayout from './MainLayout';
import {
  setIsOverviewFormEditingNotification,
  setIsOverviewFormCreatingNotification,
  setIsOverviewTableEditingNotification
} from '../../actions/core';
import { cleanOverviewTable } from '../../actions/overviewTable';

const mapDispatchToProps = {
  setIsOverviewFormEditingNotification,
  setIsOverviewFormCreatingNotification,
  setIsOverviewTableEditingNotification,
  cleanOverviewTable
};

const mapStateToProps = state => {
  return {
    isOverviewFormEditing: state.overviewForm.isOverviewFormEditing,
    isOverviewFormNewRecord: state.overviewForm.isOverviewFormNewRecord,
    isOverviewTableEditing: state.overviewTable.isOverviewTableEditing,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup,
    selectedtable: state.tableGroupDetails.selectedTable,
    modal: state.core.modal
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
