import React from 'react';
import './contentSection.css';
import RouterLink from '../../RouterLink/RouterLink';
const ContentSection = props => {
  const className = props.small ? 'small' : '';
  const contentMiddleCustomCss = props.contentMiddleCustomCss
    ? props.contentMiddleCustomCss
    : '';
  const colorfullTitleClass = props.colorfullTitle ? 'title-color-full' : '';

  const ContentMiddle = () => (
    <div className={`content-section-content ${className}`}>
      <div
        className={`content-section-row-title ${colorfullTitleClass} ${contentMiddleCustomCss}`}
        style={{
          fontSize: props.small ? '19px' : '24px',
          padding: props.small ? '5px 0px' : ''
        }}
      >
        {props.title}
      </div>
      <div className={`content-section-row-description ${className}`}>
        {props.description || ''}
      </div>
    </div>
  );

  return (
    <div className={`content-section-row-small ${className}`}>
      <img
        src={props.logo}
        alt={props.title}
        className={`content-section-title-img-small ${className}`}
      />

      {props.asideLeft && (
        <div className={`${props.asideLeftClassName || ''} ${className}`}>
          {props.asideLeft}
        </div>
      )}
      {props.redirectWhenContentMiddleClick === true ? (
        <RouterLink
          className="content-section-link"
          href={props.redirectContentMiddlePath}
        >
          <ContentMiddle />
        </RouterLink>
      ) : (
        <ContentMiddle />
      )}
      {props.asideRight}
    </div>
  );
};

export default ContentSection;
