import React from 'react';
import {
  Table,
  FormSection,
  TablePagination
} from '../../../../components/index';
import { autoGeneratedColumnsNames } from '../../../../constants/defaultValues.js';

const CellDeserialized = ({ value }) => {
  let valueDeserialized;
  if (/^\/Date\((-?\d+)\)\/$/.test(value) === true) {
    valueDeserialized = new Date(
      parseInt(/^\/Date\((-?\d+)\)\/$/.exec(value)[1], 10)
    ).toISOString();
  } else {
    valueDeserialized = value;
  }
  return <div>{valueDeserialized}</div>;
};

const DetailsTransformedData = props => {
  const { formattedData, formattedDataColumns } = props;
  const rows = formattedData.map(rowData => {
    let row = {};
    rowData.columns.forEach(column => {
      row[column.name] = column.value;
    });
    return row;
  });

  const columns = formattedDataColumns
    .filter(col => !autoGeneratedColumnsNames.includes(col.name))
    .map(col => {
      return { Header: col.name, accessor: col.name, Cell: CellDeserialized };
    });

  return (
    <FormSection className="formPreviewSection" title="Review">
      <Table
        columns={columns}
        pages={Math.ceil(rows.length / 10)}
        data={rows}
        defaultPageSize={10}
        pageSize={10}
        PaginationComponent={TablePagination}
        showPagination={true}
      />
    </FormSection>
  );
};

export default DetailsTransformedData;
