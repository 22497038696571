const validation = props => {
  let error = null;
  const { wizardStepStatusSet, wizardStepGotoNext, addNotification } = props;
  const propValidation = props.validation;

  if (propValidation.errors && propValidation.errors.length) {
    error = {
      title: 'Error in the Data Validation Step',
      message:
        'There are errors in the source data. Update your source data and restart the wizard.'
    };
  }

  if (error) {
    wizardStepStatusSet('error');
    const notification = {
      isModal: true,
      title: error.title,
      message: error.message
    };
    addNotification(notification);
    return;
  }

  wizardStepGotoNext();
};

export default validation;
