import sanitizeNamePart from '../../../lib/util/sanitizeNamePart';
import {
  CONTENT_TYPE_NAME_MAX_LENGTH,
  TABLE_CREATION_MODES
} from '../../../constants/defaultValues';

const sourceValidation = props => {
  let error = null;
  const {
    source,
    wizardStepStatusSet,
    wizardStepGotoNext,
    addNotification,
    setNewTableName,
    core: { tableCreationMode }
  } = props;

  if (tableCreationMode === TABLE_CREATION_MODES.UPLOAD) {
    if (!source.filename) {
      error = {
        title: 'Error in the Source Step',
        message:
          'No file selected. Use the upload button to select a source file or select the "Create Table without a file" creation mode.'
      };
    } else if (
      (source.filetype === 'xlsx' || source.filetype === 'xls') &&
      !source.filetypeOptions.selectedSheetName
    ) {
      error = {
        title: 'Error selecting a source',
        message:
          'No Excel sheet selected. Use the dropdown list to select the sheet holding the source data.'
      };
    } else {
      const {
        filetypeOptions: { selectedSheetName }
      } = source;
      const cleanSheetName = sanitizeNamePart(selectedSheetName);
      setNewTableName(cleanSheetName.substr(0, CONTENT_TYPE_NAME_MAX_LENGTH));
    }
  }

  if (tableCreationMode === TABLE_CREATION_MODES.TEMPLATE) {
    if (!source.data.meta.columns || !source.data.meta.columnsDefinition) {
      error = {
        title: 'Error selecting an environment',
        message:
          'No template selected. Use the dropdown list to select template for creation of the table.'
      };
    }
  }

  if (error) {
    wizardStepStatusSet('error');
    const notification = {
      isModal: true,
      title: error.title,
      message: error.message
    };
    addNotification(notification);
    return;
  }
  wizardStepGotoNext();
};

export default sourceValidation;
