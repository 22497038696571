import React, { useState, useEffect } from 'react';
import {
  CardsContainer,
  FormSection,
  ContentSection,
  ContentSectionSearch,
  CardFooter,
  Button,
  RouterLink,
  AsideLeftTableGroupSection,
  LoadingScreen,
  ModalNotification
} from '../../components';
import tableGroupLogo from '../../assets/img/database_2.svg';
import tableLogo from '../../assets/img/database_1.svg';
import sendAnalyticsEvent from '../../lib/util/sendAnalyticsEvent';
import getIconByName from '../../lib/util/getIconByName';

const tealTheme = { primary: 'black', secondary: 'white' };

const TableGroupDetails = props => {
  const [value, setValue] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsBusy(true);
      await props.getTableGroups();
      await props.setSelectedTableGroup(props.match.params.tableGroupId);
      setIsLoaded(true);
      setIsBusy(false);
    };
    if (isLoaded === false) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If it is not loaded the the Loading screen is rendered
  if (isLoaded === false) return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props.selectedTableGroup && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push('/tablegroup')}
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point all the data needed to render this layout is in place
  // ------------------------------------------------------------------

  const currentUserRole = props?.selectedTableGroup?.role;

  let showNewTableButton = false;
  let showManagePrivileges = false;
  let showViewPrivileges = false;

  switch (currentUserRole) {
    case 'OWNER':
    case 'ADMIN':
      showNewTableButton = true;
      showManagePrivileges = true;
      break;
    case 'EDITOR':
      showNewTableButton = true;
      showViewPrivileges = true;
      break;
    case 'VIEWER':
    case 'NONE':
      showViewPrivileges = true;
      break;
    default:
      break;
  }

  const searchTables = () =>
    props?.tables?.filter(ele => {
      return ele.name && ele.name.toUpperCase().includes(value.toUpperCase());
    });

  const getTablesToDisplay = searhValue =>
    searhValue?.trim() === '' ? props.tables : searchTables();

  const tablesToDisplay =
    props?.tables?.length > 0 ? getTablesToDisplay(value) : [];

  const AsideRightTableGroupSection = (
    <div className="right-aside-wrapper">
      {showNewTableButton === true && (
        <RouterLink
          href={`/tablegroup/${props.selectedTableGroup.name}/contentDefiner`}
        >
          <Button data-testid="create-table-button" theme={tealTheme} small>
            New Table
          </Button>
        </RouterLink>
      )}
      {showManagePrivileges === true && (
        <RouterLink
          href={`/tablegroup/${props.selectedTableGroup.name}/privileges`}
        >
          <Button
            onClick={() => props.getPrivileges(props.level)}
            theme={tealTheme}
            small
          >
            Manage Privileges
          </Button>
        </RouterLink>
      )}
      {showViewPrivileges === true && (
        <RouterLink
          href={`/tablegroup/${props.selectedTableGroup.name}/privileges`}
        >
          <Button
            onClick={() => props.getPrivileges(props.level)}
            theme={tealTheme}
            small
          >
            View Privileges
          </Button>
        </RouterLink>
      )}
    </div>
  );

  const AsideLeftTableSection = (
    <div className="table-groups-tiles">
      <div className="table-groups-tiles-div">
        <span className="table-groups-counter">{props.totalCount}</span>
      </div>
    </div>
  );

  const CardFooterToolbar = ({ table }) => {
    const iconName = table.hasAccess === true ? 'lockopen' : 'lock';
    return (
      <CardFooter
        leftSection={
          <div className="card-container-padlock container">
            <div className="bootstrap-col-md-1"> {getIconByName(iconName)}</div>
            <div className="bootstrap-col-md-11 card-container-padlock-text">{`version: ${table.version}`}</div>
          </div>
        }
      />
    );
  };

  const cards =
    tablesToDisplay.length > 0
      ? tablesToDisplay.map(table => ({
          title: table.name,
          subtitle: table.id,
          description: table.description || table.uri,
          width: '180px',
          height: '180px',
          iconPath: tableLogo,
          href: `/tablegroup/${props.selectedTableGroup.name}/table/${table.name}`,
          children: <CardFooterToolbar table={table} />,
          onCardClick: () =>
            handleCardClick(props.selectedTableGroup.name, table.name),
          customClass: table.hasAccess === false ? 'card-locked' : ''
        }))
      : [];

  const handleChange = val => {
    setValue(val);
  };

  const handleCardClick = (selectedTableGroupName, selectedTableName) => {
    props.setSelectedTable(selectedTableGroupName, selectedTableName);
    sendAnalyticsEvent('selectTable', {
      moduleId: 'react-content-type-grid',
      screenId: 'tableGroupDetails',
      config: { table: selectedTableName }
    });
  };

  return (
    <div data-testid="table-group-list" className="container-layout-wrapper">
      <LoadingScreen isLoading={isBusy} />
      <ContentSection
        logo={tableGroupLogo}
        title={props.selectedTableGroup.name}
        description={props.selectedTableGroup.description}
        asideLeft={
          <AsideLeftTableGroupSection
            totalTableGroupPrivileges={props.totalPrivilegesCount}
            currentUserTableGroupRole={currentUserRole}
            totalCount={props.totalCount}
          />
        }
        asideRight={AsideRightTableGroupSection}
        asideLeftClassName="content-section-col-statistic"
      />
      <ContentSectionSearch
        logo={tableLogo}
        title="Tables"
        value={value}
        handleChange={handleChange}
        asideLeft={AsideLeftTableSection}
        asideLeftClassName="content-section-col-statistic"
      />
      <FormSection className="container-layout-body">
        <CardsContainer
          cards={cards}
          classContainer="card-container-table"
          redirectWhenClick={true}
        />
      </FormSection>
    </div>
  );
};

export default TableGroupDetails;
