import React from 'react';
import { Select } from '../../index';

import {
  delimiterOptions,
  dateFormatOptions
} from '../../../constants/defaultValues';

const getOptionByValue = (options, value) =>
  options.find(option => option.value === value);
const getDelimiterOption = delimiter =>
  getOptionByValue(delimiterOptions, delimiter);
const getDateFormatOption = dateFormat =>
  getOptionByValue(dateFormatOptions, dateFormat);

const CsvOptions = props => {
  const {
    dateFormat,
    delimiter,
    handleDelimiterChange,
    handleDateFormatChange
  } = props;

  if (delimiter === null && dateFormat === null) {
    return null;
  }

  return (
    <>
      <div className="source-selector-medium-size-component">
        <Select
          label="Delimiter"
          className="select-excelsheet"
          options={delimiterOptions}
          value={getDelimiterOption(delimiter)}
          onChange={({ value }) => {
            handleDelimiterChange(value);
          }}
          placeholder="Select a Delimiter"
        />
      </div>
      <div className="source-selector-medium-size-component">
        <Select
          label="Date Format"
          className="select-excelsheet"
          options={dateFormatOptions}
          value={getDateFormatOption(dateFormat)}
          onChange={({ value }) => {
            handleDateFormatChange(value);
          }}
          placeholder="Select a Date Format"
        />
      </div>
    </>
  );
};

export default CsvOptions;
