import React from 'react';
import { FormSection } from '@nike/epic-react-ui';
import { Icon, Card, Statistic } from 'antd';

class SummaryOverview extends React.Component {
  render() {
    const {
      totalRows,
      totalColumns,
      columnsWithErrors,
      rowsWithErrors,
      cellsWithErrors
    } = this.props;
    // Cells
    const totalCells = totalRows * totalColumns;
    const validCells = totalCells - cellsWithErrors;
    const validCellsPercentage = ((100 * validCells) / totalCells).toFixed(1);
    const invalidCellsPercentage = (
      (100 * cellsWithErrors) /
      totalCells
    ).toFixed(1);
    // Rows
    const validRows = totalRows - rowsWithErrors;
    const validRowsPercentage = ((100 * validRows) / totalRows).toFixed(1);
    const invalidRowsPercentage = ((100 * rowsWithErrors) / totalRows).toFixed(
      1
    );
    // Columns
    const validColumns = totalColumns - columnsWithErrors;
    const validColumnsPercentage = (
      (100 * validColumns) /
      totalColumns
    ).toFixed(1);
    const invalidColumnsPercentage = (
      (100 * columnsWithErrors) /
      totalColumns
    ).toFixed(1);

    return (
      <FormSection className="formPreviewSection" title="data validation">
        <div className="card-row">
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Total Records"
                value={totalRows}
                precision={0}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Valid Records"
                value={validRows}
                precision={0}
                valueStyle={{ color: 'var(--er-success)' }}
                prefix={<Icon type="check-circle" />}
                suffix={`${validRowsPercentage}%`}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Invalid Records"
                value={rowsWithErrors}
                precision={0}
                valueStyle={
                  cellsWithErrors
                    ? { color: 'var(--er-error)' }
                    : { color: 'var(--er-success)' }
                }
                prefix={
                  cellsWithErrors ? (
                    <Icon type="close-circle" />
                  ) : (
                    <Icon type="check-circle" />
                  )
                }
                suffix={`${invalidRowsPercentage}%`}
              />
            </Card>
          </div>
        </div>
        <div className="card-row">
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Total Columns"
                value={totalColumns}
                precision={0}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Valid Columns"
                value={validColumns}
                precision={0}
                valueStyle={{ color: 'var(--er-success)' }}
                prefix={<Icon type="check-circle" />}
                suffix={`${validColumnsPercentage}%`}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Invalid Columns"
                value={columnsWithErrors}
                precision={0}
                valueStyle={
                  cellsWithErrors
                    ? { color: 'var(--er-error)' }
                    : { color: 'var(--er-success)' }
                }
                prefix={
                  cellsWithErrors ? (
                    <Icon type="close-circle" />
                  ) : (
                    <Icon type="check-circle" />
                  )
                }
                suffix={`${invalidColumnsPercentage}%`}
              />
            </Card>
          </div>
        </div>
        <div className="card-row">
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic title="Total Cells" value={totalCells} precision={0} />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Valid Cells"
                value={validCells}
                precision={0}
                valueStyle={{ color: 'var(--er-success)' }}
                prefix={<Icon type="check-circle" />}
                suffix={`${validCellsPercentage}%`}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Invalid Cells"
                value={cellsWithErrors}
                precision={0}
                valueStyle={
                  cellsWithErrors
                    ? { color: 'var(--er-error)' }
                    : { color: 'var(--er-success)' }
                }
                prefix={
                  cellsWithErrors ? (
                    <Icon type="close-circle" />
                  ) : (
                    <Icon type="check-circle" />
                  )
                }
                suffix={`${invalidCellsPercentage}%`}
              />
            </Card>
          </div>
        </div>
      </FormSection>
    );
  }
}

export default SummaryOverview;
