// react
import React, { PureComponent, Fragment } from 'react';
// components
import {
  Notification,
  EditableTable,
  ContentSection,
  TableGroupAsideSection,
  Table,
  TablePagination,
  Tooltip
} from '../../components';
// subcomponents
import OverviewTabletoolbar from './OverviewTableToolbar';
// assets
import tableGroupLogo from '../../assets/img/database_2.svg';
// libs
import applyFormatting from '../../lib/util/applyFormatting';
import sendAnalyticsEvent from '../../lib/util/sendAnalyticsEvent';
import getIconByName from '../../lib/util/getIconByName';
// css
import './overviewTable.css';
import 'react-datepicker/dist/react-datepicker.css';

class OverviewTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height - 19,
      width: props.width,
      pageSize: parseInt((window.innerHeight - 160) / 27, 10)
    };
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };

  hasRows = this.props?.rows?.length > 0 ? true : false;

  analyticsEventBody = {
    module_id: 'react_data_manager',
    screen_id: 'overview_table',
    table: this.props?.selectedTable?.name,
    table_group_name: this.props?.selectedTable?.tableGroupName
  };

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight - 19,
      pageSize: parseInt((window.innerHeight - 220) / 27, 10)
    });
  };

  onDownloadDataClick = format => {
    this.props.downloadRows(format);
    this.props.removeModal();
  };

  getOverviewTableColumns = () => {
    return this.props.properties.map(property => ({
      id: property.name,
      Header: (
        <Tooltip content={property.name.toUpperCase()}>
          <div className="row" style={{ marginLeft: 0 }}>
            <div className="divHeaderTableLabel">
              <label className="headerTableLabel">
                {property.nullable === 'N' ? '*  ' : ''}
                {property.label || property?.name?.toUpperCase()}
              </label>
              {getIconByName(property.datatype, 16)}
              {property.isKeyProperty === 'Y' && getIconByName('keyIcon')}
              {!!property.valueGeneratorName === true &&
                getIconByName('FingerPrint')}
            </div>
          </div>
        </Tooltip>
      ),
      accessor: property.name.toUpperCase(),
      style: {
        maxHeight: '27px',
        height: '27px'
      }
    }));
  };

  onFetchData = state => {
    const overviewTableSetings = {
      pageNr: state.page,
      pageSize: state.pageSize
    };
    if (this.props.isLoading === true || this.props.isTopReached === true)
      return;

    this.props.setOverviewTablePagination(overviewTableSetings);
    if (
      state.data.length <= state.page * state.pageSize + state.pageSize &&
      this.props.pageNr !== state.page
    )
      this.props.appendRows();
  };

  onSortedChange = newSorted => {
    if (this.props.isOverviewTableEditing === true) {
      this.props.showNoSortingFilteringAllowNotification();
    } else {
      this.props.setOverviewTableSorted(newSorted);
      this.props.getRows();
    }
  };

  onUpdatableCellFromHelper = (value, cellInfo) => {
    this.props.updateRow({ ...cellInfo }, value);
    this.props.removeModal();
  };

  onUpdatableCellInputBlur = (columnMetaData, cellInfo, event) => {
    const formatedValue = applyFormatting(columnMetaData, event.target.value);

    this.props.updateRow(cellInfo, formatedValue);
  };

  getTdProps = (_state, rowInfo) => {
    return {
      onFocus: (e, handleOriginal) => {
        if (rowInfo && rowInfo.original) {
          if (this.props.selectedRowPosition !== rowInfo.index) {
            const tableSelectedRow = { ...rowInfo.original };
            this.props.setSelectedRow(tableSelectedRow, rowInfo.index);
          }
        }
      }
    };
  };

  handleCheckBoxChange = (cellInfo, event) => {
    const checked = event.currentTarget.checked;
    if (checked === true) {
      this.props.addRowToDeletionList(cellInfo);
    } else {
      this.props.removeRowFromDeletionList(cellInfo);
    }
  };

  getError = cellInfo => {
    return this.props.overviewTableErrors
      .filter(tableError => {
        return tableError.rowIndex === cellInfo.original;
      })
      .filter(rowErr => {
        return rowErr.id === cellInfo.column.id;
      })[0];
  };

  onNewRecordClick = () => {
    this.props.createOverviewFormRecord();
  };

  onSaveRecordClick = async () => {
    const confirmation = {
      acceptCallback: () => {
        this.onAcceptConfirmationClick();
      },
      cancelCallback: () => {
        this.onCancelConfirmationClick();
      }
    };
    await this.props.setOnSaveRecordConfirmation(confirmation);
  };

  onDeleteRecordClick = () => {
    const confirmation = {
      acceptCallback: () => {
        this.onAcceptConfirmationClick();
      },
      cancelCallback: () => {
        this.onCancelConfirmationClick();
      }
    };
    this.props.setOnDeleteRecordConfirmation(confirmation);
  };

  onAcceptConfirmationClick = async () => {
    switch (this.props.confirmation.id) {
      case 'table-save':
        await this.props.saveOverviewTableAction();
        sendAnalyticsEvent('ui_create_edit_record', this.analyticsEventBody);
        break;
      case 'table-delete':
        await this.props.deleteOverviewTableAction();
        sendAnalyticsEvent('ui_delete_record', this.analyticsEventBody);
        break;
      default:
        return;
    }
  };

  handleTableGroupClick = () => {
    sendAnalyticsEvent('ui_select_table_group', this.analyticsEventBody);
    this.props.resetSelectedTable();
  };

  onCancelConfirmationClick = () => {
    switch (this.props.confirmation.id) {
      case 'table-save':
        this.props.removeConfirmation();
        break;
      case 'table-delete':
        this.props.cancelDeleteOnConfirmation();
        break;
      default:
        return;
    }
  };

  onCancelClick = () => {
    this.props.cancelOverviewTableAction();
  };

  render() {
    const { pageSize, height } = this.state;
    const columns = this.getOverviewTableColumns();
    let componentToReturn;
    if (
      this.props.notification.isNotification === true &&
      this.props.notification.isModal === false &&
      this.props.notification.isToast === false
    ) {
      componentToReturn = (
        <Notification
          isLoading={this.props.isLoading}
          notification={this.props.notification}
        />
      );
    } else {
      componentToReturn = (
        <Fragment>
          <ContentSection
            small
            colorfullTitle
            logo={tableGroupLogo}
            title={this.props.selectedTable.name}
            contentMiddleCustomCss={
              !this.props.isOverviewTableEditing ? 'link' : ''
            }
            redirectWhenContentMiddleClick={!this.props.isOverviewTableEditing}
            redirectContentMiddlePath={`/tablegroup/${this.props.selectedTableGroup.name}/table/${this.props.selectedTable.name}`}
            asideLeft={
              <TableGroupAsideSection
                selectedTableGroup={this.props.selectedTableGroup}
                isNavigable={!this.props.isOverviewTableEditing}
                handleTableGroupClick={this.handleTableGroupClick}
              />
            }
            asideRight={
              <OverviewTabletoolbar
                addModal={this.props.addModal}
                removeModal={this.props.removeModal}
                onNewRecordClick={() => this.onNewRecordClick()}
                onSaveRecordClick={() => this.onSaveRecordClick()}
                onDeleteRecordClick={() => this.onDeleteRecordClick()}
                onCancelClick={() => this.onCancelClick()}
                onDownloadDataClick={format => this.onDownloadDataClick(format)}
                overviewTableShowFilters={this.props.overviewTableShowFilters}
                isOverviewTableEditing={this.props.isOverviewTableEditing}
                setIsOverviewTableEditing={this.props.setIsOverviewTableEditing}
                setAppViewMode={this.props.setAppViewMode}
                showDeleteButton={
                  this.props?.rowsDeletionList?.length > 0 ? true : false
                }
                showLockButton={
                  this.props.currentUserTableRole === 'VIEWER'
                    ? false
                    : this.hasRows
                }
                showNewButton={
                  this.props.currentUserTableRole === 'VIEWER' ? false : true
                }
                showUploadButton={
                  this.props.currentUserTableRole === 'VIEWER' ? false : true
                }
                setDataViewMode={this.props.setDataViewMode}
                path={`/tablegroup/${this.props.selectedTableGroup.name}/table/${this.props.selectedTable.name}`}
                selectedTable={this.props.selectedTable}
                properties={this.props.properties}
                filtered={this.props.filtered}
                setOverviewTableFiltered={this.props.setOverviewTableFiltered}
              />
            }
          />
          {this.props.isOverviewTableEditing === true && (
            <div data-testid="editable-table">
              <EditableTable
                properties={this.props.properties}
                rowsDeletionList={this.props?.rowsDeletionList || []}
                rows={this.props.rows}
                pages={Math.ceil(this.props?.rows?.length / pageSize)}
                showPagination={true}
                isOverviewTableEditing={this.props.isOverviewTableEditing}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                height={height}
                minRows={0}
                onFetchData={this.onFetchData}
                onUpdatableCellInputBlur={(columnMetaData, cellInfo, event) => {
                  this.onUpdatableCellInputBlur(
                    columnMetaData,
                    cellInfo,
                    event
                  );
                }}
                handleCheckBoxChange={(cellInfo, event) => {
                  this.handleCheckBoxChange(cellInfo, event);
                }}
                getError={cellInfo => this.getError(cellInfo)}
                getTdProps={this.getTdProps}
                selectedTable={this.props.selectedTable}
                addModal={this.props.addModal}
                removeModal={this.props.removeModal}
                onUpdatableCellFromHelper={(selectedValue, cellInfo) =>
                  this.onUpdatableCellFromHelper(selectedValue, cellInfo)
                }
                setSelectedRow={this.props.setSelectedRow}
                selectedRowPosition={this.props.selectedRowPosition}
              />
            </div>
          )}
          {this.props.isOverviewTableEditing !== true && (
            <div className="container-layout-body fit" data-testid="data-grid">
              <Table
                columns={columns}
                customClass="read-only"
                data={this.props.rows}
                pages={Math.ceil(this.props?.rows?.length / pageSize)}
                sorted={this.props.sorted}
                showPagination={this.props.showPagination}
                PaginationComponent={TablePagination}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                height={height}
                minRows={0}
                onFetchData={this.onFetchData}
                onSortedChange={(newSorted, column, shiftKey) => {
                  this.onSortedChange(newSorted, column, shiftKey);
                }}
                getTdProps={this.getTdProps}
                style={{
                  maxHeight: `${this.props.height - 82}px`,
                  height: `${this.props.height - 82}px`
                }}
              />
            </div>
          )}
        </Fragment>
      );
    }
    return componentToReturn;
  }
}

export default OverviewTable;
