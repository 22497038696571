import React from 'react';
import { Spinner, Wave } from '../../components/index';
import { useOktaAuth } from '@okta/okta-react';

const WaveWrapper = props => (
  <Wave style={{ width: '100%', height: '90vh', overflow: 'hidden' }}>
    <Spinner
      title={props.title}
      ellipsesOuterThinClassName="ellipses ellipses-outer--thin ellipses-outer--thin--slow"
      ellipsesOuterThickClassName="ellipses ellipses-outer--thick ellipses-outer--thick--slow"
    />
  </Wave>
);

const SecureComponent = ({ ...props }) => {
  const { authState, oktaAuth } = useOktaAuth();

  // When the authentication is pending
  if (authState.isPending) {
    return <WaveWrapper title="Loading user information..." />;
  }

  // When the user is authenticated but the Auth is not yet in the store
  if (
    authState.isAuthenticated &&
    authState.accessToken &&
    props.authServiceStore === null
  ) {
    props.setAuthService(oktaAuth);
    return <WaveWrapper title="Setting Auth Service..." />;
  }

  // When the user is authenticated, the Auth is in the store but Admin
  // privileges have not been checked
  if (
    authState.isAuthenticated &&
    authState.accessToken &&
    props.authServiceStore &&
    props.isAdmin === null
  ) {
    props.getIsAdmin();
    return <WaveWrapper title="Checking Admin Privileges..." />;
  }

  return authState.isAuthenticated && authState.accessToken && props.children;
};

export default SecureComponent;
