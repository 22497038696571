import React from 'react';
import { MuiIconButton, RouterLink } from '../../index';
import './formToolbar.css';

const FormToolbar = props => {
  const [recordPosition, setRecordPosition] = React.useState(0);

  const CancelButton = () => (
    <MuiIconButton
      dataTestId="toolbar-cancel-button"
      keyI="IconBtnCancel"
      color="inherit"
      toolTipText="overviewForm.toolbar.cancel"
      className="toolbarButton"
      iconName="Cancel"
      onClick={() => {
        if (typeof props.onCancelClick === 'function') props.onCancelClick();
      }}
    />
  );

  const AcceptButton = () => (
    <MuiIconButton
      dataTestId="toolbar-save-button"
      keyI="IconBtnSaveRecord"
      color="inherit"
      toolTipText="overviewForm.toolbar.saveRecord"
      className="toolbarButton"
      iconName="Save"
      onClick={() => {
        props.onSaveRecordClick();
      }}
    />
  );

  const LockButton = () => (
    <MuiIconButton
      dataTestId="toolbar-lock-button"
      keyI="IconBtnTableEdit"
      color="inherit"
      toolTipText={
        props.isEditing === false
          ? 'overviewForm.toolbar.enableEditing'
          : 'overviewForm.toolbar.disableEditing'
      }
      className="toolbarButton"
      iconName={props.isEditing === true ? 'lockopen' : 'lock'}
      onClick={() => {
        props.setIsEditing(!props.isEditing);
      }}
    />
  );

  const onNavigationClick = moveTo => {
    let newPosition;
    switch (moveTo) {
      case 'first':
        if (recordPosition === 0) return;
        newPosition = 0;
        break;
      case 'previous':
        if (recordPosition === 0) return;
        newPosition = recordPosition - 1;
        break;
      case 'next':
        if (recordPosition === props.recordsCount - 1) return;
        newPosition = recordPosition + 1;
        break;
      case 'last':
        if (recordPosition === props.recordsCount - 1) return;
        newPosition = props.recordsCount - 1;
        break;
      default:
    }
    setRecordPosition(newPosition);
    props.onNavigationCallback(newPosition);
  };

  const isFormUnlocked =
    props.isEditing === true || props.isNew === true ? true : false;
  return (
    <div className="formToolBar" data-testid="toolbar-form">
      {isFormUnlocked === false && props.showNewButton === true && (
        <MuiIconButton
          keyI="IconBtnNewRecord"
          color="inherit"
          toolTipText="overviewForm.toolbar.addRecord"
          className="toolbarButton"
          iconName="AddRecord"
          onClick={() => {
            props.onNewRecordClick();
          }}
        />
      )}
      {isFormUnlocked === false && props.showDeleteButton === true && (
        <MuiIconButton
          keyI="IconBtnDeleteRecord"
          color="inherit"
          toolTipText="overviewForm.toolbar.deleteRecord"
          className="toolbarButton"
          iconName="Delete"
          dataTestId="delete-record"
          onClick={() => {
            props.onDeleteRecordClick();
          }}
        />
      )}
      {isFormUnlocked === true &&
        (props.redirectWhenAccept === true ? (
          <RouterLink href={props.redirectAcceptPath}>
            <AcceptButton />
          </RouterLink>
        ) : (
          <AcceptButton />
        ))}
      {isFormUnlocked === true &&
        (props.redirectWhenCancel === true ? (
          <RouterLink href={props.redirectCancelPath}>
            <CancelButton />
          </RouterLink>
        ) : (
          <CancelButton />
        ))}
      {isFormUnlocked === false && (
        <MuiIconButton
          keyI="iconViewTable"
          color="inherit"
          toolTipText="overviewForm.toolbar.showTable"
          className="toolbarButton"
          iconName="viewtable"
          onClick={() => {
            props.setDataViewMode('table');
          }}
        />
      )}
      {props.isNew === false && props.showLockButton === true && <LockButton />}
      {isFormUnlocked === false && props.showNavigationButtons && (
        <MuiIconButton
          keyI="IconBtnNewRecord"
          color="inherit"
          toolTipText="overviewForm.toolbar.first"
          className="toolbarButton"
          iconName="firstpage"
          onClick={() => {
            onNavigationClick('first');
          }}
        />
      )}
      {isFormUnlocked === false && props.showNavigationButtons && (
        <MuiIconButton
          keyI="IconBtnDownloadExcel"
          color="inherit"
          toolTipText="overviewForm.toolbar.previous"
          className="toolbarButton"
          iconName="chevronlefticon"
          onClick={() => {
            onNavigationClick('previous');
          }}
        />
      )}
      {isFormUnlocked === false && props.showNavigationButtons && (
        <MuiIconButton
          keyI="IconBtnFilterList"
          color="inherit"
          toolTipText="overviewForm.toolbar.next"
          className="toolbarButton"
          iconName="chevronrighticon"
          onClick={() => {
            onNavigationClick('next');
          }}
        />
      )}
      {isFormUnlocked === false && props.showNavigationButtons && (
        <MuiIconButton
          keyI="IconBtnTableEdit"
          color="inherit"
          toolTipText="overviewForm.toolbar.last"
          className="toolbarButton"
          iconName="lastpage"
          onClick={() => {
            onNavigationClick('last');
          }}
        />
      )}
    </div>
  );
};

FormToolbar.propTypes = {};

export default FormToolbar;
