import { endPoints } from '../constants/endPoints';
import { http } from '../lib/util/http';
import {
  REACT_APP_ENV,
  REACT_APP_GEM_USE_PROXY
} from '../constants/environment';
import getGemResourceName from '../lib/util/getGemResourceName';
import store from '../store/store';

export const postReplicationTarget = async replicationTarget => {
  // @todo Show a replication target name and failed status at the
  // final popup.
  const uri = `${endPoints.replicationTargets.cud}`;
  return http('post', uri, replicationTarget);
};

export const getReplicationTargetsById = async () => {
  const { tableGroupDetails, tableGroupLayout } = store.getState();
  const table = tableGroupDetails.selectedTable;
  const tableGroup = tableGroupLayout.selectedTableGroup;
  const uri = `${endPoints.replicationTargets.get}/filter?tableGroupName=${tableGroup?.name}&tableName=${table?.name}`;
  const response = await http('get', uri, {});

  return REACT_APP_ENV.toUpperCase() === 'PROD'
    ? getGemResources(response.data)
    : response.data;
};

const getGemResources = async replicationTargets =>
  Promise.all(
    replicationTargets?.map(async replicationTarget => {
      if (replicationTarget.type === 'SNOWFLAKE') {
        return { ...replicationTarget };
      }
      const gemResource = await getGemResource(replicationTarget);
      return { ...replicationTarget, ...gemResource };
    })
  );

export const getGemResource = async replicationTarget => {
  const sourceName = getGemResourceName(replicationTarget.type, 'source');
  const uriSource =
    REACT_APP_GEM_USE_PROXY === 'true'
      ? `${endPoints.gem.proxy.sources}/${sourceName}`
      : `${endPoints.gem.withoutProxy.sources}/${sourceName}`;
  let sourceResponse;
  try {
    sourceResponse = await http('get', uriSource, {});
  } catch (err) {
    sourceResponse = handleGemResponse(err);
  }

  const sinkName = getGemResourceName(replicationTarget.type, 'sink');
  const uriSink =
    REACT_APP_GEM_USE_PROXY === 'true'
      ? `${endPoints.gem.proxy.sinks}/${sinkName}`
      : `${endPoints.gem.withoutProxy.sinks}/${sinkName}`;

  let sinkResponse;
  try {
    sinkResponse = await http('get', uriSink, {});
  } catch (err) {
    sinkResponse = handleGemResponse(err);
  }

  return {
    source: sourceResponse.data,
    sink: { ...sinkResponse.data, uriSink: uriSink }
  };
};

const handleGemResponse = err => ({
  data: {
    status: {
      state: 'error',
      errors: [err]
    }
  }
});
