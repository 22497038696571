import React, { useState, useEffect } from 'react';
// router
import { withRouter } from 'react-router-dom';
import {
  Wizard,
  Timeline,
  ModalNotification,
  LoadingScreen
} from '../../components/index';

import getWizardSteps from './getWizardSteps';
const colors = { success: 'green', error: 'red', warning: 'orange' };

const TimeLineItems = ({ result }) =>
  result.map(item => (
    <Timeline.Item
      key={`antd-timeline-item-${item.parent}-${item.id}`}
      color={colors[item.type]}
    >
      {item.description}
    </Timeline.Item>
  ));

const ContentDefiner = props => {
  const [isBusy, setIsBusy] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    props.resetReplicationTargetsForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsBusy(true);
      await props.getTableGroups();
      await props.setSelectedTableGroup(props.match.params.tableGroupId);
      setIsLoaded(true);
      setIsBusy(false);
    };
    if (isLoaded === false) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If it is not loaded the the Loading screen is rendered
  if (isLoaded === false) return <LoadingScreen isLoading />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props.selectedTableGroup && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push('/tablegroup')}
      />
    );
  }

  // If the user doesn't have access to the table
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (
    props.selectedTableGroup &&
    isLoaded === true &&
    props.selectedTableGroup.hasAccess === false
  ) {
    const notification = {
      isNotification: true,
      title: 'Access Denied!',
      message: "The user doesn't have access to the specified Table Group.",
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props.history.push(`/tablegroup/${props.selectedTableGroup.name}`)
        }
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point all the data needed to render this layout is in place
  // ------------------------------------------------------------------

  const wizardSteps = getWizardSteps(props);
  const resultErrors =
    props.core.result.filter(item => {
      return item.type === 'error';
    }).length || 0;
  const resultStatus = resultErrors > 0 ? 'error' : 'success';

  return (
    <>
      <LoadingScreen isLoading={isBusy || props.isLoading} />
      <Wizard
        core={props.core}
        confirmation={props.confirmation}
        notification={props.notification}
        removeNotification={props.removeNotification}
        isError={props.isError}
        errorMessage={props.errorMessage}
        modal={props.modal}
        wizardSteps={wizardSteps}
        resultStatus={resultStatus}
        timeLineItems={<TimeLineItems result={props.core.result} />}
        status={resultStatus}
        acceptButtonTitle="New Table"
        path={`/tablegroup/${props.selectedTableGroup.name}`}
        cancelWizard={props.cancelWizard}
        resetWizard={props.resetWizard}
        wizardStepGotoIndex={props.wizardStepGotoIndex}
      />
    </>
  );
};

export default withRouter(ContentDefiner);
