import React from 'react';
import { Timeline } from 'antd';

const TimeLineItems = props => (
  <div>
    <Timeline.Item color="green">
      <p style={{ color: 'black' }}>Source configured</p>
      <p style={{ color: 'black' }}>File: {props.fileName}</p>
    </Timeline.Item>
    <Timeline.Item color="green">
      <p style={{ color: 'black' }}>Mapping Completed</p>
      <p style={{ color: 'black' }}>
        Target Table Group: {props.selectedTableGroupName}
      </p>
      <p style={{ color: 'black' }}>Target Table: {props.selectedTableName}</p>
    </Timeline.Item>
    <Timeline.Item color="green">
      <p style={{ color: 'black' }}>All Validations passed</p>
    </Timeline.Item>
    <Timeline.Item color="green">
      <p style={{ color: 'black' }}>Data Submited</p>
      <p style={{ color: 'black' }}>Records submited: {props.recordsNumber}</p>
      <p style={{ color: 'black' }}>Batches submited: {props.batchesNumber}</p>
    </Timeline.Item>
  </div>
);

export default TimeLineItems;
