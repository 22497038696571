import React from 'react';
// material-ui
import MenuIcon from '@material-ui/icons/Menu';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Save from '@material-ui/icons/Save';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirtsPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Refresh from '@material-ui/icons/Refresh';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import Edit from '@material-ui/icons/Edit';
import Cancel from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FingerPrint from '@material-ui/icons/Fingerprint';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import SettingsEthernet from '@material-ui/icons/SettingsEthernet';
import Web from '@material-ui/icons/Web';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import Today from '@material-ui/icons/Today';
// assets
import keyIcon from '../../assets/img/key16.png';
import hashtag from '../../assets/img/hashtag24.png';
import ruler from '../../assets/img/ruler24.png';
import text from '../../assets/img/text24.png';
import star from '../../assets/img/star24.png';
import privileges from '../../assets/img/users.svg';
import filterActive from '../../assets/img/filterBlack24.png';
import filterInactive from '../../assets/img/filterWhite24.png';
import EditDisabled from '../../assets/img/EditDisabled.svg';
import sink from '../../assets/img/sink.svg';
// utils
import getIsNumber from './getIsNumber';
// components
import { Icon } from '@nike/epic-react-ui';

export default function getIconByName(nameIcon, fontSize) {
  const isNumber = getIsNumber(fontSize);
  const localIconFontSize = fontSize && isNumber ? fontSize : 16;
  const materialIconFontSize = fontSize && !isNumber ? fontSize : 'small';
  switch (nameIcon.toLowerCase()) {
    case 'moreverticon':
      return (
        <MoreVertIcon
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'fingerprint':
      return (
        <FingerPrint
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'guid':
    case 'uuid':
      return (
        <SettingsEthernet
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'date':
    case 'datetime':
    case 'datetimeoffset':
      return (
        <Today
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'keyicon':
      return (
        <img
          src={keyIcon}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'privileges':
      return (
        <img
          src={privileges}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'filteractive':
      return (
        <img
          src={filterActive}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'filterinactive':
      return (
        <img
          src={filterInactive}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'star':
      return (
        <img
          src={star}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'hashtag':
    case 'integer':
    case 'single':
    case 'int16':
    case 'int32':
    case 'int64':
      return (
        <img
          src={hashtag}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'ruler':
    case 'decimal':
    case 'float':
    case 'double':
      return (
        <img
          src={ruler}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'text':
    case 'string':
      return (
        <img
          src={text}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'cancel':
      return (
        <Cancel
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'viewform':
      return (
        <Web fontSize={materialIconFontSize ? materialIconFontSize : 'small'} />
      );
    case 'viewtable':
      return (
        <ViewHeadline
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'close':
      return <Icon type="close-x" />;

    case 'preview':
      return <Icon type="preview" />;
    case 'edit':
      return (
        <Edit
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'chevronlefticon':
      return (
        <ChevronLeftIcon
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'chevronrighticon':
      return (
        <ChevronRightIcon
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'save':
      return (
        <Save
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'clouddownload':
      return (
        <CloudDownload
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'cloudupload':
      return (
        <CloudUpload
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'addrecord':
      return <Icon type="add" />;
    case 'menuicon':
      return (
        <MenuIcon
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'refresh':
      return (
        <Refresh
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'delete':
      return <Icon type="delete" />;
    case 'search':
      return <Icon type="search" />;
    case 'clear':
      return (
        <Clear
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'filterlist':
      return (
        <FilterList
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'firstpage':
      return (
        <FirtsPage
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'lastpage':
      return (
        <LastPage
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'lock':
      return (
        <Lock
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'lockopen':
      return (
        <LockOpen
          fontSize={materialIconFontSize ? materialIconFontSize : 'small'}
        />
      );
    case 'editdisabled':
      return (
        <img
          src={EditDisabled}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    case 'sink':
      return (
        <img
          src={sink}
          alt=""
          style={{
            padding: 0,
            height: `${localIconFontSize}px`
          }}
        />
      );
    default:
      return null;
  }
}
