import { TABLE_MAX_ROW_NUMBERS_TO_FETCH_SMALL } from '../../constants/defaultValues';
import { getQueryString } from '../../lib/util/getQueryString';
import { http } from '../../lib/util/http';
import mapRecordsToRows from '../../lib/util/mapRecordsToRows';

export const getRecords = async (
  rowsUrl,
  globalHeaders,
  getState,
  anchor = 0
) => {
  const { sorted: sortingColumns, filtered: filteringColumns } =
    getState().overviewTable;

  const properties = getState().tableGroupDetails.selectedTable.properties;

  const queryString = getQueryString({
    anchor,
    count: TABLE_MAX_ROW_NUMBERS_TO_FETCH_SMALL,
    sort: getSortQueryValue(sortingColumns),
    filter: getFilterQueryValue(filteringColumns, properties)
  });

  const rowsUri = `${rowsUrl}${queryString}`;
  const response = await http('get', rowsUri, null, globalHeaders);
  const records = mapRecordsToRows(response.data.records, properties);

  return {
    ...response,
    data: {
      ...response.data,
      records
    }
  };
};

const getFilterQueryValue = (filteringColumns, properties) => {
  if (filteringColumns.length === 0) {
    return '';
  }

  const filters = mapColumnsToFilters(filteringColumns);

  const getQuery = (filters, properties) => {
    return filters.reduce((acc, curr, index) => {
      // @todo UMD-2330 add handler of an empty column and value fields to the
      //  filter component
      const filteringProperty = properties.filter(
        property => curr.column === property.name
      )[0];

      const hasTextDataType = filteringProperty.datatype === 'TEXT';
      const negativeExp =
        hasTextDataType && curr.isTrue === false ? ' eq false' : '';
      const delimiter = index === filters.length - 1 ? '' : ' and ';

      return `${acc}"${curr.column}" ${curr.operator} '${curr.value}'${negativeExp}${delimiter}`;
    }, '');
  };

  return getQuery(filters, properties);
};

// @TODO UMD-2329 add this logic to a filter
//  component itself
const mapColumnsToFilters = columns => {
  return columns.length > 0
    ? columns.map(filter => {
        return {
          operator: filter.condition ? filter.condition : 'contains',
          column: filter.column,
          value: filter.value,
          isTrue: filter.isTrue !== undefined ? filter.isTrue : true
        };
      })
    : '';
};

const getSortQueryValue = sortingColumns => {
  const getQuery = columns =>
    columns.reduce((acc, curr, index) => {
      const propertyName = `${curr.id}${'  '}`;
      const order = curr.desc ? 'desc' : 'asc';
      const delimiter = index === columns.length - 1 ? '' : ',';

      return `${acc}${propertyName}${order}${delimiter}`;
    }, '');

  return getQuery(sortingColumns);
};
