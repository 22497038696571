import React from 'react';
import './privilegesOverview.css';
import { privileges } from '../../constants/privileges';
import { Table } from '../index';

const createCell = props => {
  return (
    <div className={`${props.value === true ? 'allowed' : 'prohibited'}`}></div>
  );
};

const columns = [
  {
    header: '',
    accessor: '',
    columns: [
      {
        Header: 'Rights',
        accessor: 'functionality',
        headerStyle: {
          borderRadius: '0'
        },
        className: 'cell cell-padding'
      },
      {
        Header: 'Privilege level',
        accessor: 'level',
        className: 'cell cell-padding'
      }
    ]
  },
  {
    Header: 'Roles',
    accessor: 'role',
    columns: [
      {
        Header: 'Owner',
        accessor: 'owner',
        Cell: createCell,
        className: 'cell'
      },
      {
        Header: 'Editor',
        accessor: 'editor',
        Cell: createCell,
        className: 'cell'
      },
      {
        Header: 'Viewer',
        accessor: 'viewer',
        headerStyle: {
          borderRadius: '0'
        },
        Cell: createCell,
        className: 'cell'
      }
    ]
  }
];

const PrivilegesOverview = props => {
  return (
    <Table
      className="privileges"
      columns={columns}
      data={privileges}
      showPagination={false}
      resizable={false}
      sortable={false}
      minRows={0}
    />
  );
};

export default PrivilegesOverview;
