import React from 'react';
import { FormSection, RadioGroup, SourceSelect } from '../../components';
import { loadingModeOptions } from '../../constants/defaultValues';
import sourceValidation from '../../layouts/UploadWizard/Step1_Source/sourceValidation';
import ColumnMapping, {
  validation as ColumnMappingValidation
} from '../../layouts/UploadWizard/Step3_Mapping';
import DataValidation, {
  validation as DataValidationValidation
} from '../../layouts/UploadWizard/Step4_DataValidation';
import ReviewSubmit, {
  validation as ReviewSubmitValidation
} from '../../layouts/UploadWizard/Step5_Summary';

const getList = data => {
  return data.map(item => {
    return {
      value: item.name.toLowerCase(),
      label: (
        <>
          <div
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}
          >
            {item.name}
          </div>
          <div>{item.description}</div>
        </>
      )
    };
  });
};

const getWizardSteps = props => {
  const { filetypeOptions } = props.source;

  return [
    {
      title: 'Source',
      description: 'File Upload',
      component: (
        <div data-testid="radio-group-upload-mode">
          <FormSection className="formGeneralSection" title="1. Submit Options">
            <RadioGroup
              data-testid="radio-group-upload-mode"
              required
              className="select-excelsheet"
              label="Data Loading Mode"
              value={props.dataloadingMode}
              list={getList(loadingModeOptions)}
              onChange={selected => props.setDataLoadingMode(selected)}
            />
          </FormSection>
          <SourceSelect
            tableCreationMode={props.core.tableCreationMode}
            source={props.source}
            handleChangeFile={props.sourceFileSet}
            handleChangeData={props.sourceDataSet}
            handleChangeSelectedSheet={props.sourceExcelSheetSelect}
            setIsLoading={props.setIsLoading}
            isContentDefiner={false}
            accept=".xlsx,.xls,.csv,.tsv,.txt"
            addNotification={props.addNotification}
            setCsvParserOptions={props.setCsvParserOptions}
            csvParserOptions={props.source.csvParserOptions}
          />
        </div>
      ),
      validation: () => sourceValidation(props)
    },
    {
      title: 'Mapping',
      description: 'Column Mapping',
      component: (
        <ColumnMapping
          source={props.source}
          selectedTable={props.selectedTable}
          mapping={props.mapping}
          handleChangeColumnMapping={props.mappingMapSet}
          handleChangeAllMappings={props.mappingAllSet}
        />
      ),
      validation: () => ColumnMappingValidation(props)
    },
    {
      title: 'Data Validation',
      description: 'Apply Validation Rules',
      component: (
        <DataValidation
          mapping={props.mapping}
          selectedTable={props.selectedTable}
          filetypeOptions={filetypeOptions}
          handleSetStepStatus={props.wizardStepStatusSet}
          handleChangeTransforedSetResult={props.validateTransformedResultSet}
          getGeneratedCellsCount={props.getGeneratedCellsCount}
        />
      ),
      validation: () => DataValidationValidation(props)
    },
    {
      title: 'Summary',
      description: 'Review/Submit',
      component: (
        <ReviewSubmit
          selectedTable={props.selectedTable}
          validation={props.validation}
          wizard={props.core}
          setDataLoadingMode={props.setDataLoadingMode}
        />
      ),
      validation: () => ReviewSubmitValidation(props)
    }
  ];
};

export default getWizardSteps;
