// Application base URL
export const REACT_APP_PATH = process.env.REACT_APP_PATH;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
// Root url of the backend service endpoint, for example:
// "https://api.mydomain.net/service" or "/service"
export const APP_SERVICE_PATH = process.env.REACT_APP_SERVICE_PATH;

export const APP_SERVICE_URI = `${APP_SERVICE_PATH}/${process.env.REACT_APP_SERVICE_NAME}`;

// Url of the backend metadata service
export const REACT_APP_API_METADATA_URL =
  process.env.REACT_APP_API_METADATA_URL;

// Version of a metadata api, v1
export const REACT_APP_API_METADATA_VERSION =
  process.env.REACT_APP_API_METADATA_VERSION;

export const APP_TITLE = 'application.title';

export const REACT_APP_SNOWFLAKE_DATABASE_NAME =
  process.env.REACT_APP_SNOWFLAKE_DATABASE_NAME;

export const APP_SLACK_URL =
  'https://niketechnology.slack.com/messages/CHZTSQ5JS';
export const APP_CONFLUENCE_URL =
  'https://confluence.nike.com/pages/viewpage.action?pageId=264624130';
export const APP_JIRA_URL = 'https://jira.nike.com/projects/UMD/summary';
export const APP_HELP_URL = `${REACT_APP_PATH}/docs/index.html`;
export const APP_SUPPORT_URL = `${REACT_APP_PATH}/docs/docs/prod-support.html`;

// GEM
export const REACT_APP_GEM_API_URL = process.env.REACT_APP_GEM_API_URL;
export const REACT_APP_GEM_API_VERSION = process.env.REACT_APP_GEM_API_VERSION;
export const REACT_APP_GEM_USE_PROXY =
  process.env.REACT_APP_GEM_USE_PROXY || 'false';

// Debug mode flag
export const REACT_APP_DEBUG_MODE =
  process.env.REACT_APP_DEBUG_MODE &&
  process.env.REACT_APP_DEBUG_MODE.toUpperCase() === 'TRUE';

export const REACT_APP_NSP_API_URL = process.env.REACT_APP_NSP_API_URL;
