import React from 'react';
import { FormSection, Button, Table } from '../../components';
import { Select } from 'antd';
const { Option } = Select;

const TableFilter = props => {
  const [filters, setFilters] = React.useState(props.initialFilter || []);
  const [properties] = React.useState(props.properties);

  const getColumnSelectionOptions = () => {
    return properties.map(property => {
      return (
        <Option key={property.name} value={property.name}>
          {property.name}
        </Option>
      );
    });
  };

  const getConditionSelectionOptions = conditions => {
    if (!conditions || conditions.length === 0) return;
    return conditions.map((condition, index) => {
      return (
        <Option key={condition} value={condition}>
          {condition}
        </Option>
      );
    });
  };

  const onSelectedColumnChange = (cellInfo, val) => {
    const filterProperty = properties.filter(
      property => property.name.toUpperCase() === val.toUpperCase()
    )[0];
    const conditionsProperty = getConditionOptions(filterProperty);

    const newFilters = filters.map(filter =>
      filter.id === cellInfo.original.id
        ? {
            ...filter,
            column: val,
            properties: filterProperty,
            conditionOptions: conditionsProperty
          }
        : filter
    );

    setFilters(newFilters);
  };

  const onSelectedConditionChange = (cellInfo, val) => {
    const newFilters = filters.map(filter =>
      filter.id === cellInfo.original.id
        ? { ...filter, condition: val }
        : filter
    );
    setFilters(newFilters);
  };

  const onValueChange = (cellInfo, event) => {
    const newFilters = filters.map(filter =>
      filter.id === cellInfo.original.id
        ? { ...filter, value: event.target.value }
        : filter
    );
    setFilters(newFilters);
  };

  const onRemoveFilterItem = id => {
    const newFilters = filters.filter(filter => {
      return filter.id !== id;
    });
    setFilters(newFilters);
  };

  const getConditionOptions = property => {
    switch (property.datatype.toUpperCase()) {
      case 'DATE':
      case 'DATETIME':
        return ['='];
      case 'INTEGER':
      case 'DECIMAL':
        return ['=', '>', '>=', '<', '<='];
      case 'TEXT':
      case 'UUID':
      default:
        return ['contains', '=', 'startswith'];
    }
  };

  const onAcceptFilters = () => {
    props.handleAccept(filters);
    props.handleCancel();
  };

  const getColumns = () => [
    {
      id: 'id',
      Header: '',
      accessor: 'id',
      Cell: function createCell(cellInfo) {
        return <span className="filter-setting-hidden">{cellInfo.value}</span>;
      },
      width: 12
    },
    {
      id: 'column',
      Header: 'Column',
      accessor: 'column',
      Cell: function createCell(cellInfo) {
        return (
          <Select
            key={'select-column'}
            style={{ width: '100%' }}
            showSearch={true}
            value={cellInfo.value}
            onChange={val => onSelectedColumnChange(cellInfo, val)}
          >
            {getColumnSelectionOptions()}
          </Select>
        );
      },
      width: 280 || undefined
    },
    {
      id: 'condition',
      Header: 'Condition',
      accessor: 'condition',
      Cell: function createCell(cellInfo) {
        return (
          <Select
            key={'selec-condition'}
            style={{ width: '100%' }}
            showSearch={true}
            value={cellInfo.value}
            onChange={val => onSelectedConditionChange(cellInfo, val)}
          >
            {getConditionSelectionOptions(cellInfo.original.conditionOptions)}
          </Select>
        );
      },
      width: 140 || undefined
    },
    {
      id: 'value',
      Header: 'value',
      accessor: 'value',
      Cell: function createCell(cellInfo) {
        return (
          <input
            className="filter-setting-input"
            value={cellInfo.value}
            type={
              cellInfo.original.properties.datatype
                ? cellInfo.original.properties.datatype.toUpperCase() ===
                    'INTEGER' ||
                  cellInfo.original.properties.datatype.toUpperCase() ===
                    'DECIMAL'
                  ? 'number'
                  : 'text'
                : 'text'
            }
            onChange={event => onValueChange(cellInfo, event)}
            maxLength={
              cellInfo.original.properties.length
                ? cellInfo.original.properties.length
                : undefined
            }
            disabled={cellInfo.original.column !== '' ? false : true}
          />
        );
      },
      width: 200 || undefined
    },
    {
      id: 'del',
      Header: '',
      accessor: '',
      width: 32,
      Cell: function createCell(cellInfo) {
        return (
          <button
            className="filter-setting-del-item"
            onClick={() => onRemoveFilterItem(cellInfo.original.id)}
          >
            <i className="epic-icon epic-icon-delete" />
          </button>
        );
      }
    }
  ];

  return (
    <div>
      <FormSection className="filter-setting-section-wrapper">
        <div className="filter-setting-actions-container">
          <Button
            small
            onClick={() => {
              const key = Math.random().toString(36).slice(-10);
              setFilters(
                filters.concat([
                  {
                    id: `${key}-${filters.length + 1}`,
                    column: '',
                    value: '',
                    condition: '=',
                    properties: [],
                    conditionOptions: []
                  }
                ])
              );
            }}
          >
            Add
          </Button>
          <Button small onClick={() => setFilters([])}>
            Reset
          </Button>
        </div>
        <Table
          data={filters}
          columns={getColumns()}
          showPagination={false}
          sortable={false}
          pageSize={filters.length}
        />
      </FormSection>
      <div className="modal-button-wrapper">
        <Button onClick={onAcceptFilters}>Accept</Button>
        <Button onClick={props.handleCancel}>Cancel</Button>
      </div>
    </div>
  );
};

export default TableFilter;
