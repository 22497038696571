// react
import React from 'react';
// components
import { RouterLink } from '../../../components/index';
// assets
import tableLogo from '../../../assets/img/database_1.svg';
// css
import './tableGroupAsideSection.css';

const TableGroupAsideSection = props => {
  const { selectedTableGroup, isNavigable = false } = props;

  const getElement = () => {
    if (isNavigable === true) {
      return (
        <RouterLink
          key={`nav-item-${selectedTableGroup.name}`}
          href={`/tablegroup/${selectedTableGroup.name}`}
        >
          <div
            className="epic-font-base-md aside-left-toolbar-title-link"
            style={{ fontSize: '18px' }}
          >
            {selectedTableGroup.name}
          </div>
        </RouterLink>
      );
    } else {
      return (
        <div
          className="epic-font-base-md aside-left-toolbar-title"
          style={{ fontSize: '18px' }}
        >
          {selectedTableGroup.name}
        </div>
      );
    }
  };

  return (
    <div className="aside-left-toolbar-wrapper">
      {getElement()}
      <div className={'bootstrap-col-md content-section-col-img small'}>
        <img
          src={tableLogo}
          alt={'title'}
          className={'content-section-title-img small'}
        />
      </div>
    </div>
  );
};

export default TableGroupAsideSection;
