import { SET_SELECTED_TABLE, GET_TABLES_SUCCESS } from './actionTypes';
import * as tableService from '../services/tableService';
import { resetRows, setOverviewTableSorted } from './overviewTable';
import {
  addNotification,
  removeNotification,
  addConfirmation,
  removeConfirmation,
  setIsLoading
} from './core';
import { getPrivileges } from './privileges';
import { I18n } from 'react-redux-i18n';
import sendAnalyticsEvent from '../lib/util/sendAnalyticsEvent';

export const getTables = tableGroup => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    const { error, tables } = await tableService.fetchTables(tableGroup);
    if (error) {
      dispatch(getTablesSuccess([]));
      dispatch(
        addNotification({
          isModal: true,
          title: `Error fetching the tables: ${error}`,
          message: error.message
        })
      );
    } else {
      dispatch(getTablesSuccess(tables));
    }
  } catch (err) {
    dispatch(resetTableLayout());
    dispatch(
      addNotification({
        isModal: true,
        title: 'Error fetching the tables',
        message: err
      })
    );
  }

  dispatch(setIsLoading(false));
};

export const setSelectedTable =
  (newSelectedTableGroupName, newSelectedTableName) =>
  async (dispatch, getState) => {
    const selectedTable = getState().tableGroupDetails.selectedTable;
    const newSelectedTable = getState().tableGroupDetails.tables.filter(
      ele =>
        ele.name?.toUpperCase() === newSelectedTableName?.toUpperCase() &&
        ele.tableGroupName?.toUpperCase() ===
          newSelectedTableGroupName?.toUpperCase()
    )[0];

    if (
      selectedTable?.name?.toUpperCase() ===
        newSelectedTableName?.toUpperCase() &&
      selectedTable?.tableGroupName?.toUpperCase() ===
        newSelectedTableGroupName?.toUpperCase()
    )
      return;
    dispatch(resetRows());
    dispatch(removeNotification());

    const keys = newSelectedTable
      ? Object.assign(
          [],
          [
            { id: 'OPERATION_TIMESTAMP', desc: true },
            ...newSelectedTable.properties
              .filter(property => {
                return property.isKeyProperty === 'Y';
              })
              .map(key => {
                return { id: key.name, desc: false };
              })
          ]
        )
      : [];

    dispatch(setOverviewTableSorted(keys));
    const action = { type: SET_SELECTED_TABLE, newSelectedTable };
    await dispatch(action);
    if (newSelectedTable) {
      await dispatch(getPrivileges('TABLE'));
    }
  };

export const resetTableLayout = () => dispatch => {
  const newSelectedTable = null;
  const action = { type: SET_SELECTED_TABLE, newSelectedTable };
  dispatch(action);
  dispatch(getTablesSuccess([]));
  dispatch(resetRows());
};

export const resetSelectedTable = () => dispatch => {
  const newSelectedTable = null;
  const action = { type: SET_SELECTED_TABLE, newSelectedTable };
  dispatch(action);
  dispatch(resetRows());
};

// Set the Confirmation for the Delete
export const setOnDeleteRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'form-delete',
      question: I18n.t('tableLayout.confirmation.deleteRecordQuestion'),
      children: confirmation.children,
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};

// Accept confirmation when deleting a record
export const deleteTable = (table, tableGroup) => async dispatch => {
  dispatch(removeConfirmation());
  const payload = {
    tableGroupName: tableGroup.name,
    name: table.name
  };

  const analyticsEventBody = {
    module_id: 'data_manager',
    screen_id: 'data_manager',
    table: table.name,
    table_group_name: tableGroup.name
  };

  sendAnalyticsEvent('ui_delete_table', analyticsEventBody);

  try {
    const response = await tableService.deleteTable(payload);
    if (response.status === 204) {
      await dispatch(getTables(tableGroup));
    }
  } catch (err) {
    dispatch(
      addNotification({
        isModal: true,
        title: 'Error Deleting Table',
        message: err
      })
    );
  }
};

export const getTablesSuccess = tables => ({
  type: GET_TABLES_SUCCESS,
  tables
});
