import {
  createTable as createTableNewApi,
  updateTable as updateTableApi
} from '../../services/tableService';

import sanitizeNamePart from './sanitizeNamePart';
import sendAnalyticsEvent from './sendAnalyticsEvent';

import {
  serializeColumns,
  serializeGeneratedColumns
} from '../serialization/serialize';
import { TABLE_CREATION_MODE_ANALYTIC_EVENT_NAMES } from '../../constants/tableCreation';

let result = [];

export const createTarget = async props => {
  result = [];
  result.push({
    parent: 0,
    id: 1,
    type: 'success',
    description: 'Table Group Verified'
  });
  const { core, selectedTableGroup } = props;
  const { newTableName, tableCreationMode } = core;
  const sanitizedServiceName = sanitizeNamePart(selectedTableGroup.name);

  const eventName =
    TABLE_CREATION_MODE_ANALYTIC_EVENT_NAMES[tableCreationMode.toUpperCase()];

  const analyticsEventBody = {
    module_id: 'content_definer',
    screen_id: 'content_definer',
    table: newTableName,
    table_group_name: sanitizedServiceName
  };

  sendAnalyticsEvent(eventName, analyticsEventBody);

  result.push({
    parent: 1,
    id: 1,
    type: 'warning',
    description: `Table Group "${sanitizedServiceName}" found, Used existing one.`
  });
  await createTable(props);

  return {
    result,
    tableGroupName: sanitizedServiceName,
    createdTableName: newTableName
  };
};

export const updateTable = async (props, refresh = false) => {
  result = [];
  const { table } = props;
  const sanitizedTableName = sanitizeNamePart(props.table?.name);
  const sanitizedTableGroupName = sanitizeNamePart(
    props.selectedTableGroup?.name
  );

  const properties = createProperties(props.source.data.meta);
  const newProperties = properties.filter(
    x => x.valueGeneratorMoment === undefined
  );

  const eventName = !refresh
    ? 'ui_edit_table_structure'
    : 'ui_edit_empty_table_structure';

  const analyticsEventBody = {
    module_id: 'content_definer',
    screen_id: 'content_definer',
    table: sanitizedTableName,
    table_group_name: sanitizedTableGroupName
  };

  sendAnalyticsEvent(eventName, analyticsEventBody);

  if (!refresh && newProperties.length === 0) {
    return {
      result: [
        {
          parent: 0,
          id: 1,
          type: 'error',
          description: `No new columns to update`
        }
      ]
    };
  }

  const payload = {
    refreshProperties: refresh,
    tableGroupName: sanitizedTableGroupName,
    name: sanitizedTableName,
    description: table?.description,
    usersCount: table?.usersCount,
    version: table?.version ? table?.version + 1 : 0,
    properties: refresh ? properties : newProperties
  };

  try {
    const apiResult = await updateTableApi(payload);
    if (apiResult.status === 204) {
      result.push({
        parent: 0,
        id: 1,
        type: 'success',
        description: `Table "${sanitizedTableName}" updated successfully.`
      });
    }
  } catch (error) {
    result.push({
      parent: 0,
      id: 1,
      type: 'error',
      description: `Table "${sanitizedTableName}" updated failed.`
    });
  }

  return {
    result,
    tableGroupName: sanitizedTableGroupName,
    createdTableName: sanitizedTableName
  };
};

const TABLE_DESCRIPTION = 'Table created through the Nike UMD Content Definer';

export const createTable = async props => {
  const { core, selectedTableGroup } = props;
  const { tableDescription = TABLE_DESCRIPTION, newTableName } = core;

  const sanitizedTableName = sanitizeNamePart(newTableName);
  const properties = createProperties(props.source.data.meta);

  const payload = {
    tableGroupName: selectedTableGroup.name,
    name: sanitizedTableName,
    description: tableDescription || TABLE_DESCRIPTION,
    properties
  };

  try {
    const response = await createTableNewApi(payload);

    if (response.status === 201) {
      result.push({
        parent: 1,
        id: 2,
        type: 'success',
        description: `Table "${sanitizedTableName}" successfully created.`
      });
    }
    props.saveReplicationTargets(sanitizedTableName);
    addReplicationTargetsNotification(props.replicationTargetsErrors);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    result.push({
      parent: 1,
      id: 2,
      type: 'error',
      description: `Table "${sanitizedTableName}" is not created. ${errorMessage}`
    });
    result.push({
      parent: 1,
      id: 3,
      type: 'error',
      description: 'Columns are not created.'
    });
    result.push({
      parent: 1,
      id: 4,
      type: 'error',
      description: 'Replication targets are not created.'
    });
  }
};

const addReplicationTargetsNotification = errors => {
  if (errors.length === 0) {
    result.push({
      parent: 1,
      id: 4,
      type: 'success',
      description: `Replication Targets successfully created.`
    });
  } else {
    const message = errors.join('\t');

    result.push({
      parent: 1,
      id: 5,
      type: 'error',
      description: `Replication Target is not created: ${message}`
    });
  }
};

const createProperties = metadata => {
  const { columnsDefinition, generatedColumns, addGeneratedKeyColumn } =
    metadata;

  const excelColumns = serializeColumns(
    columnsDefinition,
    addGeneratedKeyColumn
  );

  const serializedGeneratedColumns = serializeGeneratedColumns(
    generatedColumns,
    excelColumns.length
  );

  return [...excelColumns, ...serializedGeneratedColumns];
};

const getErrorMessage = error => {
  let errorMessage = '';
  if (error.message && error?.message?.length > 0) {
    errorMessage = `The error description is: ${error.message}`;
  } else {
    if (error?.response?.data)
      errorMessage = `The error description is: ${error.response.data}`;
  }
  return errorMessage;
};
