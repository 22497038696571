import React from 'react';
import ColumnDefinition from './ColumnDefinition';

const EditNotEmptyTable = props => (
  <ColumnDefinition
    isEmpty={props.isEmpty}
    source={props.source}
    core={props.core}
    modal={props.modal}
    updateColumSpec={props.updateColumSpec}
    setDatatypeColumSpec={props.setDatatypeColumSpec}
    setNextColumnSpec={props.setNextColumnSpec}
    setPreviousColumnSpec={props.setPreviousColumnSpec}
    addColumnSpec={props.addColumnSpec}
    deleteColumnSpec={props.deleteColumnSpec}
    setColumSpec={props.setColumSpec}
    setAddGeneratedKeyColumn={props.setAddGeneratedKeyColumn}
    addModal={props.addModal}
    removeModal={props.removeModal}
    setIsLoading={props.setIsLoading}
    addNotification={props.addNotification}
    isTableEdit={props.isTableEdit}
    selectedTable={props.table}
    selectedTableGroup={props.selectedTableGroup}
  />
);

export default EditNotEmptyTable;
