import React from 'react';
import { Popover, Avatar } from 'antd';
import { REACT_APP_PATH } from '../../constants/defaultValues.js';

const message = (
  <>
    <p>
      Do you want to create a copy of the table from another environment? You
      can stil do so manually, it only takes a minute.
    </p>
    <p>
      You always can{' '}
      <a
        href="https://nike.box.com/s/27rltrxfk37x7jqjsm9xxbmnxq700cd2"
        target="_blank"
        rel="noopener noreferrer"
      >
        watch the video here,
      </a>{' '}
      or go to the{' '}
      <a
        href={`${REACT_APP_PATH}/docs/docs/createtablegroup.html`}
        target="_blank"
        rel="noopener noreferrer"
      >
        documentation.
      </a>
    </p>
  </>
);

const avatarStyles = {
  backgroundColor: 'var(--er-blue)',
  verticalAlign: 'middle',
  marginLeft: 14
};

const notification = (
  <>
    <b>
      Please note that copying tables from one environment to another is
      disabled.
    </b>
    <Popover content={message}>
      <Avatar style={avatarStyles} size="small">
        ?
      </Avatar>
    </Popover>
  </>
);

export default notification;
