import { I18n } from 'react-redux-i18n';
import { addNotification, setIsLoading, addConfirmation } from './core';

import {
  getReplicationTargetsSuccess,
  getReplicationTargetsFailure,
  getReplicationTargetsStart,
  sendReplicationTargetsSuccess,
  sendReplicationTargetsFailure,
  sendReplicationTargetsStart
} from '../reducers/replicationTargets.js';

import {
  getReplicationTargetsById,
  postReplicationTarget
} from '../services/replicationTargetService';
import { configureReplicationTargets } from '../lib/util/configureReplicationTargets';

export const saveReplicationTargets =
  createdTableName => async (dispatch, getState) => {
    const formValues = getState().replicationTargets.formValues;
    const selectedTableGroupName =
      getState().tableGroupLayout.selectedTableGroup.name;
    const tableName =
      createdTableName || getState().tableGroupDetails.selectedTable.name;

    const configuredTargets = configureReplicationTargets(
      tableName,
      selectedTableGroupName,
      formValues
    );
    if (configuredTargets.length > 0) {
      try {
        sendReplicationTargetsStart();
        const promisesResult = await sendReplicationTargets(configuredTargets);
        promisesResult.forEach(result => {
          if (result.status === 201) {
            dispatch(sendReplicationTargetsSuccess(result.data.type));
          } else {
            dispatch(
              sendReplicationTargetsFailure(
                `Failed to save the replication targets. ${
                  result.status || result
                }`
              )
            );
          }
        });
      } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(sendReplicationTargetsFailure(error.message));
        dispatch(
          addNotification({
            isModal: true,
            title: 'Error sending the Replication Targets',
            message: error.message
          })
        );
      }
    }
  };

export const sendReplicationTargets = replicationTargets => {
  const promises = replicationTargets.map(async target => {
    return postReplicationTarget(target);
  });

  return Promise.all(promises);
};

export const loadReplicationTargets = () => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    dispatch(getReplicationTargetsStart());
    const result = await getReplicationTargetsById();
    dispatch(getReplicationTargetsSuccess(result));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(getReplicationTargetsFailure(error.message || error));
    dispatch(
      addNotification({
        isModal: true,
        title: 'Error fetching the Replication Targets',
        message: error.message || error
      })
    );
  }
};

// Set the confirmation after selecting an replication target
export const setOnSelectReplicationTarget = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      ...confirmation,
      id: 'select-replication-target',
      question: I18n.t(
        'replicationTargetLayout.confirmation.selectReplicationTarget'
      ),
      acceptButtonLabel: 'Create the table',
      cancelButtonLabel: 'Change replication targets'
    })
  );
};

// Set the Confirmation for the Save
export const setOnSaveRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'form-save',
      question: I18n.t(
        'replicationTargetLayout.confirmation.saveNewRecordQuestion'
      ),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};

// Set the Confirmation for the Delete
export const setOnDeleteRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'form-delete',
      question: I18n.t(
        'replicationTargetLayout.confirmation.deleteRecordQuestion'
      ),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};
