import React from 'react';
import Papa from 'papaparse';
import { FileInput, FormSection, Select } from '../../../components/index';

const delimiterOptions = [
  { value: '\t', label: 'Tab' },
  { value: ',', label: 'Comma (,)' },
  { value: ';', label: 'Semi-colon (;)' },
  { value: '|', label: 'Pipe (|)' }
];

const InputBulkList = props => {
  const { processBulkUpload, setIsLoading } = props;
  const [fileName, setFileName] = React.useState();
  const [fileResult, setFileResult] = React.useState();
  const [confParser, setConfParser] = React.useState({
    header: true,
    delimiter: ';',
    dynamicTyping: false,
    skipEmptyLines: true
  });

  const getSelectedDelimiterItem = () =>
    delimiterOptions.filter(delim => delim.value === confParser.delimiter);

  const [selectedDelimiterOption, setSelectedDelimiterOption] = React.useState(
    getSelectedDelimiterItem
  );

  const onFileInputChange = async e => {
    const selectedFile = e.target.files[0];
    setIsLoading(true);

    if (!selectedFile) {
      setFileName('');
      setFileResult(undefined);
      setIsLoading(false);
      return;
    }

    const reader = new FileReader();
    reader.onload = evt => {
      setFileName(selectedFile.name);
      setFileResult(evt.target.result);
      processCsvFile(evt.target.result);
    };
    reader.readAsBinaryString(selectedFile);
  };

  const processCsvFile = bstr => {
    const autoParse = Papa.parse(bstr, confParser);
    processBulkUpload(autoParse);
    setIsLoading(false);
  };

  const handleSelectDelimiterChange = selection => {
    const newConfParser = Object.assign(confParser, {
      delimiter: selection.value
    });
    setConfParser(newConfParser);
    setSelectedDelimiterOption(getSelectedDelimiterItem());
    if (fileResult) processCsvFile(fileResult);
  };

  return (
    <FormSection className="formGeneralSection" title="Source">
      <FileInput
        id="file-input"
        label="File"
        accept=".csv,.tsv,.txt"
        imgUrl={fileName}
        onChange={onFileInputChange}
        onRemove={() => {}}
      />
      <Select
        label="Delimiter"
        className="select-excelsheet"
        options={delimiterOptions}
        value={selectedDelimiterOption}
        onChange={handleSelectDelimiterChange}
        placeholder="Select a Delimiter"
      />
    </FormSection>
  );
};

export default InputBulkList;
