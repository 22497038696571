import { Avatar, Popover } from 'antd';
import React from 'react';

const popoverContent = (
  <div>
    <div>
      <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>
        URI: The following are the rules for naming S3 buckets in all AWS
        Regions:
      </p>
      <ul>
        <li>
          Bucket names must be unique across all existing bucket names in Amazon
          S3.
        </li>
        <li>Bucket names must comply with DNS naming conventions.</li>
        <li>
          Bucket names must be at least 3 and no more than 63 characters long.
        </li>
        <li>
          Bucket names must not contain uppercase characters or underscores.
        </li>
        <li>Bucket names must start with a lowercase letter or number.</li>
        <li>Bucket names must be a series of one or more labels.</li>
        <li>Adjacent labels are separated by a single period (.).</li>
        <li>
          Bucket names can contain lowercase letters, numbers, and hyphens.
        </li>
        <li>
          Each label must start and end with a lowercase letter or a number.
        </li>
        <li>
          Bucket names must not be formatted as an IP address (for example,
          192.168.5.4).
        </li>
      </ul>
      <p>
        For more information please go to{' '}
        <a
          href={
            'https://docs.aws.amazon.com/AmazonS3/latest/dev/BucketRestrictions.html'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Bucket Restrictions and Limitations
        </a>
      </p>
    </div>
    <div>
      {' '}
      <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>Region:</p>
      <p>
        Amazon cloud computing resources are hosted in multiple locations
        world-wide. These locations are composed of AWS Regions and Availability
        Zones
      </p>
      <p>
        For more information please go to{' '}
        <a
          href={
            'https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Concepts.RegionsAndAvailabilityZones.html'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Regions and Availability Zones
        </a>
      </p>
    </div>
  </div>
);

const AmazonTargetHelp = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center'
    }}
  >
    <h1>Custom Amazon S3 Bucket</h1>
    <Popover content={popoverContent} title="Custom Amazon S3 Bucket">
      <Avatar className="target-avatar" size="small">
        ?
      </Avatar>
    </Popover>
  </div>
);

export default AmazonTargetHelp;
