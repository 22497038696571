// react
import React from 'react';
// layouts
import GlobalError from '../../../layouts/GlobalError/GlobalError';
// components
import { ModalNotification, ModalConfirmation, ModalSimple } from '../../';

const NotificationWrapper = props => {
  return (
    <div className={props.className}>
      <GlobalError {...props} />
      <ModalSimple {...props} />
      <ModalNotification {...props} />
      <ModalConfirmation {...props} />
      {props.children}
    </div>
  );
};

export default NotificationWrapper;
