import React from 'react';
import {
  SourceSelect,
  RadioGroup,
  FormSection,
  TemplateSelector
} from '../../../components/index';

import {
  TABLE_CREATION_MODES,
  tableCreationOptions
} from '../../../constants/defaultValues';

const Source = ({
  core,
  source,
  template,
  sourceFileSet,
  sourceDataSet,
  setSourceMetaData,
  setCreationMode,
  sourceExcelSheetSelect,
  setIsLoading,
  addNotification,
  getTemplateTableGroups,
  getTemplateTables,
  isLoading,
  tableGroups
}) => {
  const handleUploadOptionChange = value => {
    sourceFileSet({});
    sourceDataSet({});
    setSourceMetaData({});
    setCreationMode(value);
  };

  return (
    <>
      <FormSection
        title="1. Create table structure"
        subtitle={
          'At this step, you can define the columns of the table: specify the titles of the headers, the length of the fields, define a regular expression and other parameters. To place the data in a table, you need to use the "explore data" button on the table view page.'
        }
        className="formGeneralSection"
      >
        <RadioGroup
          data-testid="table-creation-mode"
          value={core.tableCreationMode}
          list={tableCreationOptions}
          onChange={value => handleUploadOptionChange(value)}
        />
      </FormSection>
      {core.tableCreationMode === TABLE_CREATION_MODES.UPLOAD && (
        <SourceSelect
          tableCreationMode={core.tableCreationMode}
          source={source}
          handleChangeFile={sourceFileSet}
          handleChangeData={sourceDataSet}
          handleChangeSelectedSheet={sourceExcelSheetSelect}
          setIsLoading={setIsLoading}
          isContentDefiner
          addNotification={addNotification}
          accept=".xlsx,.xls"
        />
      )}
      {core.tableCreationMode === TABLE_CREATION_MODES.TEMPLATE && (
        <TemplateSelector
          tableGroups={tableGroups}
          template={template}
          getTemplateTableGroups={getTemplateTableGroups}
          onTableGroupChange={getTemplateTables}
          isLoading={isLoading}
          onTableChange={setSourceMetaData}
        />
      )}
    </>
  );
};

export { Source };
