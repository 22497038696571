// react
import React from 'react';
// router
import { Switch, Route } from 'react-router-dom';
// components
import {
  DataLayoutContainer,
  TableGroupLayoutContainer,
  TableGroupDetailsContainer,
  TableGroupPrivLayoutContainer,
  TableDetailsContainer,
  TablePrivLayoutContainer,
  TableRepTargetsContainer
} from '../../layouts/index';

const routes = props => (
  <Switch>
    <Route
      exact
      path={'/tablegroup'}
      render={() => <TableGroupLayoutContainer {...props} />}
    />
    <Route
      exact
      path={'/tablegroup/:tableGroupId'}
      render={() => <TableGroupDetailsContainer {...props} />}
    />
    <Route
      exact
      path={'/tablegroup/:tableGroupId/table/:tableId'}
      render={() => <TableDetailsContainer {...props} />}
    />
    <Route
      exact
      path={'/tablegroup/:tableGroupId/table/:tableId/data'}
      render={() => <DataLayoutContainer {...props} />}
    />
    <Route
      exact
      path={'/tablegroup/:tableGroupId/table/:tableId/privileges'}
      render={() => <TablePrivLayoutContainer {...props} />}
    />
    <Route
      exact
      path={'/tablegroup/:tableGroupId/table/:tableId/replicationtargets'}
      render={() => <TableRepTargetsContainer {...props} />}
    />
    <Route
      exact
      path={'/tablegroup/:tableGroupId/privileges'}
      render={() => <TableGroupPrivLayoutContainer {...props} />}
    />
  </Switch>
);

export default routes;
