import React from 'react';
import { Source } from '../../layouts/ContentDefiner/Step1_Source/Source';
import sourceValidation from '../../layouts/ContentDefiner/Step1_Source/sourceValidation';
import ColumnDefinition, {
  validation as ColumnDefinitionValidation
} from '../../layouts/ContentDefiner/Step2_Definition';
import Target, {
  validation as TargetValidation
} from '../../layouts/ContentDefiner/Step3_Target';

const getWizardSteps = props => {
  return [
    {
      title: 'Source',
      description: 'File Upload',
      component: (
        <Source
          core={props.core}
          source={props.source}
          template={props.template}
          tableGroups={props.tableGroups}
          sourceFileSet={props.sourceFileSet}
          sourceDataSet={props.sourceDataSet}
          setSourceMetaData={props.setSourceMetaData}
          setCreationMode={props.setCreationMode}
          sourceExcelSheetSelect={props.sourceExcelSheetSelect}
          setIsLoading={props.setIsLoading}
          addNotification={props.addNotification}
          getTemplateTableGroups={props.getTemplateTableGroups}
          getTemplateTables={props.getTemplateTables}
          isLoading={props.isLoading}
        />
      ),
      validation: () => sourceValidation(props)
    },
    {
      title: 'Definition',
      description: 'Table Definition',
      component: (
        <ColumnDefinition
          source={props.source}
          core={props.core}
          modal={props.modal}
          updateColumSpec={props.updateColumSpec}
          setDatatypeColumSpec={props.setDatatypeColumSpec}
          setNextColumnSpec={props.setNextColumnSpec}
          setPreviousColumnSpec={props.setPreviousColumnSpec}
          addColumnSpec={props.addColumnSpec}
          deleteColumnSpec={props.deleteColumnSpec}
          setColumSpec={props.setColumSpec}
          setAddGeneratedKeyColumn={props.setAddGeneratedKeyColumn}
          addModal={props.addModal}
          removeModal={props.removeModal}
          setIsLoading={props.setIsLoading}
          addNotification={props.addNotification}
        />
      ),
      validation: () => ColumnDefinitionValidation(props)
    },
    {
      title: 'Target',
      description: 'Final Target',
      component: (
        <Target
          core={props.core}
          tableGroups={props.tableGroups}
          selectedTableGroup={props.selectedTableGroup}
          addNotification={props.addNotification}
          setSelectedTableGroup={props.setSelectedTableGroup}
          setNewTableName={props.setNewTableName}
          updateReplicationTarget={props.updateReplicationTarget}
          setTableDescription={props.setTableDescription}
          toggleFormCheckboxes={props.toggleFormCheckboxes}
          setS3BucketField={props.setS3BucketField}
          formValues={props.formValues}
        />
      ),
      validation: () => TargetValidation(props),
      beforeNextCallback: successCb => {
        const confirmation = {
          closeButton: true,
          acceptCallback: () => {
            props.removeConfirmation();
            successCb();
          },
          cancelCallback: () => {
            props.removeConfirmation();
          }
        };
        props.setOnSelectReplicationTarget(confirmation);
      }
    }
  ];
};

export default getWizardSteps;
