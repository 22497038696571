import { addConfirmation } from './core';
import { I18n } from 'react-redux-i18n';

export const setChangesLostConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'changes-lost',
      question: I18n.t('tableLayout.confirmation.changesWillBeLostQuestion'),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};
