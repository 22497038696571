import sanitizeNamePart, { sanitize } from './sanitizeNamePart';
import { defaultProperties } from '../../constants/defaultValues.js';

const getFieldDefinition = analysis => {
  return function (column, index) {
    const columnName = column.value;
    const colInfo = analysis[columnName];
    let type = '';
    let length = defaultProperties.LENGTH;
    const types = Object.keys(colInfo.types).filter(function (colType) {
      return colType !== 'empty';
    });

    types.forEach(typ => {
      switch (typ) {
        case 'number':
          if (
            type.toUpperCase() !== 'DECIMAL' &&
            type.toUpperCase() !== 'BIGINTEGER'
          ) {
            if (colInfo.count === colInfo.countInt) {
              type = 'INTEGER';
            } else {
              type = 'DECIMAL';
            }
          }
          break;
        case 'bignumber':
          if (type.toUpperCase() !== 'DECIMAL') {
            if (colInfo.count === colInfo.countInt) {
              type = 'BIGINTEGER';
            } else {
              type = 'DECIMAL';
            }
          }
          break;
        case 'date':
          if (colInfo.countTimePart === 0) {
            type = 'DATE';
          } else {
            type = 'DATETIME';
          }
          break;
        default:
          break;
      }
    });

    if (!type) {
      if (colInfo.countInt === colInfo.count) {
        type = 'INTEGER';
      } else if (colInfo.countNumeric === colInfo.count) {
        type = 'DECIMAL';
      } else {
        type = 'TEXT';
      }
    }

    let trim = defaultProperties.TRIM,
      letterCase = defaultProperties.LETTER_CASE,
      scale = defaultProperties.SCALE,
      // Decimals equal length property
      decimals = defaultProperties.LENGTH;
    switch (type.toUpperCase()) {
      case 'TEXT':
        if (colInfo.countUUID === colInfo.count) {
          type = 'UUID';
          break;
        }
        length = colInfo.maxStringLength;
        if (colInfo.countSpaceLeft === 0 && colInfo.countSpaceRight === 0) {
          trim = 'BOTH';
        } else if (colInfo.countSpaceLeft > 0 && colInfo.countSpaceRight > 0) {
          trim = 'NONE';
        } else if (colInfo.countSpaceLeft > 0) {
          trim = 'RIGHT';
        } else if (colInfo.countSpaceRight > 0) {
          trim = 'LEFT';
        }

        if (colInfo.countLower === colInfo.count) {
          letterCase = 'LOWERCASE';
        } else if (colInfo.countUpper === colInfo.count) {
          letterCase = 'UPPERCASE';
        } else {
          letterCase = 'NONE';
        }
        break;
      case 'DECIMAL':
        scale = colInfo.maxFractionalDigits;
        length = colInfo.maxDecimalDigits + scale;
        decimals = length;
        break;
      case 'BIGINTEGER':
        type = 'DECIMAL';
        scale = 0;
        length = 24;
        break;
      case 'DATETIME':
        break;
      default:
        break;
    }
    const sanitizedColumnName = sanitizeNamePart(columnName);
    // @todo create the headers of the table from this list
    if (sanitizedColumnName)
      return {
        displayOrder: index + 1,
        name: sanitizedColumnName,
        description: defaultProperties.DESCRIPTION,
        datatype: type,
        length: length,
        scale: scale,
        nullable: colInfo.missing === 0 ? 'N' : 'Y',
        isKeyProperty:
          colInfo.unique === true && colInfo.missing === 0 ? 'Y' : 'N',
        trim: trim,
        letterCase: letterCase,
        regexPattern: defaultProperties.REGEX_PATTERN,
        validationErrorMessage: '',
        isAutoIncrement: defaultProperties.IS_AUTO_INCREMENT,
        label: sanitize(columnName),
        zeroPadding: defaultProperties.ZERO_PADDING,
        decimals: decimals,
        valueGeneratorName: defaultProperties.VALUE_GENERATOR_NAME,
        valueGeneratorMoment: defaultProperties.VALUE_GENERATOR_MOMENT,
        valueList: defaultProperties.VALUE_LIST
      };
  };
};

const _analyzeData = (sheet, num, step) => {
  const columns = sheet.columns;
  const colsInfo = {};
  const columnNames = [];
  columns
    .filter(function (column, index) {
      return index !== 0;
    })
    .forEach(function (col) {
      columnNames.push(col.value);
      colsInfo[col.value] = {
        columnName: col.value,
        count: 0,
        countInt: 0,
        countNumeric: 0,
        countSpaceLeft: 0,
        countSpaceRight: 0,
        countUpper: 0,
        countLower: 0,
        maxDecimalDigits: 0,
        maxFractionalDigits: 0,
        missing: 0,
        maxStringLength: 0,
        minStringLength: Number.POSITIVE_INFINITY,
        types: {},
        unique: true,
        values: [],
        countTimePart: 0,
        countUUID: 0
      };
    });
  const rows = sheet.data;
  const n = rows.length;
  const m = columnNames.length;
  if (num === undefined) {
    num = n;
  }
  if (step === undefined) {
    step = 1;
  }

  let i, j, k, row, colInfo, cell, type, value, match;
  for (i = k = 0; k < num && i < n; i += step, k += 1) {
    row = rows[i];
    for (j = 0; j < m; j++) {
      colInfo = colsInfo[columnNames[j]];
      cell = row[colInfo.columnName];
      type = cell !== undefined ? cell.type : 'TEXT';
      if (colInfo.types[type] === undefined) {
        colInfo.types[type] = 1;
      } else {
        colInfo.types[type] += 1;
      }
      value = cell !== undefined ? cell.value : '';
      if (colInfo.unique === true) {
        if (colInfo.values.indexOf(value) === -1) {
          colInfo.values.push(value);
        } else {
          colInfo.unique = false;
        }
      }

      if (
        cell === undefined ||
        (type && type === 'empty') ||
        value === '' ||
        value === null ||
        value === undefined
      ) {
        colInfo.missing += 1;
      } else {
        colInfo.count += 1;
        value = String(value);
        if ((match = /^(\s*[-+])?\s*(\d+)\s*$/.exec(value))) {
          // is it an integer?
          colInfo.countNumeric += 1;
          colInfo.countInt += 1;
          var numDigits = match[2].length;
          if (colInfo.maxDecimalDigits < numDigits) {
            colInfo.maxDecimalDigits = numDigits;
          }
        } else {
          match = /^(\s*[+-])?\s*(((\d+)\.(\d*))|((\d*)\.(\d+)))\s*$/.exec(
            // is it a float?
            value
          );
          if (match) {
            colInfo.countNumeric += 1;
            if (match[3]) {
              if (colInfo.maxDecimalDigits < match[4].length) {
                colInfo.maxDecimalDigits = match[4].length;
              }
              if (colInfo.maxFractionalDigits < match[5].length) {
                colInfo.maxFractionalDigits = match[5].length;
              }
            } else if (match[6]) {
              if (colInfo.maxDecimalDigits < match[7].length) {
                colInfo.maxDecimalDigits = match[7].length;
              }
              if (colInfo.maxFractionalDigits < match[8].length) {
                colInfo.maxFractionalDigits = match[8].length;
              }
            }
          }

          match = /^(\s+)?[^\s]+([.]+[^\s]+)?(\s+)?$/.exec(value);
          if (match) {
            if (match[1]) {
              colInfo.countSpaceLeft += 1;
            }
            if (match[2]) {
              colInfo.countSpaceRight += 1;
            }
          }

          if (value.toUpperCase() === value) {
            colInfo.countUpper += 1;
          }
          if (value.toLowerCase() === value) {
            colInfo.countLower += 1;
          }

          match =
            /^\s*\{?\s*[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\s*\}?\s*$/.exec(
              value
            );
          if (match) {
            colInfo.countUUID += 1;
          }
        }

        if (colInfo.maxStringLength < value.length) {
          colInfo.maxStringLength = value.length;
        }
        if (colInfo.minStringLength > value.length) {
          colInfo.minStringLength = value.length;
        }

        if (
          type === 'date' &&
          (parseInt(cell.value.substr(11, 2), 10) ||
            parseInt(cell.value.substr(14, 2), 10) ||
            parseInt(cell.value.substr(17, 2), 10) ||
            parseInt(cell.value.substr(20, 3), 10))
        ) {
          colInfo.countTimePart += 1;
        }
      }
    }
  }

  return colsInfo;
};

export default function generateFieldDefinitionsFromSheet(sheet) {
  const columns = sheet.columns;
  const sanitizedColumns = columns.filter(column =>
    sanitizeNamePart(column.value)
  );
  const rows = sheet.data;
  const numRowsToAnalyze = rows.length;
  const step = 1;
  const analysis = _analyzeData(sheet, numRowsToAnalyze, step);

  return sanitizedColumns
    .filter((column, index) => index !== 0)
    .map(getFieldDefinition(analysis));
}
