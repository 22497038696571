import React from 'react';
import { Button } from '../../components';
import '../../components/Wizard/WizardFooter/wizardFooter.css';

const EditTableFooter = props => {
  const { handleFinish, handleCancel } = props;

  return (
    <div className="footer" data-testid="footer">
      <Button
        data-testid="footer-button-next"
        className="footer-button"
        onClick={handleFinish}
      >
        Finish
      </Button>

      <Button
        data-testid="footer-button-cancel"
        className="footer-button cancel-button ml-auto"
        onClick={handleCancel}
        theme={{ primary: '#FE0000', secondary: 'white' }}
      >
        Cancel
      </Button>
    </div>
  );
};

export default EditTableFooter;
