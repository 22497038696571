import {
  SET_SELECTED_PRIVILEGE,
  GET_USERS_SUCCESS,
  GET_TABLE_GROUP_PRIV_SUCCESS,
  GET_TABLE_PRIV_SUCCESS,
  GET_USER_TABLE_GROUP_PRIV_SUCCESS,
  GET_USER_TABLE_PRIV_SUCCESS,
  GET_ROLES_SUCCESS,
  SET_IS_NEW_PRIVILEGE,
  SET_IS_EDITING_PRIVILEGE
} from '../actions/actionTypes';

export const initialState = {
  selectedPrivilege: null,
  totalTableGroupPrivileges: 0,
  isNewRecord: false,
  isEditingRecord: false,
  userTableGroupPrivileges: [],
  userTablePrivileges: [],
  tableGroupPrivileges: [],
  tablePrivileges: [],
  roles: []
};

const privileges = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case GET_TABLE_GROUP_PRIV_SUCCESS:
      return {
        ...state,
        tableGroupPrivileges: action.tableGroupPrivileges,
        totalTableGroupPrivileges: action.tableGroupPrivileges.length,
        selectedPrivilege: null
      };
    case GET_USER_TABLE_GROUP_PRIV_SUCCESS:
      return {
        ...state,
        userTableGroupPrivileges: action.userTableGroupPrivileges
      };
    case GET_TABLE_PRIV_SUCCESS:
      return {
        ...state,
        tablePrivileges: action.tablePrivileges,
        totalTablePrivileges: action.tablePrivileges.length
      };
    case GET_USER_TABLE_PRIV_SUCCESS:
      return {
        ...state,
        userTablePrivileges: action.userTablePrivileges
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.roles
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.users
      };
    case SET_SELECTED_PRIVILEGE:
      return action.newSelectedPrivilege
        ? { ...state, selectedPrivilege: action.newSelectedPrivilege }
        : { ...state, selectedPrivilege: null };
    case SET_IS_NEW_PRIVILEGE:
      return { ...state, isNewRecord: action.isNewRecord };
    case SET_IS_EDITING_PRIVILEGE:
      return { ...state, isEditingRecord: action.isEditingRecord };
    default:
      return state;
  }
};

export default privileges;
