import React from 'react';
import { Select } from 'antd';
import { Table, FormSection } from '../../../components/index';
import sanitizeNamePart from '../../../lib/util/sanitizeNamePart';

class ColumnMapping extends React.Component {
  componentDidMount() {
    const { selectedTable, source, mapping, handleChangeAllMappings } =
      this.props;
    if (Object.keys(mapping.columnMapping).length > 0) return;
    // set the 1:1 mapping based on sourceColumnName === targetColumnName
    const targetColumns = selectedTable.properties.map(col => col.name);
    const sourceColumns = source.data.meta.columns.map(col => col.name);
    const newMapping = {
      columnMapping: {},
      mappedData: null
    };

    for (let i = 0; i < sourceColumns.length; i++) {
      const targetColumnIndex = targetColumns.findIndex(
        col => sanitizeNamePart(sourceColumns[i]) === sanitizeNamePart(col)
      );

      const targetColConfig = selectedTable.properties[targetColumnIndex];
      if (
        targetColumnIndex > -1 &&
        (!targetColConfig.valueGeneratorMoment ||
          targetColConfig.valueGeneratorMoment !== 'ALWAYS')
      ) {
        newMapping.columnMapping[targetColumns[targetColumnIndex]] =
          sourceColumns[i];
      }
    }

    handleChangeAllMappings(newMapping);
  }

  sourceColumnOptions = () => {
    const { source } = this.props;
    const sourceColumns = [...source.data.meta.columns];
    const sourceColumnOptions = [
      {
        value: '__UNDEFINED__',
        label: 'IGNORE'
      }
    ];

    for (let i = 0; i < sourceColumns.length; i++) {
      sourceColumnOptions.push({
        value: sourceColumns[i].name,
        label: sourceColumns[i].name
      });
    }
    return sourceColumnOptions;
  };

  setColumnMapping = (targetColumn, sourceColumn) => {
    const { handleChangeColumnMapping } = this.props;
    handleChangeColumnMapping({ targetColumn, sourceColumn });
  };

  updatableCell = cellInfo => {
    const mapping = cellInfo.original.mapping;
    const sourceColumnSelectionOptions = this.sourceColumnOptions().map(
      (col, index) => {
        return (
          <Select.Option
            disabled={col.disabled}
            key={col.label}
            value={col.value}
          >
            {col.label}
          </Select.Option>
        );
      }
    );

    return this.getUpdatableCellComponent(
      mapping,
      sourceColumnSelectionOptions
    );
  };

  getTableColumns = () => {
    return [
      {
        id: 'mapping',
        Header: 'Source Column',
        accessor: 'mapping',
        Cell: this.updatableCell
      },

      {
        Header: 'Target Column',
        accessor: 'target'
      }
    ];
  };

  getUpdatableCellComponent = (mapping, sourceColumnSelectionOptions) => {
    return (
      <Select
        disabled={mapping.disabled}
        key={mapping.id}
        style={{ width: '100%' }}
        showSearch={true}
        value={mapping.sourceColumn}
        onChange={sourceColumn =>
          this.setColumnMapping(mapping.targetColumn, sourceColumn)
        }
      >
        {sourceColumnSelectionOptions}
      </Select>
    );
  };

  getData = () => {
    const { selectedTable, mapping } = this.props;
    const tableData = selectedTable.properties.map((col, index) => {
      let sourceColumn = '__UNDEFINED__';
      if (mapping.columnMapping[col.name]) {
        sourceColumn = mapping.columnMapping[col.name];
      }
      return {
        key: index,
        mapping: {
          id: index,
          targetColumn: col.name,
          sourceColumn,
          disabled: col.valueGeneratorMoment === 'ALWAYS'
        },
        target: col.name
      };
    });
    return tableData;
  };

  render() {
    const columns = this.getTableColumns();
    const data = this.getData();
    return (
      <FormSection className="formGeneral" title="Mapping">
        <Table
          data={data}
          columns={columns}
          showPagination={false}
          defaultPageSize={data.length}
        />
      </FormSection>
    );
  }
}

export default ColumnMapping;
