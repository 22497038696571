import {
  GET_TABLE_GROUPS_SUCCESS,
  SET_SELECTED_TABLE_GROUP
} from '../actions/actionTypes';

const initialState = {
  selectedTableGroup: null,
  totalCount: 0,
  tableGroups: null
};
const tableGroupLayout = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case GET_TABLE_GROUPS_SUCCESS:
      return {
        ...state,
        tableGroups: action.tableGroupsData,
        totalCount: action.tableGroupsData.length
      };
    case SET_SELECTED_TABLE_GROUP:
      return action.newSelectedTableGroup
        ? { ...state, selectedTableGroup: action.newSelectedTableGroup }
        : { ...state, selectedTableGroup: null };
    default:
      return state;
  }
};

export default tableGroupLayout;
