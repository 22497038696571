// react
import React, { Fragment } from 'react';
// components
import { Popover } from '../../index';
import { Icon } from '@nike/epic-react-ui';
// css
import './propertyHelp.css';
// constants
import { REACT_APP_PATH } from '../../../constants/defaultValues';

const popoverDefinitionHelpContent = props => (
  <div className="property-help-popover-content-wrapper">
    <div className="property-help-row-wrapper">
      <div className="bootstrap-col-md-12">
        <p className="property-help-accent">
          Data Type {props.property.datatype}
        </p>
      </div>
    </div>
    <div className="property-help-row-desc-wrapper">
      <div className="bootstrap-col-md-12">
        <p className="property-help-p-descp">{props.description}</p>
      </div>
    </div>
    {props.property.datatype.toUpperCase() === 'DATE' && (
      <Fragment>
        <div className="property-help-row-wrapper">
          <div className="bootstrap-col-md-12">
            <p className="property-help-accent">Format</p>
          </div>
        </div>
        <div className="property-help-row-desc-wrapper">
          <div className="bootstrap-col-md-12">
            <p className="property-help-p-descp">
              The preferred format is YYYY-MM-DD . For more information please
              go to{' '}
              <a
                href={`${REACT_APP_PATH}/docs/docs/dates-overview.html`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Working with Dates and Datetimes
              </a>
            </p>
          </div>
        </div>
      </Fragment>
    )}
    {props.property.datatype.toUpperCase() === 'DATETIME' && (
      <Fragment>
        <div className="property-help-row-wrapper">
          <div className="bootstrap-col-md-12">
            <p className="property-help-accent">Format</p>
          </div>
        </div>
        <div className="property-help-row-desc-wrapper">
          <div className="bootstrap-col-md-12">
            <p className="property-help-p-descp">
              The preferred format is YYYY-MM-DD HH:MM:SS . For more information
              please go to{' '}
              <a
                href={`${REACT_APP_PATH}/docs/docs/dates-overview.html`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Working with Dates and Datetimes
              </a>
            </p>
          </div>
        </div>
      </Fragment>
    )}
    {props.property.datatype.toUpperCase() === 'TEXT' && (
      <Fragment>
        <div className="property-help-row-wrapper">
          <div className="bootstrap-col-md-5">
            <p className="property-help-accent">Max Length</p>
          </div>
          <div className="bootstrap-col-md-7">
            <p>{props.property.length}</p>
          </div>
        </div>
        <div className="property-help-row-wrapper">
          <div className="bootstrap-col-md-5">
            <p className="property-help-accent">Letter Case</p>
          </div>
          <div className="bootstrap-col-md-7">
            <p>{props.property.letterCase}</p>
          </div>
        </div>
        <div className="property-help-row-wrapper">
          <div className="bootstrap-col-md-5">
            <p className="property-help-accent">Trim</p>
          </div>
          <div className="bootstrap-col-md-7">
            <p>{props.property.trim}</p>
          </div>
        </div>
        {!props.property.valueList && (
          <div className="property-help-row-wrapper">
            <div className="bootstrap-col-md-5">
              <p className="property-help-accent">Regex Pattern</p>
            </div>
            <div className="bootstrap-col-md-7">
              <p className="property-help-col-p">
                {props.property.regexPattern || ''}
              </p>
            </div>
          </div>
        )}
      </Fragment>
    )}
    {props.property.datatype.toUpperCase() === 'DECIMAL' && (
      <Fragment>
        <div className="property-help-row-wrapper">
          <div className="bootstrap-col-md-5">
            <p className="property-help-accent">Decimals</p>
          </div>
          <div className="bootstrap-col-md-7">
            <p>{props.property.length}</p>
          </div>
        </div>
        <div className="property-help-row-wrapper">
          <div className="bootstrap-col-md-5">
            <p className="property-help-accent">Scale</p>
          </div>
          <div className="bootstrap-col-md-7">
            <p>{props.property.scale}</p>
          </div>
        </div>
      </Fragment>
    )}
    <div className="property-help-row-wrapper">
      <div className="bootstrap-col-md-5">
        <p className="property-help-accent">Has a Static List</p>
      </div>
      <div className="bootstrap-col-md-7">
        <p>{props.property.valueList ? 'Yes' : 'No'}</p>
      </div>
    </div>

    <div className="property-help-row-wrapper">
      <div className="bootstrap-col-md-5">
        <p className="property-help-accent">Primary Key</p>
      </div>
      <div className="bootstrap-col-md-7">
        <p>{props.property.isKeyProperty ? 'Yes' : 'No'}</p>
      </div>
    </div>
    <div className="property-help-row-wrapper">
      <div className="bootstrap-col-md-5">
        <p className="property-help-accent">Mandatory</p>
      </div>
      <div className="bootstrap-col-md-7">
        <p>{props.property.nullable ? 'Yes' : 'No'}</p>
      </div>
    </div>

    <div className="property-help-row-wrapper">
      <div className="bootstrap-col-md-5">
        <p className="property-help-accent">System</p>
      </div>
      <div className="bootstrap-col-md-7">
        <p>{props.property.valueGeneratorName ? 'Yes' : 'No'}</p>
      </div>
    </div>
  </div>
);

const PropertyHelp = props => {
  return (
    <Popover
      title={props.property.name}
      content={popoverDefinitionHelpContent(props)}
    >
      <div className="property-help-icon-wrapper">
        <Icon type="walkthrough" />
      </div>
    </Popover>
  );
};

export default PropertyHelp;
