import React, { useState, useEffect } from 'react';
import {
  FormSection,
  ContentSection,
  ContentSectionSearch,
  Button,
  RouterLink,
  Table,
  AsideLeftTableSection,
  AsideLeftTableGroupSection,
  LoadingScreen,
  ModalNotification
} from '../../components';

import { tableHeaders } from '../../constants/tableHeaders';
import tableGroupLogo from '../../assets/img/database_2.svg';
import tableLogo from '../../assets/img/database_1.svg';
import propertyLogo from '../../assets/img/properties.svg';
import { deserializeProperties } from '../../lib/serialization/deserialize.js';
import { I18n } from 'react-redux-i18n';
const tealTheme = { primary: 'black', secondary: 'white' };

const TableDetails = props => {
  const [value, setValue] = useState('');
  const [isBusy, setIsBusy] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    selectedTable,
    match,
    getTableGroups,
    setSelectedTableGroup,
    setSelectedTable,
    selectedTableGroup,
    history,
    setOnDeleteRecordConfirmation,
    removeConfirmation,
    deleteTable
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      await getTableGroups();
      await setSelectedTableGroup(match.params.tableGroupId);
      await setSelectedTable(match.params.tableGroupId, match.params.tableId);
      setIsLoaded(true);
      setIsBusy(false);
    };
    if (isLoaded === false) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If it is not loaded the the Loading screen is rendered
  if (isLoaded === false) return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!selectedTableGroup && isLoaded === true && !isBusy) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => history.push('/tablegroup')}
      />
    );
  }

  // If no table has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (!selectedTable && isLoaded === true && !isBusy) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          history.push(`/tablegroup/${selectedTableGroup?.name}`)
        }
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point all the data needed to render this layout is in place
  // ------------------------------------------------------------------

  let showExploreDataButton = false;
  let showManagePrivileges = false;
  let showViewPrivileges = false;
  let showEditTableButton = false;

  switch (selectedTable?.role) {
    case 'OWNER':
    case 'ADMIN':
      showExploreDataButton = true;
      showManagePrivileges = true;
      showEditTableButton = true;
      break;
    case 'EDITOR':
      showEditTableButton = true;
      showExploreDataButton = true;
      showViewPrivileges = true;
      break;
    case 'VIEWER':
      showExploreDataButton = true;
      showViewPrivileges = true;
      break;
    case 'NONE':
      showViewPrivileges = true;
      break;
    default:
      break;
  }

  const handleDeleteClick = () => {
    const confirmation = {
      children: (
        <div className="epic-font-base" style={{ color: 'var(--er-error)' }}>
          This action is irreversible, Privileges, Replication Targets and the
          Data will be deleted along with the Table.
        </div>
      ),
      acceptCallback: async () => {
        await deleteTable(selectedTable, selectedTableGroup);
        history.push(`/tablegroup/${selectedTableGroup?.name}`);
      },
      cancelCallback: () => {
        removeConfirmation();
      }
    };

    setOnDeleteRecordConfirmation(confirmation);
  };

  const searchProperties = properties => {
    return properties?.filter(ele => {
      return ele.name.toUpperCase().includes(value.toUpperCase());
    });
  };

  const deserializedProperties = deserializeProperties(
    selectedTable?.properties
  );

  const getPropertiesToDisplay = searhValue =>
    searhValue?.trim() === ''
      ? deserializedProperties
      : searchProperties(searhValue);

  const propertiesToDisplay =
    selectedTable?.properties?.length > 0 ? getPropertiesToDisplay(value) : [];

  const AsideLeftPropertiesSection = (
    <div className="table-groups-tiles">
      <div className="table-groups-tiles-div">
        <span className="table-groups-counter" data-testid="table-properties">
          {propertiesToDisplay.length}
        </span>
      </div>
    </div>
  );

  const editTableStructureLink = `/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}/edit`;

  const AsideRightTableSection = (
    <div className="right-aside-wrapper">
      {showExploreDataButton === true && (
        <RouterLink
          href={`/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}/data`}
        >
          <Button theme={tealTheme} small>
            Explore Data
          </Button>
        </RouterLink>
      )}
      <RouterLink
        href={`/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}/replicationtargets`}
      >
        <Button theme={tealTheme} small>
          Replication Targets
        </Button>
      </RouterLink>
      {showManagePrivileges === true && (
        <RouterLink
          href={`/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}/privileges`}
        >
          <Button theme={tealTheme} small>
            Manage Privileges
          </Button>
        </RouterLink>
      )}
      {showViewPrivileges === true && (
        <RouterLink
          href={`/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}/privileges`}
        >
          <Button theme={tealTheme} small>
            View Privileges
          </Button>
        </RouterLink>
      )}
      {showManagePrivileges === true && (
        <div data-testid="delete-table">
          <Button theme={tealTheme} small onClick={handleDeleteClick}>
            Delete Table
          </Button>
        </div>
      )}
      {showEditTableButton === true && (
        <RouterLink href={editTableStructureLink}>
          <Button theme={tealTheme} small>
            {I18n.t('tableLayout.buttons.editTable')}
          </Button>
        </RouterLink>
      )}
    </div>
  );

  const handleChange = val => {
    setValue(val);
  };

  return (
    <div className="container-layout-wrapper">
      <LoadingScreen isLoading={isBusy} />
      <ContentSection
        logo={tableGroupLogo}
        title={selectedTableGroup?.name}
        redirectWhenContentMiddleClick
        redirectContentMiddlePath={`/tablegroup/${selectedTableGroup?.name}`}
        description={selectedTableGroup?.description}
        asideLeft={
          <AsideLeftTableGroupSection
            selectedTableGroup={selectedTableGroup}
            totalTableGroupPrivileges={selectedTableGroup?.privilegesCount}
            currentUserTableGroupRole={selectedTableGroup?.role}
            totalCount={selectedTableGroup?.tablesCount}
          />
        }
        asideLeftClassName="content-section-col-statistic"
      />
      <ContentSection
        logo={tableLogo}
        title={selectedTable?.name}
        description={selectedTable?.description}
        asideLeft={
          <AsideLeftTableSection
            selectedTable={selectedTable}
            currentUserTableRole={selectedTable?.role}
            totalTablePrivileges={selectedTable?.usersCount}
          />
        }
        asideRight={AsideRightTableSection}
        asideLeftClassName="content-section-col-statistic"
      />
      <ContentSectionSearch
        logo={propertyLogo}
        title="props"
        value={value}
        handleChange={handleChange}
        asideLeft={AsideLeftPropertiesSection}
        asideLeftClassName="content-section-col-statistic"
      />
      <FormSection className="container-layout-body props">
        <Table
          columns={tableHeaders}
          data={propertiesToDisplay}
          showPagination={false}
          pageSize={propertiesToDisplay ? propertiesToDisplay.length : 0}
        />
      </FormSection>
    </div>
  );
};

export default TableDetails;
