import React from 'react';
import { Popover, Avatar } from 'antd';
import { REACT_APP_PATH } from '../../../constants/defaultValues';

const styles = {
  divAvatar: {
    backgroundColor: 'var(--er-blue)',
    verticalAlign: 'middle',
    marginLeft: 14
  }
};

const popoverDefinitionHelpContent = (
  <div>
    <p style={{ fontWeight: 'bold', color: 'var(--er-accent)' }}>
      Auto-generated key
    </p>
    <p>
      As its name implies is a Primary Key and is auto generated. A Table needs
      a Primary Key and therefore is mandatory to have at least one column as a
      Primary Key. When the data doesn&apos;t contain any natural Primary Key
      then it is possible to add auto generated one. This Colummn Property is an
      UUID. The data type uuid stores Universally Unique Identifiers (UUID) as
      defined by RFC 4122, ISO/IEC 9834-8:2005, and related standards. (Some
      systems refer to this data type as a globally unique identifier, or GUID,
      instead.)
    </p>
    <p>
      For more information please go to{' '}
      <a
        href={`${REACT_APP_PATH}/docs/docs/howtodefinetables.html`}
        target="_blank"
        rel="noopener noreferrer"
      >
        How to define Tables
      </a>
    </p>
  </div>
);

const AutoGeneratedKeyHelp = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center'
    }}
  >
    <h1>2. Auto-Generated Key</h1>
    <Popover content={popoverDefinitionHelpContent} title="Auto-generated key">
      <Avatar style={styles.divAvatar} size="small">
        ?
      </Avatar>
    </Popover>
  </div>
);

export default AutoGeneratedKeyHelp;
