export const defaultProperties = {
  IS_NULLABLE: 'N',
  IS_KEY_PROPERTY: 'N',
  IS_AUTO_INCREMENT: 'N',
  LENGTH: null,
  SCALE: null,
  TRIM: 'NONE',
  LETTER_CASE: 'NONE',
  ZERO_PADDING: 'N',
  REGEX_PATTERN: null,
  VALIDATION_ERROR_MSG: null,
  VALUE_GENERATOR_MOMENT: null,
  VALUE_GENERATOR_NAME: null,
  DESCRIPTION: null,
  VALUE_LIST: null
};
