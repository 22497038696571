import React from 'react';
import { Modal, Button } from '../../index';

const ModalNotification = props => {
  if (!props || !props.notification || !props.removeNotification) return null;

  const {
    showIcon,
    closeButton,
    isModal,
    isNotification,
    title,
    message,
    children
  } = props.notification;
  if (isModal !== true || isNotification !== true) return null;
  return (
    <div className="fontBrand modal-overlay-wrapper">
      <Modal
        show
        closeButton={closeButton || false}
        swoosh={showIcon || false}
        title={title}
        onClick={props.removeNotification}
        onClose={props.removeNotification}
      >
        <div
          className="epic-font-base modal-message-wrapper"
          data-testid="modal-notification"
        >
          {message}
        </div>
        {children}
        <div className="modal-button-wrapper">
          <Button onClick={props.removeNotification}>Accept</Button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalNotification;
