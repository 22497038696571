export const dataTypes = {
  INTEGER: {
    description: 'Represents a signed 32-bit integer value.',
    typeConstructor: 'Int32',
    inputType: 'number',
    textAlign: 'right',
    iconSrc: 'Single',
    controlConstructor: 'Input'
  },
  TEXT: {
    description: 'Represents fixed- or variable-length character data.',
    typeConstructor: 'string',
    inputType: 'text',
    textAlign: 'left',
    iconSrc: 'text',
    controlConstructor: 'Input'
  },
  DECIMAL: {
    description:
      'Represents numeric values with fixed precision and scale. This type can describe a numeric value ranging from negative 10^255 + 1 to positive 10^255 -1',
    typeConstructor: 'Decimal',
    inputType: 'number',
    textAlign: 'right',
    iconSrc: 'Decimal',
    controlConstructor: 'Input'
  },
  DATE: {
    description:
      'Represents date and time with values ranging from 12:00:00 midnight, January 1, 1753 A.D. through 11:59:59 P.M, December 9999 A.D.',
    typeConstructor: 'DateTime',
    inputType: 'text',
    iconSrc: 'dateTime',
    controlConstructor: 'DatePicker',
    formIcon: false
  },
  DATETIME: {
    description:
      'Represents date and time with values ranging from 12:00:00 midnight, January 1, 1753 A.D. through 11:59:59 P.M, December 9999 A.D.',
    typeConstructor: 'DateTime',
    inputType: 'text',
    iconSrc: 'dateTime',
    controlConstructor: 'DatePicker',
    formIcon: false
  },
  UUID: {
    description: 'Represents a 16-byte (128-bit) unique identifier value.',
    typeConstructor: 'Guid',
    iconSrc: 'sap-icon://bar-code'
  }
};
