import React from 'react';
import { ContentSection, TextInput } from '../../index';

const ContentSectionSearch = props => {
  const AsideSearch = (
    <div className="content-section-col-search">
      <TextInput
        data-testid="table-group-search"
        type="text"
        className="new-text-input content-section-search-input"
        placeholder="Search by name"
        onChange={event => props.handleChange(event.target.value.trim())}
        value={props.value}
        border={true}
        full
        autoFocus
      />
      {props.asideRight}
    </div>
  );

  return (
    <ContentSection
      logo={props.logo}
      title={props.title}
      description={props.description}
      asideRight={AsideSearch}
      asideLeft={props.asideLeft}
      asideLeftClassName={props.asideLeftClassName}
    />
  );
};

export default ContentSectionSearch;
