import React from 'react';
import logo from '../../assets/img/nike_logo.png';
import './spinner.css';

const Spinner = props => {
  const {
    message,
    title,
    loadingText,
    ellipsesOuterThinClassName,
    ellipsesOuterThickClassName
  } = props;
  return (
    <div data-testid="spinner" className="message-wrapper">
      <div className="ellipses-content-wrapper">
        <header className="ellipses-header">
          <h1
            data-testid="title"
            className="ellipses-heading ellipses-header-item"
          >
            {title}
          </h1>
        </header>
        <div className="ellipses-container">
          <img
            alt="UMD"
            className="ellipses-logo"
            height="100"
            mode="fit"
            src={logo}
          />
          <span className="loading-text">{loadingText}</span>
          <div className={ellipsesOuterThinClassName}>
            <div className="ellipses ellipses-orbit" />
          </div>
          <div className={ellipsesOuterThickClassName} />
        </div>
      </div>
      <div className="fontBase textMessage">{message}</div>
    </div>
  );
};
export default Spinner;
