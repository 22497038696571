export default function createReducer(initialState, actionMap) {
  return function reducer(state, action) {
    state = state || initialState;
    const handler = actionMap[action.type];
    if (!handler) return state;

    const reducedState = handler(state, action);
    return Object.assign({}, state, reducedState);
  };
}
