import { connect } from 'react-redux';
import DataLayout from './DataLayout';
import { setDataViewMode } from '../../actions/core';
import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';
import { getTables, setSelectedTable } from '../../actions/tableGroupDetails';
import { getRows } from '../../actions/overviewTable';

const mapDispatchToProps = {
  setSelectedTableGroup,
  getTableGroups,
  getTables,
  setSelectedTable,
  setDataViewMode,
  getRows
};

const mapStateToProps = state => {
  return {
    dataViewMode: state.core.dataViewMode,
    userTablePrivileges: state.privileges.userTablePrivileges || [],
    selectedTable: state.tableGroupDetails.selectedTable,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup,
    rows: state.overviewTable.rows,
    isLoading: state.core.isLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataLayout);
