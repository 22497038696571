// Redux
import { combineReducers } from 'redux';
// Form's Reducer
import { reducer as reduxFormReducer } from 'redux-form';
// Custom Reducers
import overviewTable from '../reducers/overviewTable';
import overviewForm from '../reducers/overviewForm';
import core from '../reducers/core';
import globalError from '../reducers/error';
import tableGroupLayout from '../reducers/tableGroupLayout';
import privileges from '../reducers/privileges';
import tableGroupDetails from '../reducers/tableGroupDetails';
import replicationTargets from '../reducers/replicationTargets';
import wizards from '../reducers/wizards';
import templateSelector from '../reducers/templateSelector';

// Internationalization's Reducer
import { i18nReducer } from 'react-redux-i18n';

const makeRootReducer = () =>
  combineReducers({
    tableGroupLayout,
    privileges,
    tableGroupDetails,
    overviewTable,
    overviewForm,
    core,
    globalError,
    form: reduxFormReducer,
    i18n: i18nReducer,
    wizards,
    replicationTargets,
    template: templateSelector
  });

export default makeRootReducer;
