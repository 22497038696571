/*************************************************************************
 *
 * Just-BI Proprietary Source Code
 * _______________________________
 *
 * Copyright 2018 and onwards, Just-BI B.V., Rijswijk, the Netherlands (KVK: 27256964).
 *
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Just-BI B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained
 * herein are proprietary to Just-BI B.V. and its suppliers,
 * and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from:
 *
 * Just-BI B.V
 * Laan van Zuid Hoorn 37
 * 2289 DC, Rijswijk
 * The Netherlands
 *
 */
import en from './en.json';
import es from './es.json';
import nl from './nl.json';

export const translationsObject = Object.assign({}, en, es, nl);
