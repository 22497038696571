import React from 'react';
import { MuiIconButton, RouterLink } from '../../index';
import './cardToolbar.css';

const CardToolbar = props => {
  const PreviewButton = () => (
    <MuiIconButton
      keyI="iconBtnTableExportExcel"
      color="inherit"
      toolTipText={props.toolTipPreview || 'icons.previewRecord'}
      className="card-toolbar-button"
      iconName="preview"
      onClick={props.onPreviewClick}
    />
  );

  const NewButton = () => (
    <MuiIconButton
      keyI="IconBtnNewRecord"
      color="inherit"
      toolTipText={props.toolTipPreview || 'icons.previewRecord'}
      className="card-toolbar-button"
      iconName="addrecord"
      onClick={props.onNewRecordClick}
    />
  );

  const AdmPrivButton = () => (
    <MuiIconButton
      keyI="iconBtnTableExportExcel"
      color="inherit"
      toolTipText={props.toolTipPreview || 'icons.previewRecord'}
      className="card-toolbar-button"
      iconName="privileges"
      onClick={props.onPreviewClick}
    />
  );

  const EditButton = () => (
    <MuiIconButton
      keyI="iconBtnTableExportExcel"
      color="inherit"
      toolTipText={props.toolTipEdit || 'icons.editRecord'}
      className="card-toolbar-button"
      iconName="edit"
      onClick={props.onEditClick}
    />
  );

  const DeleteButton = () => (
    <MuiIconButton
      keyI="iconBtnDeleteRecord"
      color="inherit"
      toolTipText={props.toolTipDelete || 'icons.deleteRecord'}
      className="card-toolbar-button"
      iconName="delete"
      onClick={props.onDeleteClick}
    />
  );

  return (
    <div
      className={`card-toolbar ${props.customClass ? props.customClass : ''}`}
    >
      {props.showPreview === true &&
        (props.redirectWhenPreview === true ? (
          <RouterLink href={props.redirectPreviewPath}>
            <PreviewButton />
          </RouterLink>
        ) : (
          <PreviewButton />
        ))}
      {props.showAdmPriv === true &&
        (props.redirectWhenAdmPriv === true ? (
          <RouterLink href={props.redirectAdmPrivPath}>
            <AdmPrivButton />
          </RouterLink>
        ) : (
          <AdmPrivButton />
        ))}
      {props.showNew === true &&
        (props.redirectWhenNew === true ? (
          <RouterLink href={props.redirectNewPath}>
            <NewButton />
          </RouterLink>
        ) : (
          <NewButton />
        ))}
      {props.showEdit === true &&
        (props.redirectWhenEdit === true ? (
          <RouterLink href={props.redirectEditPath}>
            <EditButton />
          </RouterLink>
        ) : (
          <EditButton />
        ))}
      {props.showDelete === true &&
        (props.redirectWhenDelete === true ? (
          <RouterLink href={props.redirectDeletePath}>
            <DeleteButton />
          </RouterLink>
        ) : (
          <DeleteButton />
        ))}
      {props.children}
    </div>
  );
};

export default CardToolbar;
