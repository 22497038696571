import React, { useState, useEffect } from 'react';
// router
import { useParams, useHistory } from 'react-router-dom';
import {
  ModalNotification,
  Timeline,
  LoadingScreen,
  NotificationWrapper
} from '../../components/index';

import { getReplicationTargetsById } from '../../services/replicationTargetService';
import { getTableCount } from '../../services/tableService';
import EditEmptyTable from './EditEmptyTable';
import EditNotEmptyTable from './EditNotEmptyTable';
import EditTableFooter from './EditTableFooter';
import EditTableResult from './EditTableResult';
import validation from './validation';

const colors = { success: 'green', error: 'red', warning: 'orange' };
const TimeLineItems = ({ result }) =>
  result.map(item => (
    <Timeline.Item
      key={`antd-timeline-item-${item.parent}-${item.id}`}
      color={colors[item.type]}
    >
      {item.description}
    </Timeline.Item>
  ));

const EditTable = props => {
  const { tableId, tableGroupId } = useParams();
  const history = useHistory();
  const [isBusy, setIsBusy] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [replicationTargets, setReplicationTargets] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);

  const { setChangesLostConfirmation, removeConfirmation } = props;

  useEffect(() => {
    const fetchData = async () => {
      await props.resetWizard();
      await props.getTableGroups();
      await props.setSelectedTableGroup(tableGroupId);
      await props.setSelectedTable(tableGroupId, tableId);

      const { rowCount } = await getTableCount(tableGroupId, tableId);

      const replicationTargetsResults = await getReplicationTargetsById();
      setReplicationTargets(replicationTargetsResults);
      setIsEmpty(rowCount === 0);
      setIsBusy(false);
      setIsLoaded(true);
    };
    if (isLoaded === false) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If it is not loaded the the Loading screen is rendered
  if (isLoaded === false) return <LoadingScreen isLoading />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props.selectedTableGroup && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group name.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => history.push('/tablegroup')}
      />
    );
  }

  // If no table has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props.selectedTable && isLoaded && !isBusy) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table name.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => history.push(`/tablegroup/${tableGroupId}`)}
      />
    );
  }

  // If the user doesn't have access to the table
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (
    props.selectedTableGroup &&
    isLoaded === true &&
    props.selectedTableGroup.hasAccess === false
  ) {
    const notification = {
      isNotification: true,
      title: 'Access Denied!',
      message: "The user doesn't have access to the specified Table Group.",
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => history.push(`/tablegroup/${tableGroupId}`)}
      />
    );
  }

  const hasTableChanged = () => {
    const nonGeneratedColumns = props.selectedTable?.properties?.filter(
      property => !property.valueGeneratorMoment
    );
    return (
      nonGeneratedColumns?.length !==
      props.source?.data?.meta?.columnsDefinition.length
    );
  };
  const returnToTableDetails = () =>
    history.push(`/tablegroup/${tableGroupId}/table/${tableId}`);

  const handleCancel = () => {
    if (hasTableChanged()) {
      const confirmation = {
        acceptCallback: () => {
          removeConfirmation();
          returnToTableDetails();
        },
        cancelCallback: removeConfirmation
      };
      setChangesLostConfirmation(confirmation);
      return;
    }
    returnToTableDetails();
  };

  const handleFinish = () => {
    validation({ ...props, table: props.selectedTable }, isEmpty);
  };

  const resultErrors =
    props.core.result.filter(item => {
      return item.type === 'error';
    }).length || 0;
  const resultStatus = resultErrors > 0 ? 'error' : 'success';

  const replicationTargetsUpdateStatuses = replicationTargets.map(
    (target, index) => ({
      parent: 0,
      id: index + 1,
      type: 'warning',
      description: `It may take upto ONE HOUR to reflect these changes to the ${target.type} replication target. ${target.type} table will be DROPPED and RECREATED with the same name and new structure. All downstream jobs / queries must reset connections with this table.`
    })
  );

  const editComponent = isEmpty ? (
    <EditEmptyTable
      isEmpty={isEmpty}
      isBusy={isBusy}
      table={props.selectedTable}
      {...props}
    />
  ) : (
    <EditNotEmptyTable
      isEmpty={isEmpty}
      isBusy={isBusy}
      table={props.selectedTable}
      {...props}
    />
  );

  return (
    <NotificationWrapper {...props} className="upload-wizard">
      <LoadingScreen isLoading={isBusy} />
      {editComponent}
      <EditTableFooter
        handleFinish={handleFinish}
        handleCancel={handleCancel}
      />
      {props.core.currentStatus === 'completed' && (
        <EditTableResult
          key-="result-content-definer"
          title={
            resultStatus === 'success'
              ? 'Successfully Updated!'
              : 'Error processing!'
          }
          timeLineItems={
            <TimeLineItems
              result={
                props.historyresultStatus === 'success'
                  ? [...props.core.result, replicationTargetsUpdateStatuses]
                  : props.core.result
              }
            />
          }
          finishCallback={() => {
            props.resetWizard();
            returnToTableDetails();
          }}
          status={resultStatus}
        />
      )}
    </NotificationWrapper>
  );
};

export default EditTable;
