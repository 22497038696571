import { connect } from 'react-redux';
import ReplicationTargetsForm from './ReplicationTargetsForm';
import * as Actions from '../../actions/replicationTargets';

import {
  toggleFormCheckboxes,
  setS3BucketField,
  resetReplicationTargetsForm,
  resetSendingStatus
} from '../../reducers/replicationTargets';

import {
  addModal,
  removeModal,
  setIsLoading,
  getConfirmation,
  removeConfirmation,
  addNotification
} from '../../actions/core';

import {
  getTableGroups,
  setSelectedTableGroup
} from '../../actions/tableGroupLayout';

import { setSelectedTable } from '../../actions/tableGroupDetails';

const mapDispatchToProps = {
  ...Actions,
  setIsLoading,
  addModal,
  removeModal,
  removeConfirmation,
  getConfirmation,
  addNotification,
  getTableGroups,
  setSelectedTableGroup,
  setSelectedTable,
  toggleFormCheckboxes,
  setS3BucketField,
  resetReplicationTargetsForm,
  resetSendingStatus
};

const mapStateToProps = ({
  core,
  tableGroupDetails,
  tableGroupLayout,
  replicationTargets
}) => {
  return {
    dataViewMode: core.dataViewMode,
    selectedTable: tableGroupDetails.selectedTable,
    selectedTableGroup: tableGroupLayout.selectedTableGroup,
    modal: core.modal,
    replicationTargets: replicationTargets.replicationTargets,
    formValues: replicationTargets.formValues,
    errors: replicationTargets.errors,
    loading: replicationTargets.loading,
    sending: replicationTargets.sending,
    totalCount: replicationTargets.totalCount
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplicationTargetsForm);
