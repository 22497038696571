const sourceValidation = props => {
  let error = null;
  const { source, wizardStepStatusSet, wizardStepGotoNext, addNotification } =
    props;

  if (!source.filename) {
    error = {
      title: 'Error in the Source Step',
      message:
        'No file selected. Use the upload button to select a source file.'
    };
  } else if (
    (source.filetype === 'xlsx' || source.filetype === 'xls') &&
    !source.filetypeOptions.selectedSheetName
  ) {
    error = {
      title: 'Error in the Source Step',
      message:
        'No Excel sheet selected. Use the dropdown list to select the sheet holding the source data.'
    };
  }

  if (error) {
    wizardStepStatusSet('error');
    const notification = {
      isModal: true,
      title: error.title,
      message: error.message
    };
    addNotification(notification);
    return;
  }

  wizardStepGotoNext();
};

export default sourceValidation;
