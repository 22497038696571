import React from 'react';
const AsideLeftTableSection = props => (
  <div className="table-groups-tiles">
    <div className="table-groups-tiles-div">
      <div
        className="table-groups-counter"
        data-testid="table-properties-counter"
      >
        {props.selectedTable?.properties?.length}
      </div>
      <div className="content-section-row-title-grey">props</div>
    </div>
    <div className="table-groups-tiles-div">
      <div className="table-groups-counter" data-testid="table-privileges">
        {props.totalTablePrivileges}
      </div>
      <div className="content-section-row-title-grey">Privileges</div>
    </div>
    <div className="table-groups-tiles-div">
      <div className="table-groups-counter" data-testid="table-role">
        {props.currentUserTableRole}
      </div>
      <div className="content-section-row-title-grey">role</div>
    </div>
  </div>
);

export default AsideLeftTableSection;
