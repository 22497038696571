import React from 'react';

export const tableHeaders = [
  {
    Header: 'Order',
    accessor: 'displayOrder'
  },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Datatype',
    accessor: 'datatype'
  },
  {
    Header: 'Length',
    accessor: 'length'
  },
  {
    Header: 'Decimals',
    accessor: 'scale'
  },
  {
    Header: 'Mandatory',
    accessor: 'nullable',
    Cell: function createCell(cellInfo) {
      let isMandatory;
      if (cellInfo.value === 'Y') {
        isMandatory = 'N';
      } else {
        isMandatory = 'Y';
      }
      return <span>{isMandatory}</span>;
    }
  },
  {
    Header: 'Keyfield',
    accessor: 'isKeyProperty',
    Cell: function createCell(cellInfo) {
      return <span>{cellInfo.value?.toString().toUpperCase()}</span>;
    }
  },
  {
    Header: 'Trimming',
    accessor: 'trim'
  },
  {
    Header: 'Letter Case',
    accessor: 'letterCase'
  },
  {
    Header: 'Regexp Pattern',
    accessor: 'regexPattern'
  },
  {
    Header: 'Pattern Validation Message',
    accessor: 'validationErrorMessage'
  },
  {
    Header: 'List',
    accessor: 'valueList'
  }
];
