import axios from 'axios';

export const uploadRecords = async (uri, payloads, globalHeaders, method) => {
  const requests = [];
  const xoverwriteResponse = [];
  let payloadsResend = [];
  let requestMethod = method;
  let headers = Object.assign(globalHeaders, {
    'X-HTTP-Method': requestMethod
  });

  if (method === 'XOVERWRITE') {
    await axios
      .post(uri, payloads[0], {
        headers: headers
      })
      .then(function (response) {
        xoverwriteResponse.push(response);
      })
      .catch(function (error) {
        xoverwriteResponse.push(
          error.response ? error.response : error.message
        );
      });
    if (
      xoverwriteResponse[0].status === undefined ||
      xoverwriteResponse[0].status !== 200
    ) {
      return xoverwriteResponse;
    }

    headers['X-HTTP-Method'] = 'XMERGE';
    payloads = payloads.slice(1);
  }

  let responses = [];
  if (payloads.length > 0) {
    payloads.forEach(payload => {
      requests.push(
        axios
          .post(uri, payload, {
            headers: headers
          })
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            return error.response
              ? error.response
              : {
                  status: error.status,
                  statusText: error.message,
                  error: { config: { data: JSON.parse(error.config.data) } }
                };
          })
      );
    });
    responses = await axios.all(requests);
    payloadsResend = await checkResponses(responses, uri, headers);
  }
  return Object.assign(
    [],
    [...xoverwriteResponse],
    [...responses],
    [...payloadsResend]
  );
};

export const checkResponses = async (resp, uri, headers) => {
  const errorRequests = [];
  let errorRequestResponses = [];

  const errorResponses = resp.filter(res => {
    return (
      res.status === 500 ||
      res.status === 502 ||
      res.status === 504 ||
      res.status === 403 ||
      res.status === undefined
    );
  });

  if (errorResponses.length > 0) {
    errorResponses.forEach(errorResponse => {
      if (errorResponse.config) {
        errorRequests.push(
          axios
            .post(uri, errorResponse.config.data, {
              headers: headers
            })
            .then(function (response) {
              return response;
            })
            .catch(function (error) {
              return error.response
                ? error.response
                : {
                    status: error.status,
                    statusText: error.message,
                    error: { config: { data: JSON.parse(error.config.data) } }
                  };
            })
        );
      }
    });
    errorRequestResponses = await axios.all(errorRequests);
  }

  return errorRequestResponses;
};
