// tableGroupLayout
// ---------------------------------------------------------------------
// table groups
export const GET_TABLE_GROUPS_SUCCESS = 'GET_TABLE_GROUPS_SUCCESS';
export const SET_SELECTED_TABLE_GROUP = 'SET_SELECTED_TABLE_GROUP';

// tableLayout
// ---------------------------------------------------------------------
// tables
export const GET_TABLES = 'GET_TABLES';
export const GET_TABLES_SUCCESS = 'GET_TABLES_SUCCESS';
export const GET_TABLES_FAILURE = 'GET_TABLES_FAILURE';
export const SET_SELECTED_TABLE = 'SET_SELECTED_TABLE';

// table creation template
// ---------------------------------------------------------------------
export const SET_TEMPLATE_TABLE_GROUPS = 'SET_TEMPLATE_TABLE_GROUPS';
export const GET_TEMPLATE_TABLE_GROUPS = 'GET_TEMPLATE_TABLE_GROUPS';
export const SET_TEMPLATE_TABLES = 'SET_TEMPLATE_TABLES';

// overviewTable
// ---------------------------------------------------------------------
export const GET_ROWS = 'GET_ROWS';
export const APPEND_ROWS = 'APPEND_ROWS';
export const RESET_ROWS = 'RESET_ROWS';
export const RESET_SELECTED_ROW = 'RESET_SELECTED_ROW';
export const ADD_ROW_TO_DELETION_LIST = 'ADD_ROW_TO_DELETION_LIST';
export const REMOVE_FROM_DELETION_LIST = 'REMOVE_FROM_DELETION_LIST';
export const RESET_ROW_TO_DELETION_LIST = 'RESET_ROW_TO_DELETION_LIST';
export const ADD_ROW_TO_CHANGES_LIST = 'ADD_ROW_TO_CHANGES_LIST';
export const RESET_ROW_TO_CHANGES_LIST = 'RESET_ROW_TO_CHANGES_LIST';
export const GET_ROWS_SUCCESS = 'GET_ROWS_SUCCESS';
export const GET_ROWS_FAILURE = 'GET_ROWS_FAILURE';
export const SET_SELECTED_ROW = 'SET_SELECTED_ROW';
export const UPDATE_ROW = 'UPDATE_ROW';
export const SET_OVERVIEW_TABLE_FILTERED = 'SET_OVERVIEW_TABLE_FILTERED';
export const SET_OVERVIEW_TABLE_SORTED = 'SET_OVERVIEW_TABLE_SORTED';
export const SET_OVERVIEW_TABLE_PAGINATION = 'SET_OVERVIEW_TABLE_PAGINATION';
export const ADD_OVERVIEW_TABLE_ERRORS = 'ADD_OVERVIEW_TABLE_ERRORS';
export const RESET_OVERVIEW_TABLE_ERRORS = 'RESET_OVERVIEW_TABLE_ERRORS';
export const SET_IS_OVERVIEW_TABLE_EDITING = 'SET_IS_OVERVIEW_TABLE_EDITING';
export const SET_IS_TOP_REACHED = 'SET_IS_TOP_REACHED';

// overviewForm
// ---------------------------------------------------------------------
export const SET_IS_OVERVIEW_FORM_NEW_RECORD =
  'SET_IS_OVERVIEW_FORM_NEW_RECORD';
export const SET_IS_OVERVIEW_FORM_EDITING = 'SET_IS_OVERVIEW_FORM_EDITING';
export const ADD_OVERVIEW_FORM_ERRORS = 'ADD_OVERVIEW_FORM_ERRORS';
export const REMOVE_OVERVIEW_FORM_ERRORS = 'ADD_OVERVIEW_FORM_ERRORS';
export const RESET_OVERVIEW_FORM_ERRORS = 'ADD_OVERVIEW_FORM_ERRORS';

// error
// ---------------------------------------------------------------------
export const RESET_GLOBAL_ERROR = 'RESET_GLOBAL_ERROR';
export const HANDLE_GLOBAL_ERROR = 'HANDLE_GLOBAL_ERROR';

// core
// ---------------------------------------------------------------------
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_APP_VIEW_MODE = 'SET_APP_VIEW_MODE';
export const SET_DATA_VIEW_MODE = 'SET_DATA_VIEW_MODE';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_CONFIRMATION = 'REMOVE_CONFIRMATION';
export const ADD_CONFIRMATION = 'ADD_CONFIRMATION';
export const REMOVE_MODAL = 'REMOVE_MODAL';
export const ADD_MODAL = 'ADD_MODAL';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const SET_AUTH_SERVICE = 'SET_AUTH_SERVICE';

// tableGroupUsersLayout
// ---------------------------------------------------------------------
export const GET_TABLE_GROUP_PRIV_SUCCESS = 'GET_TABLE_GROUP_PRIV_SUCCESS';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const SET_SELECTED_PRIVILEGE = 'SET_SELECTED_PRIVILEGE';

export const SET_IS_NEW_PRIVILEGE = 'SET_IS_NEW_PRIVILEGE';
export const SET_IS_EDITING_PRIVILEGE = 'SET_IS_EDITING_PRIVILEGE';
export const GET_USER_TABLE_GROUP_PRIV_SUCCESS =
  'GET_USER_TABLE_GROUP_PRIV_SUCCESS';

export const GET_TABLE_PRIV_SUCCESS = 'GET_TABLE_PRIV_SUCCESS';
export const GET_USER_TABLE_PRIV_SUCCESS = 'GET_USER_TABLE_PRIV_SUCCESS';
