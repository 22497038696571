import {
  SET_TEMPLATE_TABLE_GROUPS,
  GET_TEMPLATE_TABLE_GROUPS,
  SET_TEMPLATE_TABLES
} from '../actions/actionTypes';

export const initialState = {
  tableGroups: [],
  tables: []
};

const templateSelector = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATE_TABLE_GROUPS:
      return {
        ...state,
        tableGroups: action.payload
      };
    case SET_TEMPLATE_TABLES:
      return {
        ...state,
        tables: action.payload
      };
    case GET_TEMPLATE_TABLE_GROUPS:
    default:
      return state;
  }
};

export default templateSelector;
