import { connect } from 'react-redux';
import OverviewTable from './OverviewTable';
import {
  setIsLoading,
  removeConfirmation,
  setAppViewMode,
  addModal,
  removeModal
} from '../../actions/core';
import {
  updateRow,
  getRows,
  downloadRows,
  appendRows,
  resetRows,
  setOverviewTablePagination,
  setOverviewTableSorted,
  setOverviewTableFiltered,
  setIsOverviewTableEditing,
  setSelectedRow,
  addRowToDeletionList,
  removeRowFromDeletionList,
  showNoSortingFilteringAllowNotification,
  saveOverviewTableAction,
  deleteOverviewTableAction,
  cancelOverviewTableAction,
  cancelDeleteOnConfirmation,
  setOnSaveRecordConfirmation,
  setOnDeleteRecordConfirmation
} from '../../actions/overviewTable';
import { resetSelectedTable } from '../../actions/tableGroupDetails';
import { createOverviewFormRecord } from '../../actions/overviewForm';

const mapDispatchToProps = {
  setIsLoading,
  removeConfirmation,
  setAppViewMode,
  updateRow,
  getRows,
  downloadRows,
  appendRows,
  resetRows,
  setOverviewTablePagination,
  setOverviewTableSorted,
  setOverviewTableFiltered,
  setIsOverviewTableEditing,
  setSelectedRow,
  addRowToDeletionList,
  removeRowFromDeletionList,
  showNoSortingFilteringAllowNotification,
  saveOverviewTableAction,
  deleteOverviewTableAction,
  cancelOverviewTableAction,
  cancelDeleteOnConfirmation,
  setOnSaveRecordConfirmation,
  setOnDeleteRecordConfirmation,
  createOverviewFormRecord,
  resetSelectedTable,
  addModal,
  removeModal
};

const mapStateToProps = state => {
  return {
    isTopReached: state.overviewTable.isTopReached,
    isOverviewTableEditing: state.overviewTable.isOverviewTableEditing,
    selectedRowPosition: state.overviewTable.selectedRowPosition,
    rowsDeletionList: state.overviewTable.rowsDeletionList,
    overviewTableErrors: state.overviewTable.overviewTableErrors,
    filtered: state.overviewTable.filtered,
    sorted: state.overviewTable.sorted,
    pageNr: state.overviewTable.pageNr,
    pageSize: state.overviewTable.pageSize,
    properties:
      state.tableGroupDetails.selectedTable !== null &&
      state.tableGroupDetails.selectedTable.properties
        ? state.tableGroupDetails.selectedTable.properties
        : [],
    notification: state.core.notification,
    confirmation: state.core.confirmation
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewTable);
