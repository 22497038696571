import React, { useState, useEffect } from 'react';

import {
  FormSection,
  Select,
  LoadingScreen,
  Table,
  TablePagination
} from '../index';
import notification from './notification.js';

import { tableHeaders } from '../../constants/tableHeaders';
import { deserializeProperties } from '../../lib/serialization/deserialize';

const prepareTableForStorage = table => {
  return {
    columns: table.properties.map(({ name, datatype }) => {
      return { value: name, type: datatype };
    }),
    columnsDefinition: deserializeProperties(table.properties)
  };
};

const TemplateSelector = ({
  tableGroups,
  getTemplateTableGroups,
  onTableGroupChange,
  onTableChange,
  template,
  isLoading
}) => {
  useEffect(() => {
    getTemplateTableGroups(tableGroups);
  }, [getTemplateTableGroups, tableGroups]);

  const [selectedTableGroup, setSelectedTableGroup] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);

  const handleTableGroupChange = tableGroup => {
    if (tableGroup.name === selectedTableGroup?.name) {
      return;
    }

    setSelectedTable({});
    setSelectedTableGroup(tableGroup);
    onTableGroupChange(tableGroup);
  };

  const handleTableChange = table => {
    if (table.name === selectedTable?.name) {
      return;
    }
    const preparedTable = prepareTableForStorage(table);
    setSelectedTable({ name: table.name, ...preparedTable });
    onTableChange(preparedTable);
  };

  return (
    <div data-testid="select-template">
      <FormSection
        className="formGeneralSection"
        title="2. Select a template"
        subtitle={notification}
      >
        {
          <Select
            label="Select a table group"
            border={false}
            options={template.tableGroups}
            getOptionLabel={tableGroup => tableGroup.name}
            getOptionValue={tableGroup => tableGroup.id}
            value={selectedTableGroup}
            onChange={handleTableGroupChange}
            placeholder="Select..."
            noOptionsMessage={() => 'No table groups available'}
            isLoading={isLoading}
            loadingMessage={() => 'Loading table groups...'}
            cacheOptions
            required
          />
        }
        {selectedTableGroup?.name && (
          <Select
            label="Select a table"
            border={false}
            options={template.tables}
            getOptionLabel={table => table.name}
            getOptionValue={table => table.id}
            value={selectedTable}
            onChange={handleTableChange}
            placeholder="Select..."
            noOptionsMessage={() => 'No tables available'}
            isLoading={isLoading}
            loadingMessage={() => 'Loading tables...'}
            cacheOptions
            required
          />
        )}
      </FormSection>

      {selectedTable?.columns && selectedTable?.columnsDefinition && (
        <FormSection className="formPreviewSection" title="Preview">
          <Table
            columns={tableHeaders}
            data={selectedTable.columnsDefinition}
            defaultPageSize={10}
            pageSize={10}
            PaginationComponent={TablePagination}
            showPagination
          />
        </FormSection>
      )}
      <LoadingScreen isLoading={isLoading} />
    </div>
  );
};

export default TemplateSelector;
