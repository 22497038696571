export const preprodActiveDirectoryRoles = [
  'Application.UMD.Dev.Read',
  'Application.UMD.QA.Read'
];
export const prodActiveDirectoryRoles = ['Application.UMD.Prod.Read'];

export let OKTA_ACTIVE_DIRECTORY_ROLES;
const oktaActiveDirectoryEnv = process.env.REACT_APP_OKTA_AD_ENV;

switch (oktaActiveDirectoryEnv) {
  case 'PROD':
    OKTA_ACTIVE_DIRECTORY_ROLES = prodActiveDirectoryRoles;
    break;

  case 'PREPROD':
    OKTA_ACTIVE_DIRECTORY_ROLES = preprodActiveDirectoryRoles;
    break;

  default:
    OKTA_ACTIVE_DIRECTORY_ROLES = undefined;
}

export const OKTA_INTEGRATION_ENABLED =
  process.env.REACT_APP_OKTA_INTEGRATION_ENABLED &&
  process.env.REACT_APP_OKTA_INTEGRATION_ENABLED.toUpperCase() === 'TRUE';

export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const OKTA_ISSUER_URL = process.env.REACT_APP_OKTA_ISSUER_URL;
