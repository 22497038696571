import React from 'react';
import { MuiIconButton, RouterLink } from '../../components/index';
import DownloadOptions from './DownloadOptions';
import TableFilter from './TableFilter';

const OverviewTableToolbar = props => {
  const handleDisplayDownloadOptions = () => {
    props.addModal({
      id: 'modal-download-options',
      title: 'Download Data',
      message:
        'The csv format is the preferred choice, it takes less time to be downloaded and the file is way lighter',
      children: (
        <DownloadOptions
          handleCancel={props.removeModal}
          handleAccept={format => props.onDownloadDataClick(format)}
          selectedTable={props.selectedTable}
        />
      ),
      cancelCallback: () => props.removeModal()
    });
  };

  const handleDisplayFilterOptions = () => {
    props.addModal({
      id: 'modal-filter-options',
      title: 'Filter Settings',
      message: 'Filter a range of data based on supplied criteria',
      children: (
        <TableFilter
          properties={props.properties}
          initialFilter={props.filtered}
          handleCancel={props.removeModal}
          handleAccept={props.setOverviewTableFiltered}
        />
      ),
      cancelCallback: () => props.removeModal()
    });
  };

  return (
    <div className="tableToolBar">
      {props.isOverviewTableEditing === false &&
        props.showNewButton === true && (
          <MuiIconButton
            keyI="iconBtnNewRecord"
            color="inherit"
            toolTipText="overviewTable.toolbar.addRecord"
            className="tableToolbarButton"
            iconName="AddRecord"
            dataTestId="add-data"
            onClick={props.onNewRecordClick}
          />
        )}
      {props.isOverviewTableEditing === true && (
        <MuiIconButton
          keyI="iconBtnSaveRecord"
          color="inherit"
          toolTipText="overviewTable.toolbar.saveRecord"
          className="tableToolbarButton"
          iconName="Save"
          onClick={props.onSaveRecordClick}
        />
      )}
      {props.isOverviewTableEditing === true && (
        <MuiIconButton
          keyI="iconBtnCancel"
          color="inherit"
          toolTipText="overviewTable.toolbar.cancel"
          className="tableToolbarButton"
          iconName="Cancel"
          onClick={props.onCancelClick}
        />
      )}
      {props.isOverviewTableEditing === false && (
        <MuiIconButton
          keyI="iconBtnFilterList"
          color="inherit"
          toolTipText={
            props.overviewTableShowFilters === true
              ? 'overviewTable.toolbar.hideFilters'
              : 'overviewTable.toolbar.showFilters'
          }
          className="tableToolbarButton"
          iconName="filterActive"
          onClick={handleDisplayFilterOptions}
        />
      )}
      {props.isOverviewTableEditing === true &&
        props.showDeleteButton === true && (
          <MuiIconButton
            keyI="iconBtnDeleteRecord"
            color="inherit"
            toolTipText="overviewTable.toolbar.deleteRecord"
            className="tableToolbarButton"
            iconName="Delete"
            dataTestId="delete-record"
            onClick={() => props.onDeleteRecordClick()}
          />
        )}
      {props.showUploadButton === true && (
        <RouterLink href={`${props.path}/upload`} className="buttonUpload">
          <MuiIconButton
            keyI="iconBtnTableImportExcel"
            color="inherit"
            toolTipText="overviewTable.toolbar.uploadExcel"
            className="tableToolbarButton"
            iconName="cloudupload"
            dataTestId="upload-data"
          />
        </RouterLink>
      )}
      <MuiIconButton
        keyI="iconBtnTableExportExcel"
        color="inherit"
        toolTipText="overviewTable.toolbar.downloadExcel"
        className="tableToolbarButton"
        iconName="clouddownload"
        dataTestId="download-data"
        onClick={handleDisplayDownloadOptions}
      />
      {props.isOverviewTableEditing === false && (
        <MuiIconButton
          keyI="iconViewForm"
          color="inherit"
          toolTipText="overviewTable.toolbar.showForm"
          className="tableToolbarButton"
          iconName="viewform"
          dataTestId="show-form"
          onClick={() => props.setDataViewMode('form')}
        />
      )}
      {props.showLockButton === true && (
        <MuiIconButton
          keyI="iconBtnTableEdit"
          color="inherit"
          toolTipText={
            props.isOverviewTableEditing === true
              ? 'overviewTable.toolbar.disableEditing'
              : 'overviewTable.toolbar.enableEditing'
          }
          className="tableToolbarButton"
          iconName={
            props.isOverviewTableEditing === true ? 'editdisabled' : 'edit'
          }
          dataTestId="enable-table-editing-mode"
          onClick={() =>
            props.setIsOverviewTableEditing(!props.isOverviewTableEditing)
          }
        />
      )}
    </div>
  );
};

OverviewTableToolbar.propTypes = {};

export default OverviewTableToolbar;
