import {
  SET_IS_LOADING,
  SET_APP_VIEW_MODE,
  SET_DATA_VIEW_MODE,
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION,
  REMOVE_CONFIRMATION,
  ADD_CONFIRMATION,
  REMOVE_MODAL,
  ADD_MODAL,
  SET_IS_ADMIN,
  SET_AUTH_SERVICE
} from '../actions/actionTypes';

export const initialState = {
  isLoading: false,
  isAdmin: null,
  dataViewMode: 'table',
  appViewMode: 'dataManager',
  authService: null,
  notification: {
    showIcon: false,
    isModal: false,
    isNotification: false,
    isToast: false,
    notType: 'notification',
    message: '',
    title: '',
    children: null
  },
  confirmation: {
    isConfirmation: false,
    showIcon: false,
    confType: 'confirmation',
    title: '',
    id: '',
    question: '',
    children: null,
    acceptCallback: null,
    cancelCallback: null,
    closeButton: false
  },
  modal: {
    showModal: false,
    showIcon: false,
    message: '',
    cancelCallback: null,
    title: '',
    children: null,
    modalSize: 'md',
    customClass: ''
  }
};

const core = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SET_IS_ADMIN:
      return { ...state, isAdmin: action.isAdmin };
    case SET_AUTH_SERVICE:
      return { ...state, authService: action.authService };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_APP_VIEW_MODE:
      return { ...state, appViewMode: action.appViewMode };
    case SET_DATA_VIEW_MODE:
      return { ...state, dataViewMode: action.dataViewMode };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notification: initialState.notification
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...initialState.notification,
          ...action.notification,
          isNotification: true
        }
      };
    case REMOVE_CONFIRMATION:
      return {
        ...state,
        confirmation: initialState.confirmation
      };
    case ADD_CONFIRMATION:
      return {
        ...state,
        confirmation: {
          ...initialState.confirmation,
          ...action.confirmation,
          isConfirmation: true
        }
      };
    /**
     * Simple Modal Message
     */
    case ADD_MODAL:
      return {
        ...state,
        modal: {
          ...initialState.modal,
          ...action.modal,
          showModal: true
        }
      };
    case REMOVE_MODAL:
      return {
        ...state,
        modal: initialState.modal
      };
    default:
      return state;
  }
};

export default core;
