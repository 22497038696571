// react
import React from 'react';
// router
import { withRouter } from 'react-router-dom';
// components
import { NotificationWrapper } from '../../components';
// sub-components
import MainLayoutTopBar from './MainLayoutTopBar';
import Routes from './Routes';
// css
import './mainLayout.css';

const MainLayout = props => (
  <NotificationWrapper {...props}>
    <MainLayoutTopBar
      isOverviewTableEditing={props.isOverviewTableEditing}
      isOverviewFormEditing={props.isOverviewFormEditing}
      isOverviewFormNewRecord={props.isOverviewFormNewRecord}
    />
    <Routes {...props} />
  </NotificationWrapper>
);

export default withRouter(MainLayout);
