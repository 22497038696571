import React from 'react';
import { FormSection } from '../../../components/index';
import DetailsTransformedData from './DetailsTransformedData/DetailsTransformedData';
import { Icon, Card, Statistic } from 'antd';

const ReviewSubmit = props => {
  const { wizard, selectedTable, validation } = props;
  const { sendBatches, chunckSize, cellLimit, cellsCount, batchesNumber } =
    wizard;
  const { formattedData } = validation;
  const formattedDataColumns = selectedTable.properties;

  return (
    <div data-testid="review-submit">
      <FormSection
        className="formGeneralSection"
        title="Submit Summary"
        subtitle={
          sendBatches === true
            ? 'The Number of Cells to be uploaded is too big to be sent at once. Therefore the Bulk Loading Mode has been enabled'
            : ''
        }
      >
        <div className="card-row">
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Total Records"
                value={formattedData.length}
                precision={0}
                valueStyle={{ color: 'var(--er-success)' }}
                prefix={<Icon type="check-circle" />}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Total Cells"
                value={cellsCount}
                precision={0}
                valueStyle={{ color: 'var(--er-success)' }}
                prefix={<Icon type="check-circle" />}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Total Batches"
                value={batchesNumber}
                precision={0}
                valueStyle={{ color: 'var(--er-success)' }}
                prefix={<Icon type="check-circle" />}
              />
            </Card>
          </div>
        </div>
        <div className="card-row">
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Cells Limit"
                value={cellLimit}
                precision={0}
                valueStyle={{ color: 'var(--er-warn)' }}
                prefix={<Icon type="check-circle" />}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Records for Batch"
                value={chunckSize}
                precision={0}
                valueStyle={{ color: 'var(--er-success)' }}
                prefix={<Icon type="check-circle" />}
              />
            </Card>
          </div>
          <div className="bootstrap-col-md-3">
            <Card>
              <Statistic
                title="Bulk Loading Enabled"
                value={sendBatches === true ? 'Yes' : 'No'}
                precision={0}
                valueStyle={{ color: 'var(--er-success)' }}
                prefix={
                  sendBatches ? (
                    <Icon type="check-circle" />
                  ) : (
                    <Icon type="close-circle" />
                  )
                }
              />
            </Card>
          </div>
        </div>
      </FormSection>
      <DetailsTransformedData
        formattedData={formattedData}
        formattedDataColumns={formattedDataColumns}
      />
    </div>
  );
};

export default ReviewSubmit;
