import { GET_TABLES_SUCCESS, SET_SELECTED_TABLE } from '../actions/actionTypes';

export const initialState = {
  selectedTable: null,
  totalCount: 0,
  tables: []
};

const tableGroupDetails = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case GET_TABLES_SUCCESS:
      return {
        ...state,
        tables: action.tables,
        totalCount: action.tables.length,
        selectedTable: null
      };
    case SET_SELECTED_TABLE:
      return action.newSelectedTable
        ? { ...state, selectedTable: action.newSelectedTable }
        : { ...state, selectedTable: null };
    default:
      return state;
  }
};

export default tableGroupDetails;
