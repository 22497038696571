import React from 'react';
import { Modal } from '@nike/epic-react-ui';
import './modalSimple.css';

const ModalSimple = props => {
  if (!props || !props.modal) return null;

  const {
    showModal,
    showIcon,
    message,
    cancelCallback,
    title,
    children,
    modalSize,
    customClass
  } = props.modal;
  if (showModal !== true) return null;
  return (
    <div data-testid="modal-window">
      <Modal
        show
        closeButton
        swoosh={showIcon || false}
        title={title}
        onClose={cancelCallback}
        modalSize={modalSize || 'md'}
        className={`epic-font-base modal-message-wrapper  ${customClass || ''}`}
      >
        {message}
        <div className="simple-modal-children-wrapper"> {children}</div>
      </Modal>
    </div>
  );
};

export default ModalSimple;
