//this is for the table and pagination
export const TABLE_MAX_ROW_NUMBERS_TO_FETCH_SMALL = 150;
export const TABLE_DEFAULT_PAGE_SIZE_SMALL = 18;

export const TABLE_MAX_ROW_NUMBERS_TO_FETCH_MEDIUM = 200;
export const TABLE_DEFAULT_PAGE_SIZE_MEDIUM = 18;

export const SERVICE_NAME_MAX_LENGTH = 28;
export const CONTENT_TYPE_NAME_MAX_LENGTH = 28;
export const TABLE_NAME_MIN_LENGTH = 5;

export const TABLE_DESCRIPTION_MIN_LENGTH = 10;
export const TABLE_DESCRIPTION_MAX_LENGTH = 255;
