export const TABLE_CREATION_MODES = {
  MANUAL: 'manual',
  UPLOAD: 'upload',
  TEMPLATE: 'template'
};

export const tableCreationOptions = [
  {
    label: 'Create table structure without a file',
    value: TABLE_CREATION_MODES.MANUAL
  },
  {
    label: 'Create table structure with an Excel file',
    value: TABLE_CREATION_MODES.UPLOAD
  },
  {
    label: 'Create table structure using another table as a template',
    value: TABLE_CREATION_MODES.TEMPLATE
  }
];

export const TABLE_CREATION_MODE_ANALYTIC_EVENT_NAMES = {
  MANUAL: 'ui_create_table_structure',
  UPLOAD: 'xls_create_table_structure',
  TEMPLATE: 'template_create_table_structure'
};
