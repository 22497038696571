// react
import React from 'react';
// others
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
// components
import { NavBar } from '../../components/index';
import { Icon } from '@nike/epic-react-ui';
// constants
import {
  NAVIGATION_ROUTES,
  APP_SLACK_URL,
  APP_CONFLUENCE_URL,
  APP_JIRA_URL,
  APP_HELP_URL,
  APP_SUPPORT_URL
} from '../../constants/defaultValues';
// assets
import logo from '../../assets/img/nike.png';

const navigationLinks = [
  {
    label: 'Documentation',
    href: APP_HELP_URL,
    icon: 'walkthrough',
    target: 'userDocumentation'
  },
  {
    label: 'Slack',
    href: APP_SLACK_URL,
    icon: 'slack',
    target: 'slack'
  },
  {
    label: 'Confluence',
    href: APP_CONFLUENCE_URL,
    icon: 'confluence',
    target: 'confluence'
  },
  {
    label: 'Jira',
    href: APP_JIRA_URL,
    icon: 'jira',
    target: 'jira'
  },
  {
    label: 'Support',
    href: APP_SUPPORT_URL,
    icon: 'walkthrough',
    target: 'userSupport'
  }
];

const NavigationLink = ({ label, href, icon, target }) => {
  return (
    <>
      <a
        className="navigation-link"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon className="navigation-icon" type={icon} />
        {label}
      </a>
    </>
  );
};

const AsideWrapper = (
  <div style={{ display: 'flex', marginRight: '-25px' }}>
    {navigationLinks.map(link => {
      return (
        <NavigationLink
          key={link.label}
          label={link.label}
          href={link.href}
          icon={link.icon}
          target={link.target}
        />
      );
    })}
  </div>
);

const MainLayoutTopBar = props => {
  let titleNoNavigable = '';
  let isNavigable = true;
  if (props.isOverviewTableEditing === true) {
    titleNoNavigable = 'core.toolbar.title.tableEditionMode';
    isNavigable = false;
  } else {
    if (props.isOverviewFormEditing === true) {
      titleNoNavigable = 'core.toolbar.title.formEditionMode';
      isNavigable = false;
    } else {
      if (props.isOverviewFormNewRecord === true) {
        titleNoNavigable = 'core.toolbar.title.formCreationMode';
        isNavigable = false;
      }
    }
  }
  if (isNavigable === true) {
    return (
      <div className="topbar">
        <NavBar
          name="umd"
          logo={logo}
          rootPath="/"
          routes={NAVIGATION_ROUTES}
          RouterLink={({ href, ...props }) => <Link to={href} {...props} />}
          aside={AsideWrapper}
        />
      </div>
    );
  } else {
    return (
      <div className="toopbar-title-no-navigable" data-testid="topbar-title">
        <Translate value={titleNoNavigable} />
      </div>
    );
  }
};

export default MainLayoutTopBar;
