// @todo remove redux-action
import { createAction } from '@nike/redux-action-utils';
import { setIsLoading, setAppViewMode } from './core';
import { uploadFileStatistics } from '../services/statisticsService';

// Set Wizard steps
export const wizardStepGotoNext = createAction('WIZARD_STEP_GOTO_NEXT');
export const wizardStepGotoIndex = createAction('WIZARD_STEP_GOTO_INDEX');
export const wizardStepStatusSet = createAction('WIZARD_STEP_STATUS_SET');

// Set Source related values
export const sourceFileSet = createAction('SOURCE_FILE_SET');
export const sourceDataSet = createAction('SOURCE_DATA_SET');
export const sourceExcelSheetSelect = createAction('SOURCE_EXCEL_SHEET_SET');
export const setSourceMetaData = createAction('SET_SOURCE_META_DATA');
export const setCsvParserOptions = createAction('SET_CSV_PARSER_OPTIONS');
export const setSourceFile = createAction('SET_SOURCE_FILE');

// Set Mappings
export const mappingMapSet = createAction('MAPPING_MAP_SET');
export const mappingAllSet = createAction('MAPPING_ALL_SET');
export const mappingMappedDataSet = createAction('MAPPING_MAPPEDDATA_SET');

// Set the Validations of the tranformed data
export const validateTransformedResultSet = createAction(
  'VALIDATE_TRANSFORMEDRESULT_SET'
);

// General Wizard
export const resetWizard = createAction('WIZARD_RESET');
export const setResult = createAction('SET_RESULT');
export const finalizeWizard = createAction('WIZARD_FINALIZE');
export const setCreationMode = createAction('SET_CREATION_MODE');

// Set the data loading mode for the upload wizard
export const setDataLoadingMode = createAction('SET_DATA_LOADING_MODE');

// Set the Tabla definition's related values
export const updateColumSpec = createAction('UPDATE_COLUMN_SPEC');
export const setDatatypeColumSpec = createAction('SET_DATATYPE_COLUMN_SPEC');
export const setColumSpec = createAction('SET_COLUMN_SPEC');
export const setNextColumnSpec = createAction('SET_NEXT_COLUMN_SPEC');
export const setPreviousColumnSpec = createAction('SET_PREVIOUS_COLUMN_SPEC');
export const deleteColumnSpec = createAction('DELETE_COLUMN_SPEC');
export const addColumnSpec = createAction('ADD_COLUMN_SPEC');
export const setAddGeneratedKeyColumn = createAction(
  'SET_ADD_GENERATED_KEY_COLUMN'
);
export const setDefinitionErrors = createAction('SET_DEFINITION_ERRORS');

// Set the core (table groups, tables, sys table groups, sys tables)
export const setSysServicesSuccess = createAction('SET_SYS_SERVICES_SUCCESS');

// Set the Target of the content creator
export const setSelectedService = createAction('SET_SELECTED_SERVICE');
export const setNewTableName = createAction('SET_NEW_TABLE_NAME');
export const setTableDescription = createAction('SET_TABLE_DESCRIPTION');

export const setResultWizard = result => async dispatch => {
  await dispatch(setResult(result.result));
  await dispatch(wizardStepStatusSet('completed'));
  await dispatch(setIsLoading(false));
};

export const cancelWizard = () => dispatch => {
  dispatch(resetWizard());
  dispatch(setAppViewMode('dataManager'));
};

export const sendFileStatistics = () => async (_dispatch, getState) => {
  const { source, core } = getState().wizards;
  const tableGroupDetails = getState().tableGroupDetails;

  const payload = {
    tableGroup: tableGroupDetails?.selectedTable.tableGroupName,
    table: tableGroupDetails?.selectedTable.name,
    fileType: source?.filetype,
    fileName: source?.filename,
    sheetName: source?.filetypeOptions?.selectedSheetName || '',
    rowCount: core?.rowsCount,
    cellCount: core?.cellsCount,
    batchCount: core?.batchesNumber,
    fileSize: source?.fileSize
  };

  try {
    await uploadFileStatistics(payload);
  } catch (error) {
    console.error('Error during upload a statistics about the file');
  }
};
