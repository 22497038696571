// React
import React, { useEffect, useState } from 'react';
// Components
import {
  CardsContainer,
  FormSection,
  ContentSectionSearch,
  CardFooter,
  LoadingScreen
} from '../../components';
// Css
import './tableGroupLayout.css';
// Assets
import tableGroupLogo from '../../assets/img/database_2.svg';
// Util
import getIconByName from '../../lib/util/getIconByName';

const TableGroupLayout = props => {
  const { tableGroups, totalCount } = props;
  const [value, setValue] = useState('');
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsBusy(true);
      await props.getTableGroups();
      setIsBusy(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchTableGroups = valueToSearch =>
    tableGroups.filter(ele => {
      return ele.name.toUpperCase().includes(valueToSearch.toUpperCase());
    });

  const getTableGroupsToDisplay = searhValue =>
    searhValue?.trim() === '' ? tableGroups : searchTableGroups(searhValue);

  const tableGroupsToDisplay =
    tableGroups?.length > 0 ? getTableGroupsToDisplay(value) : [];

  const CardFooterToolbar = ({ tableGroup }) => {
    const iconName = tableGroup.hasAccess === true ? 'lockopen' : 'lock';
    return (
      <CardFooter
        leftSection={
          <div className="card-container-padlock container">
            <div className="bootstrap-col-md-1"> {getIconByName(iconName)}</div>
            <div className="bootstrap-col-md-11 card-container-padlock-text">{`tables: ${tableGroup.tablesCount}`}</div>
          </div>
        }
      />
    );
  };

  const cards = tableGroupsToDisplay.map(tableGroup => ({
    'data-testid': tableGroup.name,
    title: tableGroup.name,
    description: tableGroup.description,
    width: '180px',
    height: '180px',
    iconPath: tableGroupLogo,
    href: `/tablegroup/${tableGroup.name}`,
    children: <CardFooterToolbar tableGroup={tableGroup} />,
    customClass: tableGroup.hasAccess === false ? 'card-locked' : ''
  }));

  const handleChange = val => {
    setValue(val);
  };

  const TableGroupsCounter = (
    <div className="table-groups-tiles">
      <div className="table-groups-tiles-div">
        <div className="table-groups-counter-new">{totalCount}</div>
        <div className="content-section-row-title-grey-bigfont">
          Table Groups
        </div>
      </div>
    </div>
  );

  return (
    <div data-testid="table-group-layout">
      <LoadingScreen isLoading={isBusy} />
      <ContentSectionSearch
        logo={tableGroupLogo}
        value={value}
        handleChange={handleChange}
        asideLeft={TableGroupsCounter}
      />
      <FormSection className="container-table-group-layout-body">
        <CardsContainer
          cards={cards}
          classContainer="card-container-table-group"
          redirectWhenClick={true}
        />
      </FormSection>
    </div>
  );
};

export default TableGroupLayout;
