import React, { Component } from 'react';
// components
import {
  Table,
  DatePicker,
  Tooltip,
  Popover
} from '../../../../components/index';
import { Select } from 'antd';
// css
import 'react-datepicker/dist/react-datepicker.css';
import './fieldsConfigurator.css';

const { Option } = Select;

class FieldsConfigurator extends Component {
  handleKeyDown = event => {
    if (event.keyCode === 69 && event.target.type === 'number') {
      event.preventDefault();
    }
  };

  getTableColumns = columnDefinitions => {
    return columnDefinitions.map(definition => {
      return {
        id: definition.accessor,
        Header: (
          <Tooltip content={definition.header}>
            <span>{definition.header}</span>
          </Tooltip>
        ),
        accessor: definition.accessor,
        Cell: this.updatableCell,
        width: definition.width || undefined
      };
    });
  };

  getSelectionOptions = options => {
    return options.map(option => {
      return (
        <Option
          data-testid={option.label}
          key={option.label}
          value={option.value}
        >
          {option.label}
        </Option>
      );
    });
  };

  getSelectedOptionValue = (options, value) => {
    if (!value) return null;
    const selectedOption = options.filter(
      option => option.label.toUpperCase() === value.toUpperCase()
    )[0];
    if (selectedOption === undefined) return null;
    const selectedOptionValue = selectedOption ? selectedOption.value : null;
    return selectedOptionValue;
  };

  getUpdatableCellComponent = (cellInfo, columnMetaData) => {
    const {
      onUpdatableCellInputChange,
      onUpdatableCellSelectChange,
      onUpdatableCellCheckBoxChange,
      getError
    } = this.props;

    const { value } = cellInfo;
    const { accessor, constructor, type, options } = columnMetaData;
    const error = getError(cellInfo);

    const isDisabled =
      typeof columnMetaData.disabled === 'function'
        ? columnMetaData.disabled(cellInfo.original)
        : columnMetaData.disabled !== undefined
        ? columnMetaData.disabled
        : false;
    const align = !columnMetaData.align
      ? columnMetaData.align
      : type === 'number'
      ? 'right'
      : 'left';
    const selectedOptionValue =
      constructor === 'select'
        ? this.getSelectedOptionValue(options, value)
        : null;
    let updatableCellComponent;
    if (columnMetaData.updatableCellComponent) {
      updatableCellComponent = columnMetaData.updatableCellComponent(
        cellInfo,
        value
      );
    } else {
      if (isDisabled === true) {
        updatableCellComponent = (
          <div className="div-disabled-cellComponent">{value}</div>
        );
      } else {
        switch (constructor) {
          case 'datepicker':
            updatableCellComponent = (
              <DatePicker
                value={value}
                disabled={isDisabled}
                datatype={columnMetaData.datatype}
                isClearable={!isDisabled}
              />
            );
            break;
          case 'checkbox':
            updatableCellComponent = (
              <input
                type="checkbox"
                checked={value === 'Y'}
                onChange={event => {
                  onUpdatableCellCheckBoxChange(cellInfo, event);
                }}
                className="cell-checkbox"
              />
            );
            break;
          case 'select':
            updatableCellComponent = (
              <Select
                disabled={isDisabled}
                key={`select-key-${accessor}`}
                style={{ width: '100%' }}
                showSearch={true}
                value={selectedOptionValue}
                onChange={val => onUpdatableCellSelectChange(cellInfo, val)}
              >
                {this.getSelectionOptions(options)}
              </Select>
            );
            break;
          case 'input':
          default:
            updatableCellComponent = (
              <input
                key={`input-${accessor}`}
                type={type}
                onChange={event => {
                  onUpdatableCellInputChange(cellInfo, event);
                }}
                value={value}
                readOnly={isDisabled}
                disabled={isDisabled}
                style={{
                  textAlign: `${align}`,
                  width: '100%',
                  height: '27px'
                }}
                className={error ? 'inputError' : 'cell-input'}
              />
            );
        }
      }
    }

    return error ? (
      <Popover content={error.message}>{updatableCellComponent}</Popover>
    ) : (
      updatableCellComponent
    );
  };

  updatableCell = cellInfo => {
    const { tableHeaders } = this.props;

    const columnMetaData = tableHeaders.filter(spec => {
      return spec.accessor === cellInfo.column.id;
    })[0];

    return this.getUpdatableCellComponent(cellInfo, columnMetaData);
  };

  render() {
    const { data, showPagination, pageSize, tableHeaders, sortable, sorted } =
      this.props;

    const columns = this.getTableColumns(tableHeaders);

    return (
      <Table
        data={data}
        sorted={sorted}
        columns={columns}
        showPagination={showPagination}
        pageSize={pageSize}
        defaultPageSize={pageSize}
        sortable={sortable}
        getTrProps={(state, rowInfo) => {
          return {
            'data-testid': `row-${rowInfo?.original?.displayOrder}`
          };
        }}
        getTdProps={(state, rowInfo, column) => {
          return {
            'data-testid': `cell-${column.id}`
          };
        }}
      />
    );
  }
}

export default FieldsConfigurator;
