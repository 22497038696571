import React from 'react';
import { Button, RouterLink } from '../../index';
import './wizardFooter.css';

const WizardFooter = props => {
  const {
    path,
    steps,
    activeStep,
    allStepsCompleted,
    handleBack,
    handleNext,
    handleCancel,
    handleReset
  } = props;
  const buttonNextLabel = activeStep === steps.length - 1 ? 'Finish' : 'Next';
  const content = allStepsCompleted ? (
    <>
      <div className="epic-font-base">
        All steps completed - you&apos;re finished
      </div>
      <Button
        data-testid="footer-button-reset"
        className="footer-button"
        onClick={handleReset}
      >
        Reset
      </Button>
    </>
  ) : (
    <>
      <Button
        data-testid="footer-button-back"
        className="footer-button"
        disabled={activeStep === 0}
        onClick={handleBack}
      >
        Back
      </Button>
      <Button
        data-testid="footer-button-next"
        className="footer-button"
        onClick={handleNext}
      >
        {buttonNextLabel}
      </Button>

      <Button
        data-testid="footer-button-reset"
        className="footer-button reset-button"
        onClick={handleReset}
        theme={{ primary: '#FA5400', secondary: 'white' }}
      >
        Reset
      </Button>
      <RouterLink href={path}>
        <Button
          data-testid="footer-button-cancel"
          className="footer-button cancel-button"
          onClick={handleCancel}
          theme={{ primary: '#FE0000', secondary: 'white' }}
        >
          Cancel
        </Button>
      </RouterLink>
    </>
  );

  return (
    <div className="footer" data-testid="footer">
      {content}
    </div>
  );
};

export default WizardFooter;
