export const privileges = [
  {
    functionality: 'Create',
    level: 'Table Group',
    owner: false,
    editor: false,
    viewer: false
  },
  {
    functionality: 'Create',
    level: 'Table',
    owner: true,
    editor: false,
    viewer: false
  },
  {
    functionality: 'Edit / Update / Upload data',
    level: 'Table',
    owner: true,
    editor: true,
    viewer: false
  },
  {
    functionality: 'Edit Table Structure',
    level: 'Table',
    owner: true,
    editor: true,
    viewer: false
  },
  {
    functionality: 'View',
    level: 'Table Group',
    owner: true,
    editor: true,
    viewer: true
  },
  {
    functionality: 'View',
    level: 'Table',
    owner: true,
    editor: true,
    viewer: true
  },
  {
    functionality: 'Delete data within',
    level: 'Table',
    owner: true,
    editor: true,
    viewer: false
  },
  {
    functionality: 'Delete',
    level: 'Table group',
    owner: false,
    editor: false,
    viewer: false
  },
  {
    functionality: 'Delete',
    level: 'Table',
    owner: true,
    editor: false,
    viewer: false
  },
  {
    functionality: 'Use table as a template',
    level: 'Table',
    owner: true,
    editor: false,
    viewer: false
  },
  {
    functionality: 'Manage Privileges',
    level: 'Table group',
    owner: true,
    editor: false,
    viewer: false
  },
  {
    functionality: 'Manage Privileges',
    level: 'Table',
    owner: true,
    editor: false,
    viewer: false
  },
  {
    functionality: 'Download data/records',
    level: 'Table',
    owner: true,
    editor: true,
    viewer: true
  }
];
