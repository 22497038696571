import React, { useState, useEffect } from 'react';
import { Progress as ProgressUI } from 'antd';
import './progress.css';

const Progress = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    initialize();

    return () => {
      clearInterval(initialize);
    };
    // if eslint add initialize fn to the array it will break the loader
  }, []);

  const initialize = () => {
    let step = 0.5; // the smaller this is the slower the progress bar
    let currentProgress = 0;
    const interval = setInterval(() => {
      currentProgress += step;

      let percent =
        Math.round((Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000) /
        1000;

      setProgress(percent);
      // ask antoon if we need it
      // if (isFinished) {
      //   clearInterval(interval);
      // }

      if (percent >= 100) {
        currentProgress = 100;
        setProgress(currentProgress);
        clearInterval(interval);
      } else if (percent >= 70) {
        step = 0.1;
      }
    }, 100);
  };

  return (
    <div className="container">
      <ProgressUI
        className="progress"
        type="line"
        status="active"
        percent={progress}
        strokeColor="var(--er-blue)"
        trailColor="var(--er-success)"
      />
    </div>
  );
};

export default Progress;
