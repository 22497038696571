// redux
import { connect } from 'react-redux';
// actions
import { setSelectedRow } from '../../actions/overviewTable';
import {
  getConfirmation,
  removeConfirmation,
  addModal,
  removeModal
} from '../../actions/core';
import {
  setIsOverviewFormEditing,
  createOverviewFormRecord,
  saveOverviewFormAction,
  deleteOverviewFormAction,
  cancelOverviewFormAction,
  setOnSaveRecordConfirmation,
  setOnDeleteRecordConfirmation
} from '../../actions/overviewForm';
import { resetSelectedTable } from '../../actions/tableGroupDetails';
// components
import Form from './OverviewForm';

const mapDispatchToProps = {
  setSelectedRow,
  setOnSaveRecordConfirmation,
  setOnDeleteRecordConfirmation,
  getConfirmation,
  removeConfirmation,
  createOverviewFormRecord,
  saveOverviewFormAction,
  deleteOverviewFormAction,
  cancelOverviewFormAction,
  setIsOverviewFormEditing,
  addModal,
  removeModal,
  resetSelectedTable
};

const mapStateToProps = state => {
  return {
    isLoading: state.core.isLoading,
    rows: state.overviewTable.rows,
    selectedRow: { ...state.overviewTable.selectedRow },
    initialValues: state.overviewTable.selectedRow,
    selectedRowPosition: state.overviewTable.selectedRowPosition,
    selectedTable: state.tableGroupDetails.selectedTable,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup,
    formValues:
      state.form.reduxForm && state.form.reduxForm.values
        ? state.form.reduxForm.values
        : [],
    properties:
      state.tableGroupDetails.selectedTable !== null &&
      state.tableGroupDetails.selectedTable.properties
        ? state.tableGroupDetails.selectedTable.properties
        : [],
    isOverviewFormNewRecord: state.overviewForm.isOverviewFormNewRecord,
    isOverviewFormEditing: state.overviewForm.isOverviewFormEditing,
    overviewFormErrors: state.overviewForm.overviewFormErrors,
    notification: state.core.notification
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
