import { REACT_APP_API_METADATA_URL } from './defaultValues';
import {
  REACT_APP_GEM_API_URL,
  REACT_APP_GEM_API_VERSION,
  REACT_APP_NSP_API_URL
} from './environment';

const umdUrl = `${REACT_APP_API_METADATA_URL}/api`;
const privilegesPath = `${umdUrl}/new-privileges/v3`;
const gemUrl = `${REACT_APP_GEM_API_URL}/${REACT_APP_GEM_API_VERSION}`;
const nspUrl = REACT_APP_NSP_API_URL;

export const endPoints = {
  privileges: {
    admin: `${privilegesPath}/admin`,
    users: `${umdUrl}/new-privileges/v1/users`,
    roles: `${privilegesPath}/roles`,
    privileges: `${privilegesPath}`,
    snowflake: `${umdUrl}/new-privileges/v3/snowflake`
  },
  metadata: `${umdUrl}/v3/metadata/tablegroups`,
  tableGroups: {
    tableGroups: `${umdUrl}/tablegroups/v3`,
    tables: { d: `${umdUrl}/v3/metadata/tables` }
  },
  records: {
    get: `${umdUrl}/data/v3`,
    cud: `${umdUrl}/v3/data`
  },
  replicationTargets: {
    get: `${umdUrl}/replicationtargets/v3`,
    cud: `${umdUrl}/replicationtargets/v3`
  },
  gem: {
    proxy: { sources: '/v1/sources', sinks: '/v1/sinks' },
    withoutProxy: { sources: `${gemUrl}/sources`, sinks: `${gemUrl}/sinks` },
    portal: 'https://gem.platforms.nike.com/details'
  },
  fileStatistics: `${umdUrl}/filestatistics/v3`,
  file: `${umdUrl}/files/v3`,
  nsp: {
    sinks: `${nspUrl}/sinks/details`
  }
};
