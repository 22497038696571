import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CoreLayoutContainer } from './layouts';
import store from './store/store';
import './assets/css/main.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <CoreLayoutContainer />
        </Provider>
      </BrowserRouter>
    );
  }
}
export default App;
