/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import { Result as AntdResult, Timeline } from 'antd';
import { Modal, Button } from '../../components/index';

const EditTableResult = props => (
  <div data-testid="wizard-result" className="fontBrand modal-overlay-wrapper">
    <Modal show logo title={props.title} onClose={props.finishCallback}>
      <AntdResult
        key="antd-result-upload-wizard"
        status={props.status}
        subTitle={props.subTitle}
        extra={[
          <Timeline key="timeline-upload-wizard">
            {props.timeLineItems}
          </Timeline>
        ]}
      />
      <div className="div-buttons-wrapper">
        <Button onClick={props.finishCallback}>Finish</Button>
      </div>
    </Modal>
  </div>
);

export default EditTableResult;
