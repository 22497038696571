import { createSlice } from '@reduxjs/toolkit';

const initialReplicationTargetsFormState = {
  snowflake: {
    checked: false
  },
  s3: {
    checked: false,
    s3BucketFields: {
      acl: {
        value: 'bucket-owner-full-control',
        label: 'bucket-owner-full-control'
      },
      region: null,
      uri: null
    }
  }
};

export const initialState = {
  selectedReplicationTarget: null,
  totalCount: null,
  errors: [],
  loading: 'pending',
  sending: 'pending',
  replicationTargets: [],
  formValues: initialReplicationTargetsFormState
};

const replicationTargetsSlice = createSlice({
  name: 'replicationTargets',
  initialState,
  reducers: {
    getReplicationTargetsStart(state, action) {
      return {
        ...state,
        loading: 'loading'
      };
    },
    getReplicationTargetsSuccess(state, action) {
      return {
        ...state,
        loading: 'success',
        replicationTargets: action.payload,
        totalCount: action.payload.length,
        errors: []
      };
    },
    getReplicationTargetsFailure(state, action) {
      return {
        ...state,
        loading: 'failure',
        errors: [...state.errors, action.payload],
        totalCount: 0
      };
    },
    toggleFormCheckboxes(state, action) {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.payload]: {
            ...state.formValues[action.payload],
            checked: !state.formValues[action.payload].checked
          }
        }
      };
    },
    setS3BucketField(state, action) {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          s3: {
            ...state.formValues.s3,
            s3BucketFields: {
              ...state.formValues.s3.s3BucketFields,
              ...action.payload
            }
          }
        }
      };
    },
    sendReplicationTargetsStart(state, action) {
      return {
        ...state,
        sending: 'loading'
      };
    },
    sendReplicationTargetsSuccess(state, action) {
      return {
        ...state,
        sending: 'success',
        errors: []
      };
    },
    sendReplicationTargetsFailure(state, action) {
      return {
        ...state,
        errors: [...state.errors, action.payload],
        sending: 'failure'
      };
    },
    resetReplicationTargetsForm(state, action) {
      return {
        ...state,
        formValues: {
          ...initialReplicationTargetsFormState
        }
      };
    },
    resetSendingStatus(state, action) {
      return {
        ...state,
        sending: 'pending'
      };
    }
  }
});

export const {
  getReplicationTargetsSuccess,
  getReplicationTargetsFailure,
  getReplicationTargetsStart,
  sendReplicationTargetsSuccess,
  sendReplicationTargetsFailure,
  sendReplicationTargetsStart,
  toggleFormCheckboxes,
  setS3BucketField,
  resetReplicationTargetsForm,
  resetSendingStatus
} = replicationTargetsSlice.actions;
export default replicationTargetsSlice.reducer;
