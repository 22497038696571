export const sanitize = text => {
  if (typeof text !== 'string') {
    return;
  }
  return text
    .trim()
    .replace(/(\b([-()$./\s]+)$)|(\b([-()$./\s]+))/g, function () {
      return arguments[1] ? '' : '_';
    });
};

const sanitizeNamePart = namePart => sanitize(namePart)?.toUpperCase();
export default sanitizeNamePart;
