// React
import React from 'react';
// PropTypes
import PropTypes from 'prop-types';
// Styles
import './cardDetails.css';

const CardDetails = ({ details }) => (
  <>
    {details.map(detail => (
      <div
        key={`card-row-info-${detail.label}`}
        data-testid="card-row-info"
        className="card-row-info epic-font-base-md"
      >
        <div
          key={`${detail.label}-label-col`}
          data-testid="card-row-info--label"
          className="bootstrap-col-md-2 label"
        >
          {detail.label}
          {detail.helper}
        </div>

        <div
          key={`${detail.label}-label-text`}
          data-testid="card-row-info--label-text"
          className="bootstrap-col-md text"
        >
          {detail.description}
        </div>
      </div>
    ))}
  </>
);

CardDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      /** Card's detail label */
      label: PropTypes.string,
      /** Card's detail text */
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  ).isRequired
};

export default CardDetails;
