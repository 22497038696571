import { connect } from 'react-redux';
import TablePrivLayout from './TablePrivLayout';
import {
  resetSelectedPrivilege,
  getPrivileges,
  deletePrivilege,
  setOnDeleteRecordConfirmation
} from '../../actions/privileges';

import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';
import { getTables, setSelectedTable } from '../../actions/tableGroupDetails';

import { removeConfirmation } from '../../actions/core';

const mapDispatchToProps = {
  resetSelectedPrivilege,
  getPrivileges,
  deletePrivilege,
  setOnDeleteRecordConfirmation,
  removeConfirmation,
  setSelectedTableGroup,
  getTableGroups,
  getTables,
  setSelectedTable
};

const mapStateToProps = state => {
  return {
    tableGroups: state.tableGroupLayout.tableGroups,
    tablePrivileges: state.privileges.tablePrivileges || [],
    selectedTable: state.tableGroupDetails.selectedTable,
    userTablePrivileges: state.privileges.userTablePrivileges || [],
    userTableGroupPrivileges: state.privileges.userTableGroupPrivileges || [],
    totalTablePrivileges: state.privileges.totalTablePrivileges || 0,
    totalTableGroupPrivileges: state.privileges.totalTableGroupPrivileges || 0,
    dataViewMode: state.core.dataViewMode
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePrivLayout);
