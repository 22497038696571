import { connect } from 'react-redux';
import FormTableGroup from './FormTableGroup';
import {
  setOnSaveRecordConfirmation,
  createTableGroup,
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';
import {
  getConfirmation,
  removeConfirmation,
  addNotification
} from '../../actions/core';

const mapDispatchToProps = {
  setOnSaveRecordConfirmation,
  createTableGroup,
  setSelectedTableGroup,
  getTableGroups,
  removeConfirmation,
  getConfirmation,
  addNotification
};

const mapStateToProps = state => {
  return {
    modal: state.core.modal,
    tableGroups: state.tableGroupLayout.tableGroups
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormTableGroup);
