import { connect } from 'react-redux';
import FormSnowflake from './FormSnowflake';
import * as Actions from '../../actions/privileges';

import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';
import { setSelectedTable } from '../../actions/tableGroupDetails';

const mapDispatchToProps = {
  ...Actions,
  setSelectedTableGroup,
  getTableGroups,
  setSelectedTable
};

const mapStateToProps = state => {
  return {
    level: 'TABLE',
    selectedTable: state.tableGroupDetails.selectedTable,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSnowflake);
