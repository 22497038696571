import {
  SET_APP_VIEW_MODE,
  SET_IS_LOADING,
  SET_DATA_VIEW_MODE,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  ADD_CONFIRMATION,
  REMOVE_CONFIRMATION,
  REMOVE_MODAL,
  ADD_MODAL,
  SET_IS_ADMIN,
  SET_AUTH_SERVICE
} from './actionTypes';
import store from '../store/store';
import { setLocale, I18n } from 'react-redux-i18n';
import { fetchIsAdmin } from '../services/privilegeService';

export const setDataViewMode =
  (dataViewMode = 'table') =>
  dispatch => {
    const action = { type: SET_DATA_VIEW_MODE, dataViewMode };
    dispatch(action);
  };

export const setAuthService = authService => dispatch => {
  const action = { type: SET_AUTH_SERVICE, authService };
  dispatch(action);
};

export const getAuthServiceFromStore = () => {
  return store.getState().core.authService;
};

export const setAppViewMode =
  (appViewMode = 'dataManager') =>
  dispatch => {
    const action = { type: SET_APP_VIEW_MODE, appViewMode };
    dispatch(action);
  };

export const setIsLoading =
  (isLoading = false) =>
  dispatch => {
    const action = { type: SET_IS_LOADING, isLoading };
    dispatch(action);
  };

export const setLanguage = language => {
  store.dispatch(setLocale(language));
};

export const addNotification = notification => dispatch => {
  const action = { type: ADD_NOTIFICATION, notification };
  dispatch(action);
};

export const removeNotification = () => dispatch => {
  const action = { type: REMOVE_NOTIFICATION };
  dispatch(action);
};

export const addConfirmation = confirmation => dispatch => {
  const action = { type: ADD_CONFIRMATION, confirmation };
  dispatch(action);
};

export const removeConfirmation = () => dispatch => {
  const action = { type: REMOVE_CONFIRMATION };
  dispatch(action);
};

export const addModal = modal => dispatch => {
  const action = { type: ADD_MODAL, modal };
  dispatch(action);
};

export const removeModal = () => dispatch => {
  const action = { type: REMOVE_MODAL };
  dispatch(action);
};

export const setIsOverviewFormEditingNotification = () => dispatch => {
  const notification = {
    notType: 'info',
    isModal: false,
    isToast: true,
    title: I18n.t('core.notification.isEnableFormEditingTitle'),
    message: I18n.t('core.notification.isEnableFormEditingMessage')
  };
  dispatch(addNotification(notification));
};

export const setIsOverviewFormCreatingNotification = () => dispatch => {
  const notification = {
    notType: 'info',
    isModal: false,
    isToast: true,
    title: I18n.t('core.notification.isEnableFormCreatingTitle'),
    message: I18n.t('core.notification.isEnableFormCreatingMessage')
  };
  dispatch(addNotification(notification));
};

export const setIsOverviewTableEditingNotification = () => dispatch => {
  const notification = {
    notType: 'info',
    isModal: false,
    isToast: true,
    title: I18n.t('core.notification.isEnableTableEditingTitle'),
    message: I18n.t('core.notification.isEnableTableEditingMessage')
  };
  dispatch(addNotification(notification));
};

export const getConfirmation = () => (dispatch, getState) => {
  return getState().core.confirmation;
};

export const getIsAdmin = () => async (dispatch, getState) => {
  const isAdmin = await fetchIsAdmin();
  if (getState().core.isAdmin !== isAdmin) {
    const action = { type: SET_IS_ADMIN, isAdmin };
    dispatch(action);
  }
};
