import React from 'react';
import { Modal, Button } from '../../index';

const ModalConfirmation = props => {
  const { confirmation } = props;
  const {
    showIcon = false,
    closeButton,
    question,
    isConfirmation,
    acceptCallback,
    cancelCallback,
    title,
    children,
    acceptButtonLabel = 'Accept',
    cancelButtonLabel = 'Cancel'
  } = confirmation;
  if (isConfirmation !== true) return null;
  return (
    <div
      data-testid="modal-confirmation"
      className="fontBrand modal-overlay-wrapper"
    >
      <Modal
        show
        closeButton={closeButton}
        swoosh={showIcon}
        title={title}
        onClick={cancelCallback}
        onClose={cancelCallback}
      >
        <div className="epic-font-base modal-message-wrapper">{question}</div>
        {children}
        <div className="modal-button-wrapper">
          <Button onClick={acceptCallback}>{acceptButtonLabel}</Button>
          <Button onClick={cancelCallback} inverse>
            {cancelButtonLabel}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalConfirmation;
