import React, { Component } from 'react';
import { PerspectiveCamera, Scene, Sprite } from 'three/build/three.module';
import { CanvasRenderer, SpriteCanvasMaterial } from './renderer';

class Wave extends Component {
  constructor(props) {
    super(props);
    this.amountX = 50;
    this.amountY = 50;
    this.count = 0;
    this.particles = [];
    this.animationFrameId = undefined;

    this.scene = new Scene();
    this.material = new SpriteCanvasMaterial({
      color: 0,
      program: function (context) {
        context.beginPath();
        context.arc(0, 0, 0.5, 0, Math.PI * 2, true);
        context.fill();
      }
    });
  }

  componentDidMount() {
    this.camera = new PerspectiveCamera(
      75,
      this.el.offsetWidth / this.el.offsetHeight,
      1,
      10000
    );
    this.camera.position.z = 1000;
    this.init();
    this.animate();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationFrameId);
    this.el.removeChild(this.renderer.domElement);
    this.particles.forEach(particle => this.scene.remove(particle));
    this.material.dispose();
    this.scene.children = [];
    this.renderer.domElement = null;
    this.el = null;
    this.scene = undefined;
    this.camera = null;
    this.material = undefined;
    window.removeEventListener('resize', () => this.updateComponentPosition());
  }

  updateComponentPosition() {
    if (this.camera === null) return;
    this.camera.aspect = this.el.offsetWidth / this.el.offsetHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.el.offsetWidth, this.el.offsetHeight);
  }

  init() {
    const separation = 150;
    let i = 0;
    for (let ix = 0; ix < this.amountX; ix++) {
      for (let iy = 0; iy < this.amountY; iy++) {
        const particle = (this.particles[i++] = new Sprite(this.material));
        particle.position.x = ix * separation - (this.amountX * separation) / 2;
        particle.position.z = iy * separation - (this.amountY * separation) / 2;
        this.scene.add(particle);
      }
    }
    this.renderer = new CanvasRenderer({ alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.el.offsetWidth, this.el.offsetHeight);

    this.el.appendChild(this.renderer.domElement);
    window.addEventListener('resize', () => this.updateComponentPosition());
  }

  animate() {
    this.animationFrameId = window.requestAnimationFrame(() => this.animate());
    this.camera.position.x += (-413.5 - this.camera.position.x) * 0.05;
    this.camera.position.y += (313 - this.camera.position.y) * 0.05;
    this.camera.lookAt(this.scene.position);
    let i = 0;
    for (let ix = 0; ix < this.amountX; ix++) {
      for (let iy = 0; iy < this.amountY; iy++) {
        const particle = this.particles[i++];
        particle.position.y =
          Math.sin((ix + this.count) * 0.3) * 200 +
          Math.sin((iy + this.count) * 0.5) * 25;
        particle.scale.x = particle.scale.y =
          (Math.sin((ix + this.count) * 0.3) + 1) * 4 +
          (Math.sin((iy + this.count) * 0.5) + 1) * 3;
      }
    }
    this.renderer.render(this.scene, this.camera);
    this.count += 0.1;
  }

  render() {
    return <div ref={el => (this.el = el)} {...this.props} />;
  }
}
export default Wave;
