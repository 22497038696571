export const dataTypesOptions = [
  { value: 'DATE', label: 'DATE' },
  { value: 'DATETIME', label: 'DATETIME' },
  { value: 'DECIMAL', label: 'DECIMAL' },
  { value: 'INTEGER', label: 'INTEGER' },
  { value: 'TEXT', label: 'TEXT' },
  { value: 'UUID', label: 'UUID' }
];

export const trimmingOptions = [
  { value: 'BOTH', label: 'BOTH' },
  { value: 'LEFT', label: 'LEFT' },
  { value: 'RIGHT', label: 'RIGHT' },
  { value: 'NONE', label: 'NONE' }
];

export const letterCaseOptions = [
  { value: 'UPPERCASE', label: 'UPPERCASE' },
  { value: 'LOWERCASE', label: 'LOWERCASE' },
  { value: 'NONE', label: 'NONE' }
];

export const loadingModeOptions = [
  {
    name: 'Update',
    description:
      "Update records with the existing primary key and insert new records that don't match any primary key in the file."
  },
  {
    name: 'Delete & Replace',
    description:
      'Delete ALL current content in the table with the file you will upload. All current records in the table will be deleted and replaced with new data.'
  }
];

export const delimiterOptions = [
  { value: '\t', label: 'Tab' },
  { value: ',', label: 'Comma (,)' },
  { value: ';', label: 'Semi-colon (;)' },
  { value: '|', label: 'Pipe (|)' }
];

export const dateFormatOptions = [
  {
    value: 'ISO',
    label: 'ISO Standard (2009-02-17T01:30:00Z)'
  },
  {
    value: 'mm-dd-yyyy',
    label: 'Month Day Year (02/17/2009), (02-17-2009), (2/17/2009), (2-17-2009)'
  },
  {
    value: 'dd-mm-yyyy',
    label: 'Day Month Year (17/02/2009), (17-02-2009), (17/2/2009), (17-2-2009)'
  },
  {
    value: 'yyyy-mm-dd',
    label: 'Year Month Day (2009/02/07), (2009-02-07), (2009/2/7), (2009-2-7)'
  },
  {
    value: 'yyyy-dd-mm',
    label: 'Year Day Month (2009/07/02), (2009-07-02), (2009/7/2), (2009-7-2)'
  }
];

export const generatedKeyOptions = [
  {
    value: true,
    label: "Yes, generate a key. I don't have the key right now in the data."
  },
  {
    value: false,
    label: 'No, do not generate a key. I already have a key within my data.'
  }
];
