import _ from 'lodash';
import {
  GET_ROWS_SUCCESS,
  UPDATE_ROW,
  SET_OVERVIEW_TABLE_PAGINATION,
  SET_OVERVIEW_TABLE_SORTED,
  SET_OVERVIEW_TABLE_FILTERED,
  SET_IS_OVERVIEW_TABLE_EDITING,
  SET_SELECTED_ROW,
  APPEND_ROWS,
  RESET_ROWS,
  ADD_ROW_TO_DELETION_LIST,
  REMOVE_FROM_DELETION_LIST,
  RESET_ROW_TO_DELETION_LIST,
  ADD_ROW_TO_CHANGES_LIST,
  RESET_ROW_TO_CHANGES_LIST,
  ADD_OVERVIEW_TABLE_ERRORS,
  RESET_OVERVIEW_TABLE_ERRORS,
  SET_IS_TOP_REACHED,
  RESET_SELECTED_ROW
} from './actionTypes';
import { TABLE_MAX_ROW_NUMBERS_TO_FETCH_SMALL } from '../constants/defaultValues';
import recordsService from '../services/recordsService/recordsService';
import { downloadFile } from '../services/exportService';

import {
  setIsLoading,
  addNotification,
  removeConfirmation,
  addConfirmation,
  setDataViewMode
} from './core';
import { I18n } from 'react-redux-i18n';
import DataTransformer from '../lib/util/DataTransformer';
import { resetOverviewForm } from './overviewForm';
import { sendFileStatistics, finalizeWizard } from './wizards';
import sendAnalyticsEvent from '../lib/util/sendAnalyticsEvent';

// Fetch Rows from the Backend (clean the previous Rows)
export const getRows = () => async (dispatch, getState) => {
  if (getState().tableGroupDetails.selectedTable === null) {
    return;
  }
  dispatch(setIsLoading(true));

  try {
    const isTableDataViewMode = getState().core.dataViewMode !== 'table';
    const response = await recordsService('get', null, getState);

    if (response.status === 200) {
      if (isTableDataViewMode) {
        dispatch(setDataViewMode('table'));
      }
      dispatch(getRowsSuccess(response.data.records));
      dispatch(setIsTopReached(response.data.records.length));
      dispatch(setSelectedRow(response.data.records[0], 0));
    } else {
      getRowsFailure(dispatch);
    }
    dispatch(setIsLoading(false));
  } catch (err) {
    getRowsFailure(dispatch);
  }
};

// Download rows from the backend to a file (excel or csv)
export const downloadRows =
  (format = 'xlsx') =>
  async (dispatch, getState) => {
    if (getState().tableGroupDetails.selectedTable === null) return;
    dispatch(setIsLoading(true));
    try {
      await downloadFile(getState, format);

      dispatch(setIsLoading(false));
    } catch (err) {
      getRowsFailure(dispatch);
    }
  };

export const cleanOverviewTable = () => dispatch => {
  dispatch(resetRowToDeletionList());
  dispatch(resetRowChangesList());
  dispatch(resetOverviewTableError());
  dispatch(resetSelectedRow());
};

// Append Rows from the backend (keep the previous Rows and add the new ones)
export const appendRows = () => async (dispatch, getState) => {
  if (getState().tableGroupDetails.selectedTable === null) return;
  dispatch(setIsLoading(true));
  try {
    const { rows } = getState().overviewTable;

    const count = TABLE_MAX_ROW_NUMBERS_TO_FETCH_SMALL;
    const anchor = rows.length === 0 ? 0 : rows.length / count;
    const response = await recordsService('get', null, getState, null, anchor);

    if (response.status === 200) {
      dispatch(appendRowsSuccess(response.data.records));
      dispatch(setIsTopReached(response.data.records.length));
    } else {
      getRowsFailure(dispatch);
    }
    dispatch(setIsLoading(false));
  } catch (err) {
    getRowsFailure(dispatch);
  }
};

// Set the flag when all records from the source table have been fetched
export const setIsTopReached = responseLength => {
  const isTopReached =
    responseLength < TABLE_MAX_ROW_NUMBERS_TO_FETCH_SMALL ? true : false;
  return { type: SET_IS_TOP_REACHED, isTopReached };
};

// Set the flag for the edition mode
export const setIsOverviewTableEditing =
  (isOverviewTableEditing, showNotification = true) =>
  dispatch => {
    const action = {
      type: SET_IS_OVERVIEW_TABLE_EDITING,
      isOverviewTableEditing
    };
    dispatch(action);
    if (showNotification === true)
      dispatch(
        addNotification(
          setIsOverviewTableEditingNotification(isOverviewTableEditing)
        )
      );
    if (isOverviewTableEditing === true) {
      dispatch(cleanOverviewTable());
    }
  };

// Cancel a creation or modification action
export const cancelOverviewTableAction = () => dispatch => {
  dispatch(setIsOverviewTableEditing(false));
  dispatch(getRows());
};

// Cancel a deletion on confirmation
export const cancelDeleteOnConfirmation = () => dispatch => {
  dispatch(removeConfirmation());
  dispatch(resetRowToDeletionList());
};

// Accept confirmation when saving records
export const saveOverviewTableAction = () => async (dispatch, getState) => {
  dispatch(removeConfirmation());
  dispatch(resetOverviewTableError());
  // Get the list of changes
  let rowsChangesList = getState().overviewTable.rowsChangesList;
  if (rowsChangesList.length === 0) return;
  // Getting the Table's properties
  const properties = getState().tableGroupDetails.selectedTable.properties;
  const dataTransformer = new DataTransformer(rowsChangesList, properties);
  dataTransformer.transformRows();

  const errors = dataTransformer.getErrors();
  if (errors.length > 0) {
    dispatch(addOverviewTableError(errors));
    const notification = {
      isModal: true,
      title: 'Invalid fields',
      message:
        'At least one field is not valid, please check the data and try again. For more information hover over the Fields highlighted with the red border to see what is the reason why is invalid.'
    };
    dispatch(addNotification(notification));
  } else {
    await processSave(dataTransformer, dispatch, getState);
  }
};

// Accept confirmation when deleting records
export const deleteOverviewTableAction = () => async (dispatch, getState) => {
  const properties = getState().tableGroupDetails.selectedTable.properties;
  const rowsDeletionList = getState().overviewTable.rowsDeletionList;
  if (rowsDeletionList.length === 0) {
    return;
  }
  const dataTransformer = new DataTransformer(rowsDeletionList, properties);
  dispatch(removeConfirmation());
  dispatch(resetOverviewTableError());

  dataTransformer.generateRowsToDelete();

  const rowsToDelete = dataTransformer.getRowsToDelete();

  if (rowsToDelete.length > 0)
    await processDelete(rowsToDelete, dispatch, getState);
};

// Save the changes into the backend
export const processSave = async (dataTransformer, dispatch, getState) => {
  const payload = dataTransformer.getTransformedRows();
  if (payload.length === 0) return;

  dispatch(setIsLoading(true));
  try {
    const response = await recordsService('save', payload, getState);
    switch (response.status) {
      case 200: // record created
      case 204: // record updated
        dispatch(setIsOverviewTableEditing(false, false));
        await dispatch(getRows());
        dispatch(addNotification(setUpdateSuccessNotification()));
        break;
      default:
        dispatch(addNotification(setSaveFailureNotification()));
    }
  } catch (error) {
    // TODO initialize the redux form with the payload
    // set Notification with the error
    dispatch(addNotification(setSaveFailureNotification(error)));
  }
  dispatch(setIsLoading(false));
};

// Delete records from the backend
export const processDelete = async (rowsToDelete, dispatch, getState) => {
  const payload = rowsToDelete;
  dispatch(setIsLoading(true));
  try {
    const response = await recordsService('delete', payload, getState);
    if (response.status === 204) {
      dispatch(cleanOverviewTable());
      await dispatch(getRows());
      dispatch(addNotification(setDeleteSuccessNotification()));
    }
  } catch (error) {
    // TODO initialize the redux form with the payload
    // set Notification with the error
    dispatch(addNotification(setDeleteFailureNotification(error)));
  }

  dispatch(setIsLoading(false));
};

// Upload data using the wizard
export const processUpload = () => async (dispatch, getState) => {
  const wizards = getState().wizards;
  const { validation, core } = wizards;
  const { formattedData } = validation;
  const { chunckSize } = core;

  dispatch(setIsLoading(true));

  const analyticsEvent = `xls_upload_${core.dataloadingMode}`;

  sendAnalyticsEvent(analyticsEvent, {
    module_id: 'upload_wizard',
    screen_id: 'upload_wizard'
  });

  try {
    // Chunk the data depending on the cells limit, for each chunck is created
    // a payload
    const payloads = [];
    let startChunck = 0;
    let endChunck = chunckSize;

    do {
      const chunkData = formattedData.slice(startChunck, endChunck);
      payloads.push(chunkData);
      startChunck = startChunck + chunkData.length;
      endChunck = endChunck + chunkData.length;
    } while (startChunck < formattedData.length);

    // Chunk the payloads depending on the maximum number of requests allowed
    // for each payload is created a request and a response
    let responses = [];
    let startPayloadsChunck = 0;
    let endPayloadsChunck = 6;

    const dataLoadingMode = getState().wizards.core.dataloadingMode;
    let method = dataLoadingMode === 'update' ? 'XMERGE' : 'XOVERWRITE';

    do {
      const chunkPayloads = payloads.slice(
        startPayloadsChunck,
        endPayloadsChunck
      );
      const response = await recordsService(
        'upload',
        chunkPayloads,
        getState,
        method
      );
      responses = responses.concat(response);
      method = 'XMERGE';
      startPayloadsChunck = startPayloadsChunck + chunkPayloads.length;
      endPayloadsChunck = endPayloadsChunck + chunkPayloads.length;
    } while (startPayloadsChunck < payloads.length);

    const successResponses = responses.filter(res => {
      return res.status === 200 || res.status === 201 || res.status === 204;
    });
    const errorResponses = responses.filter(res => {
      return (
        res.status === 500 ||
        res.status === 502 ||
        res.status === 504 ||
        res.status === 403 ||
        res.status === undefined
      );
    });

    let finalErrorResponses = [];
    if (successResponses.length < payloads.length) {
      finalErrorResponses = _.uniq(
        _.map(errorResponses, ele => {
          if (ele.statusText !== '') {
            return ele.statusText;
          } else {
            return ele.data;
          }
        })
      );
    }
    await dispatch(sendFileStatistics());
    await dispatch(getRows());

    dispatch(
      finalizeWizard({
        successResponses: successResponses,
        errorResponses: finalErrorResponses
      })
    );
  } catch (error) {
    dispatch(addNotification(setUploadFailureNotification(error)));
  }

  dispatch(setIsLoading(false));
};

// Add errors to the error's list of the table
export const addOverviewTableError = error => dispatch => {
  const action = { type: ADD_OVERVIEW_TABLE_ERRORS, error };
  dispatch(action);
};

// Reset the list of errors for the table
export const resetOverviewTableError = () => dispatch => {
  const action = { type: RESET_OVERVIEW_TABLE_ERRORS };
  dispatch(action);
};

// Set the Notification for the succeeded save
export const setUpdateSuccessNotification = () => ({
  notType: 'success',
  isModal: false,
  isToast: true,
  title: I18n.t('overviewTable.notification.updateSuccededTitle'),
  message: I18n.t('overviewTable.notification.updateSuccededMessage')
});

// Set the Notification for the succeeded deletion
export const setDeleteSuccessNotification = () => ({
  notType: 'success',
  isModal: false,
  isToast: true,
  title: I18n.t('overviewTable.notification.deleteSuccededTitle'),
  message: I18n.t('overviewTable.notification.deleteSuccededMessage')
});

// Set the Notification for the failed upload
export const setUploadFailureNotification = error => {
  const errorMessage = typeof error === 'string' ? error : error.response.data;

  return {
    isModal: true,
    title: I18n.t('overviewTable.notification.uploadFailureTitle'),
    message: I18n.t('overviewTable.notification.uploadFailureMessage', {
      message: errorMessage
    })
  };
};

// Set the Notification for the failed save
export const setSaveFailureNotification = error => {
  const errorMessage = error || 'Unable to save the records';
  return {
    isModal: true,
    title: I18n.t('overviewTable.notification.saveFailureTitle'),
    message: I18n.t('overviewTable.notification.saveFailureMessage', {
      message: errorMessage
    })
  };
};

// Set the Notification for the failed save
export const setDeleteFailureNotification = error => {
  const errorMessage = typeof error === 'string' ? error : error.response.data;
  return {
    isModal: true,
    title: I18n.t('overviewTable.notification.deleteFailureTitle'),
    message: I18n.t('overviewTable.notification.deleteFailureMessage', {
      message: errorMessage
    })
  };
};

export const setIsOverviewTableEditingNotification = isOverviewTableEditing => {
  if (isOverviewTableEditing === true) {
    return {
      notType: 'info',
      isModal: false,
      isToast: true,
      title: I18n.t('overviewTable.notification.enableEditingTitle'),
      message: I18n.t('overviewTable.notification.enableEditingMessage')
    };
  } else {
    return {
      notType: 'info',
      isModal: false,
      isToast: true,
      title: I18n.t('overviewTable.notification.disableEditingTitle'),
      message: I18n.t('overviewTable.notification.disableEditingMessage')
    };
  }
};

// Set the Confirmation for the Save
export const setOnSaveRecordConfirmation = confirmation => async dispatch => {
  await dispatch(checkRowChangeBeforeSave());
  await dispatch(
    addConfirmation({
      id: 'table-save',
      question: I18n.t('overviewTable.confirmation.saveNewRecordQuestion'),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};

// Set the Confirmation for the Delete
export const setOnDeleteRecordConfirmation = confirmation => dispatch => {
  dispatch(
    addConfirmation({
      id: 'table-delete',
      question: I18n.t('overviewTable.confirmation.deleteRecordQuestion'),
      acceptCallback: confirmation.acceptCallback,
      cancelCallback: confirmation.cancelCallback
    })
  );
};

export const resetRows = () => ({
  type: RESET_ROWS
});

export const resetRowToDeletionList = () => dispatch => {
  const action = {
    type: RESET_ROW_TO_DELETION_LIST
  };
  dispatch(action);
};

export const resetSelectedRow = () => dispatch => {
  const action = {
    type: RESET_SELECTED_ROW
  };
  dispatch(action);
};

export const resetRowChangesList = () => dispatch => {
  const action = {
    type: RESET_ROW_TO_CHANGES_LIST
  };
  dispatch(action);
};

export const getRowsSuccess = rows => ({
  type: GET_ROWS_SUCCESS,
  rows
});

export const appendRowsSuccess = rows => ({
  type: APPEND_ROWS,
  rows
});

export const addRowToDeletionList = cellInfo => dispatch => {
  const row = cellInfo.original;
  const action = { type: ADD_ROW_TO_DELETION_LIST, row };
  dispatch(action);
};

export const removeRowFromDeletionList = cellInfo => dispatch => {
  const row = cellInfo.original;
  const action = { type: REMOVE_FROM_DELETION_LIST, row };
  dispatch(action);
};

export const getRowsFailure = dispatch => {
  dispatch(setIsLoading(false));
  dispatch(addNotification(setGetRowsFailNotification()));
};

export const checkRowChangeBeforeSave = () => async (dispatch, getState) => {
  const selectedRowPosition = getState().overviewTable.selectedRowPosition;

  if (selectedRowPosition !== null && !isNaN(selectedRowPosition)) {
    const overviewTableSelectedRow = {
      ...getState().overviewTable.selectedRow
    };
    const rows = getState().overviewTable.rows;
    const nextRowState = rows[selectedRowPosition];
    // If the row changed then the row is added to the list of rows with changes
    if (nextRowState && !_.isEqual(overviewTableSelectedRow, nextRowState))
      await dispatch(addRowToChangesList(nextRowState));
  }
};

export const updateRow = (cellInfo, value) => dispatch => {
  const action = { type: UPDATE_ROW, cellInfo, value };
  dispatch(action);
};

export const setOverviewTablePagination = settings => dispatch => {
  const action = { type: SET_OVERVIEW_TABLE_PAGINATION, settings };
  dispatch(action);
};

export const setOverviewTableSorted =
  (sorted = []) =>
  dispatch => {
    const action = { type: SET_OVERVIEW_TABLE_SORTED, sorted };
    dispatch(action);
  };

export const setOverviewTableFiltered =
  (filtered = []) =>
  dispatch => {
    const action = { type: SET_OVERVIEW_TABLE_FILTERED, filtered };
    dispatch(action);
    dispatch(getRows());
  };

export const setSelectedRow =
  (newSelectedRow, newSelectedRowPosition) => (dispatch, getState) => {
    const overviewTableSelectedRow = {
      ...getState().overviewTable.selectedRow
    };

    if (
      overviewTableSelectedRow &&
      _.isEqual(overviewTableSelectedRow, newSelectedRow)
    )
      return;

    const isOverviewTableEditing =
      getState().overviewTable.isOverviewTableEditing;
    const selectedRowPosition = getState().overviewTable.selectedRowPosition;
    // If the table is in edition mode and there was a previous selected row
    // and the selected row's position has changed then it is necesary to check
    // if the row has changed
    if (
      isOverviewTableEditing === true &&
      selectedRowPosition !== null &&
      selectedRowPosition !== newSelectedRowPosition
    ) {
      const rows = getState().overviewTable.rows;
      const nextRowState = rows[selectedRowPosition];
      // If the row changed then the row is added to the list of rows with changes
      if (!_.isEqual(overviewTableSelectedRow, nextRowState))
        dispatch(addRowToChangesList(nextRowState));
    }

    dispatch(resetOverviewForm());
    const action = {
      type: SET_SELECTED_ROW,
      newSelectedRow,
      newSelectedRowPosition
    };
    dispatch(action);
  };

export const addRowToChangesList = row => dispatch => {
  const action = { type: ADD_ROW_TO_CHANGES_LIST, row };
  dispatch(action);
};

export const showNoSortingFilteringAllowNotification = () => dispatch => {
  dispatch(addNotification(setNoSortingFilteringAllowNotification()));
};

export const initSelectedRow = () => (dispatch, getState) => {
  const rows = getState().overviewTable.rows;
  if (rows.length > 0) {
    const newSelectedRow = rows[0];
    dispatch(setSelectedRow(newSelectedRow, 0));
  }
};

// Set the Notification for when geting rows fails
export const setGetRowsFailNotification = () => ({
  isModal: true,
  title: I18n.t('overviewTable.notification.getRowsFailedTitle'),
  message: I18n.t('overviewTable.notification.getRowsFailedMessage')
});

// Set the Notification for when the Sorting and Filtering are not allowed
// because the table is in edition mode
export const setNoSortingFilteringAllowNotification = () => ({
  isModal: true,
  title: I18n.t('overviewTable.notification.noSortingFilteringAllowTitle'),
  message: I18n.t('overviewTable.notification.noSortingFilteringAllowMessage')
});

export const getGeneratedCellsCount = () => (_dispatch, getState) => {
  const selectedTable = getState().tableGroupDetails.selectedTable;
  return selectedTable.properties.filter(prop => prop.valueGeneratorName)
    .length;
};
