import React from 'react';
const AsideLeftTableGroupSection = props => (
  <div className="table-groups-tiles">
    <div className="table-groups-tiles-div">
      <div className="table-groups-counter" data-testid="tables-counter">
        {props.totalCount}
      </div>
      <div className="content-section-row-title-grey">Tables</div>
    </div>
    <div className="table-groups-tiles-div">
      <div
        className="table-groups-counter"
        data-testid="table-group-privileges"
      >
        {props.totalTableGroupPrivileges}
      </div>
      <div className="content-section-row-title-grey">Privileges</div>
    </div>
    <div className="table-groups-tiles-div">
      <div className="table-groups-counter" data-testid="table-group-role">
        {props.currentUserTableGroupRole}
      </div>
      <div className="content-section-row-title-grey">role</div>
    </div>
  </div>
);
export default AsideLeftTableGroupSection;
