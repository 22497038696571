/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import { Result as AntdResult, Timeline } from 'antd';
import { Modal, Button, RouterLink } from '../../index';

const WizardResult = props => (
  <div data-testid="wizard-result" className="fontBrand modal-overlay-wrapper">
    <Modal show logo title={props.title} onClick={props.finishCallback}>
      <AntdResult
        key="antd-result-upload-wizard"
        status={props.status}
        subTitle={props.subTitle}
        extra={[
          <Timeline key="timeline-upload-wizard">
            {props.timeLineItems}
          </Timeline>
        ]}
      />
      <div className="div-buttons-wrapper">
        <Button onClick={props.acceptCallback}>
          {props.acceptButtonTitle}
        </Button>
        <RouterLink href={props.path}>
          <Button onClick={props.finishCallback}>Finish</Button>
        </RouterLink>
      </div>
    </Modal>
  </div>
);

export default WizardResult;
