import { endPoints } from '../constants/endPoints';
import { http } from '../lib/util/http';

const tableGroupEndpoint = endPoints.tableGroups.tableGroups;

export const fetchTableGroups = async (
  strFilter = '',
  strSort = '&sort=name'
) => {
  const uriComplement =
    strFilter !== '' ? `/${strFilter}` : '?count=500&anchor=0';

  const tableGroupsUrl = `${tableGroupEndpoint}${uriComplement}${strSort}`;
  const response = await http('get', tableGroupsUrl, {});

  return response.data;
};

export const createTableGroup = async tableGroup => {
  const uri = `${tableGroupEndpoint}`;

  return http('post', uri, tableGroup);
};
