import { connect } from 'react-redux';
import TableGroupDetails from './TableGroupDetails';
import { getTables, setSelectedTable } from '../../actions/tableGroupDetails';
import { getPrivileges } from '../../actions/privileges';
import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';

const mapDispatchToProps = {
  setSelectedTableGroup,
  getTableGroups,
  getTables,
  getPrivileges,
  setSelectedTable
};

const mapStateToProps = state => {
  return {
    tables: state.tableGroupDetails.tables || [],
    totalCount: state.tableGroupDetails.totalCount || 0,
    currentUserPrivileges: state.privileges.userTableGroupPrivileges || [],
    privileges: state.privileges.tableGroupPrivileges || [],
    totalPrivilegesCount: state.privileges.totalTableGroupPrivileges || 0,
    tableGroups: state.tableGroupLayout.tableGroups,
    selectedTableGroup: state.tableGroupLayout.selectedTableGroup
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableGroupDetails);
