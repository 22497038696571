import { endPoints } from '../constants/endPoints';
import { http } from '../lib/util/http';

export const fetchPrivileges = async (tableGroup, table, strFilter = '') => {
  const uriTableGroup = `/tablegroup/${tableGroup?.name}`;
  const uriComplement = table?.name
    ? `${uriTableGroup}/table/${table?.name}`
    : uriTableGroup;
  const uri = `${endPoints.privileges.privileges}${uriComplement}${strFilter}`;
  const response = await http('get', uri, {});
  return response.data;
};

export const fetchIsAdmin = async () => {
  const privilegeUrl = `${endPoints.privileges.admin}`;
  const response = await http('get', privilegeUrl, {});
  return response.data;
};

export const fetchUsers = async (strFilter = '', anchor = 'na') => {
  const strAnchor = anchor !== 'na' ? `&anchor=${anchor}` : '';
  const uriComplement = `?startsWith=${strFilter}&count=50${strAnchor}`;
  const uri = `${endPoints.privileges.users}${uriComplement}`;
  const response = await http('get', uri, {});
  const users =
    response.data && response.data.items
      ? response.data.items.map(user => user.email)
      : [];

  return { users: users, anchor: response.data.anchor };
};

export const upsertPrivilege = async (privilege, tableGroup, table) => {
  const uriTableGroup = `tablegroup/${tableGroup?.name}`;
  const uriComplement = table?.name
    ? `${uriTableGroup}/table/${table?.name}`
    : uriTableGroup;

  const uri = `${endPoints.privileges.privileges}/${uriComplement}`;
  return http('post', uri, privilege);
};

export const grantSnowflakePrivilege = async (privilege, tableGroup) => {
  const uriTableGroup = `tablegroup/${tableGroup}`;

  const uri = `${endPoints.privileges.snowflake}/${uriTableGroup}`;
  return http('post', uri, privilege);
};

export const findSnowflakePrivilege = async (tableGroup, table) => {
  const uriTable = `tablegroup/${tableGroup}/table/${table}`;

  const uri = `${endPoints.privileges.snowflake}/${uriTable}`;
  return http('get', uri);
};

export const deletePrivilege = async (privilege, tableGroup, table) => {
  const uriTableGroup = `/tablegroup/${tableGroup?.name}`;
  const uriComplement = table?.name
    ? `${uriTableGroup}/table/${table?.name}`
    : uriTableGroup;
  const uri = `${endPoints.privileges.privileges}${uriComplement}/${privilege.email}`;

  return http('delete', uri);
};
