// react
import React, { useState, useEffect } from 'react';
import { OverviewTableContainer, OverviewFormContainer } from '../index';
import { ModalNotification, LoadingScreen } from '../../components';

const DataLayout = props => {
  const { dataViewMode } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsBusy(true);
      await props.getTableGroups();
      await props.setSelectedTableGroup(props.match.params.tableGroupId);
      await props.setSelectedTable(
        props.match.params.tableGroupId,
        props.match.params.tableId
      );
      await props.getRows();
      setIsLoaded(true);
      setIsBusy(false);
    };
    if (isLoaded === false) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoaded === false) return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props.selectedTableGroup && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push('/tablegroup')}
      />
    );
  }

  // If no table has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (!props.selectedTable && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props.history.push(`/tablegroup/${props.selectedTableGroup.name}`)
        }
      />
    );
  }

  // If the user doesn;t have access to the table
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (
    props.selectedTable &&
    isLoaded === true &&
    props.selectedTable.hasAccess === false
  ) {
    const notification = {
      isNotification: true,
      title: 'Access Denied!',
      message: "The user doesn't have access to the specified table.",
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props.history.push(
            `/tablegroup/${props.selectedTableGroup.name}/table/${props.selectedTable.name}`
          )
        }
      />
    );
  }

  return (
    <>
      <LoadingScreen isLoading={isBusy || props.isLoading} />{' '}
      {dataViewMode === 'form' ? (
        <OverviewFormContainer
          key="overview-form-container"
          {...props}
          currentUserTableRole={props.selectedTable?.role}
        />
      ) : (
        <OverviewTableContainer
          {...props}
          currentUserTableRole={props.selectedTable?.role}
        />
      )}
    </>
  );
};

export default DataLayout;
