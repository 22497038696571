import { connect } from 'react-redux';
import TableGroupPrivLayout from './TableGroupPrivLayout';
import {
  resetSelectedPrivilege,
  getPrivileges,
  deletePrivilege,
  setOnDeleteRecordConfirmation
} from '../../actions/privileges';
import { removeConfirmation } from '../../actions/core';
import {
  setSelectedTableGroup,
  getTableGroups
} from '../../actions/tableGroupLayout';

const mapDispatchToProps = {
  resetSelectedPrivilege,
  getPrivileges,
  deletePrivilege,
  setOnDeleteRecordConfirmation,
  removeConfirmation,
  setSelectedTableGroup,
  getTableGroups
};

const mapStateToProps = state => {
  return {
    tableGroups: state.tableGroupLayout.tableGroups,
    tableGroupPrivileges: state.privileges.tableGroupPrivileges || [],
    currentUserPrivileges: state.privileges.userTableGroupPrivileges || [],
    totalPrivilegesCount: state.privileges.totalTableGroupPrivileges || 0,
    dataViewMode: state.core.dataViewMode
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableGroupPrivLayout);
