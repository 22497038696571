export const amazonRegionsOptions = [
  { value: 'us-east-2', label: 'us-east-2' },
  { value: 'us-east-1', label: 'us-east-1' },
  { value: 'us-west-1', label: 'us-west-1' },
  { value: 'us-west-2', label: 'us-west-2' },
  { value: 'ap-east-1', label: 'ap-east-1' },
  { value: 'ap-south-1', label: 'ap-south-1' },
  { value: 'ap-northeast-3', label: 'ap-northeast-3' },
  { value: 'ap-northeast-2', label: 'ap-northeast-2' },
  { value: 'ap-southeast-1', label: 'ap-southeast-1' },
  { value: 'ap-southeast-2', label: 'ap-southeast-2' },
  { value: 'ap-northeast-1', label: 'ap-northeast-1' },
  { value: 'ca-central-1', label: 'ca-central-1' },
  { value: 'cn-north-1', label: 'cn-north-1' },
  { value: 'cn-northwest-1', label: 'cn-northwest-1' },
  { value: 'eu-central-1', label: 'eu-central-1' },
  { value: 'eu-west-1', label: 'eu-west-1' },
  { value: 'eu-west-2', label: 'eu-west-2' },
  { value: 'eu-west-3', label: 'eu-west-3' },
  { value: 'eu-north-1', label: 'eu-north-1' },
  { value: 'me-south-1', label: 'me-south-1' },
  { value: 'sa-east-1', label: 'sa-east-1' }
];

export const amazonAclOptions = [
  { value: 'bucket-owner-full-control', label: 'bucket-owner-full-control' },
  { value: 'private', label: 'private' },
  { value: 'public-read', label: 'public-read' },
  { value: 'public-read-write', label: 'public-read-write' },
  { value: 'authenticated-read', label: 'authenticated-read' },
  { value: 'log-delivery-write', label: 'log-delivery-write' },
  { value: 'bucket-owner-read', label: 'bucket-owner-read' },
  { value: 'aws-exec-read', label: 'aws-exec-read' }
];

export const replicationTargetNames = {
  snowflake: 'Snowflake',
  s3: 'Amazon S3'
};
