import { connect } from 'react-redux';
import Launchpad from './Launchpad';

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    isAdmin: state.core.isAdmin
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Launchpad);
